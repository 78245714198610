import React from "react";
import { Col, Row } from "react-bootstrap";
import Chip from "../../components/Chip";
import { GreyCard } from "../../components/styled/Generalcard";
import Dateinfo from "../../components/Dateinfo";

const CanceledInitiation = ({ data }) => {
  const { dataProcurement, dataProfile } = data;

  return (
    <>
      <Row>
        <Col className="mb-5">
          <GreyCard>
            <Row className="px-4">
              <Col className="d-flex justify-content-between align-items-center mt-3">
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    color: "#454545",
                  }}
                >
                  Procurement Status
                </p>
              </Col>
            </Row>

            <Row className="m-0">
              <Col className="px-3">
                <hr style={{ background: "#C4C4C4" }} />
              </Col>
            </Row>

            <Row className="px-3 mb-3">
              <Col lg={2}>Date</Col>
              <Col>
                <Dateinfo
                  date={dataProcurement.createdAt}
                  slashed
                  showtime
                ></Dateinfo>
              </Col>
            </Row>
            <Row className="px-3 mb-3">
              <Col lg={2}>Status</Col>
              <Col lg={1} className="p-0 ml-1">
                <div style={{ width: "fit-content" }}>
                  <Chip kind={dataProcurement?.status.toLowerCase()}>
                    <p>
                      {dataProcurement?.status.charAt(0) +
                        dataProcurement?.status.slice(1).toLowerCase()}
                    </p>
                  </Chip>
                </div>
              </Col>
            </Row>
            <Row className="px-3 mb-3">
              <Col lg={2}>User Name</Col>
              <Col>
                {dataProfile.account?.firstName}
                {dataProfile.account?.lastName
                  ? " " + dataProfile.account?.lastName
                  : ""}
              </Col>
            </Row>
            <Row className="px-3 mb-4">
              <Col lg={2}>Reason</Col>
              <Col>
                <span
                  dangerouslySetInnerHTML={{
                    __html: dataProcurement.cancelReason,
                  }}
                />
              </Col>
            </Row>
          </GreyCard>
        </Col>
      </Row>
    </>
  );
};

export default CanceledInitiation;
