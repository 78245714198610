import React, { useState, useEffect, useImperativeHandle, useRef } from "react"
import { Col, Row, Form, InputGroup } from "react-bootstrap"
import { GreyCard } from "../components/styled/Generalcard"
import Buttonaction from "../components/Buttonaction"
import AuctionBanner from "../assets/images/AuctionBanner.png"
import ErrorField from "../components/Errorfield"
import Modal from "react-modal"
import IconResendOtp from "../assets/images/023-mail-20.svg"
import styled from "styled-components"
import ButtonTimer from "../components/ButtonTimer"
import { useDispatch } from "react-redux"
import { login, requestOTP } from "../redux/action/authAction"
import toast from "react-hot-toast"

export default function Vendorlogin() {
   const dispatch = useDispatch()
   const [errors, setErrors] = useState([])
   const [modalResendOtp, setModalResendOtp] = useState(false)
   const [otp, setOtp] = useState([0, 0, 0, 0])
   const otpRefs = useRef([])
   const email = useRef(localStorage.getItem("email"))

   const handleText = (e, index) => {
      if (e.key.toLowerCase() !== "backspace" && e.key.toLowerCase() !== "tab" && index !== 3) {
         otpRefs.current[index + 1].focus()
         otpRefs.current[index + 1].select()
      }
   }

   const handleSubmit = (e) => {
      e.preventDefault()

      setErrors(() => ([]))

      // construct otp
      let otpString = ""
      for (var i = 0; i < otp.length; i++) {
         const otpValue = otpRefs.current[i].value
         otpString += new String(otpValue)
      }

      // do login
      dispatch(
         login(
            { email: email.current, password: otpString },
            (_successResult) => {
               // check prev query string
               const query = new URLSearchParams(window.location.search)
               location.href = query.get("prev")
            },
            (_errors) => {
               setErrors(_errors)
            },
         ),
      )
   }

   const [sendingOtp, setSendingOtp] = useState(false)
   const handleRequestForOTP = () => {
      setErrors(() => ([]))
      if (email.current.length < 1) {
         toast.error("Please input your email first")
      } else {
         setSendingOtp(true)
         new Promise((resolve, reject) => {
            dispatch(
               requestOTP(
                  { email: email.current },
                  () => {
                     resolve()
                  },
                  (errors) => {
                     reject(errors)
                  },
               ),
            )
         })
            .then(() => {
               // on success
               if (!modalResendOtp) {
                  setModalResendOtp(true)
               }
            })
            .catch((err) => {
               //  on error
               if(err.length > 0) {
                  setErrors(err)
               } else {
                  toast.error(err.message)
               }
            })
            .finally(() => {
               setSendingOtp(false)
            })
      }
   }

   const modalStyle = {
      overlay: {
         zIndex: "1060",
         background: "rgba(0,0,0,.7)",
      },
      content: {
         top: "50%",
         left: "50%",
         right: "auto",
         bottom: "auto",
         marginRight: "-50%",
         width: "573px",
         transform: "translate(-50%, -50%)",
         borderRadius: "12px",
         transition: "all .3s ease",
         backgroundColor: "white",
         paddingRight: "0",
         paddingLeft: "0",
         paddingBottom: "0",
         overflow: "hidden",
         height: "214px",
         zIndex: "1060",
      },
   }

   return (
      <>
         <OuterWrapper className="col no-paddings">
            <ContentWrapper className="container-fluid no-gutters no-paddings">
               <Row className="no-gutters">
                  <Col
                     lg={6}
                     className="d-flex align-items-center justify-content-center"
                     style={{
                        borderRadius: 12,
                     }}
                  >
                     <Row>
                        <Col className="d-flex justify-content-center flex-column">
                           <Logo src={AuctionBanner} width="250" height="60" className="float-center" />
                           <h1
                              className="text-center text-muted"
                              style={{
                                 fontSize: "32px",
                                 fontWeight: "700",
                                 color: "#909090",
                              }}
                           >
                              Ruang Auction by Promise
                           </h1>
                        </Col>
                     </Row>
                  </Col>

                  <Col className="px-5 py-5" lg={6}>
                     <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formEmail">
                           <Form.Label style={{ color: "#909090" }}>Email</Form.Label>
                           <InputField
                              type="email"
                              value={email.current}
                              disabled={email.current.length > 0}
                              placeholder="Type email address"
                           />
                           <ErrorField errors={errors} fieldName="email" />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formOTP">
                           <Form.Label style={{ color: "#909090" }}>OTP</Form.Label>
                           <Row className="mb-2">
                              {otp.map((v, index) => {
                                 return (
                                    <Col lg={3} key={index}>
                                       <InputField
                                          type="text"
                                          maxLength="1"
                                          className={`text-center otp${index + 1}`}
                                          ref={(ref) => (otpRefs.current[index] = ref)}
                                          onKeyUp={(e) => handleText(e, index)}
                                          onClick={() => document.getElementsByClassName(`otp${index + 1}`)[0].select()}
                                       />
                                    </Col>
                                 )
                              })}
                           </Row>

                           <ErrorField errors={errors} fieldName="password" />
                           {email.current.length > 0 ? (
                              <ButtonTimer onClick={handleRequestForOTP} countdown={30} waitingText="Resend OTP in">
                                 Request OTP
                              </ButtonTimer>
                           ) : (
                              <ButtonTimer onClick={handleRequestForOTP} countdown={0}>
                                 Request OTP
                              </ButtonTimer>
                           )}
                        </Form.Group>

                        <Form.Group className="mb-3 text-center" controlId="formButton">
                           <Buttonaction blueOcean type="submit" label="Login" style={{ width: "100%" }} />
                        </Form.Group>
                     </Form>
                  </Col>
               </Row>
            </ContentWrapper>
         </OuterWrapper>

         {/* modal resend otp */}
         <Modal isOpen={modalResendOtp} ariaHideApp={false} style={modalStyle} contentLabel="Success">
            <Row style={{ marginTop: "25px", marginLeft: "30px" }}>
               <Col lg={2}>
                  <img src={IconResendOtp} alt="" />
               </Col>
               <Col lg={10}>
                  <p style={{ fontSize: "24px", fontWeight: "500" }}>OTP has been sent to your email</p>
                  <p style={{ fontSize: "12px", marginTop: "5px", maxWidth: "380px" }}>
                     OTP code has been sent to{" "}
                     <a
                        href="javascript:void()"
                        style={{
                           fontWeight: "700",
                           color: "#0772B6",
                           textDecoration: "underline",
                        }}
                     >
                        {email.current}
                     </a>
                  </p>
               </Col>
            </Row>

            <Row className="mx-0 mt-4" style={{ marginBottom: "35px" }}>
               <div className="d-flex w-100 justify-content-end mx-5">
                  <div className="mr-3">
                     <Buttonaction borderblueOcean label="Close" large onClick={() => setModalResendOtp(false)} />
                  </div>
                  <Buttonaction
                     blueOcean
                     label="Resend Email"
                     large
                     onClick={handleRequestForOTP}
                     loading={sendingOtp}
                     loadingLabel="Sending OTP..."
                  />
               </div>
            </Row>
         </Modal>
      </>
   )
}

const OuterWrapper = styled.div`
   display: grid;
   place-items: center;
   height: 100vh;
   border: none;
`

const ContentWrapper = styled.div`
   box-shadow: 0px 32px 100px rgba(0, 0, 0, 0.08);
   width: 75%;
`

const Logo = styled.img`
   max-width: 166;
   margin: 0 auto;
   padding-bottom: 10;
`

const InputField = styled(Form.Control)`
   height: 44px;
`
