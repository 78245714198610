import React from 'react'
import styled from 'styled-components'

export default function ScrollableGrid({children}) {
    return (
        <ScrollableStyle>
            {children}
        </ScrollableStyle>
    )
}

const ScrollableStyle = styled.div`
    height: 400px;
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
`