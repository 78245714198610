export function expandSidemenu() {
    return async (dispatch, getState) => {
        const { appReducer } = getState()
        await dispatch({
            type: "EXPAND_SIDEMENU",
            payload: !appReducer.isSidemenuExpanded
        })
    }
}

export function setAppLoading(isLoading) {
    return async (dispatch) => await dispatch({
        type: "SET_APP_LOADING",
        payload: isLoading
    })
}