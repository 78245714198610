const initialState = {
    dataParticipant: [],
    dataOffer: []
}

const roomReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_PARTICIPANT':
            return {
                ...state,
                dataParticipant: action.payload
            }
        case 'FETCH_OFFER':
            return {
                ...state,
                dataOffer: action.payload
            }
        default:
            return state
    }
}

export default roomReducer