const initialState = {
   vendorScores: [],
   negotiationBatches: [],
   negotiationBatch: {},
   negotiationStatus: [],
   negotiationItems: [],
   negotiation: {},
}

const negotiationReducer = (state = initialState, action) => {
   switch (action.type) {
      case "FETCH_VENDOR_SCORES":
         return {
            ...state,
            vendorScores: action.payload,
         }

      case "FETCH_NEGOTIATION_BATCHES":
         return {
            ...state,
            negotiationBatches: action.payload,
         }

      case "GET_NEGOTIATION_BATCH":
         return {
            ...state,
            negotiationBatch: action.payload,
         }

      case "GET_NEGOTIATION_STATUS":
         return {
            ...state,
            negotiationStatus: action.payload,
         }

      case "GET_NEGOTIATION_ITEMS":
         return {
            ...state,
            negotiationItems: action.payload,
         }

      case "GET_NEGOTIATION":
         return {
            ...state,
            negotiation: action.payload,
         }
      default:
         return state
   }
}

export default negotiationReducer
