import toast from "react-hot-toast"

export function login({ username }, history) {
    return async (dispatch) => {
        try {
            await dispatch({
                type: "LOGIN",
                payload: {
                    username
                }
            })

            history.push("/secured/room")

            toast.success(`Welcome, ${username}`)
        } catch (e) {
            console.log(e)
        }
    }
}

export function saveSocketSession({ socketId }) {
    return async (dispatch) => {
        await dispatch({
            type: "SAVE_SOCKET_SESSION",
            payload: socketId
        })
    }
}