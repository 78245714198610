import Apiservice from "../../services/Apiservice";

export function getTotalInitiation(callback) {
  return async () => {
    const resp = await Apiservice.invokeGet(
      `${Apiservice.url.AUCTION_URL}/api/secured/dashboard/total-initiation`
    );

    if (resp.status === 200) {
      if (callback) {
        callback(resp.data.data);
      }
    }
  };
}

export function getNewAuction(callback) {
  return async () => {
    const resp = await Apiservice.invokeGet(
      `${Apiservice.url.AUCTION_URL}/api/secured/dashboard/new-auction`
    );

    if (resp.status === 200) {
      if (callback) {
        callback(resp.data.data);
      }
    }
  };
}

export function getOnGoingAuction(callback) {
  return async () => {
    const resp = await Apiservice.invokeGet(
      `${Apiservice.url.AUCTION_URL}/api/secured/dashboard/on-going-auction`
    );

    if (resp.status === 200) {
      if (callback) {
        callback(resp.data.data);
      }
    }
  };
}

export function getNegotiation(callback) {
  return async () => {
    const resp = await Apiservice.invokeGet(
      `${Apiservice.url.AUCTION_URL}/api/secured/dashboard/negotiation`
    );

    if (resp.status === 200) {
      if (callback) {
        callback(resp.data.data);
      }
    }
  };
}

export function getEstimatedBudget(callback) {
  return async () => {
    const resp = await Apiservice.invokeGet(
      `${Apiservice.url.AUCTION_URL}/api/secured/dashboard/estimated-budget`
    );

    if (resp.status === 200) {
      if (callback) {
        callback(resp.data.data);
      }
    }
  };
}

export function getFinalPrice(callback) {
  return async () => {
    const resp = await Apiservice.invokeGet(
      `${Apiservice.url.AUCTION_URL}/api/secured/dashboard/final-price`
    );

    if (resp.status === 200) {
      if (callback) {
        callback(resp.data.data);
      }
    }
  };
}
