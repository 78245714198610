import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useImperativeHandle } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Loader } from 'rsuite';
import styled from 'styled-components';
import _ from 'lodash'


/**
 * Icon only
 * 
 * @param {*} param0 
 * @returns 
 */
export function ButtonImageLabelaction({ reverse, icon, loading, label, onButtonClick, ...props }) {
  return (
    <Button disabled={loading} onClick={onButtonClick} {...props}>
      {(loading && <><Icon><Loader /></Icon><Label>Processing..</Label></>)}
      {(!loading && icon && <Icon {...props}><img src={icon} /></Icon>)}
      {(!loading && label && <Label {...props}>{label}</Label>)}
    </Button>
  )
}

/**
 * Icon only
 * 
 * @param {*} param0 
 * @returns 
 */
export function ButtonIconaction({ size, icon, ...props }) {
  return (
    <Button {...props}>
      <Icon style={{ marginRight: 0 }}><FontAwesomeIcon icon={icon} size={size} /></Icon>
    </Button>
  )
}

export function ButtonIconactionNew({ size, icon, ...props }) {
  return (
    <ButtonIcon {...props}>
      <img src={icon} />
    </ButtonIcon>
  )
}

/**
 * Icon with label
 * 
 * @param {*} param0 
 * @returns 
 */
export default function Buttonaction({ passedInRef, faIcon, customIcon, size, label, loading, loadingLabel, children, ...props }) {

  const [isDisabled, setIsDisabled] = useState()

  useImperativeHandle(passedInRef, () => ({

    forceDisable: () => {
      setIsDisabled(true)
    }

  }));

  return (
    <Button disabled={isDisabled == undefined ? loading : isDisabled} {...props}>
      {(loading && <><Icon><Loader /></Icon><Label>{loadingLabel ? loadingLabel : 'Processing..'}</Label></>)}
      {(!loading && faIcon && <Icon {..._.omitBy(props, (e, k) => k == 'style' || k == 'onClick')} ><FontAwesomeIcon icon={faIcon} size={size} /></Icon>)}
      {(!loading && customIcon && <Icon {..._.omitBy(props, (e, k) => k == 'style' || k == 'onClick')} ><img src={customIcon} style={{width: "15px"}} /></Icon>)}
      {(!loading && label && <Label {..._.omitBy(props, (e, k) => k == 'style' || k == 'onClick')} >{label}{children && children}</Label>)}
    </Button>
  )
}

const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-weight: ${props => props.underline || props.underlinegrey || props.underlineblue || props.underlinered ? 'bold' : ''}; */
    font-weight: bold;
    height: ${props => props.small ? '34px' :
    props.xsmall ? '24px' : '44px'};
    padding: ${props => props.xsmall ? '6px' :
    props.small ? '9px' : props.underline || props.underlinegrey || props.underlineblue || props.underlinered ? '0px' : '13px'};
    background-color: ${(props) =>
    // if primary
    props.disabled ? "#ECECEC" :
    props.primary ? props.theme.colors.pristineSat40 :
      props.red ? 'transparent' :
        props.gray ? '#ECECEC' :
          props.borderlessblue ? 'transparent' :
            props.blue ? props.theme.colors.blue :
              props.blueOcean ? props.theme.colors.blueOcean :
                props.borderblue ? props.theme.colors.white :
                  props.borderblueOcean ? props.theme.colors.white :
                    props.accentRed ? props.theme.colors.accentRed :
                      // else if success
                      props.outline ? 'transparent' :
                        props.accentRed ? props.theme.colors.accentRed :
                          props.borderless ? 'transparent' : props.theme.colors.white
  };
    color: ${(props) =>
    // if primary
    props.disabled ? "#909090" :
    props.primary ? props.theme.colors.white :
      props.red ? props.theme.colors.red :
        props.blue ? props.theme.colors.white :
          props.blueOcean ? props.theme.colors.white :
            props.borderlessblue ? props.theme.colors.blue :
              props.borderblue ? props.theme.colors.blue :
                props.borderblueOcean ? props.theme.colors.blueOcean :
                  props.accentRed ? props.theme.colors.white :
                    props.underline ? props.theme.colors.pristineSat40 :
                      props.underlinegrey ? props.theme.colors.whiteDarken30 :
                        props.underlineblue ? props.theme.colors.blue :
                          props.underlinered ? props.theme.colors.red :
                            props.borderyellow ? props.theme.colors.yellowDarken :
                              // else if gray
                              props.white ? props.theme.colors.white :
                                props.gray ? props.theme.colors.whitedarken30 :
                                  // else if success
                                  props.outline ? props.theme.colors.pristineSat40 : props.theme.colors.whitedarken30

  };
    font-family: ${props => props.theme.fonts.buttons};
    box-shadow: none;
    /* border-color: ${props => props.theme.colors.pristineSat40}; */
    border-color: ${(props) =>
    // if primary
    props.disabled ? "#ECECEC" :
    props.primary ? props.theme.colors.pristineSat40 :
      props.red ? props.theme.colors.red :
        props.blue ? props.theme.colors.blue :
          props.blueOcean ? props.theme.colors.blueOcean :
            props.accentRed ? props.theme.colors.accentRed :
              props.borderless ? 'transparent' :
                props.borderblue ? props.theme.colors.blue :
                  props.borderblueOcean ? props.theme.colors.blueOcean :
                    props.borderyellow ? props.theme.colors.yellowDarken :
                      // else if gray
                      props.white ? props.theme.colors.white :
                        props.gray ? props.theme.colors.whiteDarken30 :
                          // else if success
                          props.outline ? props.theme.colors.pristineSat40 : props.theme.colors.white

  };
    border-radius: ${props => props.theme.borderRadius.small};
    border-style: solid;
    border-width: 1px;
    transition: all .3s ease;
    width: ${props => props.fullwidth ? '100%' : 'auto'};

    &:hover {
        font-weight: bold;
        text-decoration: ${props => props.underline || props.underlinegrey || props.underlinered || props.underlineblue ? 'underline' : ''};
        background-color: ${(props) =>

    props.hoverstay ? "transparent" :
      // if primary
      props.disabled ? "#ECECEC" :
      props.primary ? props.theme.colors.white :
        props.red ? props.theme.colors.red :
          props.blue ? props.theme.colors.white :
            props.blueOcean ? "#065F98" :
              props.accentRed ? props.theme.colors.white :
                props.borderlessblue ? props.theme.colors.blue :
                  props.borderblue ? props.theme.colors.blue :
                    props.borderblueOcean ? props.theme.colors.blueOcean :
                      props.borderyellow ? props.theme.colors.yellowDarken :
                        props.underline || props.underlinegrey || props.underlinered || props.underlineblue ? 'transparent' :
                          // else if gray
                          props.white ? 'transparent' :

                            props.gray ? 'transparent' :
                              // else if success
                              props.outline ? props.theme.colors.pristineSat40 : props.theme.colors.whiteDarken30

  };
        color: ${(props) =>

    props.hoverstay ? props.theme.colors.pristineSat40 :
      // if primary
      props.disabled ? "#909090" :
      props.primary ? props.theme.colors.pristineSat40 :
        props.red ? props.theme.colors.white :
          props.blue ? props.theme.colors.blue :
            props.blueOcean ? props.theme.colors.white :
              props.accentRed ? props.theme.colors.accentRed :
                props.borderlessblue ? props.theme.colors.white :
                  props.borderblue ? props.theme.colors.white :
                    props.borderblueOcean ? props.theme.colors.white :
                      props.borderyellow ? props.theme.colors.white :
                        props.underlinegrey ? props.theme.colors.whiteDarken30 :
                          props.underlineblue ? props.theme.colors.blue :
                            props.underlinered ? props.theme.colors.red :
                              // else if gray
                              props.white ? props.theme.colors.white :

                                props.gray ? props.theme.colors.whiteDarken30 :
                                  // else if success
                                  props.outline ? props.theme.colors.white : props.theme.colors.pristineSat40

  };
    }
`

const ButtonIcon = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    border-radius: ${props => props.theme.borderRadius.small};
    height: ${props => props.small ? '34px' :
    props.xsmall ? '24px' : '44px'};
    background-color: transparent;
    &:hover {
        border: 1px solid;
        border-color: ${props => props.primary ? props.theme.colors.pristineSat40 : props.noborder ? 'transparent' : props.theme.colors.whiteDarken30}
    }
`

const Icon = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${(props) => props.theme.fonts.sizes.buttonLg};
    img{  
    height:  ${(props) => props.small ? '24px' : ''};
    width:  ${(props) => props.small ? '24px' : ''};
    }
`

const Label = styled.span`
    color: inherit;
    font-family: inherit;
    margin-left: 10px;
    margin-right: 10px;
    font-size: ${(props) => props.xsmall ? '11px' :
    props.xxsmall ? '9px' : props.theme.fonts.sizes.buttonLg};
`