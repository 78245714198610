import { orderBy as _orderBy } from "lodash"
import React, { useEffect, useRef, useState } from "react"
import { Col, Form, InputGroup, Row } from "react-bootstrap"
import toast from "react-hot-toast"
import Modal from "react-modal"
import { connect, useDispatch } from "react-redux"
import { useHistory } from "react-router"
import { useParams } from "react-router-dom"
import { Loader, Tooltip, Whisper } from "rsuite"
import { io } from "socket.io-client"
import styled from "styled-components"

import Constant from "../../../constant"

// Components
import Breadcrumbs from "../../components/Breadcrumbs"
import Buttonaction from "../../components/Buttonaction"
import Chip from "../../components/Chip"
import CountdownTimer from "../../components/CountdownTimer"
import Dateinfo from "../../components/Dateinfo"
import MuiDataTable from "../../components/MuiDataTable"
import Pagetitle from "../../components/Pagetitle"
import Statuslabel from "../../components/Statuslabel"
import { GreyCard } from "../../components/styled/Generalcard"

// Redux
import { getMyProfile } from "../../redux/action/accountAction"
import {
   fetchAllAuctionInvitation,
   getAuctionStatus
} from "../../redux/action/auctionAction"
import {
   changeAuctionStatus,
   fetchAuctionInvitedVendors,
   getProcurement,
   getProcurementAuction
} from "../../redux/action/procurementAction"
import { resendInvitationEmail } from "../../redux/action/procurementAuctionAction"
import AuctionItemList from "./unit-price/AuctionItemList"

// Assets
import CloseButtonSolidBlue from "../../assets/images/CloseButtonSolidBlue.svg"
import iconResendInvitation from "../../assets/images/Icon-ResendInvitation.svg"
import CancelProcessIcon from "../../assets/images/IconCloseProcess.svg"
import PencilBlue from "../../assets/images/PencilSimpleBlue.svg"
import warningIcon from "../../assets/images/WarningIcon.svg"
import IconResendEmail from "../../assets/images/attend-icon-email.svg"

// MUI Library
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { TableBody, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material"
import BuyerAuctionInfoCard from "../../components/BuyerAuctionInfoCard"
import BuyerAuctionTimerCard from "../../components/BuyerAuctionTimerCard"
import InputAlert from "../../components/InputAlert"
import SubmitModal from "../../components/Submitmodal"
import Texteditor from "../../components/Texteditor"
import { fetchMasterData } from "../../redux/action/masterDataAction"

function BuyerAuctionSessionNotStarted({ dataProcurement, dataProfile, isAppLoading, auctionStatus }) {
   const dispatch = useDispatch()
   const history = useHistory()
   const { auctionId, procurementId } = useParams()
   const modalCancelRef = useRef()
   const dataProcurementRef = useRef(null)

   const [dataAuction, setDataAuction] = useState(undefined)
   const [dataState, setDataState] = useState({
      page: 0,
      limit: 10,
      query: "",
      selectedRows: [],
   })
   // const [numberOfItems, setNumberOfItems] = useState(0)
   const [durationStart, setDurationStart] = useState(0)
   const [vendorJoinedInRoom, setVendorJoinedInRoom] = useState(null)
   const [belowTwoUsersInRoom, setBelowTwoUsersInRoom] = useState(false)
   const [dataAuctionInvitedVendors, setDataAuctionInvitedVendors] = useState({ filteredData: [] })
   const [itemId, setItemId] = useState(null)
   const [stopTimer, setStopTimer] = useState(false)
   const [vendorInvitation, setVendorInvitation] = useState({})
   const [editEmail, setEditEmail] = useState(false)
   const [editPIC, setEditPIC] = useState(false)
   const [resendInvitationModal, setResendInvitationModal] = useState(false)
   const [emailToSend, setEmailToSend] = useState(null)
   const [picVendorToSend, setpicVendorToSend] = useState(null)
   const [isSending, setIsSending] = useState(false)
   const [isEmailSent, setIsEmailSent] = useState(false)

   const modaAdditemStyle = {
      overlay: {
         zIndex: "1060",
         background: "rgba(0,0,0,.7)",
      },
      content: {
         top: "50%",
         left: "50%",
         right: "auto",
         bottom: "auto",
         marginRight: "-50%",
         width: "573px",
         transform: "translate(-50%, -50%)",
         borderRadius: "12px",
         transition: "all .3s ease",
         backgroundColor: "white",
         paddingRight: "0",
         paddingLeft: "0",
         paddingBottom: "0",
         overflow: "hidden",
         zIndex: "1060",
      },
   }

   const fetchDataInvitation = (page = dataState.page, limit = 999999, query = dataState.query, userOnlineListId) => {
      dispatch(
         fetchAuctionInvitedVendors({ auctionId, page, limit, query }, (res) => {
            const invitedVendors = res.filteredData
            const _invitedVendors = setJoinedInRoomStatus(invitedVendors, userOnlineListId)
            setDataAuctionInvitedVendors({ ...res, filteredData: _invitedVendors })
         }),
      )
   }

   const setJoinedInRoomStatus = (invitedVendors = [], userOnlineListId = []) => {
      for (const vendor of invitedVendors) {
         let isInRoom = userOnlineListId.includes(vendor.accountId)
         userOnlineListId.length > 0 && isInRoom
         if (isInRoom) {
            vendor.joinedStatus = "JOINED"
         } else {
            vendor.joinedStatus = "NOT_JOINED"
         }
      }
      return invitedVendors
   }

   const resendEmail = async () => {
      const auctionInvitationId = vendorInvitation._id
      setIsSending(true)
      new Promise((resolve, reject) => {
         dispatch(
            resendInvitationEmail(
               auctionId,
               auctionInvitationId,
               {
                  email: emailToSend || vendorInvitation.vendorEmail,
                  picVendor: picVendorToSend || vendorInvitation.picVendor,
               },
               () => {
                  resolve()
               },
               (err) => reject(err),
            ),
         )
      })
         .then(() => {
            setIsEmailSent(true)
            setResendInvitationModal(false)
         })
         .catch((err) => {
            toast.error(err.message)
         })
         .finally(() => {
            fetchDataInvitation(dataState.page, 999999, dataState.query, userListRef.current)
            setIsSending(false), setEditEmail(false), setEditPIC(false)
         })
   }

   const RenderActionButton = ({ data }) => {
      return (
         <>
            <Buttonaction
               className="mr-2"
               type="button"
               borderblueOcean
               onClick={() => history.push(`/initiation/form/${dataAuction.procurementId}`)}
               label="Back"
            />

            {data.length > 0 &&
               data.map((v, index) => (
                  <Buttonaction
                     key={index}
                     accentRed={v.to.description == "CANCEL" ? true : false}
                     label={v.label}
                     large
                     style={{ marginRight: "24px" }}
                     onClick={() => {
                        v.to.description === "CANCEL"
                           ? modalCancelRef.current.show({
                                modalTitle: "",
                                title: "Cancel Session?",
                                description: <p>Do you want to Cancel this session?</p>,
                             })
                           : ""
                     }}
                  />
               ))}
         </>
      )
   }

   /**
    * table handling
    * ----------------
    */
   const tableColumns = [
      "Vendor Name",
      "Attend Confirmation",
      "Reason Not Attend",
      "Resend Invitation",
      "Joined Status",
   ]

   const [order, setOrder] = React.useState("asc")
   const [orderBy, setOrderBy] = React.useState("")

   const handleRequestSort = (column) => {
      const _order = order === "asc" ? "desc" : "asc"
      setOrderBy(column)
      setOrder(_order)

      const forColumn =
         column === "Vendor Name"
            ? "vendorName"
            : column === "Attend Confirmation"
            ? "status"
            : column === "Reason Not Attend"
            ? "reasonNotAttend"
            : column === "Resend Invitation"
            ? "_id"
            : column === "Joined Status"
            ? "joinedStatus"
            : ""

      let result = []
      result = _orderBy(dataAuctionInvitedVendors.filteredData, [forColumn], [_order])

      setDataAuctionInvitedVendors({
         ...dataAuctionInvitedVendors,
         filteredData: result,
      })
   }

   const userListRef = useRef([])

   /**
    * Socket setup
    * ----------------
    */
   const ENDPOINT = import.meta.env.VITE_SERVER_URL
   const socketRef = useRef()
   const setupSocket = (_dataAuction, _dataProfile) => {
      socketRef.current = io(ENDPOINT + `/company-${_dataAuction.companyId}`)

      socketRef.current.on("connect", function () {
         console.log("socket connected")
         const isServerDown = localStorage.getItem("server_down")
         if (isServerDown) {
            location.reload()
            localStorage.removeItem("server_down")
         }
      })

      // this will fired when socket failed to connect or reconnection
      socketRef.current.on("connect_error", () => {
         alert(
            "Something unexpected occurs in the server (crash or stop), the system will automatically cancel all running auctions and page will be refreshed when server is running again.",
         )
         setStopTimer(true)
         localStorage.setItem("server_down", true)
      })

      socketRef.current.emit(Constant.socketEvents.BUYER_JOINED, {
         username: _dataProfile?.account?.email,
         auctionId: _dataAuction._id,
      })

      socketRef.current.on(Constant.socketEvents.VENDOR_ATTEND, (_data) => {
         // refresh data
         fetchDataInvitation(dataState.page, 999999, dataState.query, userListRef.current)
      })

      socketRef.current.on(Constant.socketEvents.AUCTION_COUNTDOWN_BEGIN_TIMER, ({ durationLeft }) => {
         console.log(Constant.socketEvents.AUCTION_COUNTDOWN_BEGIN_TIMER, durationLeft, "durationLeft")
         setDurationStart(durationLeft)
      })

      socketRef.current.on(Constant.socketEvents.AUCTION_STARTED, () => {
         socketRef.current.disconnect()
         history.push(`/buyer-auction/${dataProcurementRef.current._id}/${auctionId}/in-progress`)
      })

      // triggered when there's a user entering room
      socketRef.current.on(Constant.socketEvents.USERS_IN_ROOM, ({ total, userList }) => {
         fetchDataInvitation(dataState.page, 999999, dataState.query, userList)
         userListRef.current = userList
         setVendorJoinedInRoom(userList.length)
      })

      // triggered when there's a user leave or disconnected from room
      socketRef.current.on(Constant.socketEvents.USERS_LEFT_IN_ROOM, ({ total, userList }) => {
         fetchDataInvitation(dataState.page, 999999, dataState.query, userList)
         userListRef.current = userList
         setVendorJoinedInRoom(userList.length)
      })

      return () => {
         socketRef.current.disconnect()
      }
   }

   const [isCanceling, setIsCanceling] = useState(false)
   const handleCancelAuction = () => {
      const payload = {
         status: Constant.auctionStatus.CANCEL,
         auction: dataAuction,
      }

      new Promise((resolve, reject) => {
         setIsCanceling(true)
         dispatch(
            changeAuctionStatus(
               payload,
               () => {
                  resolve()
               },
               () => {
                  reject()
               },
            ),
         )
      })
         .then(() => {
            socketRef.current.emit(Constant.socketEvents.CANCEL_AUCTION, payload)
            socketRef.current.emit(Constant.socketEvents.SET_EMPTY_USER_ONLINE_LIST, {})
            history.push(`/initiation/form/${dataAuction.procurementId}`)
         })
         .finally(() => setIsCanceling(false))
   }

   // this will always run when state of durationStart and vendorJoinedInRoom is updated
   useEffect(() => {
      // do check users online in room while the duration before start still counting and 1 minutes left
      if (vendorJoinedInRoom !== null && vendorJoinedInRoom < 2 && durationStart && durationStart <= 60) {
         !belowTwoUsersInRoom && setBelowTwoUsersInRoom(true)
         // toast.error(
         //    `Only ${vendorJoinedInRoom} participant in room, auction won't be proceed and will be canceled automatically`,
         //    { id: "participantWarning" },
         // )
      } else {
         belowTwoUsersInRoom && setBelowTwoUsersInRoom(false)
      }
   }, [vendorJoinedInRoom, durationStart])

   const [isLoading, setIsLoading] = useState(true)
   const [isGettingInfo, setIsGettingInfo] = useState(true)
   useEffect(async () => {
      const language = window.localStorage.getItem("lang")
      dispatch(
         fetchMasterData(language, (masterData) => {
            dispatch(
               getProcurement(
                  { procurementId },
                  (dataProcurement) => {
                     dataProcurementRef.current = dataProcurement
                  },
                  () => {},
                  masterData,
               ),
            )
         }),
      )

      dispatch(
         getMyProfile(
            (_dataProfile) => {
               new Promise((resolve, reject) => {
                  dispatch(
                     getProcurementAuction(
                        { auctionId },
                        (_dataAuction) => {
                           setupSocket(_dataAuction, _dataProfile)
                           setDataAuction(_dataAuction)

                           const auctionStatus = _dataAuction.status

                           const payload = {
                              from: auctionStatus,
                              auction: { ..._dataAuction },
                           }

                           // get auction status step
                           dispatch(getAuctionStatus(payload))

                           new Promise((resolve, reject) => {
                              dispatch(
                                 fetchAuctionInvitedVendors(
                                    { auctionId },
                                    (res) => {
                                       const invitedVendors = res.filteredData
                                       const _invitedVendors = setJoinedInRoomStatus(invitedVendors)
                                       setDataAuctionInvitedVendors({ ...res, filteredData: _invitedVendors })
                                       resolve()
                                    },
                                    () => {
                                       reject()
                                    },
                                 ),
                              )
                           })
                              .then()
                              .finally(() => setIsLoading(false))
                           resolve()
                        },
                        (__err) => {
                           reject()
                           history.goBack()
                        },
                     ),
                  )
               })
                  .then()
                  .finally(() => setIsGettingInfo(false))
            },
            (_err) => {},
         ),
      )

      dispatch(
         fetchAllAuctionInvitation(
            { auctionId },
            () => {},
            () => {},
         ),
      )

      // dispatch(
      //    fetchProcurementItems({ procurementId }, (resp) => {
      //       setNumberOfItems(resp.total)
      //       console.log(resp)
      //    }),
      // )
   }, [])

   const beforeResendEmail = (v) => {
      setVendorInvitation(v)
      setEmailToSend(v.vendorEmail)
      setpicVendorToSend(v.picVendor)
      setResendInvitationModal(true)
   }

   return (
      <>
         <Row>
            <Col>
               <Breadcrumbs data={["Dashboard", "Procurement", "New Procurement"]} />
            </Col>
         </Row>
         <Row>
            <Col className="mb-4">
               <Pagetitle
                  title="Auction Session"
                  onBackButtonClicked={() => history.push(`/initiation/form/${dataAuction?.procurementId}`)}
                  withBackButton={true}
                  lcomp={<Statuslabel label={dataAuction?.status} status={dataAuction?.status} />}
               />
            </Col>
         </Row>

         {dataAuction?.status === "CANCELED" && (
            <Row className="mb-4">
               <Col className="px-3">
                  <GreyCard style={{ padding: "32px 18px", fontSize: "12px" }}>
                     <Row>
                        <Col lg={10}>
                           <p style={{ fontSize: "18px", fontWeight: "500" }}>Status Information</p>
                        </Col>
                     </Row>
                     <hr style={{ borderColor: "#C4C4C4", marginTop: "18px" }} />

                     <Row className="mb-3">
                        <Col lg={2}>Date</Col>
                        <Col>
                           <Dateinfo
                              date={dataAuction.updatedAt}
                              format="mm/dd/yyyy HH:MM"
                              style={{ color: "#454545", fontWeight: "500" }}
                           />
                        </Col>
                     </Row>
                     <Row className="mb-3">
                        <Col lg={2}>Status</Col>
                        <Col>
                           <Chip kind="canceled">Canceled</Chip>
                        </Col>
                     </Row>
                     <Row className="mb-3">
                        <Col lg={2}>User Name</Col>
                        <Col>{dataAuction.canceledBy}</Col>
                     </Row>
                     <Row>
                        <Col lg={2}>Reason</Col>
                        <Col>
                           <span
                              dangerouslySetInnerHTML={{
                                 __html: dataAuction.cancelReason,
                              }}
                           />
                        </Col>
                     </Row>
                  </GreyCard>
               </Col>
            </Row>
         )}

         <Row>
            <Col lg={9} sm={12} className="order-lg-1 order-sm-2 mr-0 pr-0">
               {dataProcurement?.winnerType === Constant.WINNER_TYPE.MULTI_WINNER && (
                  <div className="mb-4">
                     <AuctionItemList />
                  </div>
               )}
               <GreyCard style={{ padding: "32px 18px" }}>
                  <Row>
                     <Col lg={10}>
                        <p style={{ fontSize: "18px", fontWeight: "500" }}>Vendor List</p>
                     </Col>
                  </Row>
                  <hr style={{ borderColor: "#C4C4C4", marginTop: "18px" }} />

                  {belowTwoUsersInRoom && (
                     <ExpiredMessage>
                        <Col className="mt-2" style={{ paddingTop: "3px" }} lg={12}>
                           <img src={warningIcon} className="mr-2" />
                           {/* Due date confirmation has expired, less than 2 participants joined the session. */}
                           Less than 2 participants joined the session.
                        </Col>
                     </ExpiredMessage>
                  )}

                  <Row style={{ marginBottom: "18px" }}>
                     <Col sm={4}>
                        <p style={{ fontSize: "14px", fontWeight: "700" }}>Due Date Confirmation</p>
                     </Col>
                     <Col sm={8} style={{ marginLeft: "-50px" }}>
                        <p>
                           <Dateinfo
                              date={dataAuction?.startTime}
                              format="dddd, dd mmmm yyyy"
                              style={{ fontSize: "14px" }}
                           />
                           <span style={{ fontSize: "14px", color: "#030303" }}>
                              {dataAuction?.startTime &&
                                 new Date(dataAuction?.startTime).toLocaleString("en-US", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                    hour12: false,
                                 })}
                           </span>
                        </p>
                     </Col>
                  </Row>

                  <MuiDataTable.CustomTable>
                     <TableHead>
                        <TableRow>
                           {tableColumns.map((column, index) =>
                              column === "Resend Invitation" &&
                              ![Constant.STATUS.DONE, Constant.STATUS.CANCELED].includes(dataAuction?.status) ? (
                                 <TableCell key={index} className="py-2" sx={{ whiteSpace: "nowrap" }} align={"center"}>
                                    <TableSortLabel
                                       active={orderBy === column}
                                       direction={order}
                                       IconComponent={ExpandLessIcon}
                                       onClick={() => handleRequestSort(column)}
                                    >
                                       {column}
                                    </TableSortLabel>
                                 </TableCell>
                              ) : column !== "Resend Invitation" && (
                                 <TableCell
                                    key={index}
                                    className="py-2"
                                    sx={{ whiteSpace: "nowrap" }}
                                    align={"left"}
                                 >
                                    <TableSortLabel
                                       active={orderBy === column}
                                       // disabled={index === 3 ? true : false}
                                       direction={order}
                                       IconComponent={ExpandLessIcon}
                                       onClick={() => handleRequestSort(column)}
                                    >
                                       {column}
                                    </TableSortLabel>
                                 </TableCell>
                              ),
                           )}
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {isLoading && (
                           <TableRow sx={{ background: "#F3F5F4" }}>
                              <TableCell colSpan={5} align="center" sx={{ fontWeight: 500 }}>
                                 <Loader speed="fast" content="Getting vendors..." />
                              </TableCell>
                           </TableRow>
                        )}
                        {dataAuctionInvitedVendors.filteredData.length > 0 &&
                           !isLoading &&
                           dataAuctionInvitedVendors?.filteredData.map((vendor, index) => (
                              <TableRow
                                 key={index}
                                 sx={{
                                    background: "#F3F5F4",
                                    "&:hover": {
                                       background: "transparent",
                                    },
                                 }}
                              >
                                 <TableCell
                                    className="py-2"
                                    sx={{
                                       fontWeight: 500,
                                       minWidth: "140px",
                                       textDecoration: "underline",
                                    }}
                                 >
                                    {vendor.vendorName}
                                 </TableCell>
                                 <TableCell>
                                    <Chip kind={vendor.status.toLowerCase()}>
                                       {vendor.status === "NOT_CONFIRMED"
                                          ? "Not Confirmed"
                                          : vendor.status === "NOT_ATTEND"
                                          ? "Not Attend"
                                          : vendor.status === "ATTEND"
                                          ? "Attend"
                                          : ""}
                                    </Chip>
                                 </TableCell>
                                 <TableCell className="py-2" sx={{ minWidth: "140px" }}>
                                    {!vendor.reasonNotAttend ? "-" : vendor.reasonNotAttend}
                                 </TableCell>
                                 {![Constant.STATUS.DONE, Constant.STATUS.CANCELED].includes(dataAuction?.status) && (
                                    <TableCell className="py-2" align="center">
                                       <SendInvitationButton
                                          row={vendor}
                                          onSendingEmail={() => beforeResendEmail(vendor)}
                                          isEmailSent={isEmailSent}
                                       />
                                    </TableCell>
                                 )}
                                 <TableCell>
                                    <Chip kind={vendor.joinedStatus.toLowerCase()}>
                                       {vendor.joinedStatus === "NOT_JOINED" ? "Not Joined" : "Joined"}
                                    </Chip>
                                 </TableCell>
                              </TableRow>
                           ))}
                     </TableBody>
                  </MuiDataTable.CustomTable>
               </GreyCard>
            </Col>

            <Col lg={3} sm={12} className="order-lg-2 order-sm-1">
               <Row className="mb-lg-3 mb-sm-4">
                  <Col lg={12} sm={6} className="order-lg-1 order-sm-2">
                     <BuyerAuctionTimerCard auction={dataAuction}>
                        <div className="d-flex justify-content-center align-items-center">
                           {dataAuction?.status === "CANCELED" || stopTimer ? (
                              <CountdownTimer
                                 isPlaying={false}
                                 duration={0}
                                 colors={[["#4DAF51", 0.33], ["#FFB301", 0.33], ["#F44437"]]}
                                 initialRemainingTime={0}
                                 children={
                                    <Chip
                                       kind="canceled"
                                       onClick={() =>
                                          history.push(
                                             `/buyer-auction/${dataProcurement?._id}/${auctionId}/in-progress`,
                                          )
                                       }
                                    >
                                       Canceled
                                    </Chip>
                                 }
                              />
                           ) : durationStart > 0 && durationStart !== 0 ? (
                              <CountdownTimer
                                 isPlaying={true}
                                 title="Start In"
                                 // duration={dataAuction?.status === "CANCELED" ? 0 : durationStart}
                                 duration={durationStart}
                                 colors={[["#ECECEC"]]}
                                 strokeWidth={17}
                                 size={180}
                                 trailColor={"#ECECEC"}
                                 initialRemainingTime={durationStart}
                                 children={
                                    <Statuslabel
                                       onClick={() =>
                                          history.push(
                                             `/buyer-auction/${dataProcurement?._id}/${auctionId}/in-progress`,
                                          )
                                       }
                                       status={dataAuction?.status}
                                       label={dataAuction?.status}
                                    />
                                 }
                              />
                           ) : (
                              dataAuction?.status !== "CANCELED" &&
                              dataAuction?.status !== "DONE" && (
                                 <Loader speed="fast" content="Preparing countdown timer..." vertical />
                              )
                           )}

                           {durationStart < 1 && dataAuction?.status === "DONE" && (
                              <CountdownTimer
                                 isPlaying={false}
                                 duration={0}
                                 colors={[["#4DAF51", 0.33], ["#FFB301", 0.33], ["#F44437"]]}
                                 initialRemainingTime={0}
                                 children={
                                    <Chip
                                       kind="canceled"
                                       onClick={() =>
                                          history.push(
                                             `/buyer-auction/${dataProcurement?._id}/${auctionId}/in-progress`,
                                          )
                                       }
                                    >
                                       End
                                    </Chip>
                                 }
                              />
                           )}
                        </div>
                     </BuyerAuctionTimerCard>
                  </Col>
                  <Col lg={12} sm={6} className="mt-lg-3 mt-0 order-lg-2 order-sm-1 pr-0">
                     <BuyerAuctionInfoCard
                        auction={dataAuction}
                        procurement={dataProcurement}
                        isLoading={isGettingInfo}
                        // numberOfItems={numberOfItems}
                     />
                  </Col>
               </Row>
            </Col>
         </Row>
         <Row className="mt-5 mb-5" style={{ marginLeft: "0" }}>
            <Col lg={9} className="d-flex justify-content-center">
               <RenderActionButton data={auctionStatus} />
            </Col>
         </Row>

         {/* CANCEL MODAL */}
         <SubmitModal.ModalContainer passedInRef={modalCancelRef} icon={CancelProcessIcon}>
            <div className="mt-4">
               <Texteditor onValueChange={(v) => setDataAuction((prev) => ({ ...prev, cancelReason: v }))} />
            </div>
            <SubmitModal.ModalButtonActions>
               <div className="mt-4 d-flex justify-content-end">
                  <div className="mr-3">
                     <Buttonaction
                        borderblueOcean
                        type="button"
                        label="No"
                        large
                        onClick={() => modalCancelRef.current.hide()}
                     />
                  </div>
                  <Buttonaction
                     disabled={!dataAuction?.cancelReason}
                     blueOcean
                     type="button"
                     label="Yes"
                     large
                     loading={isCanceling}
                     onClick={handleCancelAuction}
                  />
               </div>
            </SubmitModal.ModalButtonActions>
         </SubmitModal.ModalContainer>

         {/* RESEND INVITATION MODAL */}
         <Modal isOpen={resendInvitationModal} ariaHideApp={false} style={modaAdditemStyle} contentLabel="Success">
            <Form
               onSubmit={(e) => {
                  e.preventDefault(), resendEmail()
               }}
            >
               <Row className="m-4">
                  <Col lg={2}>
                     <img src={IconResendEmail} alt="" />
                  </Col>
                  <Col lg={10}>
                     <p style={{ fontSize: "24px", fontWeight: "500" }}>Resend Invitation?</p>
                     <div>
                        <p className="mt-3" style={{ fontSize: "12px", margin: "18px 0 20px 0" }}>
                           You will resend Auction invitation to the vendor
                           <br />
                           Do you want to proceed?
                        </p>

                        <hr style={{ borderColor: "#C4C4C4" }} />

                        <Form.Group
                           as={Row}
                           className={`d-flex ${editPIC ? "align-items-start" : "align-items-center"} mb-3`}
                           controlId="picVendor"
                        >
                           <Form.Label className="py-0" column xs={3} xxl={2}>
                              <p style={{ fontSize: ".75rem" }}>PIC Vendor</p>
                           </Form.Label>
                           <Col xs={9}>
                              {editPIC ? (
                                 <div className="d-flex align-items-start">
                                    <div className="w-100 mr-2">
                                       <InputGroup>
                                          <Form.Control
                                             type="text"
                                             value={picVendorToSend}
                                             onChange={({ target }) => {
                                                const pattern = /^[a-zA-Z .]+$/
                                                if (!target.value || pattern.test(target.value)) {
                                                   setpicVendorToSend(target.value)
                                                }
                                             }}
                                          />
                                       </InputGroup>
                                       <div style={{ marginTop: ".5rem" }}>
                                          {!picVendorToSend && (
                                             <InputAlert.Container type="danger">
                                                <div className="d-flex align-items-start mb-2">
                                                   <InputAlert.Icon />
                                                   <InputAlert.Text text="This information must be filled" />
                                                </div>
                                             </InputAlert.Container>
                                          )}
                                          <InputAlert.Container>
                                             <div className="d-flex align-items-start">
                                                <InputAlert.Icon />
                                                <InputAlert.Text
                                                   text="If you change the name for PIC, the previous name will be replaced with the
                                          new one"
                                                />
                                             </div>
                                          </InputAlert.Container>
                                       </div>
                                    </div>
                                    <img
                                       src={CloseButtonSolidBlue}
                                       style={{ cursor: "pointer" }}
                                       onClick={() => {
                                          setEditPIC(false), setpicVendorToSend(vendorInvitation.picVendor)
                                       }}
                                    />
                                 </div>
                              ) : (
                                 <div className="d-flex">
                                    <p style={{ marginRight: "10px" }}>{vendorInvitation.picVendor}</p>
                                    <Whisper placement="top" speaker={<Tooltip>Change PIC Vendor</Tooltip>}>
                                       <img
                                          src={PencilBlue}
                                          style={{ cursor: "pointer", position: "relative", bottom: "2px" }}
                                          onClick={() => {
                                             setpicVendorToSend(vendorInvitation.picVendor), setEditPIC(true)
                                          }}
                                       />
                                    </Whisper>
                                 </div>
                              )}
                           </Col>
                        </Form.Group>

                        <Form.Group
                           as={Row}
                           className={`d-flex ${editEmail ? "align-items-start" : "align-items-center"} mb-3`}
                           controlId="picEmail"
                        >
                           <Form.Label column xs={3} xxl={2} className="py-0">
                              <p style={{ fontSize: ".75rem" }}>PIC Email</p>
                           </Form.Label>
                           <Col xs={9}>
                              {editEmail ? (
                                 <div className="d-flex align-items-start">
                                    <div className="w-100 mr-2">
                                       <InputGroup>
                                          <Form.Control
                                             type="email"
                                             pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                             value={emailToSend}
                                             onChange={({ target }) => {
                                                setEmailToSend(target.value)
                                             }}
                                             required
                                          />
                                       </InputGroup>
                                       <div style={{ marginTop: ".5rem" }}>
                                          {!emailToSend && (
                                             <InputAlert.Container type="danger">
                                                <div className="d-flex align-items-start mb-2">
                                                   <InputAlert.Icon />
                                                   <InputAlert.Text text="This information must be filled" />
                                                </div>
                                             </InputAlert.Container>
                                          )}
                                          <div className="mb-1">
                                             <InputAlert.Container>
                                                <InputAlert.Text
                                                   text={
                                                      <p>
                                                         *avoid using{" "}
                                                         <em>
                                                            <strong>YAHOO</strong>
                                                         </em>{" "}
                                                         email due to frequent problems
                                                      </p>
                                                   }
                                                   style={{ marginLeft: 0 }}
                                                />
                                             </InputAlert.Container>
                                          </div>
                                          <InputAlert.Container>
                                             <div className="d-flex align-items-start">
                                                <InputAlert.Icon />
                                                <InputAlert.Text
                                                   text="If you change the email address, the previous email address will be replaced with
                                       the new one"
                                                />
                                             </div>
                                          </InputAlert.Container>
                                       </div>
                                    </div>
                                    <img
                                       src={CloseButtonSolidBlue}
                                       style={{ cursor: "pointer" }}
                                       onClick={() => {
                                          setEditEmail(false), setEmailToSend(vendorInvitation.vendorEmail)
                                       }}
                                    />
                                 </div>
                              ) : (
                                 <div className="d-flex">
                                    <div>
                                       <p style={{ marginRight: "10px" }}>{vendorInvitation.vendorEmail}</p>
                                    </div>
                                    {vendorInvitation.status === "NOT_CONFIRMED" && (
                                       <Whisper placement="top" speaker={<Tooltip>Change email address</Tooltip>}>
                                          <img
                                             src={PencilBlue}
                                             style={{ cursor: "pointer", position: "relative", bottom: "2px" }}
                                             onClick={() => {
                                                setEmailToSend(vendorInvitation.vendorEmail), setEditEmail(true)
                                             }}
                                          />
                                       </Whisper>
                                    )}
                                 </div>
                              )}
                           </Col>
                        </Form.Group>
                     </div>
                  </Col>
               </Row>
               <Row className="mb-4 mx-4">
                  <Col className="d-flex justify-content-end px-3">
                     <div className="mr-3">
                        <Buttonaction
                           borderblueOcean
                           type="button"
                           label="No"
                           large
                           onClick={() => {
                              setResendInvitationModal(false), setEditEmail(false), setEditPIC(false)
                           }}
                        />
                     </div>
                     <Buttonaction
                        blueOcean
                        type="submit"
                        label="Yes"
                        large
                        loading={isSending}
                        loadingLabel={"Sending..."}
                        disabled={!picVendorToSend || !emailToSend}
                     />
                  </Col>
               </Row>
            </Form>
         </Modal>
      </>
   )
}

/**
 *
 * @param {*} map
 * @returns
 */
const SendInvitationButton = ({ row, onSendingEmail, isEmailSent }) => {
   const [timer, setTimer] = useState(0)

   const handleSendingEmail = () => {
      // const auctionInvitationId = row._id
      // onSendingEmail(auctionInvitationId)
      // let seconds = 10
      // setTimer(seconds)
      // let timerInterval = setInterval(() => {
      //    if (seconds <= 0) {
      //       return clearInterval(timerInterval)
      //    } else {
      //       seconds--
      //       setTimer(seconds)
      //    }
      // }, 1000)
   }

   return (
      <>
         {(timer <= 0 && (
            <a
               href="javascript:void(0);"
               onClick={() => {
                  onSendingEmail()
                  // handleSendingEmail()
               }}
            >
               <img src={iconResendInvitation} alt="" style={{ marginLeft: "-26px" }} />
            </a>
         )) || (
            <>
               <span>Available to resend in {timer}</span>
            </>
         )}
      </>
   )
}

const mapStateToProps = (state) => {
   return {
      dataProcurement: state.procurementReducer.dataProcurement,
      dataProfile: state.accountReducer.dataProfile,
      isAppLoading: state.appReducer.isAppLoading,
      auctionStatus: state.auctionReducer.auctionStatus,
   }
}

export default connect(mapStateToProps)(BuyerAuctionSessionNotStarted)

// Styled component
const AuctionInfoList = styled(Row)`
   margin-bottom: 16px;
`

const ExpiredMessage = styled(Row)`
   border-radius: 5px;
   font-size: 12px;
   color: #c71822;
   background-color: #fde9ef;
   height: 40px;
   margin: 0 0 18px 0;
`
