import Apiservice from "../../services/Apiservice"

const API_URL = Apiservice.url.AUCTION_URL + "/api/secured/negotiation-offer"

// function NegotiationOfferService(){
export function requestForDeal(payload, callback) {
   return async () => {
      const resp = await Apiservice.invokePost(API_URL + "/request-deal", payload)
      if (resp.status === 200) {
         if (callback) {
            callback(resp.data.data)
         }
      }
   }
}

export function findOfferBeforeByNegotiationId(negoId, dateTime, callback) {
   return async () => {
      const resp = await Apiservice.invokePost(API_URL + "/find-record-before", {
         negotiationId: negoId,
         dateTime: dateTime,
      })
      if (resp.status === 200) {
         if (callback) {
            callback(resp.data.data)
         }
      }
   }
}

export function findLastOfferByNegotiationId(negoId, callback, errCallback) {
   return async () => {
      const resp = await Apiservice.invokeGet(API_URL + "/find-last-offer-by-negotiation/" + negoId)

      console.log("RESULT LAST OFFER", resp)

      if (resp.status == 200) {
         if (callback) {
            callback(resp.data.data)
         }
      } else {
          errCallback()
      }
   }
}

export function findOfferByNegotiationId(negoId, callback) {
   return async () => {
      const resp = await Apiservice.invokeGet(API_URL + "/find-by-negotiation/" + negoId)
      if (resp.status == 200) {
         if (callback) {
            callback(resp.data.data)
         }
      }
   }
}
// }

// export default NegotiationOfferService
