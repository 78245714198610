import axios from "axios";
import toast from "react-hot-toast";
import Authentication from "./api/Authentication";
import Onboarding from "./api/Onboarding";

const url = {
    AUCTION_URL: import.meta.env.VITE_SERVER_URL,
    MASTERDATA_URL: import.meta.env.VITE_MASTERDATA_URL,
    PROMISE_URL: import.meta.env.VITE_PROMISE_URL,
}

axios.interceptors.request.use(function(config){
    return config;
})

axios.interceptors.response.use(function (response) {
    return response
}, function (err) {

    if (err.response?.status === 401
        || (err.message === "Network Error" && localStorage.getItem("refresh_token"))) {
        toast.error("Session Timeout")
        localStorage.removeItem("access_token")
        localStorage.removeItem("refresh_token")
        location.href = "/buyer-login"
    }
});

const invokeGet = (url, headers = {}) => {
    const accessToken = localStorage.getItem("access_token")
    const { companyId } = headers

    return axios.get(url, {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`,
            "companyId": companyId
        }
    }).then((res) => {
        return res;
    }).catch((err) => {
        if (err.response?.status == 401) {
            return {
                data: {
                    message: "Unauthorized"
                }
            }
        }

        return err.response;
    });
}

const invokePost = (url, body) => {
    const accessToken = localStorage.getItem("access_token")

    return axios.post(url, JSON.stringify(body), {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    }).then((res) => {
        return res;
    }).catch((err) => {
        if (err.response?.status == 401) {
            return {
                data: {
                    message: "Unauthorized"
                }
            }
        }

        return err.response;
    })
}

const invokePatch = (url, body) => {
    const accessToken = localStorage.getItem("access_token")

    return axios.patch(url, JSON.stringify(body), {
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
        }
    }).then((res) => {
        return res;
    }).catch((err) => {
        if (err.response?.status == 401) {
            return {
                data: {
                    message: "Unauthorized"
                }
            }
        }

        return err.response;
    })
}

const invokeMultipartPost = (url, formData) => {
    const accessToken = localStorage.getItem("access_token")
    return axios({
        method: "POST",
        url,
        headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Bearer ${accessToken}`
        },
        data: formData
    }).then((res) => {
        return res
    }).catch((err) => {
        if (err.response?.status == 401) {
            return {
                data: {
                    message: "Unauthorized"
                }
            }
        }

        return err.response;
    })
}

const invokeDel = (url, data) => {
    const accessToken = localStorage.getItem("access_token")
    return axios.delete(url, {
        headers: {
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
        },
        data: data
    }).then((res) => {
        return res;
    }).catch((err) => {
        if (err.response?.status == 401) {
            return {
                data: {
                    message: "Unauthorized"
                }
            }
        }

        return err.response;
    });
}


const Apiservice = {
    url,
    invokeGet,
    invokePost,
    invokePatch,
    invokeMultipartPost,
    invokeDel,
    Authentication,
    Onboarding
}



export default Apiservice;