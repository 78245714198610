import React, { useEffect, useState } from "react";
import NegotiationOfferItemService from "../../services/NegotiationOfferItemService";
import {Button} from "react-bootstrap";
import { useDispatch } from "react-redux";
import MuiDataTable from "../../components/MuiDataTable";
import {
    TableBody,
    TableCell,
    TableHead,
    TableRow
  } from "@mui/material";
import Formservice from "../../services/Formservice";
import { Modal } from "rsuite";

const NegotiationOfferDetail = (params) => {
    const {offer, isOpen, fnCloseAndOpenModal} = params
    const [offerItems, setOfferItems] = useState([]);
    const dispatch = useDispatch();
    const negotiationOfferItemService = new NegotiationOfferItemService()
    const tableColumns = ['No', 'Item', 'Qty', 'Capable Qty', 'Offer'];

    useEffect(() => {
        handleGetOfferDetail();
    }, []);

    const handleGetOfferDetail = () => {
        dispatch(negotiationOfferItemService.findByNegotiationOffer(offer._id, (res) => {
            setOfferItems(res);
        }))
    }

    const handleOpenModal = () => {
        if(isOpen){
            fnCloseAndOpenModal(false)
        }else{
            fnCloseAndOpenModal(true)
        }
    }

    return (
        <Modal
            open={isOpen}
            full 
            overflow={true} 
            backdrop={true}
        >
            <Modal.Header>
                Offer Detail Log
            </Modal.Header>
            <Modal.Body>
                <MuiDataTable.CustomTable
                    showHeader={false}
                >
                    <TableHead>
                        <TableRow>
                            {tableColumns.map((column, index) => (
                                <TableCell
                                    key={index}
                                    className="py-2"
                                    sx={{
                                    whiteSpace: "nowrap",
                                    }}
                                >
                                    {column}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {offerItems.map((item, index) => (
                        <TableRow>
                            <TableCell width={10}>{index + 1}</TableCell>
                            <TableCell>{item.negotiationItem.procurementItem.itemName}</TableCell>
                            <TableCell>{item.negotiationItem.procurementItem.quantity}</TableCell>
                            <TableCell>{item.capableQty}</TableCell>
                            <TableCell>{Formservice.CurrencyFormatter(item.offer)}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </MuiDataTable.CustomTable>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleOpenModal}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default NegotiationOfferDetail;