const initialState = {
    isSidemenuExpanded: true,
    isAppLoading: false
}

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'EXPAND_SIDEMENU':
            return {
                ...state,
                isSidemenuExpanded: action.payload
            }
        case 'SET_APP_LOADING':
            return {
                ...state,
                isAppLoading: action.payload
            }
        default:
            return state
    }
}

export default appReducer