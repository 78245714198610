import React, { useEffect, useMemo, useLayoutEffect, useRef, useState, useCallback } from "react"
import io from "socket.io-client"
import toast from "react-hot-toast"
import { useParams } from "react-router"
import { differenceInSeconds } from "date-fns"
import { connect, useDispatch } from "react-redux"
import { orderBy as _orderBy } from "lodash"
import { Col, Row } from "react-bootstrap"

// Assets
import cancelSubmitOfferIcon from "../../assets/images/FolderCircleX.svg"
import warningIcon from "../../assets/images/WarningIcon.svg"
import IconCloseProcess from "../../assets/images/IconCloseProcess.svg"

// Components
import { GreyCard } from "../../components/styled/Generalcard"
import Buttonaction from "../../components/Buttonaction"
import VendorAuctionTimer from "./VendorAuctionTimer"
import VendorAuctionRank from "./VendorAuctionRank"
import VendorAuctionInfo from "./VendorAuctionInfo"
import ModalForm from "../../components/Modalform"
import ErrorField from "../../components/Errorfield"
import SubmitModal from "../../components/Submitmodal"
import VendorAuctionStyles from "./VendorAuctionStyles"
import VendorAuctionMeritPoint from "./VendorAuctionMeritPoint"
import VendorAuctionOfferBubble from "../../components/VendorAuctionOfferBubble"
import VendorAuctionDisqualificationForm from "./VendorAuctionDisqualificationForm"
import Dateinfo from "../../components/Dateinfo"

// Redux
import {
   fetchProcurementItemsPublic,
   getProcurementAuctionPublic,
   getProcurementPublic,
   fetchProcurementUnitItems,
   fetchProcurementVendorsWithoutPagination,
   fetchProcurementVendorsSimulationWithoutPagination,
} from "../../redux/action/procurementAction"
import { getMyProfile } from "../../redux/action/accountAction"
import { getInitialPriceV2, getVendorItems } from "../../redux/action/auctionAction"

// #
import Constant from "../../../constant"
import Formservice from "../../services/Formservice"
import VendorAuctionItemList from "./VendorAuctionItemList"
import VendorAuctionHeader from "./VendorAuctionHeader"
import { Message, toaster } from "rsuite"
import { fetchMasterData } from "../../redux/action/masterDataAction"

function VendorAuction({
   // dataProcurementItems,
   dataProfile,
   dataMasters,
}) {
   const dispatch = useDispatch()
   const { auctionId } = useParams()
   const modalSubmitOffer = useRef()
   const modalCancelationAuctionRef = useRef()
   const modalStopAuctionRef = useRef()
   const leaderboardRef = useRef(null)

   const [offerLogs, setOfferLogs] = useState([])
   const [myLastOffer, setMyLastOffer] = useState(0)
   const [errorFields, setErrorFields] = useState([])
   const [initialPrice, setInitialPrice] = useState(0)
   const [durationLeft, setDurationLeft] = useState(null)
   const [durationStart, setDurationStart] = useState(null)
   const [_remainingTime, setRemainingTime] = useState(null)
   const [auctionDuration, setAuctionDuration] = useState(0)
   const [auctionStatus, setAuctionStatus] = useState("NOT_STARTED")
   const [dataDisqualification, setDataDisqualification] = useState({})
   const [errorInputMessage, setErrorInputMessage] = useState("")
   const [maximumOffer, setMaximumOffer] = useState(undefined)
   const [dataProcurement, setDataProcurement] = useState({})
   const [procurementId, setProcurementId] = useState(null)
   const [participant, setParticipant] = useState({})
   const [autoReducer, setAutoReducer] = useState(null)
   const [finalOffer, setFinalOffer] = useState(null)
   const [cacheFinalOffer, setCacheFinalOffer] = useState(null)
   const [dataUnitItems, setDataUnitItems] = useState([])
   const [accountId, setAccountId] = useState("")
   const [dataAuction, setDataAuction] = useState({
      startTime: new Date(Date.now()),
      endTime: new Date(Date.now()),
   })
   const [leaderboard, setLeaderboard] = useState({
      offerLogs: [],
      ranks: [],
   })
   const [offer, setOffer] = useState({
      email: "",
      priceTooLow: 0,
      priceDisqualified: 0,
      auctionId: undefined,
      procurementId: undefined,
   })
   const [reqDisqualify, setReqDisqualify] = useState(false)
   const [reRenderCountdownTimer, setReRenderCountdownTimer] = useState(false)
   const [disableAutoReduce, setDisableAutoReduce] = useState(false)
   const [dataProcurementItems, setDataProcurementItems] = useState({})
   const [meritPointData, setMeritPointData] = useState({})
   const [vendorAdministrationPassed, setVendorAdministrationPassed] = useState(false)
   const [myCapableQty, setMyCapableQty] = useState(null)
   const [stopTimer, setStopTimer] = useState(false)
   const [procurementVendorData, setProcurementVendorData] = useState({})

   const RenderVendorAuctionTimer = () => {
      return (
         <VendorAuctionTimer
            stopTimer={stopTimer}
            dataAuction={dataAuction}
            auctionDuration={auctionDuration}
            durationLeft={durationLeft}
            dataProcurement={dataProcurement}
            durationStart={durationStart}
         />
      )
   }

   // stopped session handler

   // const [sessionStopped, setSessionStopped] = useState(false)
   // useEffect(() => {
   //    if(sessionStopped){
   //       modalStopAuctionRef.current.show({
   //          modalTitle: "",
   //          title: "Session Stopped.",
   //          description: <p>The session has been stopped by the buyer. Please check your email for more details.</p>,
   //       })
   //    }
   // }, [sessionStopped])

   // Tell react to re-render the component when the state has changed
   useEffect(() => {
      if (reRenderCountdownTimer) {
         // Set state back to false
         setReRenderCountdownTimer(false)
      }
   }, [reRenderCountdownTimer])

   useEffect(() => {
      if (dataAuction?.auctionType === "OPEN") {
         if (leaderboard?.offerLogs.length > 0) {
            const lastOffer = leaderboard?.offerLogs[0].totalOffer
            if (autoReducer) {
               setFinalOffer(Number.parseFloat(lastOffer - autoReducer).toFixed(2))
               setCacheFinalOffer(lastOffer - autoReducer)
            } else {
               setFinalOffer(Number.parseFloat(lastOffer).toFixed(2))
               setCacheFinalOffer(lastOffer)
            }
         }
      }
   }, [leaderboard])

   let ENDPOINT = import.meta.env.VITE_SERVER_URL

   const socketRef = useRef()
   const logWrapperRef = useRef()
   const modalDisqualificationRef = useRef()
   const offersRef = useRef({})

   useEffect(() => {
      if (dataUnitItems.length > 0) {
         let totalOffer = 0
         for (let index = 0; index < dataUnitItems.length; index++) {
            const element = dataUnitItems[index]

            totalOffer = totalOffer + element.qty * element.offer
         }
         setFinalOffer(Number.parseFloat(totalOffer).toFixed(2))
      }
   }, [dataUnitItems])

   // This function run for handle auto reduce input in open status type
   const handleOpenTypeAutoReduce = (reducerValue) => {
      setAutoReducer(+reducerValue)
      if (reducerValue == 0 || !reducerValue) {
         if (leaderboard?.offerLogs.length > 0) {
            setFinalOffer(Number.parseFloat(leaderboard.offerLogs[0].totalOffer).toFixed(2))
         }
         setFinalOffer(Number.parseFloat(initialPrice).toFixed(2))
      }
      setFinalOffer(() => {
         return Number.parseFloat(cacheFinalOffer - reducerValue).toFixed(2)
      })
   }

   // This function run for handle auto reduce input in close status type
   const handleCloseTypeAutoReduce = (reducerValue) => {
      setAutoReducer(+reducerValue)
      if (reducerValue == 0 || !reducerValue) {
         if (myLastOffer > 0) {
            setFinalOffer(myLastOffer - reducerValue)
         } else if (myLastOffer == 0) {
            setFinalOffer(0)
         }
      }
      setFinalOffer(() => myLastOffer - reducerValue)
   }

   const [isClientTimeSync, setIsClientTimeSync] = useState(true)
   const [errSyncMessage, setErrSyncMessage] = useState("")

   const setupSocket = (_dataProfile, _dataAuction, email) => {
      /**
       * setup socket io
       * ----------------
       */
      socketRef.current = io(ENDPOINT + `/company-${_dataAuction?.companyId}`)

      /**
       * emitters
       * ----------------
       */

      socketRef.current.emit(Constant.socketEvents.VENDOR_JOINED, {
         username: _dataProfile?.account?.email,
         auctionId,
         vendorClientTime: new Date(),
      })

      /**
       * receivers
       * ----------------
       */
      socketRef.current.on("connect", () => {
         console.log("Socket connecting succesfully!")
         const isServerDown = localStorage.getItem("server_down")
         if (isServerDown) {
            location.reload()
            localStorage.removeItem("server_down")
         }
      })

      // this will fired when socket failed to connect or reconnection
      socketRef.current.on("connect_error", () => {
         alert(
            "Something unexpected occurs in the server (crash or stop), the system will automatically cancel all running auctions and page will be refreshed when server is running again.",
         )
         setStopTimer(true)
         localStorage.setItem("server_down", true)
      })

      socketRef.current.on(Constant.socketEvents.FETCH_PROCUREMENT_UNIT_ITEMS, (data) => {})

      socketRef.current.on(Constant.socketEvents.FETCH_VENDOR_OFFERS, (data) => {
         setMyLastOffer(data.lastOffer)
      })

      socketRef.current.on(Constant.socketEvents.VENDOR_JOINED, (_offerLogs) => {
         setOfferLogs(_offerLogs)
      })

      socketRef.current.on(Constant.socketEvents.VENDOR_MAKE_OFFER_SUCCESS, (_offerDetail) => {
         setOfferLogs((v) => v.concat(_offerDetail))
      })

      socketRef.current.on(Constant.socketEvents.AUCTION_LEADERBOARD, (_leaderboard) => {
         setLeaderboard(_leaderboard)
         localStorage.setItem("leaderboard", JSON.stringify(_leaderboard))
         // leaderboardRef.current = _leaderboard
         if (_leaderboard && _leaderboard.length > 0) {
            setMaximumOffer(_leaderboard?.offerLogs[0]?.totalOffer)
            setFinalOffer(Number.parseFloat(_leaderboard?.offerLogs[0]?.totalOffer).toFixed(2))
         }
      })

      socketRef.current.on(Constant.socketEvents.VENDOR_MAKE_OFFER_ERROR, (_err) => {
         toast.error(_err.msg)
         setErrorFields((v) =>
            v.concat({
               param: "totalOffer",
               msg: _err.msg,
            }),
         )

         setTimeout(() => {
            setErrorFields([])
         }, 1000)
      })

      // socketRef.current.on("disconnected", () => {
      //    setTimeout(() => {
      //       reconnectSocketHandler()
      //    }, 10000)
      // })

      socketRef.current.on(Constant.socketEvents.AUCTION_COUNTDOWN_TIMER, (_timer) => {
         setDurationLeft(_timer.durationLeft)
      })

      socketRef.current.on(Constant.socketEvents.AUCTION_COUNTDOWN_BEGIN_TIMER, (_timer) => {
         setDurationStart(_timer.durationLeft)
      })

      socketRef.current.on(Constant.socketEvents.AUCTION_STARTED, () => {
         setAuctionStatus("ON_GOING")

         // to fix leading price set to 0 when auction started
         socketRef.current.emit(Constant.socketEvents.VENDOR_JOINED, {
            username: _dataProfile?.account?.email,
            auctionId,
            vendorClientTime: new Date(),
         })
      })

      socketRef.current.on(Constant.socketEvents.AUCTION_STOPPED, () => {
         socketRef.current.emit(Constant.socketEvents.SET_EMPTY_USER_ONLINE_LIST, {})
         socketRef.current.disconnect()
         setAuctionStatus("DONE")
         localStorage.removeItem("autoReducer")
         toast.success("Auction has been finished")
         modalSubmitOffer.current.hide()
      })

      socketRef.current.on(Constant.socketEvents.AUCTION_FORCE_STOPPED, () => {
         socketRef.current.emit(Constant.socketEvents.SET_EMPTY_USER_ONLINE_LIST, {})
         socketRef.current.disconnect()
         setAuctionStatus("DONE")
         modalStopAuctionRef.current.show({
            modalTitle: "",
            title: "Session Stopped.",
            description: <p>The session has been stopped by the buyer. Please check your email for more details.</p>,
         })
         toast.success("Auction has been stopped")
      })

      socketRef.current.on(Constant.socketEvents.UPDATE_SINGLE_OFFER, (_offer) => {
         // for disqualified purpose (when vendor hit request dusqualified or buyer hit disqualified the offer)
         // set state in offer bubble with targeted offer ID to disqualified
         const isOfferDisqualified = _offer.priceDisqualified
         if (isOfferDisqualified) {
            const disqualifiedOfferId = _offer._id
            offersRef.current[disqualifiedOfferId].disqualifyOffer()
         }
      })

      socketRef.current.on(Constant.socketEvents.AUCTION_VENDOR_OFFERS, (_auctionVendorOffers) => {
         // this purpose is only for open auction if some of vendor's offer is disqualified by buyer
         const isOpenAuction = dataAuction?.auctionType === "OPEN"
         if (isOpenAuction) {
            setOfferLogs(_auctionVendorOffers)
         }
      })

      socketRef.current.on(Constant.socketEvents.AUCTION_UPDATE_COUNTDOWN_TIMER, ({ extendTimer }) => {
         setReRenderCountdownTimer(extendTimer)
      })

      socketRef.current.on(Constant.socketEvents.VENDOR_MAKE_OFFER_ITEM_SUCCESS, (data) => {
         setMyLastOffer(data[0].unitOffer)
      })

      socketRef.current.on(Constant.socketEvents.REFRESH_BUYER_AUCTION_VENDOR_LIST, (data) => {
         const { disqualifiedAndRevertToVendorLastOffer } = data
         dispatch(
            getMyProfile((_dataProfile) => {
               const _accountId = _dataProfile.account._id

               dispatch(
                  getProcurementAuctionPublic({ auctionId }, (auction) => {
                     const _procurementId = auction.procurementId
                     const language = window.localStorage.getItem("lang")
                     dispatch(
                        fetchMasterData(language, (masterData) => {
                           dispatch(
                              getProcurementPublic({ procurementId: _procurementId }, (procurement) => {
                                 if (auction.auctionModel !== Constant.AUCTION_MODEL.SIMULATION) {
                                    dispatch(
                                       fetchProcurementVendorsWithoutPagination(
                                          { procurementId: auction.procurementId },
                                          (res) => {
                                             const procurementVendors = res.data
                                             if (procurement.biddingType === "UNIT_PRICE") {
                                                funcSetMeritPointDataAndLastOfferForUnit(
                                                   auction,
                                                   procurementVendors,
                                                   _accountId,
                                                   auctionId,
                                                   disqualifiedAndRevertToVendorLastOffer,
                                                )
                                             } else {
                                                funcSetMeritPointDataAndLastOfferForBulk(
                                                   auction,
                                                   procurementVendors,
                                                   _accountId,
                                                   _procurementId,
                                                   disqualifiedAndRevertToVendorLastOffer,
                                                )
                                             }
                                          },
                                       ),
                                    )
                                 } else {
                                    dispatch(
                                       fetchProcurementVendorsSimulationWithoutPagination(
                                          { procurementId: auction.procurementId },
                                          (res) => {
                                             const procurementVendors = res.data
                                             if (procurement.biddingType === "UNIT_PRICE") {
                                                funcSetMeritPointDataAndLastOfferForUnit(
                                                   auction,
                                                   procurementVendors,
                                                   _accountId,
                                                   auctionId,
                                                   disqualifiedAndRevertToVendorLastOffer,
                                                )
                                             } else {
                                                funcSetMeritPointDataAndLastOfferForBulk(
                                                   auction,
                                                   procurementVendors,
                                                   _accountId,
                                                   _procurementId,
                                                   disqualifiedAndRevertToVendorLastOffer,
                                                )
                                             }
                                          },
                                       ),
                                    )
                                 }
                              }),
                           )
                        }),
                     )
                  }),
               )
            }),
         )
      })

      socketRef.current.on(Constant.socketEvents.RESULT_CANCEL_AUCTION, (dataAuction) => {
         setDataAuction(dataAuction)
         modalCancelationAuctionRef.current.show({
            modalTitle: "",
            title: "Session Canceled.",
            description: <p>The session canceled by the buyer.</p>,
         })
      })

      socketRef.current.on(Constant.socketEvents.VALIDATE_VENDOR_CLIENT_TIME, ({ dateSync, timeSync, message }) => {
         if (!dateSync || !timeSync) {
            setIsClientTimeSync(false)
            setErrSyncMessage(message)
         } else {
            setIsClientTimeSync(true)
         }
      })

      return () => {
         socketRef.current.disconnect()
      }
   }

   const funcSetMeritPointDataAndLastOfferForUnit = (auction, procurementVendors, accountId, auctionId, revert) => {
      const vendor = procurementVendors?.find((vendor) => vendor.accountId.toString() === accountId.toString())
      const vendorId = vendor?._id
      dispatch(
         getVendorItems({ vendorId }, (vendorItems) => {
            dispatch(
               fetchProcurementUnitItems({ auctionId }, (unitItems) => {
                  const [item] = unitItems
                  const [vendorItem] = vendorItems.filter((_item) => _item.procurementItemId === item.procurementItemId)
                  setMeritPointData((prev) => ({
                     ...prev,
                     ...vendorItem,
                     administrationPassed: vendor.administrationPassed,
                  }))

                  const procurementVendorItem = vendorItem
                  // when buyer disqualified vendor's request disqualify
                  if (revert) {
                     setMyLastOffer(procurementVendorItem.lastPrice)
                  }
               }),
            )
         }),
      )
   }

   const funcSetMeritPointDataAndLastOfferForBulk = (auction, procurementVendors, accountId, procurementId, revert) => {
      const participantData = procurementVendors.filter(
         (v) => v.accountId == accountId && v.procurementId == procurementId,
      )
      const administrationPassed = participantData[0].administrationPassed
      const technicalScore = participantData[0].technicalScore
      const priceScore = participantData[0].priceScore

      const newParticipantData = { ...participantData }
      newParticipantData.administrationPassed = administrationPassed
      newParticipantData.technicalScore = technicalScore
      newParticipantData.priceScore = priceScore

      setMeritPointData(newParticipantData)

      // when buyer disqualified vendor's request disqualify
      if (revert) {
         const vendor = procurementVendors.filter((v) => v.accountId == accountId)
         const vendorLastPrice = vendor[0].lastPrice
         if (auction.auctionModel === Constant.AUCTION_MODEL.SIMULATION && vendorLastPrice === 0) {
            setMyLastOffer(auction.initialPrice)
         } else {
            setMyLastOffer(vendorLastPrice)
         }
      }
   }

   const handleReqDisqualify = (_data) => {
      setDataDisqualification(_data)

      modalDisqualificationRef.current.show()
   }

   const requestForDisqualification = (_data) => {
      if (dataProfile) {
         socketRef.current.emit(Constant.socketEvents.VENDOR_REQ_FOR_DISQUALIFICATION, _data)

         modalDisqualificationRef.current.hide()
      }
   }

   const beforeSubmitOffer = () => {
      modalSubmitOffer.current.show({
         title: "Are you sure?",
         modalTitle: "",
         description: "Once you have submitted, you need to ask to disqualify first to cancel your last offer",
      })
   }

   const handleSendOffer = () => {
      if (auctionStatus !== Constant.auctionStatus.DONE) {
         if (!finalOffer) {
            // If vendor bid 0 IDR or null
            toast.error(
               <p>
                  Couldn't send <strong>0</strong> offer
               </p>,
               { duration: 10000 },
            )
         } else {
            if (dataAuction?.auctionType === "OPEN") {
               // Check if vendors bid exist
               // console.log(leaderboard)
               // console.log({
               //    initialPrice: initialPrice - dataAuction.minimalDecrement,
               // })

               if (leaderboard?.offerLogs.length > 0 && !leaderboard?.offerLogs[0].totalOffer == 0) {
                  const currentLeading = leaderboard.leadingPrice
                  const minimunFromLastLeading = currentLeading - dataAuction?.minimalDecrement

                  if (finalOffer > minimunFromLastLeading) {
                     toast.error(
                        // Total offer should be lower than leading price
                        <p>
                           Your offer should be lower than{" "}
                           <strong>
                              {Formservice.CurrencyFormatter(currentLeading, {
                                 currency: procurementVendorData?.currency,
                              })}
                           </strong>{" "}
                           with minimal decrement{" "}
                           <strong>
                              {Formservice.CurrencyFormatter(dataAuction?.minimalDecrement, {
                                 currency: procurementVendorData?.currency,
                              })}
                           </strong>
                        </p>,
                        { duration: 10000, style: { minWidth: "400px" } },
                     )
                  } else if (!finalOffer) {
                     // If vendor bid 0 IDR or null
                     toast.error(
                        <p>
                           Your offer should be lower than{" "}
                           <strong>
                              {Formservice.CurrencyFormatter(currentLeading, {
                                 currency: procurementVendorData?.currency,
                              })}
                           </strong>{" "}
                           with minimal decrement{" "}
                           <strong>
                              {Formservice.CurrencyFormatter(dataAuction?.minimalDecrement, {
                                 currency: procurementVendorData?.currency,
                              })}
                           </strong>{" "}
                           and above <strong>0</strong>
                        </p>,
                        { duration: 10000, style: { minWidth: "400px" } },
                     )
                  } else {
                     socketRef.current.emit(Constant.socketEvents.VENDOR_MAKE_OFFER, {
                        ...offer,
                        totalOffer: finalOffer,
                        createdAt: new Date(Date.now()),
                        updatedAt: new Date(Date.now()),
                        email: dataProfile.account.email,
                     })
                  }
               } else {
                  const minimumFromInitialPrice = initialPrice - dataAuction.minimalDecrement

                  if (finalOffer > minimumFromInitialPrice) {
                     toast.error(
                        // Total offer should be lower than initial price
                        <p>
                           Your offer should be lower than{" "}
                           <strong>
                              {Formservice.CurrencyFormatter(initialPrice, {
                                 currency: procurementVendorData?.currency,
                              })}
                           </strong>{" "}
                           with minimal decrement{" "}
                           <strong>
                              {Formservice.CurrencyFormatter(dataAuction?.minimalDecrement, {
                                 currency: procurementVendorData?.currency,
                              })}
                           </strong>
                        </p>,
                        { duration: 10000, style: { minWidth: "400px" } },
                     )
                  } else if (!finalOffer) {
                     // If vendor bid 0 IDR or null
                     toast.error(
                        <p>
                           Your offer should be lower than{" "}
                           <strong>
                              {Formservice.CurrencyFormatter(initialPrice, {
                                 currency: procurementVendorData?.currency,
                              })}
                           </strong>{" "}
                           with minimal decrement{" "}
                           <strong>
                              {Formservice.CurrencyFormatter(dataAuction?.minimalDecrement, {
                                 currency: procurementVendorData?.currency,
                              })}
                           </strong>{" "}
                           and above <strong>0</strong>
                        </p>,
                        { duration: 10000, style: { minWidth: "400px" } },
                     )
                  } else {
                     socketRef.current.emit(Constant.socketEvents.VENDOR_MAKE_OFFER, {
                        ...offer,
                        totalOffer: finalOffer,
                        createdAt: new Date(Date.now()),
                        updatedAt: new Date(Date.now()),
                        email: dataProfile.account.email,
                     })
                  }
               }
            }

            if (dataAuction?.auctionType === "CLOSE") {
               // when vendor has made their first bid
               if (myLastOffer > 0) {
                  const minimumNextBid = myLastOffer - dataAuction?.minimalDecrement
                  if (finalOffer <= minimumNextBid) {
                     setErrorInputMessage("")
                     socketRef.current.emit(Constant.socketEvents.VENDOR_MAKE_OFFER, {
                        ...offer,
                        totalOffer: finalOffer,
                        createdAt: new Date(Date.now()),
                        updatedAt: new Date(Date.now()),
                        email: dataProfile.account.email,
                     })
                  } else if (!finalOffer) {
                     // If vendor bid 0 IDR or null
                     toast.error(
                        <p>
                           Your offer should be lower than{" "}
                           <strong>
                              {Formservice.CurrencyFormatter(myLastOffer, {
                                 currency: procurementVendorData?.currency,
                              })}
                           </strong>{" "}
                           with minimal decrement{" "}
                           <strong>
                              {Formservice.CurrencyFormatter(dataAuction?.minimalDecrement, {
                                 currency: procurementVendorData?.currency,
                              })}
                           </strong>{" "}
                           and above <strong>0</strong>
                        </p>,
                        { duration: 10000, style: { minWidth: "400px" } },
                     )
                  } else {
                     // Total offer should be lower than last offer
                     toast.error(
                        <p>
                           Your offer should be lower than{" "}
                           <strong>
                              {Formservice.CurrencyFormatter(myLastOffer, {
                                 currency: procurementVendorData?.currency,
                              })}
                           </strong>{" "}
                           with minimal decrement{" "}
                           <strong>
                              {Formservice.CurrencyFormatter(dataAuction?.minimalDecrement, {
                                 currency: procurementVendorData?.currency,
                              })}
                           </strong>
                           ,
                        </p>,
                        { duration: 10000, style: { minWidth: "400px" } },
                     )
                  }
               } else {
                  // if (dataProfile && errorFields.length === 0) {
                  socketRef.current.emit(Constant.socketEvents.VENDOR_MAKE_OFFER, {
                     ...offer,
                     totalOffer: finalOffer - autoReducer,
                     createdAt: new Date(Date.now()),
                     updatedAt: new Date(Date.now()),
                     email: dataProfile.account.email,
                  })
                  // }
               }
            }
         }
      } else {
         toast.error("Can't submit offer. Auction has been finished")
      }
      modalSubmitOffer.current.hide()
   }

   useEffect(() => {
      if (dataAuction?.auctionType === "CLOSE" && myLastOffer > 0) {
         setFinalOffer(Number.parseFloat(myLastOffer - autoReducer).toFixed(2))
      }
   }, [myLastOffer])

   const handleSendTotalByUnit = () => {
      if (auctionStatus !== Constant.auctionStatus.DONE) {
         if (!parseFloat(finalOffer)) {
            // If vendor bid 0 IDR or null
            toast.error(
               <p>
                  Couldn't send <strong>0</strong> offer
               </p>,
               { duration: 10000 },
            )
         } else {
            if (dataAuction?.auctionType === "OPEN") {
               // Check if vendors bid exist
               if (leaderboard?.offerLogs.length > 0 && leaderboard?.offerLogs[0].totalOffer != 0) {
                  const currentLeading = leaderboard.leadingPrice
                  const minimunFromLastLeading = currentLeading - dataAuction?.minimalDecrement
                  if (finalOffer > minimunFromLastLeading) {
                     toast.error(
                        // Total offer should be lower than leading price
                        <p>
                           Your offer should be lower than{" "}
                           <strong>
                              {Formservice.CurrencyFormatter(currentLeading, {
                                 currency: procurementVendorData?.currency,
                              })}
                           </strong>{" "}
                           with minimal decrement{" "}
                           <strong>
                              {Formservice.CurrencyFormatter(dataAuction?.minimalDecrement, {
                                 currency: procurementVendorData?.currency,
                              })}
                           </strong>
                           ,
                        </p>,
                        { duration: 10000, style: { minWidth: "400px" } },
                     )
                  } else if (!finalOffer) {
                     // If vendor bid 0 IDR or null
                     toast.error(
                        <p>
                           Your offer should be lower than{" "}
                           <strong>
                              {Formservice.CurrencyFormatter(currentLeading, {
                                 currency: procurementVendorData?.currency,
                              })}
                           </strong>{" "}
                           and above <strong>0</strong>
                        </p>,
                        { duration: 10000, style: { minWidth: "400px" } },
                     )
                  } else {
                     let offerItems = []
                     for (const offer of dataUnitItems) {
                        const newOffer = {
                           auctionItemId: offer._id,
                           // totalOffer: offer.totalOffer,
                           // unitOffer: offer.offer,
                           totalOffer: parseFloat(offer.totalOffer).toFixed(2),
                           unitOffer: parseFloat(offer.offer).toFixed(2),
                        }
                        offerItems.push(newOffer)
                     }
                     const json = {
                        auctionId,
                        username: dataProfile?.account.email,
                        offerItems: offerItems,
                        createdAt: new Date(Date.now()),
                        updatedAt: new Date(Date.now()),
                     }
                     socketRef.current.emit(Constant.socketEvents.VENDOR_MAKE_OFFER_ITEM, json)
                  }
               } else {
                  const minimumFromInitialPrice = initialPrice - dataAuction?.minimalDecrement
                  if (finalOffer > minimumFromInitialPrice) {
                     toast.error(
                        // Total offer should be lower than initial price
                        <p>
                           Your offer should be lower than{" "}
                           <strong>
                              {Formservice.CurrencyFormatter(initialPrice, {
                                 currency: procurementVendorData?.currency,
                              })}
                           </strong>{" "}
                           with minimal decrement{" "}
                           <strong>
                              {Formservice.CurrencyFormatter(dataAuction?.minimalDecrement, {
                                 currency: procurementVendorData?.currency,
                              })}
                           </strong>
                        </p>,
                        { duration: 10000, style: { minWidth: "400px" } },
                     )
                  } else if (!finalOffer) {
                     // If vendor bid 0 IDR or null
                     toast.error(
                        <p>
                           Your offer should be lower than{" "}
                           <strong>
                              {Formservice.CurrencyFormatter(currentLeading, {
                                 currency: procurementVendorData?.currency,
                              })}
                           </strong>{" "}
                           and above <strong>0</strong>
                        </p>,
                        { duration: 10000, style: { minWidth: "400px" } },
                     )
                  } else {
                     let offerItems = []
                     for (const offer of dataUnitItems) {
                        const newOffer = {
                           auctionItemId: offer._id,
                           // totalOffer: offer.totalOffer,
                           // unitOffer: offer.offer,
                           totalOffer: parseFloat(offer.totalOffer).toFixed(2),
                           unitOffer: parseFloat(offer.offer).toFixed(2),
                        }
                        offerItems.push(newOffer)
                     }
                     const json = {
                        auctionId,
                        username: dataProfile?.account.email,
                        offerItems: offerItems,
                        createdAt: new Date(Date.now()),
                        updatedAt: new Date(Date.now()),
                     }
                     socketRef.current.emit(Constant.socketEvents.VENDOR_MAKE_OFFER_ITEM, json)
                  }
               }
            }

            if (dataAuction?.auctionType === "CLOSE") {
               // when vendor has made their first bid
               if (myLastOffer > 0) {
                  const minimumNextBid = myLastOffer - dataAuction?.minimalDecrement
                  if (finalOffer <= minimumNextBid) {
                     let offerItems = []
                     for (const offer of dataUnitItems) {
                        const newOffer = {
                           auctionItemId: offer._id,
                           // totalOffer: offer.totalOffer,
                           // unitOffer: offer.offer,
                           totalOffer: parseFloat(offer.totalOffer).toFixed(2),
                           unitOffer: parseFloat(offer.offer).toFixed(2),
                        }
                        offerItems.push(newOffer)
                     }
                     const json = {
                        auctionId,
                        username: dataProfile?.account.email,
                        offerItems: offerItems,
                        createdAt: new Date(Date.now()),
                        updatedAt: new Date(Date.now()),
                     }
                     socketRef.current.emit(Constant.socketEvents.VENDOR_MAKE_OFFER_ITEM, json)
                  } else if (!parseFloat(finalOffer)) {
                     // If vendor bid 0 IDR or null
                     toast.error(
                        <p>
                           Your offer should be lower than{" "}
                           <strong>
                              {Formservice.CurrencyFormatter(myLastOffer, {
                                 currency: procurementVendorData?.currency,
                              })}
                           </strong>{" "}
                           with minimal decrement{" "}
                           <strong>
                              {Formservice.CurrencyFormatter(dataAuction?.minimalDecrement, {
                                 currency: procurementVendorData?.currency,
                              })}
                           </strong>{" "}
                           and above <strong>0</strong>
                        </p>,
                        { duration: 10000, style: { minWidth: "400px" } },
                     )
                  } else {
                     // Total offer should be lower than last offer
                     toast.error(
                        <p>
                           Your offer should be lower than{" "}
                           <strong>
                              {Formservice.CurrencyFormatter(myLastOffer, {
                                 currency: procurementVendorData?.currency,
                              })}
                           </strong>{" "}
                           with minimal decrement{" "}
                           <strong>
                              {Formservice.CurrencyFormatter(dataAuction?.minimalDecrement, {
                                 currency: procurementVendorData?.currency,
                              })}
                           </strong>
                        </p>,
                        { duration: 10000, style: { minWidth: "400px" } },
                     )
                  }
               } else {
                  let offerItems = []
                  for (const offer of dataUnitItems) {
                     const newOffer = {
                        auctionItemId: offer._id,
                        totalOffer: parseFloat(offer.totalOffer).toFixed(2),
                        unitOffer: parseFloat(offer.offer).toFixed(2),
                     }
                     offerItems.push(newOffer)
                  }
                  const json = {
                     auctionId,
                     username: dataProfile?.account.email,
                     offerItems: offerItems,
                     createdAt: new Date(Date.now()),
                     updatedAt: new Date(Date.now()),
                  }
                  socketRef.current.emit(Constant.socketEvents.VENDOR_MAKE_OFFER_ITEM, json)
               }
            }
         }
      } else {
         toast.error("Can't submit offer. Auction has been finished")
      }
      modalSubmitOffer.current.hide()
   }

   const handleSendUnitPrice = () => {
      if (auctionStatus !== Constant.auctionStatus.DONE) {
         if (!dataUnitItems[0].offer || !dataUnitItems[0].totalOffer) {
            // If vendor bid 0 IDR or null
            toast.error(
               <p>
                  Couldn't send <strong>0</strong> offer
               </p>,
               { duration: 10000 },
            )
         } else {
            if (dataAuction?.auctionType === Constant.AUCTION_TYPE.OPEN) {
               const isReverseAuction = dataProcurement.negotiationType === Constant.NEGOTIATION_TYPE.REVERSE_AUCTION
               const isForwardAuction = dataProcurement.negotiationType === Constant.NEGOTIATION_TYPE.FORWARD_AUCTION
               const minimalDecrement = dataAuction.minimalDecrement
               const currentLeading = leaderboard.leadingPrice

               let lower = false
               let higher = false

               for (const unitItem of dataUnitItems) {
                  if (isReverseAuction) {
                     if (currentLeading) {
                        const minimumNextBid = currentLeading - minimalDecrement
                        if (unitItem.offer > minimumNextBid) {
                           higher = true
                           toast.error(
                              <p>
                                 Your offer should be lower than{" "}
                                 <strong>
                                    {Formservice.CurrencyFormatter(currentLeading, {
                                       currency: procurementVendorData?.currency,
                                    })}
                                 </strong>{" "}
                                 with minimal decrement of{" "}
                                 <strong>
                                    {Formservice.CurrencyFormatter(minimalDecrement, {
                                       currency: procurementVendorData?.currency,
                                    })}
                                 </strong>
                              </p>,
                              { duration: 10000, style: { minWidth: "400px" } },
                           )
                        } else {
                           let offerItems = []
                           for (const offer of dataUnitItems) {
                              const newOffer = {
                                 auctionItemId: offer._id,
                                 totalOffer: offer.totalOffer,
                                 unitOffer: offer.offer,
                              }
                              offerItems.push(newOffer)
                           }
                           const json = {
                              auctionId,
                              username: dataProfile?.account.email,
                              offerItems: offerItems,
                              createdAt: new Date(Date.now()),
                              updatedAt: new Date(Date.now()),
                           }

                           socketRef.current.emit(Constant.socketEvents.VENDOR_MAKE_OFFER_ITEM, json)
                           modalSubmitOffer.current.hide()
                        }
                     } else {
                        const minimumNextBid = initialPrice - minimalDecrement
                        if (unitItem.offer > minimumNextBid) {
                           higher = true
                           toast.error(
                              <p>
                                 Your offer should be lower than{" "}
                                 <strong>
                                    {Formservice.CurrencyFormatter(initialPrice, {
                                       currency: procurementVendorData?.currency,
                                    })}
                                 </strong>{" "}
                                 with minimal decrement of{" "}
                                 <strong>
                                    {Formservice.CurrencyFormatter(minimalDecrement, {
                                       currency: procurementVendorData?.currency,
                                    })}
                                 </strong>
                              </p>,
                              { duration: 10000, style: { minWidth: "400px" } },
                           )
                        } else {
                           let offerItems = []
                           for (const offer of dataUnitItems) {
                              const newOffer = {
                                 auctionItemId: offer._id,
                                 totalOffer: offer.totalOffer,
                                 unitOffer: offer.offer,
                              }
                              offerItems.push(newOffer)
                           }
                           const json = {
                              auctionId,
                              username: dataProfile?.account.email,
                              offerItems: offerItems,
                              createdAt: new Date(Date.now()),
                              updatedAt: new Date(Date.now()),
                           }

                           socketRef.current.emit(Constant.socketEvents.VENDOR_MAKE_OFFER_ITEM, json)
                           modalSubmitOffer.current.hide()
                        }
                     }
                  } else if (isForwardAuction) {
                     // if (currentLeading) {
                     //    const minimumNextBid = currentLeading - minimalDecrement
                     //    if (unitItem.offer < minimumNextBid) {
                     //       lower = true
                     //    }
                     // } else {
                     //    const minimumNextBid = initialPrice - minimalDecrement
                     //    if (unitItem.offer < minimumNextBid) {
                     //       lower = true
                     //    }
                     // }
                  }
               }
            } else if (dataAuction?.auctionType === Constant.AUCTION_TYPE.CLOSE) {
               if (myLastOffer != 0 && dataUnitItems[0].offer >= myLastOffer) {
                  // If vendor bid above last vendor offer
                  toast.error(
                     <p>
                        Your offer should be lower than{" "}
                        <strong>
                           {Formservice.CurrencyFormatter(myLastOffer, {
                              currency: procurementVendorData?.currency,
                           })}
                        </strong>{" "}
                        and above <strong>0</strong>
                     </p>,
                     { duration: 10000, style: { minWidth: "400px" } },
                  )
               } else {
                  if (myLastOffer != 0) {
                     const minimumNextBid = myLastOffer - dataAuction?.minimalDecrement
                     if (dataUnitItems[0].offer <= minimumNextBid) {
                        let offerItems = []
                        for (const offer of dataUnitItems) {
                           const newOffer = {
                              auctionItemId: offer._id,
                              totalOffer: offer.totalOffer,
                              unitOffer: offer.offer,
                           }
                           offerItems.push(newOffer)
                        }
                        const json = {
                           auctionId,
                           username: dataProfile?.account.email,
                           offerItems: offerItems,
                           createdAt: new Date(Date.now()),
                           updatedAt: new Date(Date.now()),
                        }
                        socketRef.current.emit(Constant.socketEvents.VENDOR_MAKE_OFFER_ITEM, json)
                        modalSubmitOffer.current.hide()
                     } else {
                        toast.error(
                           <p>
                              Your offer should be lower than{" "}
                              <strong>
                                 {Formservice.CurrencyFormatter(myLastOffer, {
                                    currency: procurementVendorData?.currency,
                                 })}
                              </strong>{" "}
                              with minimal decrement{" "}
                              <strong>
                                 {Formservice.CurrencyFormatter(dataAuction?.minimalDecrement, {
                                    currency: procurementVendorData?.currency,
                                 })}
                              </strong>
                           </p>,
                           { duration: 10000, style: { minWidth: "400px" } },
                        )
                     }
                  } else {
                     let offerItems = []
                     for (const offer of dataUnitItems) {
                        const newOffer = {
                           auctionItemId: offer._id,
                           totalOffer: offer.totalOffer,
                           unitOffer: offer.offer,
                        }
                        offerItems.push(newOffer)
                     }
                     const json = {
                        auctionId,
                        username: dataProfile?.account.email,
                        offerItems: offerItems,
                        createdAt: new Date(Date.now()),
                        updatedAt: new Date(Date.now()),
                     }
                     socketRef.current.emit(Constant.socketEvents.VENDOR_MAKE_OFFER_ITEM, json)
                     modalSubmitOffer.current.hide()
                  }
               }
            }
         }
      } else {
         toast.error("Can't submit offer. Auction has been finished")
      }
      modalSubmitOffer.current.hide()
   }

   const validateAutoReducer = () => {
      if (autoReducer < dataAuction?.minimalDecrement && autoReducer > 0) {
         setErrorFields((v) =>
            v.concat({
               param: "autoReducer",
               msg: `Value must be higher than ${Formservice.CurrencyFormatter(dataAuction?.minimalDecrement)}`,
            }),
         )
      } else {
         const temp = errorFields.filter((v) => v.param != "autoReducer")
         setErrorFields()
         setErrorFields(temp)
      }
   }

   /**
    * Scroll down to the latest bubble chat
    *
    */
   useEffect(() => {
      if (offerLogs.length > 0) {
         document.querySelector(`#bubble${offerLogs.length - 1}`)?.scrollIntoView({
            behavior: "smooth",
         })
      }
   }, [offerLogs, leaderboard])

   useEffect(() => {
      if (durationLeft == 0) {
         dispatch(getProcurementAuctionPublic({ auctionId }))
      }
   }, [durationLeft])

   useEffect(() => {
      dispatch(
         getMyProfile((_dataProfile) => {
            const _accountId = _dataProfile.account._id
            const accountEmail = _dataProfile.account.email

            setAccountId(_accountId)

            dispatch(
               getProcurementAuctionPublic(
                  { auctionId },
                  (auction) => {
                     const minimalDecrement = auction.minimalDecrement
                     const auctionStatus = auction.status
                     const _procurementId = auction.procurementId

                     setProcurementId(_procurementId)

                     // setup socket
                     setupSocket(_dataProfile, auction, accountEmail)

                     // set auction
                     setDataAuction(auction)

                     // set initial status
                     setAuctionStatus(auctionStatus)
                     // setSessionStopped(auctionStatus == "DONE")

                     // set auction and procurement id on offer object
                     setOffer((v) => ({
                        ...v,
                        auctionId: auctionId,
                        procurementId: _procurementId,
                     }))

                     // get procurement
                     const language = window.localStorage.getItem("lang")
                     dispatch(
                        fetchMasterData(language, (masterData) => {
                           dispatch(
                              getProcurementPublic(
                                 { procurementId: _procurementId },
                                 (procurement) => {
                                    const isTotalPrice =
                                       procurement.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE
                                    const isTotalByUnitPrice =
                                       procurement.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE_BY_UNIT
                                    const isUnitPrice =
                                       procurement.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE

                                    // set data procurement & initial offer
                                    setDataProcurement(procurement)
                                    // setFinalOffer(procurement.total)
                                    if (isTotalByUnitPrice || isUnitPrice) {
                                       dispatch(
                                          fetchProcurementUnitItems({ auctionId }, (unitItems) => {
                                             setDataUnitItems(unitItems)
                                          }),
                                       )
                                    }
                                 },
                                 () => {},
                                 masterData,
                              ),
                           )

                           // get participant
                           if (auction.auctionModel !== Constant.AUCTION_MODEL.SIMULATION) {
                              dispatch(
                                 fetchProcurementVendorsWithoutPagination(
                                    { procurementId: _procurementId },
                                    (participant) => {
                                       setParticipant(participant)
                                       const [participantData] = participant.data.filter(
                                          (v) => v.accountId == _accountId && v.procurementId == _procurementId,
                                       )
                                       const currency = masterData.currencies.find(
                                          (currency) => currency._id === participantData.currencyId,
                                       ).value
                                       setProcurementVendorData((prev) => ({
                                          ...prev,
                                          ...participantData,
                                          currency: currency,
                                       }))
                                       setMeritPointData(participantData)
                                       setVendorAdministrationPassed(participantData.administrationPassed)
                                    },
                                 ),
                              )
                           } else {
                              dispatch(
                                 fetchProcurementVendorsSimulationWithoutPagination(
                                    { procurementId: _procurementId },
                                    (participant) => {
                                       setParticipant(participant)
                                       const [participantData] = participant.data.filter(
                                          (v) => v.accountId == _accountId && v.procurementId == _procurementId,
                                       )
                                       const currency = masterData.currencies.find(
                                          (currency) => currency._id === participantData.currencyId,
                                       ).value
                                       setProcurementVendorData((prev) => ({
                                          ...prev,
                                          ...participantData,
                                          currency: currency,
                                       }))
                                       setMeritPointData(participantData)
                                       setVendorAdministrationPassed(participantData.administrationPassed)
                                    },
                                 ),
                              )
                           }
                        }),
                     )

                     // set reducer based on the minimum decrement
                     // setAutoReducer(minimalDecrement);

                     // fetch procurement item
                     dispatch(
                        fetchProcurementItemsPublic(
                           {
                              procurementId: auction.procurementId,
                              limit: 10000,
                           },
                           (procurementItems) => {
                              setDataProcurementItems(procurementItems)
                           },
                        ),
                     )
                  },
                  (_err) => {
                     toast.error("Failed to get auction data")
                  },
               ),
            )
         }),
      )

      const autoReducer = parseInt(localStorage.getItem("autoReducer"))
      if (autoReducer) {
         setAutoReducer(autoReducer)
      }

      // return localStorage.removeItem("checkTimer")
   }, [])

   useEffect(() => {
      const vendor = participant.data?.find((vendor) => vendor.accountId.toString() === accountId.toString())
      const vendorId = vendor?._id

      if (
         dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE &&
         Object.entries(participant).length > 0 &&
         dataUnitItems.length > 0
      ) {
         dispatch(
            getVendorItems({ vendorId }, (vendorItems) => {
               const [item] = dataUnitItems
               const [vendorItem] = vendorItems.filter((_item) => _item.procurementItemId === item.procurementItemId)
               setMyCapableQty(vendorItem)
               setMeritPointData((prev) => ({
                  ...prev,
                  ...vendorItem,
                  administrationPassed: vendor.administrationPassed,
               }))
            }),
         )
      }
   }, [participant, dataUnitItems])

   useEffect(() => {
      if (procurementId) {
         dispatch(
            getInitialPriceV2({ auctionId }, (res) => {
               setInitialPrice(res.initialPrice)
            }),
         )
      }
   }, [procurementId])

   useEffect(() => {
      setAuctionDuration(differenceInSeconds(new Date(dataAuction?.endTime), new Date(dataAuction?.startTime)))
      const remainingTime = differenceInSeconds(new Date(Date.now()), dataAuction?.startTime)

      setRemainingTime(remainingTime)
   }, [dataAuction])

   const reconnectSocketHandler = () => {
      setupSocket()
   }

   useEffect(() => {
      if (procurementId) {
         // if (!isClientTimeSync) {
         socketRef.current.emit(Constant.socketEvents.CHECKING_VENDOR_CLIENT_TIME, {
            auctionId,
            vendorClientTime: new Date(),
         })
         // }
      }
   }, [durationStart, durationLeft])

   // useEffect(async () => {
   //    if (isClientTimeSync) {
   //       toaster.push(
   //          <Message
   //             showIcon
   //             type="warning"
   //             header="Warning"
   //             style={{
   //                // background: "#F9F1DA",
   //                // color: "#454545",
   //                fontSize: "14px",
   //                fontWeight: "600",
   //                maxWidth: "50%",
   //             }}
   //          >
   //             {errSyncMessage}
   //          </Message>,
   //          { placement: "topCenter" },
   //       )
   //    }
   // }, [isClientTimeSync])

   // Variables
   const isUnitPrice = dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE
   const isTotalPrice = dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE
   const isTotalByUnitPrice = dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE_BY_UNIT

   const isMeritPoint = dataProcurement?.evaluationMethod === Constant.EVALUATION_METHODS.MERIT_POINT
   const isSimulation = dataAuction?.auctionModel === Constant.AUCTION_MODEL.SIMULATION

   return (
      <>
         <Col lg={12} style={{ padding: "5% 60px 0 60px", position: "relative" }}>
            {!isClientTimeSync && (
               <Row className="mt-3">
                  <Col className="px-0">
                     <div
                        className="mt-3 d-flex align-items-center px-3"
                        style={{
                           backgroundColor: "#fde9ef",
                           fontSize: "12px",
                           fontWeight: "500",
                           borderRadius: "5px",
                           height: "40px",
                           marginLeft: "30px",
                        }}
                     >
                        <img src={warningIcon} className="mr-2" />
                        <p>
                           Our system detected that your local computer date & time seems to haven't been set correctly,
                           or you have set it manually. Please make sure to set the date & time by configure it as
                           automatically.
                        </p>
                     </div>
                  </Col>
               </Row>
            )}
            {dataAuction?.status === "CANCELED" && (
               <Row className={`${!isClientTimeSync ? "mt-3" : "mt-0"}`}>
                  <Col className="px-0">
                     <div
                        className="mt-3 d-flex align-items-center px-3"
                        style={{
                           backgroundColor: "#fde9ef",
                           color: "#909090",
                           fontSize: "12px",
                           fontWeight: "400",
                           borderRadius: "5px",
                           height: "40px",
                           marginLeft: "30px",
                        }}
                     >
                        <img src={warningIcon} className="mr-2" />
                        <p>
                           This session canceled by <strong> {dataAuction?.canceledBy} </strong>
                           at
                           <strong>
                              {" "}
                              <Dateinfo
                                 date={dataAuction?.updatedAt}
                                 format="dd/mm/yyyy HH:MM"
                                 style={{ all: "unset" }}
                              />
                           </strong>{" "}
                           The cancelation reason is{" "}
                           <strong
                              style={{ display: "inline-block", lineHeight: "0px" }}
                              dangerouslySetInnerHTML={{
                                 __html: dataAuction?.cancelReason,
                              }}
                           />
                        </p>
                     </div>
                  </Col>
               </Row>
            )}

            <Row>
               <Col lg={12} className="mt-3 px-lg-3 px-sm-0">
                  <VendorAuctionHeader dataProcurement={dataProcurement} dataAuction={dataAuction} />
               </Col>
            </Row>

            {dataAuction && auctionDuration && (
               <Row className="mt-4">
                  <Col
                     lg={dataProcurement?.evaluationMethod === "MERIT_POINT" ? 3 : 4}
                     sm={12}
                     className="px-lg-3 px-sm-0 mb-lg-0 mb-sm-4"
                  >
                     <VendorAuctionInfo dataAuction={dataAuction} dataProcurement={dataProcurement} />
                  </Col>

                  {isMeritPoint && meritPointData && (
                     <Col lg={3} sm={6} className="px-lg-3 pl-sm-0">
                        <VendorAuctionMeritPoint
                           dataUnitItems={dataUnitItems}
                           participant={participant.data}
                           dataAuction={dataAuction}
                           dataProcurement={dataProcurement}
                           accountId={accountId}
                           meritPointData={meritPointData}
                           vendorAdministrationPassed={vendorAdministrationPassed}
                        />
                     </Col>
                  )}

                  <Col
                     lg={dataProcurement?.evaluationMethod === Constant.EVALUATION_METHODS.MERIT_POINT ? 3 : 4}
                     sm={6}
                     className="px-lg-3 pr-sm-0"
                  >
                     <VendorAuctionRank
                        dataAuction={dataAuction}
                        dataProcurement={dataProcurement}
                        accountId={dataProfile?.account?._id}
                        leaderboard={leaderboard}
                        leaderboardRef={leaderboardRef}
                        myLastOffer={myLastOffer}
                        participant={participant}
                        initialPrice={initialPrice}
                        // leaderboard.leadingPrice
                     />
                  </Col>

                  <Col
                     lg={dataProcurement?.evaluationMethod === "MERIT_POINT" ? 3 : 4}
                     className="px-lg-3 px-sm-0 mt-lg-0 mt-sm-3"
                  >
                     <RenderVendorAuctionTimer />
                  </Col>
               </Row>
            )}

            <Row className="mt-4 mb-5" style={{ position: "relative" }}>
               <Col lg={4} style={{ zIndex: 100 }}>
                  <VendorAuctionStyles.OfferLogsWrapper>
                     <VendorAuctionStyles.SectionTitle className="mt-4">
                        Price Offer Log
                     </VendorAuctionStyles.SectionTitle>
                     <Row className="mt-3" style={{ marginLeft: "0", marginRight: "0" }}>
                        <VendorAuctionStyles.LogWrapper ref={logWrapperRef} lg={12}>
                           {offerLogs.map((item, index) => (
                              <VendorAuctionOfferBubble
                                 passedInRef={(ref) => (offersRef.current[item._id] = ref)}
                                 key={index}
                                 idx={index}
                                 componentId={`bubble${index}`}
                                 data={item}
                                 accountId={dataProfile?.account?._id}
                                 canReqDisqualify={
                                    index === offerLogs.length - 1 &&
                                    offerLogs[offerLogs.length - 1]?.accountId === dataProfile?.account?._id
                                       ? true
                                       : false
                                 }
                                 handleReqDisqualify={handleReqDisqualify}
                                 reqDisqualify={reqDisqualify}
                                 setReqDisqualify={setReqDisqualify}
                                 auctionStatus={auctionStatus}
                                 currency={item?.currency}
                              />
                           ))}

                           {dataAuction?.status === "CANCELED" && (
                              <div
                                 className="mx-1 mb-2 mt-3"
                                 style={{
                                    backgroundColor: "#ECECEC",
                                    borderRadius: "5px",
                                    padding: "10px",
                                 }}
                              >
                                 <div
                                    className="d-flex justify-content-center"
                                    style={{ color: "#909090", fontSize: "12px", fontWeight: "500" }}
                                 >
                                    <p>
                                       <strong>{dataAuction?.canceledBy} </strong>
                                       has canceled the session at
                                       <strong>
                                          {" "}
                                          <Dateinfo
                                             style={{ all: "unset" }}
                                             date={dataAuction?.updatedAt}
                                             format="dd/mm/yyyy HH:MM"
                                          />
                                       </strong>
                                    </p>
                                 </div>
                              </div>
                           )}
                        </VendorAuctionStyles.LogWrapper>
                     </Row>
                  </VendorAuctionStyles.OfferLogsWrapper>
               </Col>

               <Col lg={8}>
                  <VendorAuctionItemList
                     dataProcurement={dataProcurement}
                     dataProcurementItems={dataProcurementItems}
                     setDataProcurementItems={setDataProcurementItems}
                     dataUnitItems={dataUnitItems}
                     setDataUnitItems={setDataUnitItems}
                     beforeSubmitOffer={beforeSubmitOffer}
                     isUnitPrice={isUnitPrice}
                     isTotalByUnitPrice={isTotalByUnitPrice}
                     isTotalPrice={isTotalPrice}
                     isSimulation={isSimulation}
                     auctionStatus={auctionStatus}
                     disableAutoReduce={disableAutoReduce}
                     autoReducer={autoReducer}
                     errorFields={errorFields}
                     errorInputMessage={errorInputMessage}
                     finalOffer={finalOffer}
                     setFinalOffer={setFinalOffer}
                     myCapableQty={myCapableQty}
                     procurementVendorData={procurementVendorData}
                  />
               </Col>
            </Row>
         </Col>

         <ModalForm
            passedInRef={modalDisqualificationRef}
            title={"Price Disqualification"}
            modalTitle={"Price Disqualification"}
         >
            <VendorAuctionDisqualificationForm
               procurementVendorData={procurementVendorData}
               dataAuction={dataAuction}
               dataProcurement={dataProcurement}
               dataOffer={dataDisqualification}
               handleSubmit={requestForDisqualification}
               handleCancel={() => {
                  modalDisqualificationRef.current.hide(), setReqDisqualify(false)
               }}
            />
         </ModalForm>
         <SubmitModal.ModalContainer passedInRef={modalSubmitOffer} icon={cancelSubmitOfferIcon}>
            <SubmitModal.ModalButtonActions>
               <Row className="pt-4 mx-0 px-0">
                  <Col lg={10} className="d-flex align-items-center justify-content-end pr-0">
                     <Buttonaction
                        borderblueOcean
                        type="button"
                        label="No"
                        large
                        onClick={() => modalSubmitOffer.current.hide()}
                     />
                  </Col>
                  <Col className="d-flex align-items-center justify-content-end pr-0">
                     <Buttonaction
                        blueOcean
                        type="button"
                        label="Yes"
                        large
                        onClick={
                           isTotalPrice
                              ? handleSendOffer
                              : isTotalByUnitPrice
                              ? handleSendTotalByUnit
                              : isUnitPrice && handleSendUnitPrice
                        }
                     />
                  </Col>
               </Row>
            </SubmitModal.ModalButtonActions>
         </SubmitModal.ModalContainer>

         <SubmitModal.ModalContainer
            passedInRef={modalCancelationAuctionRef}
            icon={IconCloseProcess}
            withCloseButton={true}
         />

         <SubmitModal.ModalContainer
            passedInRef={modalStopAuctionRef}
            icon={IconCloseProcess}
            withCloseButton={true}
            afterClosed={() => location.reload()}
         />
      </>
   )
}

const mapStateToProps = (state) => {
   return {
      // dataProcurementItems: state.procurementReducer.dataProcurementItems,
      dataProfile: state.accountReducer.dataProfile,
      dataProcurementVendors: state.procurementReducer.dataProcurementVendors,
      dataProcurementUnitItems: state.procurementReducer.dataProcurementUnitItems,
      dataMasters: state.masterDataReducer.dataMasters,
   }
}

export default connect(mapStateToProps)(VendorAuction)
