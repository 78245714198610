import React, { useImperativeHandle, useState } from "react"
import { Col, Row } from "react-bootstrap"
import CloseModalIcon from "../assets/images/CloseModalIcon.svg"
import Modal from "react-modal"

function ModalContainer({ passedInRef, children, icon, withCloseButton = true, afterClosed }) {
   const [modalState, setModalState] = useState({
      modalTitle: "",
      title: "",
      description: "",
      isOpen: false,
   })

   const modalSubmitStyle = {
      overlay: {
         zIndex: "1060",
         background: "rgba(0,0,0,.7)",
      },
      content: {
         top: "50%",
         left: "50%",
         right: "auto",
         bottom: "auto",
         // marginRight: "-50%",

         transform: "translate(-50%, -50%)",
         borderRadius: "12px",
         transition: "all .3s ease",
         backgroundColor: "white",
         paddingRight: "20px",
         paddingLeft: "20px",
         paddingTop: !withCloseButton ? "50px" : "30px",
         paddingBottom: "40px",
         overflowY: "auto",
         overflowX: "hidden",
         minHeight: "100px",
         maxHeight: "90vh",
         zIndex: "1060",
         minWidth: "573px",
      },
   }

   useImperativeHandle(passedInRef, () => ({
      show: ({ modalTitle = "", title, description }) => {
         setModalState({
            modalTitle,
            title,
            description,
            isOpen: true,
         })
      },

      hide: () => {
         setModalState((_state) => ({
            ..._state,
            isOpen: false,
         }))
      },
   }))

   return (
      <Modal
         isOpen={modalState.isOpen}
         ariaHideApp={false}
         style={modalSubmitStyle}
         contentLabel={modalState.modalTitle}
      >
         {withCloseButton && (
            <Row>
               <Col className="d-flex justify-content-end px-4 py-2">
                  <img
                     src={CloseModalIcon}
                     alt=""
                     style={{ cursor: "pointer" }}
                     onClick={() => {
                        if (afterClosed) afterClosed()
                        setModalState((x) => ({
                           ...x,
                           isOpen: false,
                        }))
                     }}
                  />
               </Col>
            </Row>
         )}
         <Row className=" px-4">
            <Col lg={2} className="px-0" style={{ textAlign: "center" }}>
               <img src={icon} alt="" style={{ width: "90%" }} />
            </Col>
            <Col lg={10} className="pr-0">
               <p style={{ fontSize: "24px", fontWeight: "500" }}>{modalState.title}</p>
               <p style={{ fontSize: "12px", marginTop: "15px", width: "100%" }}>{modalState.description}</p>
            </Col>
         </Row>
         <Row className=" px-4">
            <Col className="px-0">{children}</Col>
         </Row>
      </Modal>
   )
}

function ModalButtonActions({ children }) {
   return <>{children}</>
}

const SubmitModal = {
   ModalContainer,
   ModalButtonActions,
}

export default SubmitModal
