import Apiservice from "../services/Apiservice";

const API_URL = Apiservice.url.AUCTION_URL + "/api/authentication";

function AuthenticationService(){
    this.decrypt = function(text, callback){
        return async () => {
            const resp = await Apiservice.invokeGet(API_URL+ "/decrypt?q=" + text);
            if (resp.status == 200) {
                if (callback) {
                    callback(resp.data.data);
                }
            }
        }
    }
}

export default AuthenticationService