import React, { useEffect, useRef, useState } from "react"
import { Col, Row } from "react-bootstrap"
import toast from "react-hot-toast"
import { connect, useDispatch } from "react-redux"
import { useHistory } from "react-router"
import { useParams } from "react-router"
import { Modal, Loader } from "rsuite"
import Breadcrumbs from "../../../../../../components/Breadcrumbs"
import Buttonaction from "../../../../../../components/Buttonaction"
import Pagetitle from "../../../../../../components/Pagetitle"
import Tipscard from "../../../../../../components/Tipscard"
import InitiationInfoform from "../../../../../../modules/initiation/InitiationInfoform"
import ProcurementItemList from "../../../../../../modules/buyer-auction/unit-price/ProcurementItemList"
import AuctionParticipantList from "../../../../../../modules/buyer-auction/unit-price/AuctionParticipantList"
import CanceledInitiation from "../../../../../../modules/initiation/CanceledInitiation"
import NegotiationSetSchedule from "../../../../../../pages/buyer/homepage/procurement/negotiation/negotiation-offline/NegotiationSetSchedule"
import NegotiationInfo from "../../../../../../pages/buyer/homepage/procurement/negotiation/component/negotiation-info/NegotiationInfo"
import ErrIcon from "../../../../../../assets/images/error.svg"
import SuccessIcon from "../../../../../../assets/images/success.svg"
import Statuslabel from "../../../../../../components/Statuslabel"
import CancelProcessIcon from "../../../../../../assets/images/IconCloseProcess.svg"
import FolderAlertIcon from "../../../../../../assets/images/FolderAlert.svg"
import { getProcurement, resetDataProcurement } from "../../../../../../redux/action/procurementAction"
import { updateStatus } from "../../../../../../redux/action/negotiationAction"
import { getMyProfile } from "../../../../../../redux/action/accountAction"
import { changeStatus } from "../../../../../../redux/action/negotiationBatchAction"
import SubmitModal from "../../../../../../components/Submitmodal"
import Constant from "../../../../../../../constant"
import { Alert, Snackbar } from "@mui/material"
import ProcurementItemService from "../../../../../../services/ProcurementItemService"
import NegotiationBatchItemService from "../../../../../../services/NegotiationBatchItemService"
import NegotiationBatchService from "../../../../../../services/NegotiationbatchService"
import NegotiationService from "../../../../../../services/NegotiationService"
import NegotiationItem from "../component/negotiation-item/NegotiationItem"
import NegotiationOffer from "../negotiation-offline/NegotiationOffer"
import ModalForm from "../../../../../../components/Modalform"
import Texteditor from "../../../../../../components/Texteditor"
import IconLeavePage from "../../../../../../assets/images/IconLeavePage.svg"
import TickIcon from "../../../../../../assets/images/TickIcon.svg"
import { format } from "date-fns"
import warningIcon from "../../../../../../assets/images/WarningIcon.svg"
import Dateinfo from "../../../../../../components/Dateinfo"
import FailedCreateBatchIcon from "../../../../../../assets/images/IconCloseProcess.svg"

function Negotiation({ dataProcurement, dataProfile, negotiationBatch }) {
   const { procurementId, batchId } = useParams()
   const dispatch = useDispatch()
   const history = useHistory()
   // const [batchStatusChanged, setBatchStatusChanged] = useState(false)

   const modalCancelRef = useRef()
   const modalCreateNegoRef = useRef()
   const modalDeleteNego = useRef()
   const modalStatusRef = useRef()
   const modalNegotiationVendorRef = useRef()
   const modalCancelNegoRef = useRef()
   const modalEndSessionRef = useRef()
   const modalCreateSuccessRef = useRef()
   const modalFailedCreateBatchRef = useRef()

   const procurementItemService = new ProcurementItemService()
   const negotiationBatchItemService = new NegotiationBatchItemService()
   const negotiationBatchService = new NegotiationBatchService()
   const negotiationService = new NegotiationService()

   const [_dataProcurement, set_DataProcurement] = useState({ status: "DRAFT" })
   const [selectedVendors, setSelectedVendors] = useState()
   const [selectedItems, setSelectedItems] = useState([])
   const [initialSelectedItems, setInitialSelectedItems] = useState()
   const [showEndSessionConfirm, setShowEndSessionConfirm] = useState(false)
   const [endSuccess, setEndSuccess] = useState(false)
   const [negoStatusChangeTo, setNegoStatusChangeTo] = useState("")
   const [draftSuccess, setDraftSuccess] = useState(false)
   const [statuses, setStatuses] = useState()
   const [negoBatchPayload, setNegoBatchPayload] = useState({
      procurementId,
      companyId: null,
      status: null,
      negotiationType: null,
      startDate: null,
      endDate: null,
      procurementItemIds: [],
      procurementVendorIds: [],
   })

   useEffect(() => {
      dispatch(resetDataProcurement())
      initNegotiationBatch()

      handleGetSelectedItems()
      handleGetSelectedVendors()
   }, [])

   const initNegotiationBatch = () => {
      if (batchId) {
         dispatch(
            negotiationBatchService.findById(batchId, (res) => {
               console.log("NEGO BATCH", res)
               setNegoBatchPayload(res)
               getStatus(res)
            }),
         )
      } else {
         dispatch(
            getMyProfile(
               (_dataProfile) => {
                  const copyNegoBatch = negoBatchPayload
                  copyNegoBatch.companyId = _dataProfile.company._id
                  getStatus(copyNegoBatch)
                  setNegoBatchPayload((prev) => ({ ...prev, companyId: _dataProfile.company._id }))
               },
               (err) => {
                  console.log(err)
               },
            ),
         )
      }
   }

   const getStatus = (negotiationBatch) => {
      dispatch(
         negotiationBatchService.getStatus(negotiationBatch, negotiationBatch.status, (res) => {
            setStatuses(res)
         }),
      )
   }

   const handleGetSelectedVendors = () => {
      if (batchId) {
         dispatch(
            negotiationService.findByBatchId(batchId, (res) => {
               if (res.length > 0) {
                  const arr = []
                  for (const item of res) {
                     arr.push(item.vendorId)
                  }
                  setSelectedVendors(arr)
               } else {
                  setSelectedVendors([])
               }
            }),
         )
      } else {
         setSelectedVendors([])
      }
   }

   const handleGetSelectedItems = () => {
      dispatch(
         getProcurement({ procurementId }, (res) => {
            if (batchId) {
               dispatch(
                  negotiationBatchItemService.findByBatchId(batchId, (resItems) => {
                     const arr = []
                     for (const item of resItems) {
                        arr.push(item.procurementItem)
                     }
                     setInitialSelectedItems(arr)
                  }),
               )
            } else {
               dispatch(
                  procurementItemService.findByProcurementId(res._id, (resItems) => {
                     if (res.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE) {
                        setInitialSelectedItems([resItems[0]])
                     } else {
                        setInitialSelectedItems(resItems)
                     }
                  }),
               )
            }
         }),
      )
   }

   const handleEndSession = () => {
      setShowEndSessionConfirm(false)
      setEndSuccess(true)

      dispatch(
         updateStatus({ negotiationBatch, status: negoStatusChangeTo }, (res) =>
            // dispatch(getNegotiationBatch(batchId, (res) => setCurrentNegoStatusAndData(res.status)))
            history.push(`/initiation/form/${procurementId}`),
         ),
      )
   }

   const validateChangeStatus = () => {
      if (!negoBatchPayload.negotiationType) {
         toast.error("Please select negotiation type")
      } else if (!negoBatchPayload.startDate) {
         toast.error("Please select schedule date")
      } else if (selectedItems.length === 0) {
         toast.error("Please select items")
      } else if (selectedVendors.length === 0) {
         toast.error("Please select vendors")
      } else {
         return true
      }
   }

   const [isCreatingNego, setIsCreatingNego] = useState(false)
   const [isLoading, setIsLoading] = useState(false)

   const [changeToStatus, setChangeToStatus] = useState("")
   const handleChangeStatus = () => {
      const toDraft = changeToStatus === Constant.STATUS.DRAFT
      const toOnGoing = changeToStatus === Constant.STATUS.ON_GOING
      const toDelete = changeToStatus === Constant.STATUS.DELETE

      if (!toDelete) {
         const procurementStartDate = new Date(dataProcurement.startDate).getTime()
         const procurementEndDate = new Date(dataProcurement.endDate).getTime()
         const inputStartDate = new Date(
            new Date(negoBatchPayload.startDate).getFullYear(),
            new Date(negoBatchPayload.startDate).getMonth(),
            new Date(negoBatchPayload.startDate).getDate(),
            new Date(negoBatchPayload.startDate).getHours(),
            new Date(negoBatchPayload.startDate).getMinutes(),
            0, // set input second to 0
         ).getTime()

         const startDateFormat = format(new Date(dataProcurement.startDate), "dd MMMM yyyy kk:mm")

         if (toDraft || toOnGoing) {
            if (dataProcurement.startDate && dataProcurement.endDate) {
               const endDateFormat = format(new Date(dataProcurement.endDate), "dd MMMM yyyy kk:mm")
               if (inputStartDate < procurementStartDate || inputStartDate > procurementEndDate) {
                  toast.error(
                     <p>
                        Please input <strong>start date</strong> between <strong>{startDateFormat}</strong> and{" "}
                        <strong>{endDateFormat}</strong>
                     </p>,
                     { id: "sessionTime", duration: 5000 },
                  )
                  return
               }
            } else {
               if (inputStartDate < procurementStartDate) {
                  toast.error(
                     <p>
                        Please input <strong>start date</strong> above <strong>{startDateFormat}</strong>
                     </p>,
                     { id: "sessionTime", duration: 5000 },
                  )
                  return
               }
            }

            if (toDraft) {
               new Promise((resolve, reject) => {
                  dispatch(
                     negotiationBatchService.changeStatus(
                        {
                           ...negoBatchPayload,
                           startDate: new Date(
                              new Date(negoBatchPayload.startDate).getFullYear(),
                              new Date(negoBatchPayload.startDate).getMonth(),
                              new Date(negoBatchPayload.startDate).getDate(),
                              new Date(negoBatchPayload.startDate).getHours(),
                              new Date(negoBatchPayload.startDate).getMinutes(),
                              0,
                           ),
                        },
                        changeToStatus,
                        (res) => {
                           dispatch(
                              negotiationBatchItemService.saveAsDraft(res, selectedItems, (resItems) => {
                                 dispatch(
                                    negotiationService.saveAsDraft(res, selectedVendors, (resVendors) => {
                                       if (!batchId) {
                                          toast.success("Negotiation is successfully saved as draft", {
                                             duration: 3000,
                                          })
                                       } else {
                                          toast.success("Negotiation info is successfully updated", {
                                             duration: 3000,
                                          })
                                       }
                                       setDraftSuccess(true)
                                       setTimeout(() => {
                                          history.push(`/initiation/form/${procurementId}`)
                                       }, 2000)
                                    }),
                                 )
                              }),
                           )
                        },
                        (err) => {
                           modalFailedCreateBatchRef.current.show({
                              modalTitle: "",
                              title: "Create negotiation batch failed",
                              description: <p>{err.data.message}</p>,
                           })
                        },
                     ),
                  )
               })
            } else if (toOnGoing) {
               if (validateChangeStatus()) {
                  setIsCreatingNego(true)
                  new Promise((resolve, reject) => {
                     dispatch(
                        negotiationBatchService.changeStatus(
                           {
                              ...negoBatchPayload,
                              startDate: new Date(
                                 new Date(negoBatchPayload.startDate).getFullYear(),
                                 new Date(negoBatchPayload.startDate).getMonth(),
                                 new Date(negoBatchPayload.startDate).getDate(),
                                 new Date(negoBatchPayload.startDate).getHours(),
                                 new Date(negoBatchPayload.startDate).getMinutes(),
                                 0,
                              ),
                           },
                           changeToStatus,
                           (res) => {
                              resolve(res)
                           },
                           (errRes) => {
                              // toast.error(errRes.data.message)
                              modalCreateNegoRef.current.hide()
                              modalFailedCreateBatchRef.current.show({
                                 modalTitle: "",
                                 title: "Create negotiation batch failed",
                                 description: <p>{errRes.data.message}</p>,
                              })
                              reject()
                           },
                        ),
                     )
                  })
                     .then((res) => {
                        dispatch(
                           negotiationBatchItemService.saveAsDraft(res, selectedItems, (resItems) => {
                              dispatch(
                                 negotiationService.saveAsDraft(res, selectedVendors, (resVendors) => {
                                    modalCreateNegoRef.current.hide()
                                    setIsCreatingNego(false)
                                    modalCreateSuccessRef.current.show({
                                       modalTitle: "",
                                       title: "Negotiation Session has been Created",
                                       description: (
                                          <div className="">
                                             <p>Your negotiation session has been created.</p>
                                             <Loader
                                                speed="slow"
                                                size="small"
                                                className="w-100 d-flex justify-content-end mt-2"
                                             />
                                          </div>
                                       ),
                                    })
                                    setTimeout(() => {
                                       modalCreateSuccessRef.current.hide()
                                    }, 3000)

                                    history.push("/negotiation/session/" + res.procurementId + "/" + res._id)
                                    getStatus(res)
                                    setNegoBatchPayload(res)
                                 }),
                              )
                           }),
                        )
                     })
                     .catch()
                     .finally(() => {})
               }
            }
         } else {
            requestChangeStatus(negoBatchPayload, changeToStatus)
               .then((negoBatchPayload) => {
                  const status = negoBatchPayload.status
                  if (status === Constant.STATUS.CANCELED) {
                     modalCancelRef.current.hide()

                     // setTimeout(() => {
                     // initNegotiationBatch()
                     history.push("/initiation/form/" + procurementId)
                     // }, 3000)
                  } else if (status === Constant.STATUS.DONE) {
                     toast.success("Negotiation is successfully ended", {
                        duration: 3000,
                     })
                     history.push("/initiation/form/" + procurementId)
                  }
               })
               .finally(() => setIsLoading(false))
         }
      } else if (toDelete) {
         dispatch(
            negotiationBatchService.changeStatus(negoBatchPayload, changeToStatus, (res) => {
               toast.success("Negotiation is successfully deleted", {
                  duration: 3000,
               })

               history.push("/initiation/form/" + procurementId)
            }),
         )
      }
   }

   const requestChangeStatus = (negoBatchPayload, toStatus) => {
      setIsLoading(true)
      return new Promise((resolve, reject) => {
         dispatch(
            changeStatus(
               negoBatchPayload,
               toStatus,
               (res) => {
                  // history.push("/initiation/form/" + res.procurementId)
                  resolve(res)
               },
               () => {
                  reject()
               },
            ),
         )
      })
   }

   const handleCancelSession = () => {
      dispatch(
         updateStatus({ negotiationBatch, status: negoStatusChangeTo }, (res) => {
            history.push(`/initiation/form/${procurementId}`)
         }),
      )
   }

   const handleButtonRed = (status) => {
      if (status.to.description === Constant.STATUS.DELETE) {
         return true
      } else if (status.to.description === Constant.STATUS.CANCELED) {
         return true
      }
      return false
   }

   const handleButtonBorderBlueOcean = (status) => {
      if (status.to.description === Constant.STATUS.DRAFT) {
         return true
      } else if (status.to.description === Constant.STATUS.DONE) {
         return true
      }
      return false
   }

   const RenderActionButton = ({ data }) => {
      return (
         <>
            {data.map((v, index) => (
               <Buttonaction
                  disabled={!negoBatchPayload.negotiationType}
                  key={index}
                  borderblueOcean={handleButtonBorderBlueOcean(v)}
                  blueOcean={v.to.description === Constant.STATUS.ON_GOING ? true : false}
                  red={handleButtonRed(v)}
                  label={v.label}
                  large
                  style={{ marginRight: "24px" }}
                  onClick={() => {
                     setChangeToStatus(v.to.description)
                     modalStatusRef.current = v
                     switch (v.to.description) {
                        case Constant.STATUS.ON_GOING:
                           modalCreateNegoRef.current.show({
                              title: "Create negotiation session?",
                              modalTitle: "",
                              description: <p>The data you made before cannot be change. Do you want to Proceed?</p>,
                           })
                           break
                        case Constant.STATUS.DELETE:
                           modalDeleteNego.current.show({
                              title: "Delete negotiation session?",
                              modalTitle: "",
                              description: <p>The data you made will be lost. Do you want to Proceed?</p>,
                           })
                           break
                        case Constant.STATUS.CANCELED:
                           modalCancelRef.current.show({
                              modalTitle: "",
                              title: "Cancel Session?",
                              description: <p>Do you want to Cancel this session?</p>,
                           })
                           break
                        case Constant.STATUS.DONE:
                           modalEndSessionRef.current.show({
                              modalTitle: "",
                              title: "Are you sure to end this session?",
                              description: (
                                 <p>
                                    If you end this session, the data you made before cannot be changed. Do you want to
                                    close?
                                 </p>
                              ),
                           })
                           break
                        case Constant.STATUS.DRAFT:
                           handleChangeStatus()
                           break
                        default:
                           // handleChangeStatus(v)
                           break
                     }
                  }}
               />
            ))}
         </>
      )
   }

   const handleSelectedItems = (itemIds) => {
      setSelectedItems([...itemIds])
   }

   const handleSelectedVendors = (vendorIds) => {
      setSelectedVendors([...vendorIds])
   }

   return (
      <>
         <Row>
            <Col lg={10}>
               <>
                  <Row>
                     <Col>
                        <Breadcrumbs data={["Dashboard", "Procurement", "Initiation", "Negotiation"]} />
                     </Col>
                  </Row>
                  <Row>
                     <Col className="mb-4">
                        <Pagetitle
                           title="Negotiation"
                           onBackButtonClicked={() => history.push(`/initiation/form/${procurementId}`)}
                           withBackButton={true}
                           lcomp={
                              <Statuslabel
                                 status={negoBatchPayload?.status ? negoBatchPayload?.status : "NEW"}
                                 label={negoBatchPayload?.status ? negoBatchPayload?.status : "NEW"}
                                 className="mx-0"
                              />
                           }
                        />
                     </Col>
                  </Row>

                  {dataProcurement?.status === "CANCELED" && (
                     <CanceledInitiation data={{ dataProcurement, dataProfile }} />
                  )}

                  {Object.entries(negoBatchPayload).length > 0 && (
                     <>
                        {negoBatchPayload.status === Constant.STATUS.CANCELED && (
                           <Row className="pl-3 mb-3">
                              <Col className="px-1 mx-2">
                                 <div
                                    className="d-flex align-items-center px-3"
                                    style={{
                                       backgroundColor: "#fde9ef",
                                       color: "#909090",
                                       fontSize: "12px",
                                       fontWeight: "400",
                                       borderRadius: "5px",
                                       height: "40px",
                                    }}
                                 >
                                    <img src={warningIcon} className="mr-2" />
                                    <p>
                                       This session canceled by <strong> {negoBatchPayload.canceledBy} </strong>
                                       at
                                       <strong>
                                          {" "}
                                          <Dateinfo
                                             date={negoBatchPayload.updatedAt}
                                             format="dd/mm/yyyy HH:MM"
                                             style={{ all: "unset" }}
                                          />
                                       </strong>{" "}
                                       The cancelation reason is{" "}
                                       <strong
                                          style={{ display: "inline-block", lineHeight: "0px" }}
                                          dangerouslySetInnerHTML={{
                                             __html: negoBatchPayload.cancelReason,
                                          }}
                                       />
                                    </p>
                                 </div>
                              </Col>
                           </Row>
                        )}
                     </>
                  )}

                  <InitiationInfoform readOnly={true} negotiationBatch={negotiationBatch} />

                  <NegotiationInfo
                     negotiationBatch={negoBatchPayload}
                     readOnly={
                        negoBatchPayload?.status === Constant.STATUS.ON_GOING ||
                        negoBatchPayload?.status === Constant.STATUS.DONE ||
                        negoBatchPayload?.status === Constant.STATUS.CANCELED
                     }
                     setNegoBatchPayload={setNegoBatchPayload}
                  />

                  {(dataProcurement && negoBatchPayload?.status === Constant.STATUS.DRAFT && initialSelectedItems) ||
                  dataProcurement?.biddingType !== Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE ||
                  // && negoBatchPayload?.status === Constant.STATUS.ON_GOING
                  negoBatchPayload?.status === null ? (
                     <ProcurementItemList
                        selectedItems={initialSelectedItems}
                        fnHandleSelectedItems={handleSelectedItems}
                        negotiationBatch={negoBatchPayload}
                        readOnly={
                           negoBatchPayload?.status === Constant.STATUS.ON_GOING ||
                           negoBatchPayload?.status === Constant.STATUS.DONE ||
                           negoBatchPayload?.status === Constant.STATUS.CANCELED
                        }
                        setNegoBatchPayload={setNegoBatchPayload}
                     />
                  ) : (
                     dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE &&
                     (negoBatchPayload?.status === Constant.STATUS.ON_GOING ||
                        negoBatchPayload?.status === Constant.STATUS.DONE ||
                        negoBatchPayload?.status === Constant.STATUS.CANCELED) && <NegotiationItem />
                  )}

                  {selectedVendors !== undefined &&
                     (negoBatchPayload?.status === Constant.STATUS.DRAFT || negoBatchPayload?.status === null) && (
                        <AuctionParticipantList
                           forCreateNego={true}
                           selectedVendors={selectedVendors}
                           negotiationBatch={negotiationBatch}
                           setNegoBatchPayload={setNegoBatchPayload}
                           fnHandleSelectedVendor={handleSelectedVendors}
                           readOnly={
                              negoBatchPayload?.status === Constant.STATUS.DONE ||
                              negoBatchPayload?.status === Constant.STATUS.CANCELED
                           }
                           modalNegotiationVendorRef={modalNegotiationVendorRef}
                        />
                     )}

                  {negoBatchPayload &&
                     negoBatchPayload?.status !== Constant.STATUS.DRAFT &&
                     negoBatchPayload?.status !== null && (
                        <NegotiationSetSchedule
                           currentNegoStatusAndData={negoBatchPayload}
                           modalNegotiationVendorRef={modalNegotiationVendorRef}
                        />
                     )}

                  <NegotiationOffer passedInRef={modalNegotiationVendorRef} negotiationBatch={negotiationBatch} />

                  <div className="d-flex justify-content-center mb-5">
                     {statuses && <RenderActionButton data={statuses} />}
                  </div>
               </>
            </Col>

            <Col lg={2} className="position-relative">
               <Tipscard />
            </Col>
         </Row>

         {/* CONFIRM MODAL END SESSION */}
         <Modal open={showEndSessionConfirm} onClose={() => setShowEndSessionConfirm(false)}>
            <Modal.Body style={{ display: "grid", placeItems: "center" }}>
               <img
                  src={ErrIcon}
                  style={{
                     background: "linear-gradient(180deg, #5DBCF9 0%, #0772B6 100%)",
                     borderRadius: "50%",
                  }}
               />
               <h5 style={{ fontSize: "18px", margin: "18px auto" }}>Are you sure to end this session?</h5>
               <p style={{ marginBottom: "32px" }}>
                  If you end this session, the data you made before cannot be changed.{" "}
               </p>
               <div className="d-flex justify-content-center" style={{ columnGap: "24px" }}>
                  <Buttonaction
                     borderblueOcean
                     type="button"
                     label="Cancel"
                     large
                     style={{ width: "140px" }}
                     onClick={() => setShowEndSessionConfirm(false)}
                  />
                  <Buttonaction
                     blueOcean
                     type="button"
                     label="End Session"
                     large
                     style={{ width: "140px" }}
                     onClick={handleEndSession}
                  />
               </div>
            </Modal.Body>
         </Modal>

         {/* SUCCESS MODAL */}
         <Modal open={endSuccess} onClose={() => setEndSuccess(false)}>
            <Modal.Body style={{ display: "grid", placeItems: "center" }}>
               <img
                  src={SuccessIcon}
                  style={{
                     background: "linear-gradient(180deg, #5DBCF9 0%, #0772B6 100%)",
                     borderRadius: "50%",
                  }}
               />
               <h5 style={{ fontSize: "24px", margin: "18px auto" }}>Data has been saved</h5>
               <p style={{ marginBottom: "32px" }}>Your work has been successfully saved </p>
            </Modal.Body>
         </Modal>

         <SubmitModal.ModalContainer passedInRef={modalCreateSuccessRef} icon={TickIcon} withCloseButton={false} />

         {/* CANCEL MODAL */}
         <SubmitModal.ModalContainer passedInRef={modalCancelRef} icon={CancelProcessIcon}>
            <div className="mt-4">
               <Texteditor
                  // data={formState.specification}
                  onValueChange={(v) => setNegoBatchPayload((prev) => ({ ...prev, cancelReason: v }))}
               />
            </div>
            <SubmitModal.ModalButtonActions>
               <div className="mt-4 d-flex justify-content-end">
                  <div className="mr-3">
                     <Buttonaction
                        borderblueOcean
                        type="button"
                        label="No"
                        large
                        onClick={() => modalCancelRef.current.hide()}
                     />
                  </div>
                  <Buttonaction
                     disabled={!negoBatchPayload?.cancelReason}
                     blueOcean
                     type="button"
                     label="Yes"
                     large
                     loading={isLoading}
                     onClick={handleChangeStatus}
                  />
               </div>
            </SubmitModal.ModalButtonActions>
         </SubmitModal.ModalContainer>

         {/* DELETE ACTION */}
         <SubmitModal.ModalContainer passedInRef={modalDeleteNego} icon={FolderAlertIcon}>
            <SubmitModal.ModalButtonActions>
               <Row className="pt-4 mx-0 px-0">
                  <Col lg={8} className="d-flex align-items-center justify-content-end pr-0">
                     <Buttonaction
                        borderblueOcean
                        type="button"
                        label="Cancel"
                        large
                        onClick={() => modalDeleteNego.current.hide()}
                     />
                  </Col>
                  <Col className="d-flex align-items-center justify-content-end pr-0">
                     <Buttonaction red type="button" label="Delete Session" large onClick={handleChangeStatus} />
                  </Col>
               </Row>
            </SubmitModal.ModalButtonActions>
         </SubmitModal.ModalContainer>

         {/* CREATE SESSION MODAL */}
         <SubmitModal.ModalContainer passedInRef={modalCreateNegoRef} icon={FolderAlertIcon}>
            <SubmitModal.ModalButtonActions>
               <Row className="pt-4 mx-0 px-0">
                  <Col lg={8} className="d-flex align-items-center justify-content-end pr-0">
                     <Buttonaction
                        borderblueOcean
                        type="button"
                        label="Cancel"
                        large
                        onClick={() => modalCreateNegoRef.current.hide()}
                     />
                  </Col>
                  <Col className="d-flex align-items-center justify-content-end pr-0">
                     <Buttonaction
                        blueOcean
                        type="button"
                        label="Create Session"
                        large
                        loading={isCreatingNego}
                        onClick={() => handleChangeStatus(modalStatusRef.current)}
                     />
                  </Col>
               </Row>
            </SubmitModal.ModalButtonActions>
         </SubmitModal.ModalContainer>

         {/* END SESSION MODAL */}
         <SubmitModal.ModalContainer passedInRef={modalEndSessionRef} closeButton={false} icon={IconLeavePage}>
            <SubmitModal.ModalButtonActions>
               <div className="mt-4 d-flex justify-content-end">
                  <div className="mr-3">
                     <Buttonaction
                        borderblueOcean
                        type="button"
                        label="Cancel"
                        large
                        onClick={() => modalEndSessionRef.current.hide()}
                     />
                  </div>
                  <Buttonaction
                     style={{ whiteSpace: "nowrap" }}
                     blueOcean
                     type="button"
                     label="End Session"
                     large
                     loading={isLoading}
                     onClick={handleChangeStatus}
                  />
               </div>
            </SubmitModal.ModalButtonActions>
         </SubmitModal.ModalContainer>

         {/* DRAFT SUCCESS SNACKBAR */}
         <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            open={draftSuccess}
            autoHideDuration={6000}
            onClose={() => setDraftSuccess(!draftSuccess)}
         >
            <Alert
               variant="filled"
               onClose={() => setDraftSuccess(!draftSuccess)}
               severity="success"
               sx={{ width: "100%", marginBottom: "8%" }}
            >
               Your work has been saved
            </Alert>
         </Snackbar>

         {/* FAILED CREATE BATCH */}
         <SubmitModal.ModalContainer passedInRef={modalFailedCreateBatchRef} icon={FailedCreateBatchIcon} />
      </>
   )
}

const mapStateToProps = (state) => {
   return {
      dataProfile: state.accountReducer.dataProfile,
      dataProcurement: state.procurementReducer.dataProcurement,
      negotiationBatch: state.negotiationReducer.negotiationBatch,
   }
}

export default connect(mapStateToProps)(Negotiation)
