import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, ButtonToolbar, Container, FlexboxGrid, Form, Grid, InputNumber } from 'rsuite'
import ChatItem from '../components/ChatItem'
import ControlLabel from '../components/ControlLabel'
import ScrollableGrid from '../components/ScrollableGrid'
import { fetchOffer, joiningRoom, makeOffer } from '../redux/action/roomAction'
import socketIOClient from 'socket.io-client'
import Apiservice from '../services/Apiservice'
import toast from 'react-hot-toast'
import { saveSocketSession } from '../redux/action/sessionAction'

let socket

function Roompage({ dataProfile, dataOffer }) {
    const history = useHistory()
    const dispatch = useDispatch()
    const [pageState, setPageState] = useState({
        price: 0
    })

    const initiateSocketConnection = () => {
        if (!socket) {
            socket = socketIOClient(Apiservice.url.AUCTION_URL);
            socket.emit("joined", dataProfile?.username)

            /**
             * Listeners
             * 
             */
            socket.on("NewUserJoined", data => {
                if (data.username != dataProfile?.username) {
                    toast.success(`${data.username} has joined the room`)
                }
            });

            socket.on("new offer created", data => {
                dispatch(fetchOffer())
            })

            socket.on("UserHasLeft", (data) => {
                toast.success(data.username + " has left the auction room")
            })

            socket.on("connected", (socketId) => {
                dispatch(saveSocketSession({ socketId }))
            });

            socket.on("disconnect", () => {
                toast.success("You have been disconnected")
            });
        }
    }

    const handleSubmitOffer = () => {
        if (pageState.price > 0 && dataProfile?.username) {
            socket.emit("user make offer", {
                username: dataProfile?.username,
                price: pageState.price
            })

            // dispatch(makeOffer({
            //     username: dataProfile?.username,
            //     price: pageState.price
            // }))
        }
    }

    useEffect(() => {
        if (!dataProfile) {
            history.push("/login")
        } else {
            // fetch offer prices
            dispatch(fetchOffer())
            // user is joining the room
            dispatch(joiningRoom({ username: dataProfile.username }, () => {
                // creating the socket bridge
                initiateSocketConnection()
            }))
        }
    }, [])

    return (
        <Container style={{ height: '100vh', background: 'white', width: '100%' }}>
            <Grid style={{ padding: '3rem' }}>
                <ScrollableGrid>
                    {dataOffer && dataOffer.length > 0 &&
                        <>
                            {dataOffer.map((v) => {
                                return <ChatItem username={v?.username} offer={v.price} createdAt={v.created_at} />
                            })}
                        </>}
                </ScrollableGrid>

                <FlexboxGrid>
                    <Form.Group>
                        <ControlLabel>Input your offer</ControlLabel>
                        <InputNumber onChange={(v) => setPageState(_state => ({
                            ..._state,
                            price: v
                        }))} />
                    </Form.Group>

                    <Form.Group>
                        <ButtonToolbar>
                            <Button type="submit" appearance="primary">Hit Enter to Bid</Button>
                        </ButtonToolbar>
                    </Form.Group>
                </FlexboxGrid>
            </Grid>
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        dataProfile: state.sessionReducer.dataProfile,
        dataOffer: state.roomReducer.dataOffer
    }
}

export default connect(mapStateToProps)(Roompage)