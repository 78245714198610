import Apiservice from "../services/Apiservice";

const API_URL = Apiservice.url.AUCTION_URL + "/api/secured/spectator";

function SpectatorService(){
    this.addSpectator = function(payload, callback){
        return async () => {
            const resp = await Apiservice.invokePost(API_URL + "/add-spectator", payload);
            if (resp.status == 200) {
                if (callback) {
                    callback(resp.data.data);
                }
            }
        }
    }
}

export default SpectatorService
