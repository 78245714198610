const initialState = {
  dataLogNegotiation: null,
  dataLogAuction: null,
};

const reportAction = (state = initialState, action) => {
  switch (action.type) {
    case "LOG_NEGOTIATION":
      return {
        ...state,
        dataLogNegotiation: action.payload,
      };
    case "LOG_AUCTION":
      return {
        ...state,
        dataLogAuction: action.payload,
      };
    default:
      return state;
  }
};

export default reportAction;
