import { CountdownCircleTimer } from "react-countdown-circle-timer"

import React from "react"

function CountdownTimer({ isPlaying, duration, initialRemainingTime, colors, trailColor, title = "Timer", children }) {
   return (
      <CountdownCircleTimer
         isPlaying={isPlaying}
         // duration={dataAuction?.status === "CANCELED" ? 0 : durationStart}
         duration={duration}
         colors={colors}
         strokeWidth={17}
         size={180}
         trailColor={trailColor}
         initialRemainingTime={initialRemainingTime}
      >
         {({ remainingTime }) => {
            const hours = Math.floor(remainingTime / 3600)
            const minutes = Math.floor((remainingTime % 3600) / 60)
            const seconds = remainingTime % 60

            return (
               <div className="d-flex flex-column align-items-center">
                  <p
                     style={{
                        color: "#909090",
                        fontSize: "18px",
                        marginBottom: "10px",
                     }}
                  >
                     {title}
                  </p>
                  <p
                     className="d-flex align-items-center mt-0 mb-3"
                     style={{
                        color: "#909090",
                        fontSize: "24px",
                        fontWeight: "500",
                     }}
                  >
                     {`${hours.toString().length === 1 ? `0${hours}` : hours} : ${
                        minutes.toString().length === 1 ? `0${minutes}` : minutes
                     } : ${seconds.toString().length === 1 ? `0${seconds}` : seconds}`}
                  </p>
                  {children}
               </div>
            )
         }}
      </CountdownCircleTimer>
   )
}

export default CountdownTimer
