import Apiservice from "../services/Apiservice";

const API_URL = Apiservice.url.AUCTION_URL + "/api/secured/account";

function AccountService(){
    this.findById = function(accountId, callback){
        return async () => {
            const resp = await Apiservice.invokeGet(API_URL + "/" + accountId);
            if (resp.status == 200) {
                if (callback) {
                    callback(resp.data.data);
                }
            }
        }
    }
    this.findLikeEmailAndRole = function(email, role, callback){
        return async () => {
            const resp = await Apiservice.invokeGet(API_URL + "/find-like-email-and-role?email=" + email + "&role=" + role )
            if(resp.status === 200 && callback){
                callback(resp.data.data);
            }
        }
    }
}

export default AccountService