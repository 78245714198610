const initialState = {
    dataProfile: {}
}

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_MY_PROFILE':
            return {
                ...state,
                dataProfile: action.payload
            }        
        default:
            return state
    }
}

export default accountReducer