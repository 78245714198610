import Apiservice from "../../services/Apiservice"

const API_URL = Apiservice.url.AUCTION_URL + "/api/secured/negotiation-item"

export function findByNegotiationId(negoId, callback, errCallback) {
   return async () => {
      const resp = await Apiservice.invokeGet(API_URL + "/find-by-negotiation/" + negoId)

      console.log("FETCH RESULT", resp)
      if (resp.status == 200) {
         if (callback) {
            callback(resp.data.data)
         }
      } else {
        errCallback()
      }
   }
}
