import React, { useImperativeHandle, useState } from "react"
import { Col, Row } from "react-bootstrap"
import Modal from "react-modal"
import CloseModalIcon from "../assets/images/CloseModalIcon.svg"

export default function ModalForm({
   passedInRef,
   title = "",
   modalTitle = "",
   children,
   closeButton = false,
   modalFor = "",
   withActionButton = false,
   actionButtonComp,
   width,
}) {
   const [modalState, setModalState] = useState({
      isOpen: false,
   })

   useImperativeHandle(passedInRef, () => ({
      show: () => {
         setModalState((_state) => ({
            ..._state,
            isOpen: true,
         }))
      },

      hide: () => {
         setModalState((_state) => ({
            ..._state,
            isOpen: false,
         }))
      },
   }))

   const modalAddItemStyle = {
      overlay: {
         zIndex: "1060",
         background: "rgba(0,0,0,.7)",
      },
      content: {
         top: "50%",
         left: "50%",
         right: "auto",
         bottom: "auto",
         marginRight: "-50%",
         width: width || "732px",
         maxHeight: "95vh",
         transform: "translate(-50%, -50%)",
         borderRadius: "12px",
         transition: "all .3s ease",
         backgroundColor: "white",
         paddingRight: "0",
         paddingLeft: "0",
         paddingBottom: "0",
         overflow: "auto",
         zIndex: "1060"
      },
   }

   const modalAddItemStyle2 = {
      overlay: {
         zIndex: "1060",
         background: "rgba(0,0,0,.7)",
      },
      content: {
         top: "50%",
         left: "50%",
         right: "auto",
         bottom: "auto",
         marginRight: "-50%",
         width: "fit-content",
         transform: "translate(-50%, -50%)",
         borderRadius: "12px",
         transition: "all .3s ease",
         backgroundColor: "white",
         paddingRight: "0",
         paddingLeft: "0",
         paddingBottom: "0",
         overflow: "auto",
         zIndex: "1060"
      },
   }

   return (
      <Modal
         isOpen={modalState.isOpen}
         ariaHideApp={false}
         style={modalFor === "SCORING_FORM" ? modalAddItemStyle2 : modalAddItemStyle}
         contentLabel={modalTitle}
      >
         <Row style={{ margin: "10px 10px 0" }}>
            <Col lg={12}>
               {!withActionButton && (
                  <div className="d-flex justify-content-between">
                     <p style={{ fontSize: "18px", fontWeight: "700" }}>{title}</p>
                     {closeButton && (
                        <img
                           src={CloseModalIcon}
                           alt=""
                           style={{ cursor: "pointer" }}
                           onClick={() =>
                              setModalState((x) => ({
                                 ...x,
                                 isOpen: false,
                              }))
                           }
                        />
                     )}
                  </div>
               )}

               {withActionButton && (
                  <>
                     <div className="d-flex justify-content-end mb-4">
                        {closeButton && (
                           <img
                              src={CloseModalIcon}
                              alt=""
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                 setModalState((x) => ({
                                    ...x,
                                    isOpen: false,
                                 }))
                              }
                           />
                        )}
                     </div>
                     <div className="d-flex justify-content-between align-items-center">
                        <p style={{ fontSize: "18px", fontWeight: "700" }}>{title}</p>
                        {actionButtonComp}
                     </div>
                  </>
               )}
               <hr style={{ borderColor: "#C4C4C4" }} />
            </Col>
         </Row>

         {children}
      </Modal>
   )
}
