import Apiservice from "../../services/Apiservice"

const API_URL = Apiservice.url.AUCTION_URL + "/api/secured/negotiation-batch"

export function findById (batchId, callback) {
   return async () => {
      const resp = await Apiservice.invokeGet(API_URL + "/" + batchId)
      if (resp.status == 200) {
         if (callback) {
            callback(resp.data.data)
         }
      }
   }
}
export function getStatus (negotiationBatch, from, callback) {
   return async () => {
      const resp = await Apiservice.invokePost(API_URL + "/get-status", {
         negotiationBatch: negotiationBatch,
         from: from,
      })
      if (resp.status == 200) {
         if (callback) {
            callback(resp.data.data)
         }
      }
   }
}
export function changeStatus (negotiationBatch, status, callback, errCallback) {
   return async () => {
      const resp = await Apiservice.invokePost(API_URL + "/change-status", {
         negotiationBatch: negotiationBatch,
         status: status,
      })
      if (resp.status == 200) {
         if (callback) {
            callback(resp.data.data)
         }
      } else {
         if (errCallback) {
            errCallback(resp)
         }
      }
   }
}

export function deleteBatch (batchId, callback) {
   return async () => {
      const resp = await Apiservice.invokeDel(API_URL + "/", {
         negotiationBatchId: batchId,
      })
      if (resp.status == 200) {
         if (callback) {
            callback(resp.data.data)
         }
      }
   }
}
