import React from 'react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';

export default function Texteditor({onValueChange, placeholder = "", data}) {
    return (
        <>
            <CKEditor
                editor={ClassicEditor}
                data={data}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    onValueChange(data);
                }}
                placeholder={placeholder}
            />
        </>
    )
}