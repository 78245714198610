import React, { useImperativeHandle, useState } from 'react';
import { Col, Row } from "react-bootstrap";
import Modal from 'react-modal';
import CloseModalIcon from '../assets/images/CloseModalIcon.svg';
import SubmitSuccessPassingGradeIcon from '../assets/images/SubmitSuccessPassingGradeIcon.svg';

export default function SuccessModal({ passedInRef, children }) {
    const [modalState, setModalState] = useState({
        modalTitle: "",
        title: "",
        description: "",
        isOpen: false
    })

    const modalSubmitStyle = {
        overlay: {
            zIndex: '1060',
            background: 'rgba(0,0,0,.7)'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            width: '573px',
            transform: 'translate(-50%, -50%)',
            borderRadius: '12px',
            transition: 'all .3s ease',
            backgroundColor: 'white',
            paddingRight: '0',
            paddingLeft: '0',
            paddingBottom: '0',
            overflow: 'hidden',
            height: '270px',
            zIndex: '1060'
        }
    }

    useImperativeHandle(passedInRef, () => ({

        show: ({ modalTitle, title, description }) => {
            setModalState(_state => ({
                modalTitle,
                title,
                description,
                isOpen: true
            }))
        },

        hide: () => {
            setModalState(_state => ({
                ..._state,
                isOpen: false
            }))
        }

    }));

    return (
        <Modal 
            isOpen={modalState.isOpen}
            ariaHideApp={false}
            style={modalSubmitStyle}
            contentLabel={modalState.modalTitle}
        >
            <Row style={{ marginLeft: '500px' }}>
                <Col lg={12}>
                    <img src={CloseModalIcon} alt="" style={{ cursor: 'pointer' }} onClick={() => setModalState(x => ({
                        ...x,
                        isOpen: false
                    }))} />
                </Col>
            </Row>
            <Row style={{ marginTop: '25px', marginLeft: '15px' }}>
                <Col lg={2}>
                    <img src={SubmitSuccessPassingGradeIcon} alt="" />
                </Col>
                <Col lg={10}>
                    <p style={{ fontSize: '24px', fontWeight: '500' }}>{modalState.title}</p>
                    <p style={{ fontSize: '12px', marginTop: '10px', maxWidth: '380px' }}>
                        {modalState.description}
                    </p>
                </Col>
            </Row>

            {children}
        </Modal>
    )
}

