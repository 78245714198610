import Apiservice from "../services/Apiservice";

const API_URL = Apiservice.url.AUCTION_URL + "/api/secured/procurement-vendor-item";

function ProcurementVendorItemService(){
    this.findByVendor = function(vendorId, callback){
        return async () => {
            const resp = await Apiservice.invokeGet(API_URL + "/" + vendorId + "/by-vendor");
            if (resp.status == 200) {
                if (callback) {
                    callback(resp.data.data);
                }
            }
        }
    }
}

export default ProcurementVendorItemService