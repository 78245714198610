import React from 'react'
import styled from 'styled-components'

export default function NotificationItemCounter({ count = 0 }) {
    return (
        <>
            {count > 0 &&
                <CounterStyle>{count}</CounterStyle>
            }

        </>
    )
}

const CounterStyle = styled.div`
    background-color: #E8444D;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: 10px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 38px;
    bottom: 18px;
`