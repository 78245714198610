import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { TableBody, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import { Col, Form, InputGroup } from "react-bootstrap"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import Constant from "../../../../../constant"
import Buttonaction from "../../../../components/Buttonaction"
import CurrencyInput from "../../../../components/CurrencyInput"
import MuiDataTable from "../../../../components/MuiDataTable"
import Formservice from "../../../../services/Formservice"
import NegotiationOfferService from "../../../../services/NegotiationOfferService"

const TotalPriceByUnit = (params) => {
   const {
      itemInitialPrice,
      negotiation,
      accountId,
      lastOffer,
      procurementVendorItems,
      negotiationItems,
      socketRefNegotiation,
      fromAccountId,
      toAccountId,
      dataProcurement,
   } = params

   const negotiationOfferService = new NegotiationOfferService()
   const inputOffer = useRef()
   const dispatch = useDispatch()
   const [offerPayloads, setOfferPayloads] = useState([])
   const [disableOffer, setDisableOffer] = useState(true)

   useEffect(() => {
      generateOfferPayload(lastOffer)
      handleDisableOffer(lastOffer?.offer)
      initSocket()
   }, [])

   const initSocket = () => {
      socketRefNegotiation.current.on(Constant.socketEvents.RESPONSE_NEGO_OFFER, (resp) => {
         handleDisableOffer(resp)
         dispatch(
            negotiationOfferService.findLastOfferByNegotiationId(resp.negotiationId, (resp) => {
               generateOfferPayload(resp)
            }),
         )
      })
   }

   const sendOffer = () => {
      const payload = {
         negotiation: negotiation,
         negotiationOfferItems: offerPayloads,
         fromAccountId: fromAccountId,
         toAccountId: toAccountId,
         createdAt: new Date(Date.now()),
      }
      socketRefNegotiation.current.emit(Constant.socketEvents.MAKE_NEGO_OFFER_ITEMS, payload)
   }

   const handleDisableOffer = (offer) => {
      setDisableOffer(true)
      if (offer) {
         if (offer.status === Constant.NEGOTIATION_STATUS_OFFER.NOT_DEAL) {
            if (offer.fromAccountId === accountId) {
               setDisableOffer(false)
            }
         } else if (offer.status !== Constant.NEGOTIATION_STATUS_OFFER.DEAL) {
            if (offer.toAccountId === accountId) {
               setDisableOffer(false)
            }
         }
      } else if (negotiation.fromAccountId === accountId) {
         setDisableOffer(false)
      }
   }

   const sumAllOffer = () => {
      let sum = 0
      offerPayloads.forEach((item) => {
         sum += item.offer * item.minCapableQty
      })
      return sum
   }

   const generateOfferPayload = (_lastOffer) => {
      const arr = []
      let payload
      for (const item of negotiationItems) {
         payload = {}
         payload.negotiationItemId = item._id
         payload.itemCode = item.procurementItem.itemCode
         payload.itemName = item.procurementItem.itemName
         payload.qty = item.procurementItem.qty
         payload.minCapableQty = item.procurementItem.minCapableQty
         payload.UOM = item.procurementItem.UOM
         payload.offer = item.lastPrice
         payload.capableQty = item.procurementItem.minCapableQty
         if (_lastOffer) {
            const { offerItems } = _lastOffer
            for (const offerItem of offerItems) {
               if (offerItem.negotiationItem.procurementItemId === item.procurementItemId) {
                  payload.offer = offerItem.offer
                  payload.capableQty = offerItem.capableQty
               }
            }
         }
         arr.push(payload)
      }

      setOfferPayloads(arr)
   }

   const handleChange = (idx, value, type) => {
      let _offers
      _offers = [...offerPayloads]
      if (type === "OFFER") {
         _offers[idx].offer = value
      }
      setOfferPayloads(_offers)
   }

   return (
      <>
         {itemInitialPrice.length > 0 && (
            <>
               <MuiDataTable.CustomTable
                  stickyHeader={true}
                  containerStyles={{
                     overflowX: "auto",
                     maxHeight: "240px",
                     "&::-webkit-scrollbar": {
                        height: "8px",
                        width: "0",
                     },
                     "&::-webkit-scrollbar-thumb": {
                        background: "#C4C4C4",
                     },
                     scrollbarWidth: "thin",
                     scrollbarColor: "#C4C4C4",
                  }}
               >
                  <TableHead>
                     <TableRow>
                        <TableCell
                           className="py-2"
                           size="small"
                           sx={{ whiteSpace: "nowrap", background: "#D0D6E0", width: "0" }}
                        >
                           <TableSortLabel
                              //    active={orderBy === "Item Name"}
                              //    direction={order}
                              onClick={(v) => handleRequestSort(v.target.outerText)}
                              IconComponent={ExpandLessIcon}
                           >
                              Item Code
                           </TableSortLabel>
                        </TableCell>
                        <TableCell className="py-2" size="small" sx={{ background: "#D0D6E0" }}>
                           <TableSortLabel
                              //    active={orderBy === "Item Name"}
                              //    direction={order}
                              onClick={(v) => handleRequestSort(v.target.outerText)}
                              IconComponent={ExpandLessIcon}
                           >
                              Item Name
                           </TableSortLabel>
                        </TableCell>
                        <TableCell className="py-2" size="small" sx={{ whiteSpace: "nowrap", background: "#D0D6E0" }}>
                           <TableSortLabel
                              //    active={orderBy === "Item Name"}
                              //    direction={order}
                              onClick={(v) => handleRequestSort(v.target.outerText)}
                              IconComponent={ExpandLessIcon}
                           >
                              Needed Quantity
                           </TableSortLabel>
                        </TableCell>
                        <TableCell
                           align="right"
                           className="py-2"
                           size="small"
                           sx={{ whiteSpace: "nowrap", background: "#D0D6E0" }}
                        >
                           <TableSortLabel
                              //    active={orderBy === "Item Name"}
                              //    direction={order}
                              onClick={(v) => handleRequestSort(v.target.outerText)}
                              IconComponent={ExpandLessIcon}
                           >
                              Unit Price
                           </TableSortLabel>
                        </TableCell>
                        <TableCell
                           align="right"
                           className="py-2"
                           size="small"
                           sx={{ whiteSpace: "nowrap", background: "#D0D6E0" }}
                        >
                           <TableSortLabel
                              //    active={orderBy === "Item Name"}
                              //    direction={order}
                              onClick={(v) => handleRequestSort(v.target.outerText)}
                              IconComponent={ExpandLessIcon}
                           >
                              Sub Total Price
                           </TableSortLabel>
                        </TableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {offerPayloads?.map((item, index) => (
                        <TableRow
                           key={index}
                           sx={{
                              background: "#F3F5F4",
                              "&:hover": {
                                 background: "transparent",
                                 cursor: "pointer",
                              },
                           }}
                        >
                           <TableCell className="py-2">{item.itemCode}</TableCell>
                           <TableCell className="py-2" sx={{ minWidth: "120px" }}>
                              {item.itemName}
                           </TableCell>
                           <TableCell style={{ width: "102px" }} className="py-2">
                              {item.minCapableQty}
                           </TableCell>
                           <TableCell style={{ whiteSpace: "nowrap" }}>
                              <div style={{ width: "200px" }}>
                                 <InputGroup style={{ width: "100%" }}>
                                    <CurrencyInput
                                       ref={inputOffer}
                                       placeholder="0"
                                       className="form-control text-right"
                                       disabled={
                                          disableOffer ||
                                          negotiation?.status === Constant.STATUS.NOT_STARTED ||
                                          negotiation?.status === Constant.STATUS.CANCELED ||
                                          negotiation?.status === Constant.STATUS.DONE
                                       }
                                       value={item.offer}
                                       onChange={(evt) =>
                                          handleChange(index, evt.target.value.toString().split(",").join(""), "OFFER")
                                       }
                                       // onClick={() => inputOffer.current.select()}
                                       // onComponentBlur={() => localStorage.setItem("autoReducer", autoReducer)}
                                    />
                                    <InputGroupText>IDR</InputGroupText>
                                 </InputGroup>
                              </div>
                           </TableCell>
                           <TableCell align="right" style={{ whiteSpace: "nowrap" }} className="py-2">
                              {Formservice.CurrencyFormatter(item.minCapableQty * item.offer, {
                                 currency: dataProcurement?.currency,
                              })}
                           </TableCell>
                        </TableRow>
                     ))}
                  </TableBody>
               </MuiDataTable.CustomTable>
               <Col className="mt-3 px-0" lg={12}>
                  <div className="d-flex justify-content-end">
                     <Form.Label column="sm" lg={7}>
                        <p className="d-flex justify-content-end mt-1" style={{ fontSize: "14px" }}>
                           Sub Total
                        </p>
                     </Form.Label>
                     <Col sm={5} className="pr-0">
                        <InputGroup className="mb-3">
                           <CurrencyInput
                              placeholder="0"
                              className="form-control text-right"
                              value={sumAllOffer()}
                              //    onChange={(evt) => handleChange(evt.target.value)}
                              // onComponentBlur={() => localStorage.setItem("autoReducer", autoReducer)}
                              disabled
                           />
                           <InputGroupText>IDR</InputGroupText>
                        </InputGroup>
                        {/* <ErrorField fieldName="nego_offer" errors={errorFields} /> */}
                     </Col>
                  </div>
                  <div className="d-flex justify-content-end align-items-center">
                     <Buttonaction
                        blueOcean
                        label="Send Offer"
                        disabled={
                           disableOffer ||
                           negotiation?.status === Constant.STATUS.NOT_STARTED ||
                           negotiation?.status === Constant.STATUS.CANCELED ||
                           negotiation?.status === Constant.STATUS.DONE
                        }
                        style={{ minWidth: "276px" }}
                        onClick={() => sendOffer()}
                     />
                  </div>
               </Col>
            </>
         )}
      </>
   )
}

export default TotalPriceByUnit

const InputGroupText = styled(InputGroup.Text)`
   margin-left: -3px;
   font-size: 12px;
   z-index: 0;
`
