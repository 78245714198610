import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import NotificationItemCounter from "./NotificationItemCounter";

export function HeadermenuItemAccount({ picture, name, ...props }) {
  return (
    <MenuItem {...props}>
      <ProfilePic {...props}>{picture}</ProfilePic>
      <Label>{name}</Label>
    </MenuItem>
  );
}

export function HeadermenuItemAction({
  icon,
  onClick,
  withCounter = false,
  counterValue = 0,
  ...props
}) {
  return (
    <MenuItem onlyicon onClick={onClick} {...props}>
      {withCounter && (
        <>
          <NotificationItemCounter count={counterValue} />
        </>
      )}
      {/* <Icon><FontAwesomeIcon icon={icon}/></Icon> */}
      <Icon>
        <img src={icon} />
      </Icon>
    </MenuItem>
  );
}

export default function HeadermenuItem({ icon, label, linkTo, ...props }) {
  return (
    <MenuItem {...props}>
      <Icon>
        <img src={icon} />
      </Icon>
      <Label>{label}</Label>
    </MenuItem>
  );
}

const MenuItem = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: ${(props) => props.theme.headermenu.fontColor};
  font: ${(props) => props.theme.fonts.regular};
  padding: 2px 4px;
  margin-top: -4px;
  cursor: pointer;
  &:hover {
    color: ${(props) =>
      props.buyertype
        ? props.theme.headermenu.fontColorHovered
        : props.theme.colors.blueOcean};
    background-color: ${(props) =>
      props.onlyicon ? props.theme.colors.whiteDarken15 : ""};
    border-radius: ${(props) => props.theme.borderRadius.small};
  }

  //   &:not(:last-child) {
  //     &:after {
  //       content: "";
  //       width: 1px;
  //       background-color: ${(props) => props.theme.colors.whiteDarken15};
  //       position: relative;
  //       height: 20px;
  //       left: 10px;
  //     }
  //   }
`;

const Icon = styled.span`
  padding: 0 10px;
  display: flex;
  cursor: pointer;
`;

const Label = styled.label`
  color: #454545;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0;
  cursor: pointer;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const ProfilePic = styled.div`
  width: 25px;
  height: 25px;
  margin-right: 10px;
  margin-left: 10px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.buyertype ? props.theme.colors.pristine : props.theme.colors.blue};
  color: ${(props) => props.theme.colors.white};
  border-radius: 50%;
`;
