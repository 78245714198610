import Apiservice from "../services/Apiservice";

const API_URL = Apiservice.url.AUCTION_URL + "/api/secured/invitation";

function InvitationService(){
    this.findByForeignKeyAndToAccountIdAndType = function(foreignKey, toAccountId, type, callback){
        return async() => {
            const resp = await Apiservice.invokePost(API_URL + "/find-by-to-owner-and-foreign-key",{
                foreignKey:foreignKey,
                toAccountId:toAccountId,
                type:type
            });
            if (resp.status == 200) {
                if (callback) {
                    callback(resp.data.data);
                }
            }
        }
    }
}

export default InvitationService