import React, { useEffect, useImperativeHandle, useState } from "react"
import styled from "styled-components"
import { Col, Row } from "react-bootstrap"
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import smallAlert from "../assets/images/icon-alert-sm.svg"
import Buttonaction from "./Buttonaction"
import Formservice from "../services/Formservice"
import Timeinfo from "./Timeinfo"
import smallRedUnion from "../assets/images/Icon-smallRedUnion.svg"
import RedFlag from "../assets/images/Icon-RedFlag.svg"
import Dateinfo from "./Dateinfo"
import moment from "moment"

import { Backdrop, Modal, Fade } from "@mui/material"
import Constant from "../../constant"

export default function BuyerAuctionOfferBubble({
   data,
   dataProcurement,
   dataAuction,
   handleDisqualify,
   initialPrice,
   passedInRef,
   auctionStatus,
   isOfferRequestDisqualified,
   isOfferDisqualified
}) {
   const [reqForDisqualification, setReqForDisqualification] = useState(false)
   const [isDisqualified, setIsDisqualified] = useState(false)
   const [reason, setReason] = useState("")
   const [reasonBeforeDisqualified, setReasonBeforeDisqualified] = useState("")

   useEffect(() => {
      if (data.reqForDisqualification) {
         setReqForDisqualification(true)
      } else {
         setReqForDisqualification(false)
      }

      if (data.priceDisqualified) {
         setIsDisqualified(true)
      }

      if (data.reason) {
         setReason(data.reason)
      }
   }, [])

   useImperativeHandle(passedInRef, () => ({
      setThisComponentToDefaultState: () => {
         setReqForDisqualification(false)
         setIsDisqualified(false)
         setReason("")
         setReasonBeforeDisqualified("")
      },

      updateReqDisqualifyStatus: (_isRequesting) => {
         setReqForDisqualification(_isRequesting.reqForDisqualification)
         setReasonBeforeDisqualified(_isRequesting.reason)
      },

      updateAsDisqualified: (_reason) => {
         setIsDisqualified(true)
         setReason(_reason)
      },
   }))

   return (
      <>
         {!isDisqualified || !isOfferDisqualified ? (
            <OfferBubble className="mt-3">
               <Col lg={12} className="p-0">
                  {reqForDisqualification || isOfferRequestDisqualified ? (
                     <ReqDisqualifyMessage className="d-flex align-items-center">
                        <img src={smallAlert} alt="alert" className="mr-2" />
                        <p>
                           <span style={{ fontWeight: "700" }}>Vendor request</span> to disqualified this price
                        </p>
                     </ReqDisqualifyMessage>
                  ) : (
                     <></>
                  )}
                  <OfferHead>
                     <Col lg={8}>
                        <p>{data.vendorName}</p>
                     </Col>
                     <Col lg={4} className="d-flex justify-content-end">
                        <Dateinfo date={data.createdAt} format="dd/mm/yyyy" />
                        <span style={{ color: "#030303" }}>
                           {
                              data.createdAt &&
                                 new Date(data.createdAt).toLocaleString("en-US", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                    hour12: false,
                                 })
                              // <RunTest date={data.createdAt} />
                           }
                        </span>
                     </Col>
                  </OfferHead>
                  <OfferPrice>
                     <Col lg={9}>
                        {Formservice.CurrencyFormatter(data.totalOffer, { currency: data?.currency })}
                        {dataProcurement && dataAuction?.auctionType === "OPEN"
                           ? data.totalOffer <= dataProcurement.total && <img src={RedFlag} className="ml-3" />
                           : data.totalOffer < initialPrice && <img src={RedFlag} className="ml-3" />}
                     </Col>
                     {reqForDisqualification ? (
                        <Col lg={3} className="d-flex justify-content-end mt-3">
                           <Buttonaction
                              // disabled={auctionStatus !== "ON_GOING" || dataAuction.status !== Constant.STATUS.ON_GOING}
                              accentRed
                              xsmall
                              faIcon={faTimesCircle}
                              label="Disqualify"
                              onClick={() =>
                                 handleDisqualify({
                                    offerData: { ...data, reason: reasonBeforeDisqualified || reason },
                                    offerId: data._id,
                                    auctionId: data.auctionId,
                                    accountId: data.accountId,
                                 })
                              }
                           />
                        </Col>
                     ) : (
                        <></>
                     )}
                  </OfferPrice>
               </Col>
            </OfferBubble>
         ) : (
            <>
               <Row
                  className="mt-3"
                  style={{
                     backgroundColor: "#FFFFFF",
                     borderRadius: "5px",
                     padding: "10px 18px",
                     border: "1px solid #D0D6E0",
                  }}
               >
                  <Col lg={3} style={{ margin: "auto" }}>
                     <Row>
                        <Col style={{ fontSize: "12px" }} className="text-muted">
                           <p>{data.vendorName}</p>
                        </Col>
                     </Row>
                     <Row>
                        <Col
                           style={{
                              fontSize: "16px",
                              fontWeight: "600",
                              marginTop: "8px",
                           }}
                           className="text-muted"
                        >
                           <p>{Formservice.CurrencyFormatter(data.totalOffer, { currency: data?.currency })}</p>
                        </Col>
                     </Row>
                  </Col>

                  <Col
                     lg={6}
                     style={{
                        borderLeft: "1px solid #D0D6E0",
                        borderRight: "1px solid #D0D6E0",
                     }}
                  >
                     <div style={{ fontSize: "12px" }}>
                        <div>
                           <strong>Reason</strong>
                        </div>
                        <p>{reason}</p>
                     </div>
                  </Col>

                  <Col
                     lg={3}
                     style={{
                        fontSize: "12px",
                        margin: "auto",
                        padding: "0 0 0 15px",
                     }}
                  >
                     <div className="text-right">
                        {/* <p>09/09/2021 - 07:59 PM</p> */}
                        <Dateinfo date={data.updatedAt} format="dd/mm/yyyy" />
                        <span style={{ color: "#030303" }}>
                           {data.updatedAt &&
                              new Date(data.updatedAt).toLocaleString("en-US", {
                                 hour: "2-digit",
                                 minute: "2-digit",
                                 second: "2-digit",
                                 hour12: false,
                              })}
                        </span>

                        <div className="d-flex justify-content-end    my-2">
                           {dataAuction?.auctionType === "CLOSE" && data.totalOffer < initialPrice ? (
                              <img src={smallRedUnion} alt="" className="inline-block mr-2" />
                           ) : (
                              <></>
                           )}
                           <p style={{ color: "#F44437", fontWeight: "700" }}>Disqualified</p>
                        </div>
                        <p>
                           {/* <b>PIC</b> Arina Pramudhita */}
                           <b>PIC</b> {data.picName}
                        </p>
                     </div>
                  </Col>
               </Row>
            </>
         )}

         <Modal BackdropComponent={Backdrop} BackdropProps={{ timeout: 500 }}>
            <Fade></Fade>
         </Modal>
      </>
   )
}

const OfferBubble = styled(Row)`
   background-color: #ffffff;
   border-radius: 5px;
`

const ReqDisqualifyMessage = styled.div`
   color: #c71822;
   font-size: 12px;
   background-color: #fde9ef;
   border-radius: 5px 5px 0 0;
   padding: 12px 18px;
`

const OfferHead = styled(Row)`
   font-size: 12px;
   padding: 12px 18px 6px;
`

const OfferPrice = styled(Row)`
   padding: 0 18px 12px;
   font-size: 18px;
   font-weight: 600;
`
