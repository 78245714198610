import React from 'react'
import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import AttendExpired from '../../assets/images/attend-expired.svg'

export default function AttendanceExpired() {
    return (
        <Row>
            <Col style={{ height: '100vh', flexDirection: "column", justifyContent: "center", alignItems: "center", display: "flex" }}>
                <Row>
                    <Col className="d-flex justify-content-center align-items-center mt-5">
                        <img src={AttendExpired} />
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex justify-content-center align-items-center mt-1">
                        <p>Opps.. this link has been used or expired</p>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

const TextStyle = styled.p`
    color: #909090;
    font-size: 24px;
`