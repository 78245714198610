import React, { useEffect, useImperativeHandle, useRef, useState } from "react"
import styled from "styled-components"
import { useDispatch } from "react-redux"
import { Col, Row } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons"

// Assets
import LogisticIcon from "../../../../assets/images/LogisticIcon.png"

// Components
import ModalForm from "../../../../components/Modalform"
import Buttonaction from "../../../../components/Buttonaction"

// Redux
import { fetchProcurementVendorItems } from "../../../../redux/action/negotiationAction"
import { fetchProcurementItems } from "../../../../redux/action/procurementAction"

import VendorItems from "./VendorItems"
import { connect } from "react-redux"
import ProcurementItems from "./ProcurementItems"

// #
import Constant from "../../../../../constant"
import { Loader } from "rsuite"

function AddItemVendor({ passedInRef, dataProcurement, dataProcurementVendorItems }) {
   const dispatch = useDispatch()
   const { procurementId } = useParams()

   // Component state
   const [addItem, setAddItem] = useState(false)
   const [vendorId, setVendorId] = useState(null)
   const [isLoading, setIsLoading] = useState(false)

   // Component refs
   const addItemVendorRef = useRef()

   useImperativeHandle(passedInRef, () => ({
      show: (vendorId) => {
         addItemVendorRef.current.show()
         setVendorId(vendorId)

         _fetchProcurementVendorItems(vendorId)
            .then((res) => {
               res.length > 0 && setAddItem(false)
               dispatch(fetchProcurementItems({ procurementId, limit: 999999 }, (_res) => {}))
            })
            .catch()
            .finally(() => {
               setIsLoading(false)
            })
      },

      hide: () => {
         addItemVendorRef.current.hide()
      },
   }))

   const _fetchProcurementVendorItems = async (vendorId) => {
      return new Promise((resolve, reject) => {
         setIsLoading(true)
         dispatch(
            fetchProcurementVendorItems(
               vendorId,
               (res) => resolve(res),
               () => reject(),
            ),
         )
      })
   }

   return (
      <>
         <ModalForm
            passedInRef={addItemVendorRef}
            title={addItem ? "Add Item" : "Vendor Item"}
            closeButton={true}
            withActionButton={
               isLoading || addItem || dataProcurement?.status !== Constant.procurementStatus.DRAFT ? false : true
            }
            actionButtonComp={
               <Buttonaction
                  //  disabled={isMeritPoint && totalItem < 1}
                  blueOcean
                  label="Add Item"
                  faIcon={faPlusCircle}
                  onClick={() => setAddItem(true)}
               />
            }
            width="90vw"
         >
            <Row className="mx-0">
               {isLoading ? (
                  <Col className="d-flex justify-content-center mx-3 mt-3 mb-5 align-items-center">
                     <Loader speed="fast" size="md" content="Getting data..." />
                  </Col>
               ) : (
                  dataProcurementVendorItems.length < 1 &&
                  !addItem && (
                     <Col className="d-flex justify-content-center align-items-center m-5 flex-column">
                        <img style={{ marginLeft: "-10px" }} src={LogisticIcon} />
                        <span
                           className="mt-3"
                           style={{
                              textAlign: "center",
                              color: "#909090",
                              fontWeight: "bold",
                           }}
                        >
                           No list available
                           <br />
                           Let’s try to add one!
                        </span>
                     </Col>
                  )
               )}

               {addItem && !isLoading && (
                  <Col className="px-4">
                     <ProcurementItems
                        addItemVendorRef={addItemVendorRef}
                        setAddItem={setAddItem}
                        vendorId={vendorId}
                        setIsLoading={setIsLoading}
                     />
                  </Col>
               )}

               {dataProcurementVendorItems?.length > 0 && !addItem && !isLoading && (
                  <Col className="px-4">
                     <VendorItems setAddItem={setAddItem} vendorId={vendorId} addItemVendorRef={addItemVendorRef} />
                  </Col>
               )}
            </Row>
         </ModalForm>
      </>
   )
}

const mapStateToProps = (state) => ({
   dataProcurement: state.procurementReducer.dataProcurement,
   dataProcurementVendorItems: state.procurementReducer.dataProcurementVendorItems,
})

export default connect(mapStateToProps)(AddItemVendor)
