import React, { useEffect, useImperativeHandle, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import toast from "react-hot-toast"
import { connect, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { SelectPicker } from "rsuite"
import Buttonaction from "../../../components/Buttonaction"
import ErrorField from "../../../components/Errorfield"
import Modal from "react-modal"
import { fetchMasterData } from "../../../redux/action/masterDataAction"
import {
   createProcurementVendor,
   fetchProcurementVendors,
   getProcurementVendor,
   updateProcurementVendor,
} from "../../../redux/action/procurementAction"
import Skeleton from "react-loading-skeleton"
import InputAlert from "../../../components/InputAlert"

function InitiationVendorForm({
   isAppLoading,
   dataMasters,
   passedInRef,
   createVendorsChecked,
   setDataProcurementVendors,
   dataState,
}) {
   const dispatch = useDispatch()
   const [formState, setFormState] = useState({
      procurementVendorId: undefined,
      name: "",
      email: "",
      phone: "",
      phonePrefix: "",
      city: "",
      picVendor: "",
   })

   const [modalState, setModalState] = useState({
      isOpen: false,
      opMode: "view", // view | edit | viewNoSave
   })

   const { procurementId } = useParams()
   const [errors, setErrors] = useState([])
   const [isLoading, setIsLoading] = useState(false)

   useImperativeHandle(passedInRef, () => ({
      show: ({ opMode, procurementVendorId }) => {
         setErrors([])
         setModalState((_state) => ({
            isOpen: true,
            opMode,
         }))

         if (opMode === "view" || (opMode === "viewNoSave" && procurementVendorId)) {
            setFormState((_state) => ({ ..._state, procurementVendorId }))

            setIsLoading(true)
            new Promise((resolve, reject) => {
               dispatch(
                  getProcurementVendor(
                     { procurementVendorId },
                     (_result) => {
                        resolve(_result)
                     },
                     () => {
                        reject()
                     },
                  ),
               )
            })
               .then((_result) => {
                  setFormState({
                     ..._result,
                     procurementVendorId,
                     phonePrefix: _result.phone.startsWith("+62") ? _result.phone.substr(0, 3) : "",
                     phone: _result.phone.startsWith("+62")
                        ? _result.phone.substr(3, _result.phone.length)
                        : _result.phone,
                  })
               })
               .catch(() => {
                  alert("Data not found")
               })
               .finally(() => setIsLoading(false))
         } else {
            setFormState({
               procurementVendorId: undefined,
               name: "",
               email: "",
               phone: "",
               phonePrefix: "",
               city: "",
               picVendor: "",
            })
         }
      },

      hide: () => {
         setModalState((_state) => ({
            ..._state,
            isOpen: false,
         }))
      },
   }))

   const submitVendor = () => {
      if (formState.procurementVendorId) {
         dispatch(
            updateProcurementVendor(
               {
                  ...formState,
                  procurementId,
               },
               () => {
                  dispatch(
                     fetchProcurementVendors(
                        {
                           procurementId,
                           page: dataState.page + 1,
                           limit: dataState.limit,
                        },
                        (res) => {
                           setDataProcurementVendors(res)
                           // createVendorsChecked(res.filteredData);
                           // createVendorsChecked(res.filteredData)
                        },
                     ),
                  )

                  toast.success("Vendor has been updated successfully")
                  passedInRef.current.hide()

                  setErrors([])
               },
               (_errors) => {
                  setErrors(_errors)
               },
            ),
         )
      } else {
         dispatch(
            createProcurementVendor(
               {
                  ...formState,
                  procurementId,
               },
               (res) => {
                  dispatch(
                     fetchProcurementVendors(
                        {
                           procurementId,
                           page: 0,
                           limit: dataState.limit,
                        },
                        (res) => {
                           setDataProcurementVendors(res)
                           createVendorsChecked(res.filteredData)
                        },
                     ),
                  )

                  if (!res) {
                     toast.error("Email already added to other vendor")
                  } else {
                     toast.success("Vendor has been added successfully")
                  }
                  passedInRef.current.hide()
                  setErrors([])
               },
               (_errors) => {
                  setErrors(_errors)
               },
            ),
         )
      }
   }

   useEffect(() => {
      if (!dataMasters) dispatch(fetchMasterData())

      console.log(createVendorsChecked)
   }, [])

   return (
      <Modal isOpen={modalState.isOpen} ariaHideApp={false} style={modaAdditemStyle} contentLabel="Success">
         <Row style={{ marginTop: "10px", marginLeft: "10px", marginRight: "10px" }}>
            <Col lg={12}>
               <p style={{ fontSize: "18px", fontWeight: "500" }}>
                  {formState.procurementVendorId ? "Edit Vendor" : "Add Vendor"}
               </p>
               <hr style={{ borderColor: "#C4C4C4" }} />
            </Col>
         </Row>

         <Row style={{ marginTop: "10px", marginLeft: "10px", marginRight: "10px" }}>
            <Col className="px-4" lg={12}>
               <Form className="roboto-form">
                  <Form.Group
                     as={Row}
                     className={`${modalState.opMode === "viewNoSave" ? "mb-1" : "mb-3"} align-items-center`}
                     controlId="company_name"
                  >
                     <Form.Label column sm={3}>
                        <p style={{ fontSize: "12px" }}>Company Name</p>
                     </Form.Label>
                     <Col>
                        {modalState.opMode === "viewNoSave" ? (
                           <span>{isLoading ? <Skeleton width="50%" /> : formState.name}</span>
                        ) : (
                           <>
                              <Form.Control
                                 value={formState.name}
                                 type="text"
                                 placeholder="Type company name"
                                 size="sm"
                                 onChange={({ target }) =>
                                    setFormState((v) => ({
                                       ...v,
                                       name: target.value,
                                    }))
                                 }
                              />
                              <ErrorField errors={errors} fieldName="name" />
                           </>
                        )}
                     </Col>
                  </Form.Group>

                  <Form.Group
                     as={Row}
                     className={`${modalState.opMode === "viewNoSave" ? "mb-1" : "mb-3"} align-items-center`}
                     controlId="email"
                  >
                     <Form.Label column sm={3}>
                        <p style={{ fontSize: "12px" }}>Email</p>
                     </Form.Label>
                     <Col>
                        {modalState.opMode === "viewNoSave" ? (
                           <span>{isLoading ? <Skeleton width="40%" /> : formState.email}</span>
                        ) : (
                           <>
                              <Form.Control
                                 value={formState.email}
                                 type="email"
                                 placeholder="E.g. companyname@mail.com"
                                 size="sm"
                                 onChange={({ target }) =>
                                    setFormState((v) => ({
                                       ...v,
                                       email: target.value,
                                    }))
                                 }
                              />
                              <div className="mt-1">
                                 <InputAlert.Container>
                                    <InputAlert.Text
                                       text={
                                          <p>
                                             *avoid using{" "}
                                             <em>
                                                <strong>YAHOO</strong>
                                             </em>
                                             {" "}
                                             email due to frequent problems
                                          </p>
                                       }
                                       style={{ marginLeft: 0 }}
                                    />
                                 </InputAlert.Container>
                              </div>
                           </>
                        )}

                        <ErrorField errors={errors} fieldName="email" />
                     </Col>
                  </Form.Group>

                  <Form.Group
                     as={Row}
                     className={`${modalState.opMode === "viewNoSave" ? "mb-1" : "mb-3"} align-items-center`}
                     controlId="phone"
                  >
                     <Form.Label column sm={3}>
                        <p style={{ fontSize: "12px" }}>Phone</p>
                     </Form.Label>
                     {modalState.opMode === "viewNoSave" ? (
                        <Col>
                           <span>{isLoading ? <Skeleton width="30%" /> : formState.phone}</span>
                        </Col>
                     ) : (
                        <>
                           <Col
                              sm={2}
                              style={{ paddingRight: 5 }}
                              className={`${errors.find((error) => error.param == "phone") ? "align-self-start" : ""}`}
                           >
                              <SelectPicker
                                 size="lg"
                                 value={formState.phonePrefix}
                                 searchable={false}
                                 data={dataMasters?.phonePrefix}
                                 className="selectpicker"
                                 onChange={(v) =>
                                    setFormState((vv) => ({
                                       ...vv,
                                       phonePrefix: v,
                                    }))
                                 }
                              />
                              <ErrorField errors={errors} fieldName="phonePrefix" />
                           </Col>
                           <Col>
                              <Form.Control
                                 value={formState.phone}
                                 type="text"
                                 placeholder="Phone number without 0"
                                 size="sm"
                                 onChange={({ target }) =>
                                    setFormState((v) => ({
                                       ...v,
                                       phone: target.value,
                                    }))
                                 }
                              />
                              <ErrorField errors={errors} fieldName="phone" />
                           </Col>
                        </>
                     )}
                  </Form.Group>

                  <Form.Group
                     as={Row}
                     className={`${modalState.opMode === "viewNoSave" ? "mb-1" : "mb-3"} align-items-center`}
                     controlId="city"
                  >
                     <Form.Label column sm={3}>
                        <p style={{ fontSize: "12px" }}>City</p>
                     </Form.Label>
                     <Col>
                        {modalState.opMode === "viewNoSave" ? (
                           <span>{isLoading ? <Skeleton width="20%" /> : formState.city}</span>
                        ) : (
                           <>
                              <Form.Control
                                 value={formState.city}
                                 type="text"
                                 placeholder="Type city name"
                                 size="sm"
                                 onChange={({ target }) =>
                                    setFormState((v) => ({
                                       ...v,
                                       city: target.value,
                                    }))
                                 }
                              />
                              <ErrorField errors={errors} fieldName="city" />
                           </>
                        )}
                     </Col>
                  </Form.Group>

                  <Form.Group
                     as={Row}
                     className={`${modalState.opMode === "viewNoSave" ? "mb-1" : "mb-3"} align-items-center`}
                     controlId="pic_vendor"
                  >
                     <Form.Label column sm={3}>
                        <p style={{ fontSize: "12px" }}>PIC Vendor</p>
                     </Form.Label>
                     <Col>
                        {modalState.opMode === "viewNoSave" ? (
                           <span>{isLoading ? <Skeleton width="35%" /> : formState.picVendor}</span>
                        ) : (
                           <>
                              <Form.Control
                                 value={formState.picVendor}
                                 type="text"
                                 placeholder="PIC Vendor"
                                 size="sm"
                                 onChange={({ target }) =>
                                    setFormState((v) => ({
                                       ...v,
                                       picVendor: target.value,
                                    }))
                                 }
                              />
                              <ErrorField errors={errors} fieldName="picVendor" />
                           </>
                        )}
                     </Col>
                  </Form.Group>
               </Form>
            </Col>
         </Row>

         <div className="d-flex justify-content-end mt-2 mb-4 px-4">
            <div className="mr-3">
               <Buttonaction
                  type="button"
                  borderblueOcean
                  label="Cancel"
                  large
                  onClick={() => passedInRef.current.hide()}
               />
            </div>
            {modalState.opMode !== "viewNoSave" && (
               <Buttonaction
                  type="button"
                  blueOcean
                  label="Save"
                  loading={isAppLoading}
                  onClick={() => submitVendor()}
                  large
               />
            )}
         </div>
      </Modal>
   )
}

const modaAdditemStyle = {
   overlay: {
      zIndex: "1060",
      background: "rgba(0,0,0,.7)",
   },
   content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      width: "732px",
      transform: "translate(-50%, -50%)",
      borderRadius: "12px",
      transition: "all .3s ease",
      backgroundColor: "white",
      paddingRight: "0",
      paddingLeft: "0",
      paddingBottom: "0",
      overflow: "auto",
      height: "fit-content",
      zIndex: "1060",
   },
}

const mapStateToProps = (state) => {
   return {
      isAppLoading: state.appReducer.isAppLoading,
      dataMasters: state.masterDataReducer.dataMasters,
   }
}

export default connect(mapStateToProps)(InitiationVendorForm)
