import Apiservice from "./Apiservice";

const API_URL = Apiservice.url.AUCTION_URL + "/api/secured/negotiation-item";

function NegotiationItemService(){
    this.findByNegotiationId = function(negoId, callback){
        return async () => {
            const resp = await Apiservice.invokeGet(API_URL + "/find-by-negotiation/" + negoId);
            if (resp.status == 200) {
                if (callback) {
                    callback(resp.data.data);
                }
            }
        }
    }
}

export default NegotiationItemService
