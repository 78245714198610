import Apiservice from "../../services/Apiservice"

export function joiningRoom({username}, callback) {
    return async (dispatch) => {
        try {
            await Apiservice.invokePost(Apiservice.url.AUCTION_URL + "/api/room/join", {
                username
            })

            callback()
        } catch (e) {
            console.log(e)
        }
    }
}

export function makeOffer({username, price}) {
    return async (dispatch) => {
        try {
            await Apiservice.invokePost(Apiservice.url.AUCTION_URL + "/api/room/make_offer", {
                username,
                price
            })
        } catch (e) {
            console.log(e)
        }
    }
}

export function fetchOffer() {
    return async (dispatch) => {
        try {
            const resp = await Apiservice.invokeGet(Apiservice.url.AUCTION_URL + "/api/room/fetch_offer")
            if (resp.status == 200 || resp.status == 201) {
                await dispatch({
                    type: "FETCH_OFFER",
                    payload: resp.data.data
                })
            }
        } catch (e) {
            console.log(e)
        }
    }
}