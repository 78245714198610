import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'

import { Grid, Form, ButtonToolbar, Button } from 'rsuite'
import { login } from '../redux/action/sessionAction'
import ControlLabel from '../components/ControlLabel'
import { useHistory } from 'react-router-dom'

export default function Loginpage() {
    const dispatch = useDispatch()
    const history = useHistory()
    const [componentState, setComponentState] = useState({
        username: undefined
    })

    const isValid = () => {
        return componentState.username && componentState.username.length > 0
    }

    const handleSubmit = (e) => {
        if (isValid()) {
            dispatch(login({ username: componentState.username }, history))
        } else {
            toast.error("Please complete the following field")
        }
    }

    return (
        <>
            <Grid align="middle">
                <Form.Group onSubmit={handleSubmit}>
                    <ControlLabel>What is your username?</ControlLabel>
                    <Form.Control key="username" value={componentState.username} onChange={(v) => setComponentState(state => (
                        {
                            ...state,
                            username: transformStringToCode(v)
                        }
                    ))} name="username" />
                </Form.Group>

                <Form.Group>
                    <ButtonToolbar>
                        <Button type="submit" appearance="primary" disabled={!isValid()}>Login</Button>
                    </ButtonToolbar>
                </Form.Group>
            </Grid>
        </>
    )
}




const transformStringToCode = (str) => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim

    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to = "aaaaeeeeiiiioooouuuunc------";
    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9]/gi, '') // remove invalid chars
        .replace(/\s+/g, '') // collapse whitespace 
        .replace(/-+/g, ''); // collapse dashes

    if (str.length > 20) return str.substr(0, 20);
    return str;
}