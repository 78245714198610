import React, { useEffect, useState } from "react"
import toast from "react-hot-toast"
import styled from "styled-components"
import { Col, Row } from "react-bootstrap"
import { orderBy as _orderBy } from "lodash"
import { useParams } from "react-router-dom"

// Assets
import ThumbnailPlaceholder from "../../../../assets/images/ThumbnailPlaceholder.svg"

// Components
import Buttonaction from "../../../../components/Buttonaction"
import MuiDataTable from "../../../../components/MuiDataTable"
import { AnnouncementInfo } from "../../../../components/Announcement"

// Redux
import { connect, useDispatch } from "react-redux"
import { fetchProcurementVendorItems } from "../../../../redux/action/negotiationAction"
import { addItemsVendor, fetchProcurementItems, sortProcuremenItems } from "../../../../redux/action/procurementAction"

// MUI library
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import {
   Checkbox as MuiCheckbox,
   TableBody,
   TableCell,
   TableHead,
   TablePagination,
   TableRow,
   TableSortLabel,
} from "@mui/material"

function ProcurementItems({
   addItemVendorRef,
   dataProcurementItems,
   dataProcurementVendorItems,
   setAddItem,
   vendorId,
   setIsLoading,
}) {
   const dispatch = useDispatch()
   const { procurementId } = useParams()

   const handleAddVendorItems = () => {
      const payloads = []
      for (const selectedId of selectedItemIds) {
         const payload = {}
         const item = dataProcurementItems.filteredData.find((item) => item._id == selectedId)

         payload.promiseProcurementVendorItemId = 0
         payload.procurementVendorId = vendorId
         payload.procurementItemId = selectedId
         payload.item = item
         payload.saving = 0
         payload.lastPrice = 0
         payload.capableQty = 0
         payload.indicatorPrice = 0
         payload.technicalScore = 0

         payloads.push(payload)
      }

      let error = false
      for (const payload of payloads) {
         dispatch(
            addItemsVendor(
               payload,
               () => {},
               () => {
                  error = true
               },
            ),
         )
      }

      if (!error) {
         setIsLoading(true)
         setTimeout(() => {
            dispatch(
               fetchProcurementVendorItems(
                  vendorId,
                  (res) => {
                     console.log("RESULLLO", res)
                     setIsLoading(false)
                     setIndeterminate(false)
                     setAddItem(false)
                  },
                  () => {},
               ),
            )
         }, 3000)
      }
   }

   /**
    * Table handlong
    * ----------------
    */
   const columns = ["Thumbnail", "Item Code", "Item Name", "PR Line", "Qty", "Minimum Capable QTY", "UOM"]

   const [order, setOrder] = useState("asc")
   const [orderBy, setOrderBy] = useState("")
   const [indeterminate, setIndeterminate] = useState(false)
   const [selectedItemIds, setSelectedItemIds] = useState([])

   const handleRequestSort = (column) => {
      const _order = order === "asc" ? "desc" : "asc"
      setOrderBy(column)
      setOrder(_order)

      const forColumn =
         column === "Item Code"
            ? "itemCode"
            : column === "Item Name"
            ? "itemName"
            : column === "PR Line"
            ? "prLine"
            : column === "Qty"
            ? "quantity"
            : column === "Minimum Capable QTY"
            ? "minCapableQty"
            : column === "UOM"
            ? "uom"
            : ""

      let result = []
      result = _orderBy(dataProcurementItems.filteredData, [forColumn], [_order])

      dispatch(sortProcuremenItems(result))
   }

   const handleCheckAll = (e) => {
      const isChecked = e.target.checked
      if (isChecked) {
         const itemSelectedIds = dataProcurementItems.filteredData.map((vendor) => {
            return vendor._id
         })
         setSelectedItemIds(itemSelectedIds)
      } else {
         setSelectedItemIds([])
      }
   }

   const handleSelectItem = (e) => {
      const isChecked = e.target.checked
      if (isChecked) {
         const isSelected = selectedItemIds.includes(e.target.value)
         if (!isSelected) {
            const newSelectedItemIds = [...selectedItemIds, e.target.value]
            setSelectedItemIds(newSelectedItemIds)
         }
      } else {
         const newSelectedItemIds = selectedItemIds.filter((id) => id != e.target.value)
         setSelectedItemIds(newSelectedItemIds)
      }
   }

   const handleSearchProcurementItems = ({ target }) => {
      const { value } = target
      dispatch(
         fetchProcurementItems({ procurementId, limit: 999999, query: value }, () => {
            setSelectedItemIds([])
         }),
      )
   }

   const findIndeterminate = () => {
      const itemSelected = selectedItemIds.length
      const totalItemInView = dataProcurementItems.filteredData.length
      if (itemSelected != 0 && itemSelected < totalItemInView) {
         setIndeterminate(true)
      } else {
         setIndeterminate(false)
      }
   }

   // Always run when select / unselect an item
   useEffect(() => {
      findIndeterminate()
   }, [selectedItemIds])

   return (
      <>
         <AnnouncementInfo withBody={false} text="Select item you want to add" />
         <MuiDataTable.CustomTable
            stickyHeader={true}
            containerStyles={{
               maxHeight: "240px",
               "&::-webkit-scrollbar": {
                  height: "8px",
                  width: "0",
               },
               "&::-webkit-scrollbar-thumb": {
                  background: "#C4C4C4",
               },
               scrollbarWidth: "thin",
               scrollbarColor: "#C4C4C4",
            }}
            showHeader={true}
            headerComponent={
               <MuiDataTable.Filter
                  withSearchBar={true}
                  onKeyUp={(v) => v.keyCode === 13 && handleSearchProcurementItems(v)}
               />
            }
            // footerComponent={
            //    <TablePagination
            //       sx={{ background: "#ffffff", borderBottom: "none" }}
            //       page={dataState.page}
            //       count={dataProcurementItems.total}
            //       rowsPerPage={dataState.limit}
            //       rowsPerPageOptions={[1, 2, 5, 10, 15, 20, { label: "All", value: -1 }]}
            //       onPageChange={handlePageChange}
            //       onRowsPerPageChange={handleRowsPerPageChange}
            //       className="d-flex justify-content-end"
            //    />
            // }
         >
            <TableHead>
               <TableRow>
                  <TableCell sx={{ background: "#D0D6E0" }}>
                     <MuiCheckbox
                        size="small"
                        className="px-0 py-2"
                        indeterminate={indeterminate}
                        checked={selectedItemIds.length == dataProcurementItems.filteredData.length}
                        onChange={handleCheckAll}
                     />
                  </TableCell>
                  {columns.map((column, index) => (
                     <TableCell
                        className="py-2"
                        key={index}
                        align={index === 4 || index === 5 ? "right" : ""}
                        sx={{ whiteSpace: "nowrap", background: "#D0D6E0" }}
                     >
                        <TableSortLabel
                           disabled={index == 0}
                           active={orderBy === column}
                           direction={order}
                           onClick={() => handleRequestSort(column)}
                           IconComponent={ExpandLessIcon}
                        >
                           {column}
                        </TableSortLabel>
                     </TableCell>
                  ))}
               </TableRow>
            </TableHead>
            <TableBody>
               {dataProcurementItems.filteredData.length > 0 ? (
                  dataProcurementItems.filteredData.map((item, index) => (
                     <TableRow
                        key={index}
                        sx={{
                           background: "#F3F5F4",
                           "&:hover": {
                              background: "transparent",
                              cursor: "pointer",
                           },
                        }}
                        // onClick={() => modalVendorsPerItemRef.current.show(item._id)}
                     >
                        <TableCell size="small">
                           {dataProcurementVendorItems?.find((_item) => _item.procurementItemId === item._id) ? (
                              <MuiCheckbox disabled size="small" checked className="px-0 py-2" />
                           ) : (
                              <MuiCheckbox
                                 size="small"
                                 value={item._id}
                                 checked={selectedItemIds.includes(item._id)}
                                 className="px-0 py-2"
                                 onChange={handleSelectItem}
                              />
                           )}
                        </TableCell>

                        <TableCell
                           className="py-2"
                           size="small"
                           sx={{
                              whiteSpace: "nowrap",
                           }}
                        >
                           <ThumbnailWrapper>
                              <Thumbnail
                                 src={item.images.length > 0 ? item.images[0].url : ThumbnailPlaceholder}
                                 alt=""
                                 noImg={item.images.length === 0 && true}
                              />
                           </ThumbnailWrapper>
                        </TableCell>

                        <TableCell className="py-2">{item.itemCode}</TableCell>
                        <TableCell className="py-2">{item.itemName}</TableCell>
                        <TableCell className="py-2">{item.prLine}</TableCell>
                        <TableCell className="py-2" align="right">
                           {item.quantity}
                        </TableCell>
                        <TableCell className="py-2" align="right">
                           {item.minCapableQty}
                        </TableCell>
                        <TableCell className="py-2">{item.uom}</TableCell>
                     </TableRow>
                  ))
               ) : (
                  <TableRow sx={{ background: "#F3F5F4" }}>
                     <TableCell colSpan={columns.length + 1} align="center" sx={{ fontWeight: 500 }}>
                        No records to display
                     </TableCell>
                  </TableRow>
               )}
            </TableBody>
         </MuiDataTable.CustomTable>

         <div className="mx-0 my-4 d-flex justify-content-end">
            <div className="mr-3">
               <Buttonaction
                  type="button"
                  borderblueOcean
                  label="Cancel"
                  large
                  onClick={() => {
                     setAddItem(false), addItemVendorRef.current.hide()
                  }}
               />
            </div>
            <Buttonaction type="button" blueOcean label="Add" onClick={handleAddVendorItems} large />
         </div>
      </>
   )
}

const mapStateToProps = (state) => ({
   dataProcurementItems: state.procurementReducer.dataProcurementItems,
   dataProcurementVendorItems: state.procurementReducer.dataProcurementVendorItems,
})

export default connect(mapStateToProps)(ProcurementItems)

const ThumbnailWrapper = styled.div`
   background: #ffffff;
   border: 1px solid #ececec;
   border-radius: 3px;
   height: 30px;
   width: 30px;
   display: grid;
   place-items: center;
`

const Thumbnail = styled.img`
   border-radius: 3px;
   height: ${(props) => (props.noImg ? "80%" : "100%")};
   width: ${(props) => (props.noImg ? "80%" : "100%")};
   object-fit: cover;
`
