import Apiservice from "../services/Apiservice";

const API_URL = Apiservice.url.AUCTION_URL + "/api/secured/procurement-item";

function ProcurementItemService(){
    this.findByProcurementId = function(id, callback){
        return async () => {
            const resp = await Apiservice.invokeGet(API_URL+ "/" + id + "/find-by-procurement");
            if (resp.status == 200) {
                if (callback) {
                    callback(resp.data.data);
                }
            }
        }
    }
}

export default ProcurementItemService