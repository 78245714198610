import React from 'react'
import { Form } from "react-bootstrap"

export default function ErrorField({ errors, fieldName }) {
    
    return (
        <>
            {errors?.filter(v => v.param === fieldName).map(({ msg }, index) => {
                return <Form.Text className="text-danger" key={index}>
                    {msg}
                </Form.Text>
            })}
        </>
    )
}