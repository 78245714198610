import Apiservice from "../services/Apiservice";

const API_URL = Apiservice.url.AUCTION_URL + "/api/secured/company";

function CompanyService(){
    this.findById = function(id, callback){
        return async () => {
            const resp = await Apiservice.invokeGet(API_URL+"/"+id);
            if (resp.status == 200) {
                if (callback) {
                    callback(resp.data.data);
                }
            }
        }
    }
    this.findByIds = function(ids, callback){
        return async () => {
            if(ids.length > 0){
                let query = ""
                for(let i=0;i<ids.length;i++){
                    query += "&id=" + ids[i]
                }
    
                const resp = await Apiservice.invokeGet(API_URL+"/find-by-ids?1=1"+query);
                if (resp.status == 200) {
                    if (callback) {
                        callback(resp.data.data);
                    }
                }
            }
        }
    }
}

export default CompanyService