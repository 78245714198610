const initialState = {
    dataProfile: undefined,
    dataSocketId: undefined
}

const sessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                dataProfile: action.payload
            }
        case 'SAVE_SOCKET_SESSION':
            return {
                ...state,
                dataSocketId: action.payload
            }
        default:
            return state
    }
}

export default sessionReducer