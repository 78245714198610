import { dispatch } from "react-hot-toast"
import Apiservice from "../../services/Apiservice"

export function getUserSession(email, callback) {
    return async (dispatch) => {
        const resp = await Apiservice.invokeGet(Apiservice.url.AUCTION_URL + "/api/secured/account/sessions?email=" + encodeURIComponent(email));
        if(resp.status === 200){
            const {data} = resp.data
            if(callback){
                callback(data)
            }
        }
    }
}

export function getMyProfile(callback, errorCallback) {
    return async (dispatch) => {
        try {
            const resp = await Apiservice.invokeGet(Apiservice.url.AUCTION_URL + "/api/secured/account/my-profile")
            if (resp.status == 200) {
                const { data } = resp.data
                // transform company phone
                // if the value is not null
                const v = data.company
                if (v) {
                    let companyPhone = v.companyPhone
                    if (v.companyPhone && v.companyPhone.length > 0 && v.companyPhone.startsWith("62")) {
                        companyPhone =  v.companyPhone.substr(2, v.companyPhone.length)
                    }

                    data.company = {
                        ...v,                    
                        companyPhone
                    }
                }

                await dispatch({
                    type: "GET_MY_PROFILE",
                    payload: data
                })

                if (callback)
                    callback(data)
            } else {
                if (errorCallback)
                    errorCallback()
            }
        } catch (e) {
            if(errorCallback) {
                console.log(e)
                errorCallback(e)
            }
        }
    }
}