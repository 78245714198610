import React, { useEffect, useRef, useState } from "react"
import { Col, Row } from "react-bootstrap"
import toast from "react-hot-toast"
import { useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import Constant from "../../../../../constant"
import FolderAlertIcon from "../../../../assets/images/FolderAlert.svg"
import CancelProcessIcon from "../../../../assets/images/IconCloseProcess.svg"
import IconLeavePage from "../../../../assets/images/IconLeavePage.svg"
import warningIcon from "../../../../assets/images/WarningIcon.svg"
import Buttonaction from "../../../../components/Buttonaction"
import Dateinfo from "../../../../components/Dateinfo"
import SubmitModal from "../../../../components/Submitmodal"
import Texteditor from "../../../../components/Texteditor"
import { getMyProfile } from "../../../../redux/action/accountAction"
import AccountService from "../../../../services/AccountService"
import NegotiationService from "../../../../services/NegotiationService"
import Chat from "../../../Chat"
import NegotiationOfferAsListModule from "../../../negotiation-offer/negotiation-offer-as-list"
import SpectatorModule from "../../../spectator/spectator.module"
import ChatReadOnly from "../ChatReadOnly"
import NegotiationInformation from "../NegotiationInformation"
import OfferNegotiation from "../offer-negotiation.module"

const BuyerNegotiationOnline = () => {
   const history = useHistory()
   const { negotiationId, negotiationBatchId } = useParams()
   const modalStatusRef = useRef()
   const modalStartNegoOnlineRef = useRef()
   const modalCancelNegoOnlineRef = useRef()
   const modalEndSessionNegoOnlineRef = useRef()
   const dispatch = useDispatch()
   const negotiationService = new NegotiationService()
   const accountService = new AccountService()

   const [permitMessage, setPermitMessage] = useState(false)
   const [accountId, setAccountId] = useState(null)
   const [accountName, setAccountName] = useState(null)
   const [toAccountId, setToAccountId] = useState(null)
   const [statuses, setStatuses] = useState([])
   const [negotiation, setNegotiation] = useState({})
   const [account, setAccount] = useState({})
   const [changeToStatus, setChangeToStatus] = useState("")
   const [offers, setOffers] = useState([])
   const [isStarting, setIsStarting] = useState(false)

   const vendorLink = location.href.replace("/buyer/", "/vendor/")

   const offerNegotiationRef = useRef()

   useEffect(() => {
      getNegotiationDetail()
   }, [
      negotiation?.status,
   ]) /** negostatus' added here so that checkPermitMessage() read its changes when nego is canceled */

   const getNegotiationDetail = () => {
      dispatch(
         negotiationService.findById(negotiationId, (res) => {
            setToAccountId(res.toAccountId)
            setNegotiation(res)
            getStatuses(res)
            dispatch(
               accountService.findById(res.fromAccountId, (resAccountFrom) => {
                  setAccountName(resAccountFrom.email)
                  setAccountId(resAccountFrom._id)
                  checkPermitMessage(resAccountFrom._id)
               }),
            )
         }),
      )
   }

   const getStatuses = (negotiation) => {
      dispatch(
         negotiationService.findStatus(negotiation, negotiation.status, (res) => {
            setStatuses(res)
         }),
      )
   }

   const checkPermitMessage = (userId) => {
      dispatch(
         getMyProfile((profile) => {
            setAccount(profile.account)
            if (profile.account._id === userId) {
               setPermitMessage(true)
            }
         }),
      )
   }

   const handleChangeStatus = () => {
      dispatch(
         negotiationService.changeStatus(negotiation, changeToStatus, (res) => {
            const socketRefNegotiation = offerNegotiationRef.current.getSocketRef()

            if (res.status === Constant.STATUS.ON_GOING) {
               setIsStarting(true)
               const isVendorAlreadyOffer = offers.find((offer) => {
                  if (offer.from.role === Constant.ROLE.SELLER_AUCTION) {
                     return offer
                  }
               })
               if (!isVendorAlreadyOffer) {
                  new Promise((resolve, reject) => {
                     dispatch(
                        negotiationService.findStatus(
                           res,
                           res.status,
                           (statuses) => {
                              resolve(statuses)
                           },
                           (err) => reject(err),
                        ),
                     )
                  })
                     .then((statuses) => {
                        const newStatus = statuses.filter((status) => status.label.toLowerCase() !== "end session")
                        console.log("NEW STATUS", newStatus)
                        setStatuses(newStatus)
                        modalStartNegoOnlineRef.current.hide()
                     })
                     .finally(() => {
                        setIsStarting(false)
                     })
               }

               socketRefNegotiation.current.emit(Constant.socketEvents.BEGIN_ONLINE_NEGOTIATION, {
                  roomId: Constant.ROOM_SOCKET.NEGOTIATION + "-" + negotiationId,
                  negotiation: res,
               })
            } else if (res.status === Constant.STATUS.CANCELED) {
               modalCancelNegoOnlineRef.current.hide()
               socketRefNegotiation.current.emit(Constant.socketEvents.CANCEL_ONLINE_NEGOTIATION, {
                  roomId: Constant.ROOM_SOCKET.NEGOTIATION + "-" + negotiationId,
                  negotiation: res,
               })
               getStatuses(res)
            } else if (res.status === Constant.STATUS.DONE) {
               toast.success("Negotiation online session is successfully ended", {
                  duration: 3000,
               })
               modalEndSessionNegoOnlineRef.current.hide()
               socketRefNegotiation.current.emit(Constant.socketEvents.END_ONLINE_NEGOTIATION, {
                  roomId: Constant.ROOM_SOCKET.NEGOTIATION + "-" + negotiationId,
                  negotiation: res,
               })
               getStatuses(res)
            }
            setNegotiation(res)
         }),
      )
   }

   useEffect(() => {
      if (statuses.length > 0) {
         const isVendorAlreadyOffer = offers.find((offer) => {
            if (offer.from.role === Constant.ROLE.SELLER_AUCTION) {
               return offer
            }
         })

         console.log("IN", { offers, isVendorAlreadyOffer })

         if (!isVendorAlreadyOffer) {
            const newStatus = statuses.filter((status) => status.label.toLowerCase() !== "end session")
            setStatuses(newStatus)
         } else {
            dispatch(
               negotiationService.findById(negotiationId, (negotiation) => {
                  dispatch(
                     negotiationService.findStatus(negotiation, negotiation.status, (res) => {
                        setStatuses(res)
                     }),
                  )
               }),
            )
         }
      }
   }, [offers])

   return (
      <div className="ml-3 mr-4" style={{ marginTop: "10px" }}>
         {negotiation.status === Constant.STATUS.CANCELED && (
            <Row className="mb-3">
               <Col>
                  <div
                     className="d-flex align-items-center px-3"
                     style={{
                        backgroundColor: "#fde9ef",
                        color: "#454545",
                        fontSize: "12px",
                        fontWeight: "400",
                        borderRadius: "5px",
                        height: "40px",
                     }}
                  >
                     <img src={warningIcon} className="mr-2" />
                     <p>
                        This negotiation has been canceled at{" "}
                        <strong>
                           {" "}
                           <Dateinfo
                              date={negotiation.updatedAt}
                              format="dd mmmm yyyy HH:MM"
                              style={{ all: "unset" }}
                           />
                        </strong>{" "}
                        by <strong> {negotiation.canceledBy}</strong>.
                        The reason is &ldquo;
                        <strong
                           style={{ display: "inline-block", lineHeight: "0px" }}
                           dangerouslySetInnerHTML={{
                              __html: negotiation.cancelReason,
                           }}
                        />&rdquo;.
                     </p>
                  </div>
               </Col>
            </Row>
         )}
         <Row className="mx-0 px-0">
            <Col className="px-0" md="8">
               <NegotiationInformation
                  negotiationBatchId={negotiationBatchId}
                  negotiationId={negotiationId}
               ></NegotiationInformation>
            </Col>
            <Col className="ml-4 px-0">
               {accountId !== null && toAccountId !== null && (
                  <SpectatorModule
                     roomId={negotiationId}
                     foreignKey={negotiationId}
                     userId={accountId}
                     permitAdd={![Constant.STATUS.DONE, Constant.STATUS.CANCELED].includes(negotiation?.status)}
                     fromAccountId={accountId}
                     toAccountId={toAccountId}
                     module={Constant.MODULES.NEGOTIATION}
                     link={vendorLink}
                  ></SpectatorModule>
               )}
            </Col>
         </Row>
         <Row className="mt-4">
            <Col md="5" className="px-0">
               {accountName !== null &&
               accountId !== null &&
               (negotiation?.status === Constant.STATUS.ON_GOING ||
                  negotiation?.status === Constant.STATUS.DONE ||
                  (negotiation?.status === Constant.STATUS.CANCELED && negotiation?.startDate)) ? (
                  <Chat
                     account={account}
                     permitSendMessage={permitMessage}
                     roomId={negotiationId}
                     negotiation={negotiation}
                     username={accountName}
                     userId={accountId}
                  ></Chat>
               ) : (
                  <ChatReadOnly negotiation={negotiation} />
               )}
            </Col>
            <Col md="7">
               {accountName !== null && accountId !== null && toAccountId !== null && (
                  <OfferNegotiation
                     account={account}
                     passedInRef={offerNegotiationRef}
                     username={accountName}
                     accountId={accountId}
                     negotiationId={negotiationId}
                     fromAccountId={accountId}
                     toAccountId={toAccountId}
                     negotiation={negotiation}
                     setNegotiation={setNegotiation}
                  ></OfferNegotiation>
               )}
            </Col>
         </Row>
         <Row className="mt-4 mx-0">
            <Col md="12" className="px-0">
               {accountName !== null && accountId !== null && (
                  <NegotiationOfferAsListModule
                     roomId={negotiationId}
                     negotiationId={negotiationId}
                     username={accountName}
                     _setOffers={setOffers}
                  ></NegotiationOfferAsListModule>
               )}
            </Col>
         </Row>
         {negotiation && (
            <Row className="mt-5 mb-4 d-flex justify-content-center">
               <div className="order-2">
                  <Buttonaction
                     borderblueOcean
                     label="Back"
                     medium
                     style={{ marginLeft: "20px", fontSize: "14px", fontWeight: "700", padding: "12px" }}
                     onClick={() => history.goBack()}
                  />
               </div>
               {statuses?.map((status, index) => {
                  console.log("LOOP", status)
                  const to = status.to.description
                  return (
                     <div
                        className={`${
                           to === Constant.STATUS.ON_GOING || to === Constant.STATUS.DONE
                              ? "order-3"
                              : to === Constant.STATUS.CANCELED && "order-1"
                        }`}
                        key={index}
                     >
                        <Buttonaction
                           blueOcean={to === Constant.STATUS.ON_GOING || to === Constant.STATUS.DONE}
                           red={to === Constant.STATUS.CANCELED}
                           label={status.label}
                           medium
                           style={{ marginLeft: "20px", fontSize: "14px", fontWeight: "700", padding: "12px" }}
                           onClick={() => {
                              setChangeToStatus(to)
                              modalStatusRef.current = status
                              switch (to) {
                                 case Constant.STATUS.ON_GOING:
                                    modalStartNegoOnlineRef.current.show({
                                       title: "Start Negotiation Online Session",
                                       modalTitle: "",
                                       description: <p>Are you sure to start the negotiation online session?</p>,
                                    })
                                    break
                                 case Constant.STATUS.CANCELED:
                                    modalCancelNegoOnlineRef.current.show({
                                       modalTitle: "",
                                       title: "Cancel Negotiation Online Session",
                                       description: (
                                          <>
                                             <p>
                                                Once you canceled the session, the data will be reverted back to
                                                previous one automatically.
                                             </p>
                                             <p>Are you sure to cancel the negotiation online session?</p>
                                          </>
                                       ),
                                    })
                                    break
                                 case Constant.STATUS.DONE:
                                    modalEndSessionNegoOnlineRef.current.show({
                                       modalTitle: "",
                                       title: "End Negotiation Online Session",
                                       description: <p>Are you sure to end the negotiation online session?</p>,
                                    })
                                    break
                                 default:
                                    break
                              }
                           }}
                        />
                     </div>
                  )
               })}
            </Row>
         )}

         <SubmitModal.ModalContainer passedInRef={modalStartNegoOnlineRef} icon={FolderAlertIcon}>
            <SubmitModal.ModalButtonActions>
               <div className="mt-4 d-flex justify-content-end">
                  <div className="mr-3">
                     <Buttonaction
                        borderblueOcean
                        type="button"
                        label="Cancel"
                        large
                        onClick={() => modalStartNegoOnlineRef.current.hide()}
                     />
                  </div>
                  <Buttonaction
                     blueOcean
                     type="button"
                     label="Start Session"
                     large
                     loading={isStarting}
                     onClick={() => handleChangeStatus(modalStatusRef.current)}
                  />
               </div>
            </SubmitModal.ModalButtonActions>
         </SubmitModal.ModalContainer>

         <SubmitModal.ModalContainer
            passedInRef={modalEndSessionNegoOnlineRef}
            closeButton={false}
            icon={IconLeavePage}
         >
            <SubmitModal.ModalButtonActions>
               <Row className="pt-4 mr-0 ml-5 px-0">
                  <Col lg={8} className="d-flex align-items-center justify-content-end pr-0">
                     <Buttonaction
                        borderblueOcean
                        type="button"
                        label="Cancel"
                        large
                        onClick={() => modalEndSessionNegoOnlineRef.current.hide()}
                     />
                  </Col>
                  <Col className="d-flex align-items-center justify-content-end pr-0">
                     <Buttonaction
                        style={{ whiteSpace: "nowrap" }}
                        blueOcean
                        type="button"
                        label="End Sesssion"
                        large
                        onClick={handleChangeStatus}
                     />
                  </Col>
               </Row>
            </SubmitModal.ModalButtonActions>
         </SubmitModal.ModalContainer>

         <SubmitModal.ModalContainer passedInRef={modalCancelNegoOnlineRef} icon={CancelProcessIcon}>
            <div className="mt-4">
               <Texteditor onValueChange={(v) => setNegotiation((prev) => ({ ...prev, cancelReason: v }))} />
            </div>
            <SubmitModal.ModalButtonActions>
               <div className="mt-4 d-flex justify-content-end">
                  {/* <Row className="pt-4 mx-0 px-0">
                  <Col lg={10} className="d-flex align-items-center justify-content-end pr-0"> */}
                  <div className="mr-3">
                     <Buttonaction
                        borderblueOcean
                        type="button"
                        label="No"
                        large
                        onClick={() => modalCancelNegoOnlineRef.current.hide()}
                     />
                  </div>
                  {/* </Col> */}
                  {/* <Col className="d-flex align-items-center justify-content-end pr-0"> */}
                  <Buttonaction blueOcean type="button" label="Yes" large onClick={handleChangeStatus} />
                  {/* </Col> */}
                  {/* </Row> */}
               </div>
            </SubmitModal.ModalButtonActions>
         </SubmitModal.ModalContainer>
      </div>
   )
}

export default BuyerNegotiationOnline
