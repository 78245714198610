import React, { useEffect, useState } from "react";
import { Card, Col, Row, Container } from "react-bootstrap";
import CartTips from "../assets/images/CardTips.svg";
import Buttonaction from "../components/Buttonaction";

export default function Tipscard({ content, page }) {
  const [show, setShow] = useState(true);
  const [dontShowAgain, setDontShowAgain] = useState(false);

  useEffect(() => {
    if (dontShowAgain) {
      setShow(false);
    } else {
      setShow(true);
      localStorage.setItem("showTips", true);
    }
  }, [dontShowAgain]);

  return (
    <>
      {show && (
        <Container
          style={{
            backgroundColor: "#F1F1F1",
            borderRadius: "10px",
            borderColor: "none",
            position: "sticky",
            top: page == "createAuction" ? 260 : 440,
            // right: 35,
          }}
        >
          <Row>
            <Col
              lg={7}
              style={{
                marginTop: "20px",
                paddingLeft: "38%",
                fontSize: "18px",
                fontWeight: "900",
              }}
            >
              Tips
            </Col>
            <Col lg={1}>
              <p>
                <img
                  src={CartTips}
                  alt=""
                  style={{ height: "45px", marginTop: "10px" }}
                />
              </p>
            </Col>
          </Row>
          <Row style={{ paddingLeft: "14px", paddingRight: "13px" }}>
            <p className="my-3" style={{ fontSize: "12px", fontWeight: "500" }}>
              {content}
            </p>
          </Row>
          <Row className="d-flex justify-content-center">
            <Buttonaction
              blueOcean
              label="Ok"
              xsmall
              onClick={() => setShow(false)}
              // onClick={() => history.push('/form')}
              style={{ width: "60%" }}
            />
          </Row>
          <Row
            className="d-flex justify-content-center"
            style={{ padding: "22px 15% 21px" }}
          >
            <p
              className="mb-3 text-center"
              style={{
                cursor: "pointer",
                fontSize: "10px",
                color: "#0772B6",
                fontWeight: "500",
              }}
              onClick={() => {
                setDontShowAgain(true), localStorage.setItem("showTips", false);
              }}
            >
              Don’t Show This Anymore
            </p>
          </Row>
        </Container>
      )}
    </>
  );
}
