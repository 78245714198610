import Apiservice from "../../services/Apiservice";

export function requestTrialAccount(payload, callback, errorCallback) {
  return async (dispatch) => {
    const resp = await Apiservice.invokePost(
      Apiservice.url.AUCTION_URL + "/api/landing-page",
      payload
    );
    if (resp.status == 200) {
      callback(resp);
    } else {
      errorCallback(resp.data.errors);
    }
  };
}
