import { dispatch } from "react-hot-toast"
import Apiservice from "../../services/Apiservice"
import { setAppLoading } from "./appAction"

export function createOrUpdateLog(logs, callback, errCallback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokePost(
         `${Apiservice.url.AUCTION_URL}/api/secured/negotiation-log/create-or-update`,
         { logs },
      )

      if (resp.status === 200) {
         if (callback) {
            callback(resp.data.data)
         }
      } else {
        if(errCallback) {
            errCallback()
        }
      }
   }
}

export function deleteLog(logIds, callback, errCallback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokeDel(
         `${Apiservice.url.AUCTION_URL}/api/secured/negotiation-log/delete`,
         { logIds },
      )

      if (resp.status === 200) {
         if (callback) {
            callback(resp.data.data)
         }
      } else {
        if(errCallback) {
            errCallback()
        }
      }
   }
}

export function getLogPerNegotiation(negotiationId, callback, errCallback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokeGet(
         `${Apiservice.url.AUCTION_URL}/api/secured/negotiation-log/bni-log-per-negotiation/${negotiationId}`,
      )

      if (resp.status === 200) {
         if (callback) {
            callback(resp.data)
         }
      } else {
         if (errCallback) {
            errCallback(resp)
         }
      }
   }
}
