import { isAfter, isBefore } from "date-fns"
import moment from "moment"

const disabledDate = (date, startDate, endDate = null) => {
   const _startDate = moment.parseZone(startDate).utc(true).format()
   const _endDate = moment.parseZone(endDate).utc(true).format()
   const currentDate = moment.parseZone(new Date(Date.now())).utc(true).format()

   const picker = new Date(date)
   picker.setHours(0)
   picker.setMinutes(0)
   picker.setSeconds(0)

   console.log({
      date: { raw: new Date(picker), format: new Date(date).getTime() },
      currentDate: { raw: currentDate, format: new Date(Date.now()).getTime() },
      _startDate: { raw: _startDate, format: new Date(_startDate).getTime() },
      _endDate: { raw: _endDate, format: new Date(_endDate, 0, 0, 0).getTime() },
   })

   return (
      isBefore(new Date(date).getTime(), new Date(_startDate).getTime()) ||
      isAfter(new Date(picker).getTime(), new Date(_endDate).getTime()) ||
      isBefore(new Date(picker.setHours(24)).getTime(), new Date(Date.now()).getTime())
      // isBefore(date, currentDate.setDate(currentDate.getDate() - 1)) ||
      // isAfter(date, _endDate.setDate(_endDate.getDate() + 1))
   )
}

const DatepickerUtil = { disabledDate }

export default DatepickerUtil
