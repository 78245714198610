import React, { useEffect, useState } from "react"
import { Route } from "react-router"
import { Redirect, useLocation, useHistory } from "react-router-dom"
import { Placeholder } from "rsuite"

import { useDispatch } from "react-redux"
import { getMyProfile } from "../redux/action/accountAction"
import Constant from "../../constant"

import jwt from "jwt-decode"
import { logoutUserKeycloak, refreshToken } from "../redux/action/authAction"

export default function PrivateRoute({ component: Component, path, ...rest }) {
   const dispatch = useDispatch()
   const history = useHistory()
   const [dataProfile, setDataProfile] = useState({ account: {}, company: {} })

   const query = new URLSearchParams(useLocation().search)
   const accessTokenUrl = query.get("access_token")
   const refreshTokenUrl = query.get("refresh_token")

   useEffect(() => {
      if (Object.entries(dataProfile.account).length > 0) {
         window.addEventListener("storage", function (e) {
            if (e.key === "access_token") {
               const accessToken = window.localStorage.getItem("access_token")
               if (accessToken) {
                  const decryptToken = jwt(e.newValue)
                  const decryptOldToken = jwt(e.oldValue)

                  // condition for handling 2 different account login in the same browser
                  if (accessToken && decryptOldToken && decryptToken.email !== decryptOldToken.email) {
                     // do logout old user in keycloak
                     dispatch(logoutUserKeycloak(decryptOldToken.email))
                  }
               }
            }
         })
      }
   }, [dataProfile])

   const isLogin = () => {
      const accessToken = localStorage.getItem("access_token") || (accessTokenUrl && refreshTokenUrl)
      return accessToken
   }

   useEffect(() => {
      console.log({ accessTokenUrl, refreshTokenUrl })
      if (accessTokenUrl && refreshTokenUrl) {
         localStorage.setItem("access_token", accessTokenUrl)
         localStorage.setItem("refresh_token", refreshTokenUrl)
   
         // remove query search from url
         const href = window.location.pathname
         history.push(href)
         window.location.reload()
      }

      dispatch(
         getMyProfile((dataProfile) => {
            setDataProfile(dataProfile)
         }),
      )
   }, [])

   const hasLoginAndAccesLanding = isLogin() && path == "/"
   const hasNotLoginAndAccessLanding = !isLogin() && path == "/"

   const RenderComponent = ({ props }) => {
      if (dataProfile.account.role == Constant.ROLE.BUYER_AUCTION) {
         // only run this for role buyer

         if (dataProfile.company && dataProfile.company.isOnboarding && !location.pathname.startsWith("/buyer-login")) {
            // if user has onboarding data, then allow to visit target page
            return <Component {...props} />
         } else {
            if (location.pathname.startsWith("/onboarding")) {
               // if user is access onboarding
               return <Component {...props} />
            }

            // is user access another page with private route
            return <RedirectToOnboarding to="/onboarding" />
         }
      } else {
         return <Component {...props} />
      }
   }

   const RedirectToLogin = ({ props }) => {
      if (location.pathname.startsWith("/buyer-login")) {
         return <Component {...props} />
      } else {
         location.href = "/buyer-login?err=1"
      }
   }

   return (
      // Show the component only when the user is logged in
      // Otherwise, redirect the user to /signin page
      <Route
         {...rest}
         render={(props) =>
            hasLoginAndAccesLanding ? (
               <RedirectToDashboard to="/dashboard" />
            ) : hasNotLoginAndAccessLanding ? (
               <Component {...props} />
            ) : isLogin() ? (
               <RenderComponent props={props} />
            ) : (
               <RedirectToLogin props={props} />
               // <Redirect
               //   to={{
               //     pathname: '/oauth2/authorization/oidc',
               //     search: props.location.search,
               //     state: {from: props.location}
               //   }}
               // />
            )
         }
      />
   )
}

const RedirectToLanding = () => {
   useEffect(() => {
      location.href = "/"
   }, [])

   return (
      <>
         <Placeholder />
      </>
   )
}

const RedirectToDashboard = () => {
   useEffect(() => {
      location.href = "/dashboard"
   }, [])

   return (
      <>
         <Placeholder />
      </>
   )
}

const RedirectToOnboarding = () => {
   useEffect(() => {
      location.href = "/onboarding"
   }, [])

   return (
      <>
         <Placeholder />
      </>
   )
}
