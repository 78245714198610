import * as React from "react";
import { connect, useDispatch } from "react-redux";
import { Col, Row, Card, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Modal from "react-modal";
import styled from "styled-components";
import Typewriter from "typewriter-effect";

// Redux
import { fetchMasterData } from "../../../redux/action/masterDataAction";
import { requestTrialAccount } from "../../../redux/action/landingPageAction";

// MUI Library
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

// RSuite Library
import { Animation, SelectPicker } from "rsuite";

// User-defined Components
import YouTubeEmbed from "../../../components/YouTubeEmbed";
import Buttonaction from "../../../components/Buttonaction";
import SuccessModal from "../../../components/Successmodal";

// Assets
import AuctionBannerWhite from "../../../assets/images/AuctionBannerWhite.svg";
import Header from "../../../assets/images/landingpage/header.svg";
import Benefit from "../../../assets/images/landingpage/benefit.svg";
import Telescope from "../../../assets/images/landingpage/icon/telescope.svg";
import ShoppingCart from "../../../assets/images/landingpage/icon/shopping-cart.svg";
import RealTime from "../../../assets/images/landingpage/icon/real-time.svg";
import Document from "../../../assets/images/landingpage/icon/document.svg";
import Tick from "../../../assets/images/landingpage/icon/tick.svg";
import Linkedin from "../../../assets/images/landingpage/icon/linkedin.svg";
import Phone from "../../../assets/images/landingpage/icon/phone.svg";
import Instagram from "../../../assets/images/landingpage/icon/instagram.svg";
import YouTube from "../../../assets/images/landingpage/icon/youtube.svg";
import Location from "../../../assets/images/landingpage/icon/location.svg";
import Mail from "../../../assets/images/landingpage/icon/mail.svg";
import ChevronDown from "../../../assets/images/landingpage/icon/chevron-down.svg";
import WhiteWave from "../../../assets/images/landingpage/white-wave.svg";

const LandingPage = ({ dataMasters }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [formInput, setFormInput] = React.useState({
    name: "",
    email: "",
    phonePrefix: "",
    phone: "",
    companyName: "",
  });

  React.useEffect(() => {
    if (!dataMasters) dispatch(fetchMasterData());
  }, [formInput]);

  const modalFreeTrial = React.useRef();

  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const submitFreeTrial = () => {
    setLoading(true);
    new Promise((resolve, reject) => {
      dispatch(
        requestTrialAccount(
          formInput,
          (res) => {
            resolve();
          },
          () => {
            reject();
          }
        )
      );
    })
      .then(() => {
        setShow(false);
        modalFreeTrial.current.show({
          title: "Permintaan berhasil dikirim",
          description: "Kami akan segera menghubungi Anda",
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setFormInput({
          name: "",
          email: "",
          phonePrefix: "",
          phone: "",
          companyName: "",
        });
      });
  };

  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const pages = [
    { menu: "Beranda", href: "#home" },
    { menu: "Keunggulan", href: "#benefit" },
    { menu: "FAQ", href: "#faq" },
  ];

  const firstBenefit = [
    {
      image: Telescope,
      title: "Online Auction",
      subTitle:
        "Keamanan proses lelang yang terjaga sehingga tetap kredibel dan transparan",
    },
    {
      image: ShoppingCart,
      title: "Negotiation",
      subTitle:
        "Dapatkan penawaran kompetitif dari setiap vendor dan lakukan negosiasi untuk mendapatkan harga termurah",
    },
    {
      image: RealTime,
      title: "Realtime",
      subTitle:
        "Pantau proses lelang secara aktual untuk menjaga anda dari penipuan yang terjadi selama proses lelang",
    },
    {
      image: Document,
      title: "Reporting",
      subTitle:
        "Lihat dan cetak laporan riwayat proses lelang kapanpun dan dimanapun anda butuhkan",
    },
  ];

  const secondBenefit = [
    {
      text: "Monitoring secara aktual, komprehensive dan aman untuk menjaga keadilan selama proses lelang berlangsung",
    },
    { text: "Tampilan yang lebih mudah digunakan" },
    {
      text: "Beragam metode auction yang sesuai dengan kebutuhan organisasi anda seperti metode least cost system, passing grade atau merit point.",
    },
    {
      text: "Membantu anda mendapatkan penawaran terendah sehingga mencapai target best saving yang maksimal",
    },
    { text: "Lihat dan cetak riwayat auction kapanpun anda butuhkan" },
  ];

  const [faq, setFaq] = React.useState([
    {
      question: "Apa itu Ruang Auction?",
      answer:
        "Ruang Auction adalah aplikasi berbasis cloud untuk melakukan reverse auction pada proses pengadaan barang dan/atau jasa.",
      show: false,
    },
    {
      question: "Siapa yang dapat menggunakan Ruang Auction?",
      answer:
        "Seluruh perusahaan yang melakukan pengadaan pada semua kategori usaha.",
      show: false,
    },
    {
      question: "Bagaiamana pricing pada Ruang Auction?",
      answer:
        "Ruang Auction membebankan user-per-month dalam melakukan penagihan penggunaannya.",
      show: false,
    },
    {
      question: "Apakah saya dapat mengajukan demo?",
      answer: "Ya, silakan kontak sales dan marketing kami.",
      show: false,
    },
    {
      question:
        "Browser apa yang dapat digunakan untuk mengakses Ruang Auction?",
      answer:
        "Disarankan menggunakan browser Google Chrome atau Mozila Firefox dengan versi terbaru.",
      show: false,
    },
  ]);

  return (
    <>
      <AppBar
        id="beranda"
        position="static"
        style={{ backgroundColor: "#00446B", position: "fixed", zIndex: "100" }}
      >
        <Container
          maxWidth="l"
          style={{ boxShadow: "0px 0px 30px 0px #0000001A", zIndex: "99" }}
        >
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
            >
              <img
                src={AuctionBannerWhite}
                alt="Ruang Auction Logo"
                style={{
                  height: "35px",
                  position: "relative",
                }}
              />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page, index) => (
                  <MenuItem
                    key={index}
                    onClick={handleCloseNavMenu}
                    href={page.href}
                  >
                    <Typography textAlign="center">{page.menu}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
            >
              <img src={AuctionBannerWhite} width="100" />
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page, index) => (
                <HoverAppBar
                  href={page.href}
                  key={index}
                  onClick={handleCloseNavMenu}
                  sx={{
                    width: "fit-content",
                    my: 2,
                    color: "white",
                    display: "block",
                    marginRight: "40px",
                    textAlign: "center",
                    height: "100%",
                    marginLeft: index === 0 && "50px",
                  }}
                >
                  {page.menu}
                </HoverAppBar>
              ))}
            </Box>

            <div
              className="mr-5"
              style={{
                fontSize: "16px",
                fontWeight: "500",
                color: "#FFFFFF",
                cursor: "pointer",
              }}
              onClick={() => history.push("/buyer-login")}
            >
              Login
            </div>

            <Button
              variant="contained"
              style={{
                backgroundColor: "#03D4B4",
                fontSize: "14px",
                fontWeight: "700",
                height: "52px",
                margin: "14px 0",
              }}
              onClick={() => setShow(true)}
            >
              Uji Coba Gratis
            </Button>
          </Toolbar>
        </Container>
      </AppBar>
      <div
        id="home"
        className="position-relative"
        style={{
          backgroundColor: "#00446B",
          height: "788px",
          paddingTop: "80px",
        }}
      >
        <Row className="mx-0 h-100 px-4">
          <Col className="d-flex align-items-center">
            <div>
              <div>
                <p
                  style={{
                    color: "white",
                    fontWeight: "700",
                    fontSize: "40px",
                  }}
                >
                  Lakukan Proses Lelang Dengan
                </p>
              </div>
              <div
                style={{
                  fontSize: "40px",
                  fontWeight: "700",
                  color: "white",
                }}
              >
                <Typewriter
                  onInit={(typewriter) => {
                    typewriter
                      .typeString("Efisien")
                      .pauseFor(2500)
                      .deleteAll()
                      .typeString("Aktual")
                      .pauseFor(2500)
                      .deleteAll()
                      .typeString("Transparan")
                      .pauseFor(2500)
                      .deleteAll()
                      .typeString("Mudah")
                      .pauseFor(2500)
                      .start();
                  }}
                  options={{
                    loop: true,
                  }}
                />
              </div>
              <div
                className="my-4"
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: "#FFFFFF",
                }}
              >
                Dapatkan pengalaman lelang terbaik Anda dan raih best saving
                secara maksimal
              </div>
              <div>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#03D4B4",
                    fontSize: "14px",
                    fontWeight: "700",
                    height: "52px",
                  }}
                  onClick={() => setShow(true)}
                >
                  Uji Coba Gratis
                </Button>
              </div>
            </div>
          </Col>
          <Col className="d-flex align-items-center justify-content-center">
            <img
              src={Header}
              alt="Header Image"
              style={{ position: "relative", inset: "-50px -25px" }}
              width="95%"
            />
          </Col>
        </Row>
        <img
          src={WhiteWave}
          className="position-absolute"
          style={{ width: "99.6vw", left: "0", bottom: "0" }}
        />
      </div>
      <div id="benefit" style={{ textAlign: "center", padding: "35px 0" }}>
        <p
          style={{
            fontWeight: "500",
            fontSize: "32px",
            color: "#00446B",
          }}
        >
          Pengalaman Lelang Terbaik Untuk Anda
        </p>
      </div>
      <Row xs={1} md={4} className="mx-0 px-4">
        {firstBenefit.map((v, index) => (
          <Col key={index}>
            <Card className="h-100" style={{ borderRadius: "20px" }}>
              <div
                style={{ width: "auto" }}
                className="d-flex align-items-center"
              >
                <Card.Img
                  variant="top"
                  src={v.image}
                  style={{
                    backgroundSize: "cover",
                    margin: "40px 0px 30px -89px",
                  }}
                  height="100"
                />
              </div>
              <Card.Body className="d-flex flex-column justify-content-between">
                <Card.Title
                  style={{
                    left: "14px",
                    fontSize: "24px",
                    fontWeight: "500",
                    color: "#00446B",
                  }}
                >
                  {v.title}
                </Card.Title>
                <p
                  style={{
                    fontWeight: "normal",
                    fontSize: "14px",
                    margin: "-5px 0 30px 0px",
                    color: "#454545",
                  }}
                >
                  {v.subTitle}
                </p>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <Row
        style={{ marginTop: "140px", marginBottom: "140px" }}
        className="mx-0 px-4"
      >
        <img src={Benefit} alt="Benefit Image" height="400" />
        <Col>
          <p
            style={{
              color: "#00446B",
              fontWeight: "500",
              fontSize: "32px",
              marginLeft: "30px",
            }}
          >
            Mengapa harus Ruang Auction?
          </p>
          {secondBenefit.map((v, index) => (
            <Col key={index}>
              <div
                className="d-flex"
                style={{
                  columnGap: "10px",
                  marginTop: index === 0 && "30px",
                  marginBottom: "35px",
                  marginLeft: "25px",
                }}
              >
                <img src={Tick} alt="Tick Asset" height="25" />
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "normal",
                    color: "454545",
                  }}
                >
                  {v.text}
                </p>
              </div>
            </Col>
          ))}
        </Col>
      </Row>
      <Row
        className="mx-0 px-4"
        style={{ marginTom: "140px", marginBottom: "140px" }}
      >
        <Col>
          <YouTubeEmbed
            embedId="Ks4thBCYNzI"
            landingPage={true}
            height="420px"
            width="670px"
          />
        </Col>
        <Col className="d-flex align-items-center">
          <p
            style={{ fontSize: "32px", fontWeight: "500", color: "#00446B" }}
            className="mx-5"
          >
            Lelang Aman, Kredibel &amp; Transparan Hanya Di Ruang Auction
          </p>
        </Col>
      </Row>
      <Row className="mx-0 px-4" style={{ marginTop: "50px" }}>
        <Col lg={4}>
          <p
            id="faq"
            style={{ fontWeight: "500", fontSize: "32px", color: "#00446B" }}
          >
            Pertanyaan yang sering ditanyakan
          </p>
        </Col>
        <Col>
          {faq.map((data, index) => (
            <div
              key={index}
              style={{
                marginBottom: index < faq.length - 1 ? "70px" : "100px",
              }}
            >
              <div
                className="d-flex align-items-center justify-content-between"
                style={{
                  border: "2px solid #F3F5F4",
                  height: "68px",
                  paddingLeft: "18px",
                  paddingRight: "18px",
                }}
                onClick={() => {
                  const newFaq = [...faq];
                  newFaq[index].show = !newFaq[index].show;
                  setFaq(newFaq);
                }}
              >
                <p
                  style={{
                    color: "#454545",
                    fontSize: "18px",
                    fontWeight: "500"
                  }}
                >
                  {data.question}
                </p>
                <img
                  style={{
                    transition: "all .3s ease-in-out",
                    transform: data.show && "rotate(180deg)",
                  }}
                  src={ChevronDown}
                  alt="Collapse Asset"
                />
              </div>
              <Animation.Collapse in={data.show}>
                <div
                  style={{
                    border: "2px solid #F3F5F4",
                    backgroundColor: "#F3F5F4",
                    padding: "18px",
                    borderRadius: "0 0 4px 4px",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#454545",
                    }}
                  >
                    {data.answer}
                  </p>
                </div>
              </Animation.Collapse>
            </div>
          ))}
        </Col>
      </Row>
      <div
        className="py-5"
        style={{
          backgroundColor: "#00446B",
          paddingLeft: "120px",
          paddingRight: "120px",
        }}
      >
        <img src={AuctionBannerWhite} alt="Ruang Auction Logo" height="50" />
        <p
          className="mt-3"
          style={{ fontSize: "12px", fontWeight: "400", color: "white" }}
        >
          Jl. Tebet Timur Dalam Raya No.43, Tebet
        </p>
        <p
          style={{
            fontSize: "12px",
            fontWeight: "400",
            color: "white",
            marginTop: "0px",
          }}
        >
          Kota Jakarta Selatan, Jakarta 12820, Indonesia
        </p>
        <Row className="mx-0">
          <Col lg={4}></Col>
          <Col className="px-0">
            <p
              style={{
                fontSize: "14px",
                fontWeight: "700",
                color: "white",
                margin: "3px 0px 0px 5px",
              }}
            >
              Kunjungi kami di
            </p>
          </Col>
        </Row>
        <Row className="mx-0">
          <Col
            lg={4}
            style={{ margin: "15px 0 0 0", paddingLeft: "8px" }}
            className="   px-0"
          >
            <div className="mb-3 d-flex">
              <img src={Mail} alt="Mail Asset" />
              <HoverUnderline>
                <a
                  href="mailto:info@mmi-pt.com"
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "white",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                >
                  info@mmi-pt.com
                </a>
              </HoverUnderline>
            </div>
            <div className="mb-3 d-flex">
              <img src={Phone} alt="Phone Asset" />
              <HoverUnderline>
                <a
                  href="tel:0218357385"
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "white",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                >
                  (021) 8357385
                </a>
              </HoverUnderline>
            </div>
            <div className="d-flex">
              <img src={Location} alt="Location Asset" />
              <HoverUnderline>
                <a
                  href="https://maps.google.com/?cid=4705446568304153647"
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "white",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                >
                  PT. Mitra Mandiri Informatika
                </a>
              </HoverUnderline>
            </div>
          </Col>
          <Col
            style={{ margin: "15px 0 0 0", paddingLeft: "8px" }}
            className="px-0"
          >
            <div className="mb-3 d-flex">
              <img src={Instagram} alt="Mail Asset" />
              <HoverUnderline>
                <a
                  href="https://www.instagram.com/mitra.mandiriinformatika/"
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "white",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                >
                  Mitra Mandiri Informatika
                </a>
              </HoverUnderline>
            </div>
            <div className="mb-3 d-flex">
              <img src={Linkedin} alt="Phone Asset" />
              <HoverUnderline>
                <a
                  href="https://www.linkedin.com/company/pt.-mitra-mandiri-informatika/"
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "white",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                >
                  PT. Mitra Mandiri Informatika
                </a>
              </HoverUnderline>
            </div>
            <div className="d-flex">
              <img src={YouTube} alt="Location Asset" />
              <HoverUnderline>
                <a
                  href="https://www.youtube.com/channel/UCAfYA7ffiNNrvAqdcMaAaQw"
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "white",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                >
                  Mitra Mandiri Informatika
                </a>
              </HoverUnderline>
            </div>
          </Col>
        </Row>
      </div>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{
          backgroundColor: "#0772B6",
          height: "50px",
          textAlign: "center",
          fontWeight: "400",
          fontSize: "14px",
          color: "white",
        }}
      >
        Copyright 2022 &#169; Ruang Auction Powered by Promise
      </div>
      <Modal
        isOpen={show}
        style={{
          overlay: {
            zIndex: "1060",
            background: "rgba(0,0,0,.7)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "12px",
            transition: "all .3s ease",
            backgroundColor: "white",
            overflow: "auto",
            maxHeight: "80vh",
            zIndex: "1060",
            padding: "32px",
          },
        }}
      >
        <p
          style={{
            fontSize: "18px",
            fontWeight: "500",
            color: "#454545",
            textAlign: "center",
          }}
        >
          Dapatkan Uji Coba Aplikasi Ruang Auction Gratis
        </p>
        <p
          style={{
            fontWeight: "400",
            fontSize: "12px",
            color: "#909090",
            textAlign: "center",
          }}
        >
          Kami akan menghubungi anda dengan segera untuk memberikan uji coba
          aplikasi
        </p>
        <hr style={{ borderColor: "#C4C4C4" }} />

        <Form.Group>
          <Form.Label
            style={{ fontSize: "12px", fontWeight: "400", color: "#454545" }}
          >
            Nama Anda
          </Form.Label>
          <Form.Control
            value={formInput.name}
            type="text"
            size="lg"
            style={{ height: "98%" }}
            onChange={(v) =>
              setFormInput({ ...formInput, name: v.target.value })
            }
          />
        </Form.Group>
        <Form.Group>
          <Form.Label
            style={{ fontSize: "12px", fontWeight: "400", color: "#454545" }}
          >
            Email Perusahaan
          </Form.Label>
          <Form.Control
            value={formInput.email}
            type="email"
            size="lg"
            style={{ height: "98%" }}
            onChange={(v) =>
              setFormInput({ ...formInput, email: v.target.value })
            }
          />
        </Form.Group>
        <Form.Group>
          <Form.Label
            style={{ fontSize: "12px", fontWeight: "400", color: "#454545" }}
          >
            Nomor Handphone
          </Form.Label>
          <Row className="mx-0">
            <Col lg={3} className="mx-0 px-0">
              <SelectPicker
                size="lg"
                value={formInput.phonePrefix}
                data={dataMasters?.phonePrefix}
                searchable={false}
                className="selectpicker"
                onChange={(v) => {
                  setFormInput({
                    ...formInput,
                    phonePrefix: v,
                  });
                }}
              />
            </Col>
            <Col className="px-0 ml-2">
              <Form.Control
                value={formInput.phone}
                type="number"
                size="lg"
                style={{ height: "98%" }}
                placeholder="Phone number without 0"
                onChange={(v) =>
                  setFormInput({ ...formInput, phone: v.target.value })
                }
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group>
          <Form.Label
            style={{ fontSize: "12px", fontWeight: "400", color: "#454545" }}
          >
            Nama Perusahaan
          </Form.Label>
          <Form.Control
            value={formInput.companyName}
            type="text"
            size="lg"
            style={{ height: "98%" }}
            onChange={(v) =>
              setFormInput({ ...formInput, companyName: v.target.value })
            }
          />
        </Form.Group>
        <Row className="mt-4" style={{ width: "100%" }}>
          <Col className="d-flex justify-content-end" lg={6}>
            <Buttonaction
              type="button"
              borderblueOcean
              label="Batal"
              onClick={() => setShow(false)}
              large
            />
          </Col>

          <Col className="d-flex justify-content-start" lg={6}>
            <Buttonaction
              loading={loading}
              type="button"
              label="Minta Uji Gratis"
              blueOcean
              large
              onClick={submitFreeTrial}
            />
          </Col>
        </Row>
      </Modal>
      <SuccessModal passedInRef={modalFreeTrial} />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    dataMasters: state.masterDataReducer.dataMasters,
  };
};

export default connect(mapStateToProps)(LandingPage);

const HoverUnderline = styled.div`
  &:hover {
    text-decoration: underline;
  }
`;

const HoverAppBar = styled(Button)`
  position: relative;
  &:hover {
    color: #03d4b4;
    &::after {
      content: "";
      position: absolute;
      display: block;
      height: 5px;
      border-radius: 50px;
      background-color: #03d4b4;
      width: 100%;
      bottom: -22px;
      left: 0;
      z-index: 100000;
    }
  }
`;
