import Apiservice from "../services/Apiservice";

export function getChatMessages(roomId, callback){
    return async () => {
        const resp = await Apiservice.invokeGet(
            `${Apiservice.url.AUCTION_URL}/api/chat/messages/${roomId}`
        );
        if (resp.status == 200) {
            if (callback) {
                callback(resp.data.data);
            }
        }
    }
}

export function searchMessageById(id, callback){
    return async () => {
        const resp = await Apiservice.invokeGet(
            `${Apiservice.url.AUCTION_URL}/api/chat/messages/${id}/search-by-id`
        );
        if (resp.status == 200) {
            if (callback) {
                callback(resp.data.data);
            }
        }
    }
}