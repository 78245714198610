import React, { useEffect, useImperativeHandle, useRef, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import toast from "react-hot-toast"
import { connect, useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import Buttonaction from "../../../components/Buttonaction"
import ModalForm from "../../../components/Modalform"
import { updateProcurementVendorAdministrationScore } from "../../../redux/action/procurementAction"

function ScoringAdministrationForm({ isAppLoading, passedInRef }) {
   const dispatch = useDispatch()
   const [formState, setFormState] = useState({
      vendorName: "",
      procurementVendorId: "",
      procurementNumber: "",
      isPassed: false,
   })

   useEffect(() => {
      console.log(formState)
   }, [formState])

   const modalRef = useRef()
   const { procurementId } = useParams()
   const [errors, setErrors] = useState([])

   const saveScore = () => {
      dispatch(
         updateProcurementVendorAdministrationScore(
            {
               procurementId,
               procurementVendorId: formState.procurementVendorId,
               passed: formState.isPassed,
            },
            () => {
               toast.success("Vendor administration score has been updated")
               modalRef.current.hide()
               setFormState({ ...formState, isPassed: false })
            },
            (errors) => {
               setErrors(errors)
            },
         ),
      )
   }

   useImperativeHandle(passedInRef, () => ({
      show: ({ vendorName, procurementNumber, procurementVendorId }) => {
         setFormState((v) => ({
            ...v,
            vendorName: vendorName,
            procurementNumber: procurementNumber,
            procurementVendorId: procurementVendorId,
         }))

         modalRef.current.show()
      },
   }))

   return (
      <ModalForm passedInRef={modalRef} title="Administration Score">
         <Row style={{ marginTop: "10px", marginLeft: "10px", marginRight: "10px" }}>
            <Col className="px-4" lg={12}>
               <Form className="roboto-form">
                  <Form.Group as={Row} className="mb-2 align-items-center" controlId="formHorizontalEmail">
                     <Form.Label column sm={3}>
                        <p style={{ fontSize: "12px" }}>Procurement Number</p>
                     </Form.Label>
                     <Col className="p-0">
                        <span>{formState.procurementNumber}</span>
                     </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-2 align-items-center" controlId="formHorizontalEmail">
                     <Form.Label column sm={3}>
                        <p style={{ fontSize: "12px" }}>Vendor</p>
                     </Form.Label>
                     <Col className="p-0">
                        <span>{formState.vendorName}</span>
                     </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-2 align-items-center" controlId="formHorizontalEmail">
                     <Form.Label column sm={3}>
                        <p style={{ fontSize: "12px" }}>Score</p>
                     </Form.Label>
                     <Col sm={1} style={{ fontSize: "12px" }} className="px-0">
                        <Form.Check
                           checked={formState.isPassed}
                           type="radio"
                           label="Pass"
                           name="formHorizontalRadios"
                           id="formHorizontalRadios1"
                           onChange={(v) => setFormState({ ...formState, isPassed: true })}
                        />
                     </Col>
                     <Col style={{ fontSize: "12px" }}>
                        <Form.Check
                           checked={!formState.isPassed}
                           type="radio"
                           label="Not Pass"
                           name="formHorizontalRadios"
                           id="formHorizontalRadios2"
                           onChange={(v) => setFormState({ ...formState, isPassed: false })}
                        />
                     </Col>
                  </Form.Group>
               </Form>
            </Col>
         </Row>

         <div className="mt-3 mb-4 d-flex justify-content-end px-4">
            <div className="mr-3">
               <Buttonaction
                  type="button"
                  borderblueOcean
                  label="Cancel"
                  large
                  onClick={() => modalRef.current.hide()}
               />
            </div>
            <Buttonaction type="button" blueOcean label="Save" loading={isAppLoading} onClick={saveScore} large />
         </div>
      </ModalForm>
   )
}

const mapStateToProps = (state) => {
   return {
      isAppLoading: state.appReducer.isAppLoading,
   }
}

export default connect(mapStateToProps)(ScoringAdministrationForm)
