import React, { useEffect, useState } from "react";
import { Form, FormGroup } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Checkbox, CheckPicker, DateRangePicker } from "rsuite";
import styled from "styled-components";
import Buttonaction from "./Buttonaction";
import { ButtonImageLabelaction } from "./Buttonaction";
import Pagetitle from "./Pagetitle";
import Searchfield from "./Searchfield";
import TrashIcon from "../assets/images/TrashIcon.svg";

function Filter({
  statusOptions = [],
  permissionOptions = [
    { label: "Head Group", value: "HEAD_GROUP" },
    { label: "Staff Admin Group", value: "STAFF_ADMIN_GROUP" },
  ],
  positionModuleOptions = [
    { label: "Procurement", value: "Procurement" },
    { label: "Requester", value: "Requester" },
  ],
  biddingOptions = [],
  positionOptions = [],
  moduleApprovalOptions = [],
  departmentOptions = [],
  yearOptions = [],
  withStatusFilter = false,
  withBiddingFilter = false,
  withPermissonFilter = false,
  withPositionFilter = false,
  withModuleApprovalFilter = false,
  withDepartmentFilter = false,
  withDateRange = false,
  withSearchBar = false,
  withYearFilter = false,
  withPositionModuleFilter = false,
  onDateRangeValueChanged,
  onSearchButtonClicked,
  handleSearch,
  handleSearchYear,
  handleSearchStatus,
  handleSearchBidding,
  handleSearchDepartment,
  handleClearSearchDate,
  handleSearchPosition,
  handleSearchModuleApproval,
  handleSearchPositionModule,
  ...props
}) {
  const [searchValue, setSearchValue] = useState("");

  return (
    <>
      <FilterSection {...props}>
        <FilterContent>
          {withPositionModuleFilter && (
            <CheckPicker
              placeholder="Module"
              onChange={handleSearchPositionModule}
              searchable={false}
              data={positionModuleOptions}
            />
          )}
          {/* {withPermissonFilter && <CheckPicker placeholder="Permission" searchable={false} data={permissionOptions} />} */}
          {/* {withPositionFilter && <CheckPicker onChange={handleSearchPosition} placeholder="Position" searchable={false} data={positionOptions} />} */}
          {/* {withModuleApprovalFilter && <CheckPicker onChange={handleSearchModuleApproval} placeholder="Module Approval" searchable={false} data={moduleApprovalOptions} />} */}
          {/* {withDepartmentFilter && <CheckPicker onChange={handleSearchDepartment} placeholder="Department" searchable={false} data={departmentOptions} />} */}
          {/* {withYearFilter && <CheckPicker onChange={handleSearchYear} placeholder="Year" searchable={false} data={yearOptions} />} */}
          {withDateRange && (
            <DateRangePicker
              onChange={onDateRangeValueChanged}
              onClean={handleClearSearchDate}
              placeholder="Filter by Date"
            />
          )}
          {withStatusFilter && (
            <CheckPicker
              onChange={handleSearchStatus}
              placeholder="Status"
              searchable={false}
              data={statusOptions}
            />
          )}
          {withBiddingFilter && (
            <CheckPicker
              onChange={handleSearchBidding}
              placeholder="Bidding Type"
              searchable={false}
              data={biddingOptions}
            />
          )}
        </FilterContent>
        {withSearchBar && (
          <FilterContent className="mb-3">
            <Form inline onSubmit={(e) => e.preventDefault()}>
              <FormGroup>
                <Searchfield
                  placeholder="Search"
                  onChange={handleSearch}
                  onSearchButtonClicked={onSearchButtonClicked}
                />
                {/* <Buttonaction outline gray label="Search" icon={faSearch} onClick={onSearchButtonClicked} /> */}
              </FormGroup>
            </Form>
          </FilterContent>
        )}
      </FilterSection>
    </>
  );
}

function Selector({
  tableId,
  listViewClicked,
  tableViewClicked,
  removeBtnClicked,
  removeBtnDisabled = false,
}) {
  const [checkAll, setCheckAll] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);

  const handleSelectAll = () => {
    try {
      let x = document.querySelector(`#${tableId}`);
      let v =
        x.querySelectorAll(".rdt_TableHeadRow")[0].childNodes[0].childNodes[0]
          .childNodes[0].childNodes[0].childNodes[0];
      v.click();
      console.log(v);
    } catch (e) {}
    setCheckAll(!checkAll);
    setIndeterminate(false);
  };

  onchange = () => {
    setTimeout(() => {
      try {
        let v =
          document.getElementsByClassName("rdt_TableHeadRow")[0].childNodes[0]
            .childNodes[0];
        if (v.classList.contains("rs-checkbox-indeterminate") === true) {
          setCheckAll(false);
          setIndeterminate(true);
          console.log("indetrminateni");
        } else if (v.classList.contains("rs-checkbox-checked") === true) {
          setCheckAll(true);
          setIndeterminate(false);
          console.log("nononono");
        }
      } catch (e) {}
    }, 500);
  };

  return (
    <SelectorSection>
      <div
        style={{ display: "flex", alignItems: "center", paddingLeft: "5px" }}
      >
        <Checkbox
          id="check-all"
          checked={checkAll}
          indeterminate={indeterminate}
          onClick={handleSelectAll}
          name="check-all"
        />
        <span className="text-white">Select All</span>
        <ButtonImageLabelaction
          style={{ marginLeft: "5px", marginTop: "-1px" }}
          icon={TrashIcon}
          label="Remove"
          white
          borderless
          onClick={removeBtnClicked}
          disabled={removeBtnDisabled}
        />
      </div>
      <div style={{ display: "flex" }}>
        {/* <ButtonIconaction
                    style={{ marginRight: "5px", marginLeft: "5px" }}
                    icon={faTable}
                    outline
                    small
                    size='xs'
                    onClick={tableViewClicked}
                />
                <ButtonIconaction
                    style={{ marginRight: "5px", marginLeft: "5px" }}
                    icon={faList}
                    gray
                    small
                    size='xs'
                    onClick={listViewClicked}
                /> */}
      </div>
    </SelectorSection>
  );
}

function Table({
  id,
  showHeader,
  styles = {
    tablesection: {},
  },
  filterComp,
  pageTitle,
  pageTitleRComp,
  pageTitleLComp,
  selectAllComp,
  ...props
}) {
  const resetRoundingEffect = async () => {
    document.querySelectorAll(".rdt_TableRow").forEach((v, k) => {
      v.classList.remove("is-rounded-top");
      v.classList.remove("is-rounded-bottom");
    });
  };

  const handleRowHovered = async (prev, next) => {
    resetRoundingEffect();

    // if (prev) {
    try {
      document.querySelector(".row-" + prev).classList.add("is-rounded-top");
    } catch (e) {
      // console.log(e)
    }
    // }

    if (next) {
      try {
        document
          .querySelector(".row-" + next)
          .classList.add("is-rounded-bottom");
      } catch (e) {
        // console.log(e)
      }
    }
  };

  useEffect(() => {
    document.querySelectorAll(".rdt_TableRow").forEach((v, k) => {
      v.classList.add("row-" + k);
      v.setAttribute("data-index", k);

      v.onmouseenter = function (e) {
        const id = new Number(e.target.getAttribute("data-index"));
        const next = id + 1;
        const prev = id - 1;

        handleRowHovered(prev, next);
      };
    });
  });

  return (
    <>
      {showHeader == true && (
        <HeaderSection>
          <Pagetitle
            title={pageTitle}
            rcomp={pageTitleRComp}
            lcomp={pageTitleLComp}
          />
          {filterComp}
        </HeaderSection>
      )}
      {selectAllComp}
      <TableSection
        id={id}
        style={styles.tablesection}
        onMouseLeave={() => resetRoundingEffect()}
      >
        <DataTable
          expandableRowsHideExpander={false}
          {...props}
        />
      </TableSection>
    </>
  );
}

const HeaderSection = styled.div`
  /* position: fixed; */
`;
const SelectorSection = styled.div`
  background-color: #0772b6;
  margin: 15px 10px 0px 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: space-between;
`;

const FilterSection = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: transparent;
`;

const FilterContent = styled.div`
  display: flex;

  div {
    margin: 0 5px;
  }
`;

const TableSection = styled.div`
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  .rdt_TableHeadRow {
    .rs-checkbox-wrapper {
      display: none;
    }
  }
`;

const Status = styled.div`
  height: 15px;
  width: 15px;
  background-color: ${(props) =>
    props.status == "DRAFT"
      ? props.theme.colors.whiteDarken30
      : props.status == "PENDING_APPROVAL"
      ? props.theme.colors.yellow
      : props.theme.colors.whiteDarken30};
  border-radius: 50%;
  margin-right: 15px;
`;

const Datatable = {
  Table,
  Filter,
  Selector,
};

export default Datatable;
