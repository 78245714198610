import React, { useState, useEffect } from 'react'
import { Col, Row, Form, InputGroup } from "react-bootstrap";
import Buttonaction from '../../components/Buttonaction';
import Datatable from "../../components/Datatable";
import PromiseAuctionAvatar from "../../assets/images/promise-auction-avatar.svg";
import PromiseLogo from "../../assets/images/PromiseLogo.svg";
import IconAuctionDetails from "../../assets/images/Icon-autction-details.svg";
import InstagramIcon from "../../assets/images/instagram.svg";
import FacebookIcon from "../../assets/images/facebook.svg";
import LinkedinIcon from "../../assets/images/linkedin.svg";
import YoutubeIcon from "../../assets/images/youtube.svg";

export default function JoinAuction() {
  const data = [
    {
      id: 1,
      itemCode: 'L001',
      itemName: 'Laptop Toshiba Satelite C800',
      qty: 2,
      uom: 'Unit'
    },
    {
      id: 2,
      itemCode: 'P010',
      itemName: 'Proyektor Infocus Projector LED T22 2000 Lumens Resolution 1280 x 720',
      qty: 2,
      uom: 'Unit'
    },
    {
      id: 3,
      itemCode: 'S100',
      itemName: 'Screen Projector Motorized 150” 4:3 Layar ProyektorElektrik 150 Inch',
      qty: 1,
      uom: 'Unit'
    }
  ];

  const columns =[
    {
      name: 'Thumbnail',
      sortable: true,
      cell: (row) => (
        <div
          data-tag="allowRowEvents"
          style={{ display: "flex", alignItems: "center" }}
        >
          {row.itemCode}
        </div>
      )
    },
    {
      name: 'Item Code',
      sortable: true,
      cell: (row) => (
        <div
          data-tag="allowRowEvents"
          style={{ display: "flex", alignItems: "center" }}
        >
          {row.itemCode}
        </div>
      )
    },
    {
      name: 'Item Name',
      sortable: true,
      cell: (row) => (
        <div
          data-tag="allowRowEvents"
          style={{ display: "flex", alignItems: "center" }}
        >
          {row.itemName}
        </div>
      )
    },
    {
      name: 'Qty',
      sortable: true,
      cell: (row) => (
        <div
          data-tag="allowRowEvents"
          style={{ display: "flex", alignItems: "center" }}
        >
          {row.qty}
        </div>
      )
    },
    {
      name: 'UOM',
      sortable: true,
      cell: (row) => (
        <div
          data-tag="allowRowEvents"
          style={{ display: "flex", alignItems: "center" }}
        >
          {row.uom}
        </div>
      )
    }
  ];

  return(
    <Col className="mb-5" lg={12} style={{ paddingRight: '60px', paddingLeft: '10%' }}>
      <Row className="mt-5 d-flex justify-content-center">
        <Col lg={10}>
          <p style={{ fontSize: '18px', fontWeight: '500' }}>Auction Invitation PROCINI/001/2020</p>
          <hr style={{ height: '1px', color: '#C4C4C4', backgroundColor: '#C4C4C4' }}/>
          <Row className="mt-3" style={{ fontSize: '12px' }}>
            <Col lg={1}>
              <img src={PromiseAuctionAvatar} alt="" />
            </Col>
            <Col lg={11}>
              <Row style={{ marginLeft: '-5%' }}>
                <Col lg={6}>
                  <p><b>Promise Auction</b>  [promiseauction@mmi-pt.com]</p>
                </Col>
                <Col lg={6}>
                  <p className="d-flex justify-content-end">Feb 19 (1 Hour ago)</p>
                </Col>
              </Row>
              <Row style={{ marginLeft: '-3.9%' }}>
                <Col>
                  to user
                </Col>
              </Row>
              <Row className="mt-3" style={{ marginLeft: '-3.9%', backgroundColor: '#F3F5F4', padding: '12px' }}>
                <Col>
                  <img src={PromiseLogo} alt="" />
                </Col>
              </Row>
              <Row className="mt-3">
                <p style={{ fontSize: '14px', fontWeight: '500' }}>Dear Mr / Mrs Rep. of PT Agung Sejahtera,</p>
              </Row>
              <Row className="mt-3">
                <p style={{ fontSize: '14px', fontWeight: '500' }}>PROMISE Corp. hereby invites your company to join online auction with details below.</p>
              </Row>
              <hr style={{ marginLeft: '-3.6%', height: '1px', color: '#C4C4C4', backgroundColor: '#C4C4C4', width: '105%' }}/>
              <Row style={{ marginLeft: '-3%' }}>
                <Col lg={1}>
                  <img src={IconAuctionDetails} alt="" />
                </Col>
                <Col lg={11} style={{ fontSize: '18px', fontWeight: '600', marginLeft: '-3.9%' }}>
                  <p>Auction Details</p>
                </Col>
                <Col className="mt-1" lg={12} style={{ fontSize: '14px' }}>
                  <Row style={{ marginLeft: '0' }}>
                    <Col className="mt-3" lg={3} style={{ marginLeft: '-1.5%' }}>
                      Procurement Name
                    </Col>
                    <Col className="mt-3" lg={9}>
                      Pengadaan OS License dan Annual Technical Support Tahun 2020
                    </Col>
                    <Col className="mt-3" lg={3} style={{ marginLeft: '-1.5%' }}>
                      Auction Number
                    </Col>
                    <Col className="mt-3" lg={9}>
                      Sesi-01/Auction
                    </Col> 
                    <Col className="mt-3" lg={3} style={{ marginLeft: '-1.5%' }}>
                      Auction Time
                    </Col>
                    <Col className="mt-3" lg={9}>
                      18/09/2020 11:00 - 18/09/2020 12:00
                    </Col>
                    <Col className="mt-3" lg={3} style={{ marginLeft: '-1.5%' }}>
                      Auction Type
                    </Col>
                    <Col className="mt-3" lg={9}>
                      Open Auction
                    </Col>
                    <Col className="mt-3 text-muted" lg={12} style={{ marginLeft: '-1.5%' }}>
                      For this following items
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <Datatable.Table
                          data={data}
                          columns={columns}
                          showHeader={true}
                          customStyles={{
                            headRow: {
                              style: {
                                background: "#D0D6E0",
                              },
                            },
                            rows: {
                              style: {
                                cursor: "pointer",
                                background: "#F3F5F4",
                                "&:hover": {
                                  background: "white",
                                  borderBottom: "none",
                                  ".rdt_TableCell": {
                                    background: "white",
                                  },
                                },
                              },
                            },
                            cells: {
                              style: {
                                background: "#f3f5f4",
                                "&:hover": {
                                  background: "white",
                                },
                              },
                            },
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-2" style={{ fontSize: '14px' }}>
                    <Col className="mt-3" lg={12}>
                      Please confirm your auction participation before <b>Sep 18, 2020 10:30</b>
                    </Col>
                    <Col className="mt-3" lg={12}>
                      Kindly confirm your attendance by clicking button or link below 
                    </Col>
                    <Col className="mt-4 mr-4" lg={1}>
                      <Buttonaction
                        blueOcean
                        small
                        label="Attend"
                      />
                    </Col>
                    <Col className="mt-4" lg={2}>
                      <Buttonaction
                        borderblueOcean
                        small
                        label="Not Attend"
                      />
                    </Col>
                  </Row>
                  <div className="mt-4" style={{ fontSize: '14px' }}>
                    <p>If button is not accessible</p>
                    <p>click here to attend</p>
                    <a href="">bit.ly/09AKJ</a>
                    <p className="mt-2">or click here to not Attend</p>
                    <a href="">bit.ly/09AKJ</a>
                    <p className="mt-4">Please contact our <b>Customer Service</b> for further inquiries</p>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3" style={{ marginLeft: '-3.9%', backgroundColor: '#F3F5F4', padding: '12px' }}>
                <Col lg={6}>
                  Copyright &#169; 2022 PROMISE, all Right Reserved.
                </Col>
                <Col lg={6} className="d-flex justify-content-end" style={{ marginLeft: '0px' }}>
                  <Row >
                    <p className="mt-1">Follow Us</p>
                    <Col lg={1} className="mr-3"> 
                      <img src={FacebookIcon} alt="" />
                    </Col>
                    <Col lg={1} className="mr-3">
                      <img src={YoutubeIcon} alt="" />
                    </Col>
                    <Col lg={1} className="mr-3">
                      <img src={LinkedinIcon} alt="" />
                    </Col>
                    <Col lg={1}className="mr-3">
                      <img src={InstagramIcon} alt="" />
                    </Col>
                  </Row>
                </Col>
                
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  )
}