import React, { useEffect, useState } from "react"
import { Col } from "react-bootstrap"
import { CountdownCircleTimer } from "react-countdown-circle-timer"
import Chip from "../../components/Chip"
import { GreyCard } from "../../components/styled/Generalcard"
import { differenceInSeconds } from "date-fns"
import Statuslabel from "../../components/Statuslabel"

export default function VendorAuctionTimer({
   stopTimer,
   auctionDuration,
   durationLeft,
   dataProcurement,
   dataAuction,
   durationStart = 0,
}) {
   return (
      <GreyCard
         className="py-0 d-flex align-items-center"
         style={{
            // paddingLeft: "20px",
            width: dataProcurement?.evaluationMethod === "MERIT_POINT" ? "96%" : "80%",
            marginLeft: dataProcurement?.evaluationMethod !== "MERIT_POINT" && "20%",
            // height: dataAuction?.auctionType !== "OPEN" ? "200px" : "100%",
            height: "100%"
         }}
      >
         <Col className="d-flex justify-content-center" style={{ cursor: "pointer" }}>
            {durationLeft > 0 && durationLeft != null && !stopTimer && (
               <CountdownCircleTimer
                  isPlaying={durationLeft > 0 && dataAuction?.status !== "CANCELED" && !stopTimer}
                  duration={auctionDuration}
                  colors={[["#4DAF51", 0.33], ["#FFB301", 0.33], ["#F44437"]]}
                  strokeWidth={17}
                  size={180}
                  initialRemainingTime={durationLeft}
               >
                  {({ remainingTime }) => {
                     const hours = Math.floor(remainingTime / 3600)
                     const minutes = Math.floor((remainingTime % 3600) / 60)
                     const seconds = remainingTime % 60

                     return (
                        <div className="d-flex flex-column align-items-center">
                           <p
                              style={{
                                 color: "#909090",
                                 fontSize: "18px",
                                 marginBottom: "10px",
                              }}
                           >
                              Timer
                           </p>
                           <p
                              className="d-flex align-items-center mt-0 mb-3"
                              style={{
                                 color: "#909090",
                                 fontSize: "24px",
                                 fontWeight: "500",
                              }}
                           >
                              {`${hours.toString().length === 1 ? `0${hours}` : hours} : ${
                                 minutes.toString().length === 1 ? `0${minutes}` : minutes
                              } : ${seconds.toString().length === 1 ? `0${seconds}` : seconds}`}
                           </p>
                           <Chip kind="ongoing">On Going</Chip>
                        </div>
                     )
                  }}
               </CountdownCircleTimer>
            )}

            {(durationLeft == 0 ||
               dataAuction?.status === "DONE" ||
               dataAuction?.status === "CANCELED" ||
               stopTimer) && (
               <CountdownCircleTimer
                  isPlaying={false}
                  duration={auctionDuration}
                  colors={[["#4DAF51", 0.33], ["#FFB301", 0.33], ["#F44437"]]}
                  strokeWidth={17}
                  size={180}
                  initialRemainingTime={0}
               >
                  {({ remainingTime }) => {
                     const hours = Math.floor(remainingTime / 3600)
                     const minutes = Math.floor((remainingTime % 3600) / 60)
                     const seconds = remainingTime % 60

                     return (
                        <div className="d-flex flex-column align-items-center">
                           <p
                              style={{
                                 color: "#909090",
                                 fontSize: "18px",
                                 marginBottom: "10px",
                              }}
                           >
                              Timer
                           </p>
                           <p
                              className="d-flex align-items-center mt-0 mb-3"
                              style={{
                                 color: "#F44437",
                                 fontSize: "24px",
                                 fontWeight: "500",
                              }}
                           >
                              {`${hours.toString().length === 1 ? `0${hours}` : hours} : ${
                                 minutes.toString().length === 1 ? `0${minutes}` : minutes
                              } : ${seconds.toString().length === 1 ? `0${seconds}` : seconds}`}
                           </p>
                           <Chip kind="canceled">End</Chip>
                        </div>
                     )
                  }}
               </CountdownCircleTimer>
            )}

            {durationLeft == null &&
               durationStart > 0 &&
               dataAuction?.status !== "DONE" &&
               dataAuction.status !== "CANCELED" &&
               !stopTimer && (
                  <CountdownCircleTimer
                     isPlaying={!stopTimer}
                     duration={durationStart}
                     colors={[["#ECECEC"]]}
                     strokeWidth={17}
                     size={180}
                     trailColor={"#ECECEC"}
                  >
                     {({ remainingTime }) => {
                        const hours = Math.floor(remainingTime / 3600)
                        const minutes = Math.floor((remainingTime % 3600) / 60)
                        const seconds = remainingTime % 60

                        return (
                           <div className="d-flex flex-column align-items-center">
                              <p
                                 style={{
                                    color: "#909090",
                                    fontSize: "18px",
                                    marginBottom: "10px",
                                 }}
                              >
                                 Timer
                              </p>
                              <p
                                 className="d-flex align-items-center mt-0 mb-3"
                                 style={{
                                    color: "#909090",
                                    fontSize: "24px",
                                    fontWeight: "500",
                                 }}
                              >
                                 {`${hours.toString().length === 1 ? `0${hours}` : hours} : ${
                                    minutes.toString().length === 1 ? `0${minutes}` : minutes
                                 } : ${seconds.toString().length === 1 ? `0${seconds}` : seconds}`}
                              </p>
                              {/* <Chip kind="canceled">End</Chip> */}
                              <Statuslabel status="NOT_STARTED" label="NOT_STARTED" />
                           </div>
                        )
                     }}
                  </CountdownCircleTimer>
               )}
         </Col>
      </GreyCard>
   )
}
