const initialState = {
    dataCompanyTypes: [],
    dataPhonePrefixes: [],
    dataProvinces: [],
    dataCities: [],
    dataDistricts: [],
    dataMasters: undefined
}

const masterDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_COMPANY_TYPES':
            return {
                ...state,
                dataCompanyTypes: action.payload
            }
        case 'FETCH_PHONE_PREFIXES':
            return {
                ...state,
                dataPhonePrefixes: action.payload
            }
        case 'FETCH_PROVINCES':
            return {
                ...state,
                dataProvinces: action.payload
            }
        case 'FETCH_CITIES':
            return {
                ...state,
                dataCities: action.payload
            }
        case 'FETCH_DISTRICTS':
            return {
                ...state,
                dataDistricts: action.payload
            }
        case 'FETCH_MASTER_DATA':
            return {
                ...state,
                dataMasters: action.payload
            }
        default:
            return state
    }
}

export default masterDataReducer