const initialState = {
   dataAuctionInvitations: {
      filteredData: [],
   },
   auctionStatus: [],
   auctionItems: { isLoading: false, isError: false, data: [] },
}

const auctionReducer = (state = initialState, action) => {
   switch (action.type) {
      case "FETCH_AUCTION_INVITATIONS":
         return {
            ...state,
            dataAuctionInvitations: action.payload,
         }

      case "GET_AUCTION_STATUS":
         return {
            ...state,
            auctionStatus: action.payload,
         }

      case "RESET_AUCTION_STATUS":
         return {
            ...state,
            auctionStatus: [],
         }
      case "GET_AUCTION_ITEMS_PENDING":
         return {
            ...state,
            auctionItems: { ...state.auctionItems, isLoading: true, isError: false },
         }
      case "GET_AUCTION_ITEMS_FULFILLED":
         return {
            ...state,
            auctionItems: { ...state.auctionItems, isLoading: false, isError: false, data: action.payload },
         }
      case "GET_AUCTION_ITEMS_REJECTED":
         return {
            ...state,
            auctionItems: { ...state.auctionItems, isLoading: false, isError: false },
         }
      default:
         return state
   }
}

export default auctionReducer
