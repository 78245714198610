import Apiservice from "./Apiservice";

const API_URL = Apiservice.url.AUCTION_URL + "/api/secured/negotiation-offer";

function NegotiationOfferService(){
    this.requestForDeal = function(payload, callback) {
        return async () => {
            const resp = await Apiservice.invokePost(API_URL + "/request-deal", payload);
            if(resp.status === 200){
                if(callback){
                    callback(resp.data.data);
                }
            }
        }
    }

    this.findOfferBeforeByNegotiationId = function(negoId, dateTime, callback){
        return async () => {
            const resp = await Apiservice.invokePost(API_URL + "/find-record-before", {negotiationId: negoId, dateTime: dateTime})
            if(resp.status === 200){
                if(callback){
                    callback(resp.data.data);
                }
            }
        }
    }

    this.findLastOfferByNegotiationId = function(negoId, callback) {
        return async () => {
            const resp = await Apiservice.invokeGet(API_URL + "/find-last-offer-by-negotiation/" + negoId);
            if (resp.status == 200) {
                if (callback) {
                    callback(resp.data.data);
                }
            }
        }
    }

    this.findOfferByNegotiationId = function(negoId, callback){
        return async () => {
            const resp = await Apiservice.invokeGet(API_URL + "/find-by-negotiation/" + negoId);
            if (resp.status == 200) {
                if (callback) {
                    callback(resp.data.data);
                }
            }
        }
    }
}

export default NegotiationOfferService
