import Apiservice from "../Apiservice"
const API_URL = import.meta.env.VITE_SERVER_URL

const RegisterCompany = async(payload) => {
  const apiResult = await Apiservice.invokePost(`${API_URL}api/secured/company`, payload)

  return apiResult
}

const CreateCompanyAddress = async(payload) => {
  const apiResult = await Apiservice.invokePost(`${API_URL}api/secured/company-address`, payload)

  return apiResult
}

const CreateCompanyLegalInfo = async(payload) => {
  const apiResult = await Apiservice.invokePost(`${API_URL}api/secured/legal-info`, payload)

  return apiResult
}

const Onboaring = {
  RegisterCompany,
  CreateCompanyAddress,
  CreateCompanyLegalInfo
}

export default Onboaring