import React, { Component, useEffect } from "react";

export default function(ComposedComponent){
    class CheckLanguage extends Component{
        componentWillMount(){
            if(!localStorage.getItem("lang")){
                localStorage.setItem("lang", "US");
            }
        }

        render() {
            return <ComposedComponent {...this.props}/>
        }
    }

    return CheckLanguage;
}