import toast from "react-hot-toast"
import Apiservice from "../../services/Apiservice"
import { setAppLoading } from "./appAction"

export function register({ firstName, lastName, email, password, confirmPassword, optIn, reference, salesName }, callback, errorCallback) {
   return async (dispatch) => {
      dispatch(setAppLoading(true))
      try {
         const resp = await Apiservice.invokePost(Apiservice.url.AUCTION_URL + "/api/authentication/register", {
            firstName,
            lastName,
            email,
            password,
            confirmPassword,
            optIn,
            reference,
            salesName,
            role: "buyer_auction",
         })

         console.log(Apiservice.url.AUCTION_URL)

         if (resp.status == 200) {
            callback()
         } else {
            errorCallback(resp.data.errors)
         }
      } catch (e) {
         errorCallback(e.errors)
      } finally {
         dispatch(setAppLoading(false))
      }
   }
}

export function login({ email, password }, callback, errorCallback) {
   return async (dispatch) => {
      dispatch(setAppLoading(true))
      try {
         const resp = await Apiservice.invokePost(Apiservice.url.AUCTION_URL + "/api/authentication/login", {
            email,
            password,
         })
         if (resp.status == 200) {
            const { data } = resp.data
            localStorage.setItem("access_token", data.access_token)
            localStorage.setItem("refresh_token", data.refresh_token)
            localStorage.setItem("email", email) // utk bisa hapus session keycloak ketika timeout

            callback(data)
         } else {
            errorCallback(resp.data.errors)
         }
      } catch (e) {
         errorCallback(e)
      } finally {
         dispatch(setAppLoading(false))
      }
   }
}

export function refreshToken() {
   return async (dispatch) => {
      try {
         const resp = await Apiservice.invokePost(Apiservice.url.AUCTION_URL + "/api/authentication/refresh-token", {
            refreshToken: localStorage.getItem("refresh_token"),
         })

         if (resp.status == 200) {
            const { data } = resp.data
            localStorage.setItem("access_token", data.access_token)
            localStorage.setItem("refresh_token", data.refresh_token)
         } else {
            toast.error("Failed to refresh token")
         }
      } catch (e) {
         console.log(e)
      }
   }
}

export function logout(email) {
   return async (dispatch) => {
      try {
         const resp = await Apiservice.invokePost(Apiservice.url.AUCTION_URL + "/api/authentication/logout", {
            email: email,
         })
         if (resp.status === 200) {
            localStorage.removeItem("access_token")
            localStorage.removeItem("refresh_token")
            localStorage.removeItem("email")
            location.href = "/buyer-login"
         }
         location.href = "/buyer-login"
      } catch (e) {
         console.log("e", e)
      }
   }
}

export function logoutUserKeycloak(email) {
   return async (dispatch) => {
      try {
         const resp = await Apiservice.invokePost(Apiservice.url.AUCTION_URL + "/api/authentication/logout", {
            email: email,
         })
         if (resp.status === 200) {
            console.log(`User ${email} has been logout from keycloak`)
         }
      } catch (e) {
         console.log("e", e)
      }
   }
}

export function requestOTP({ email }, callback, errorCallback) {
   return async (dispatch) => {
      dispatch(setAppLoading(true))
      try {
         const resp = await Apiservice.invokePost(Apiservice.url.AUCTION_URL + "/api/authentication/request-otp", {
            email,
         })

         if (resp.status == 200) {
            callback()
         } else if(resp.status == 500) {
            errorCallback(resp.data)
         } else if(resp.status >= 400) {
            errorCallback(resp.data.errors)
         }
      } catch (e) {
         console.log({e})
         errorCallback(e.errors)
      } finally {
         dispatch(setAppLoading(false))
      }
   }
}

export function resetPassword({ email }, callback, errorCallback) {
   return async (dispatch) => {
      dispatch(setAppLoading(true))
      try {
         const resp = await Apiservice.invokePost(Apiservice.url.AUCTION_URL + "/api/authentication/reset-password", {
            email,
         })

         if (resp.status == 200) {
            callback && callback()
         } else {
            errorCallback && errorCallback(resp.data.errors)
         }
      } catch (e) {
         errorCallback && errorCallback(e.errors)
      } finally {
         dispatch(setAppLoading(false))
      }
   }
}

export function changePassword({ email, password, confirmPassword }, callback, errorCallback) {
   return async (dispatch) => {
      dispatch(setAppLoading(true))
      try {
         const resp = await Apiservice.invokePost(Apiservice.url.AUCTION_URL + "/api/authentication/change-password", {
            email,
            password,
            confirmPassword
         })

         if (resp.status == 200) {
            callback && callback()
         } else {
            errorCallback && errorCallback(resp.data.errors)
         }
      } catch (e) {
         errorCallback && errorCallback(e.errors)
      } finally {
         dispatch(setAppLoading(false))
      }
   }
}
