import React from "react"
import { DatePicker } from "rsuite"

function DatePickerInput(props) {
   const { predefinedRanges = [] } = props
   const ranges = [
      {
         label: "today",
         value: new Date(),
         closeOverlay: true,
      },
      ...predefinedRanges,
   ]

   return <DatePicker {...props} ranges={ranges}/>
}

export default DatePickerInput
