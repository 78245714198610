import React, { useEffect, useImperativeHandle, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { connect } from "react-redux"
import { useParams } from "react-router"
import { fetchProcurementItemsPublic, getVendorsByProcurementItemId } from "../../redux/action/procurementAction"
import LogisticIcon from "../../assets/images/LogisticIcon.png"
import styled from "styled-components"
import MuiDataTable from "../../components/MuiDataTable"
import { TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel } from "@mui/material"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { orderBy as _orderBy } from "lodash"
import Constant from "../../../constant"
import { getAuctionItems } from "../../redux/action/auctionAction"

function AttendanceConfirmationItemList({ passedInRef, dataProcurement, auctionInvitationData, setItemId }) {
   const dispatch = useDispatch()
   const { procurementId } = useParams()
   const [dataProcurementItems, setDataProcurementItems] = useState({
      filteredData: [],
   })

   const [vendorsByProcurementItems, setVendorsByProcurementItems] = useState([])

   const [dataState, setDataState] = useState({
      page: 0,
      limit: 5,
      query: "",
   })

   useImperativeHandle(passedInRef, () => ({
      getItemByVendorId: (vendorId) => {
         const result = vendorsByProcurementItems.find((vendor) => vendor.procurementVendorId == vendorId)
         return result
      },
   }))

   useEffect(() => {
      dispatch(
         getAuctionItems({ auctionId: auctionInvitationData.auctionId }, (res) => {
            setDataProcurementItems(res)
            setItemId(res.filteredData[0].procurementItemId)
            dispatch(
               getVendorsByProcurementItemId(res.filteredData[0].procurementItemId, (res) => {
                  setVendorsByProcurementItems(res)
               }),
            )
         }),
      )
   }, [])

   /**
    * Table handling
    * ----------------
    */
   const tableColumns = ["Item Code", "Item Name", "Qty", "Minimal Capable Qty", "UOM"]
   const [order, setOrder] = React.useState("asc")
   const [orderBy, setOrderBy] = React.useState("")
   const [emptySearch, setEmptySearch] = useState(false)

   const handleRequestSort = (column) => {
      const _order = order === "asc" ? "desc" : "asc"
      setOrderBy(column)
      setOrder(_order)

      const forColumn =
         column === "Item Code"
            ? "itemCode"
            : column === "Item Name"
            ? "itemName"
            : column === "PR Line"
            ? "prLine"
            : column === "Qty"
            ? "quantity"
            : column === "UOM"
            ? "uom"
            : column === "Estimated Price Per Unit"
            ? "pricePerUnit"
            : column === "Subtotal" && "subTotal"

      let result = []
      result = _orderBy(dataProcurementItems.filteredData, [forColumn], [_order])

      setDataProcurementItems({ ...dataProcurementItems, filteredData: result })
   }

   const handleRowsPerPageChange = (v, { props }) => {
      const { value } = props
      dispatch(
         getAuctionItems(
            {
               ...dataState,
               auctionId: auctionInvitationData.auctionId,
               limit: value,
            },
            (res) => {
               setDataState({
                  ...dataState,
                  limit: value,
               })
               setDataProcurementItems(res)
            },
         ),
      )
   }

   const handlePageChange = (v, newPage) => {
      dispatch(
         getAuctionItems(
            {
               ...dataState,
               auctionId: auctionInvitationData.auctionId,
               page: newPage + 1,
            },
            (res) => {
               setDataState({ ...dataState, page: newPage })
               setDataProcurementItems(res)
            },
         ),
      )
   }

   const search = ({ target }) => {
      const { value } = target
      dispatch(
         getAuctionItems(
            { auctionId: auctionInvitationData.auctionId, page: 1, limit: dataState.limit, query: value },
            (res) => {
               if (res.total === 0) {
                  setDataProcurementItems(res)
                  setEmptySearch(true)
               } else {
                  setDataProcurementItems(res)
               }
            },
         ),
      )
      setDataState((state) => ({
         ...state,
         query: value,
      }))
   }
   /**
    * end
    * ----------------
    */

   return (
      <>
         {(dataProcurementItems.total === 0 && !emptySearch && (
            <Row>
               <Col className="d-flex justify-content-center align-items-center m-5 flex-column">
                  <img style={{ marginLeft: "-10px" }} src={LogisticIcon} />
                  <span
                     className="mt-3"
                     style={{
                        textAlign: "center",
                        color: "#909090",
                        fontWeight: "bold",
                     }}
                  >
                     No list available
                  </span>
               </Col>
            </Row>
         )) || (
            <>
               <MuiDataTable.CustomTable
                  showHeader={true}
                  headerComponent={
                     <MuiDataTable.Filter
                        withSearchBar={true}
                        handleSearch={(v) =>
                           setDataState({
                              ...dataState,
                              query: v.target.value,
                           })
                        }
                        onKeyUp={(v) => v.keyCode === 13 && search(v)}
                     />
                  }
                  footerComponent={
                     dataProcurement.biddingType !== Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE && (
                        <TablePagination
                           sx={{ background: "#ffffff", borderBottom: "none" }}
                           page={dataState.page}
                           count={dataProcurementItems.total}
                           rowsPerPage={dataState.limit}
                           rowsPerPageOptions={[1, 2, 5, 10, 15, 20, { label: "All", value: -1 }]}
                           onPageChange={handlePageChange}
                           onRowsPerPageChange={handleRowsPerPageChange}
                           className="d-flex justify-content-end"
                        />
                     )
                  }
               >
                  <TableHead>
                     <TableRow>
                        {tableColumns.map((column, index) => (
                           <TableCell
                              key={index}
                              className="py-2"
                              sx={{
                                 whiteSpace: "nowrap",
                              }}
                              align={index === 2 || index === 3 ? "right" : ""}
                           >
                              <>{column}</>
                           </TableCell>
                        ))}
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {dataProcurementItems.filteredData.length > 0 ? (
                        // itemsChecked.length > 0 &&
                        dataProcurementItems.filteredData.map((item, index) => (
                           <TableRow
                              // selected={true}
                              sx={{
                                 background: "#F3F5F4",
                                 "&:hover": {
                                    background: "transparent",
                                 },
                              }}
                              key={index}
                              style={{ cursor: "pointer" }}
                              onClick={(v) => {
                                 v.target.type !== "checkbox" && viewDetail(item)
                              }}
                           >
                              <TableCell className="py-2">{item.itemCode}</TableCell>
                              <TableCell className="py-2">{item.itemName}</TableCell>
                              <TableCell className="py-2" align="right">
                                 {item.qty}
                              </TableCell>
                              <TableCell className="py-2" align="right">
                                 {item.minCapableQty}
                              </TableCell>
                              <TableCell className="py-2">{item.uom}</TableCell>
                           </TableRow>
                        ))
                     ) : (
                        <TableRow sx={{ background: "#F3F5F4" }}>
                           <TableCell colSpan={5} align="center" sx={{ fontWeight: 500 }}>
                              No records to display
                           </TableCell>
                        </TableRow>
                     )}
                  </TableBody>
               </MuiDataTable.CustomTable>
            </>
         )}
      </>
   )
}

export default AttendanceConfirmationItemList
