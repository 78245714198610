import React, { useEffect, useRef, useState } from "react"
import { Col, Form } from "react-bootstrap"
import Modal from "react-modal"
import styled from "styled-components"
import Buttonaction from "../../components/Buttonaction"
import AuctionBanner from "../../assets/images/AuctionBanner.png"
import SubmitModal from "../../components/Submitmodal"
import NotAttendLogo from "../../assets/images/not-attend.svg"
import { useLocation, useParams } from "react-router-dom"
import { useDispatch } from "react-redux"
import { attendInvitation2 } from "../../redux/action/procurementAuctionAction"
import toast from "react-hot-toast"
import { getAuctionInvitationData } from "../../redux/action/auctionAction"

export default function NotAttendAuction() {
   const dispatch = useDispatch()
   const { auctionId, invitationId } = useParams()

   const query = new URLSearchParams(useLocation().search)
   const email = query.get("email")

   const [error, setError] = useState([])
   const [formState, setFormState] = useState({ email: email, reason: "" })
   const [submitted, setSubmitted] = useState(false)
   const inputEmailRef = useRef()
   const inputReasonRef = useRef()
   const modalNotAttend = useRef()

   const handleSubmit = (e) => {
      e.preventDefault()
      inputEmailRef.current.disabled = true
      inputReasonRef.current.disabled = true
      setSubmitted(true)
   }

   const submitNotAttend = () => {
      dispatch(
         attendInvitation2(
            { auctionInvitationId: invitationId, isAttending: false, reasonNotAttend: formState.reason, email },
            () => {
               modalNotAttend.current.show({
                  title: "Thank you for your confirmation",
                  modalTitle: "",
                  description: "See you in another auction!",
               })
            },
            (_error) => {
               toast.error(_error[0]?.msg)
            },
         ),
      )
   }

   useEffect(() => {
      dispatch(
         getAuctionInvitationData(
            { auctionInvitationId: invitationId },
            (res) => {
               if (res.status === "NOT_ATTEND") {
                  setSubmitted(true)
                  setFormState((prev) => ({ ...prev, reason: res.reasonNotAttend }))
               } else {
                  console.log({ res })
                  setFormState((prev) => ({ ...prev, ...res }))
               }
            },
            (err) => {
               setError(err.errors)
               toast.error(err.errors[0].msg)
            },
         ),
      )
   }, [])

   return (
      <>
         <Modal />
         <OuterWrapper>
            <InputWrapper>
               <Logo src={AuctionBanner} width="200" />
               <div>
                  <p className="mb-4" style={{ fontSize: "12px" }}>
                     You are declared to not attend the auction session, please fulfill the reason to submit
                  </p>

                  <Form onSubmit={(e) => handleSubmit(e)}>
                     <Form.Group style={{ marginBottom: "18px" }} controlId="email">
                        <Label>Email</Label>
                        <InputField
                           disabled={email}
                           ref={inputEmailRef}
                           submitted={submitted}
                           name="email"
                           type="email"
                           placeholder="Company address"
                           onChange={(e) =>
                              setFormState({
                                 ...formState,
                                 [e.target.name]: e.target.value,
                              })
                           }
                           value={email}
                        />
                     </Form.Group>
                     <Form.Group className="d-flex flex-column" style={{ marginBottom: "32px" }} controlId="reason">
                        <Label>Reason</Label>
                        <InputField
                           disabled={submitted || error.length > 0 || formState.status !== "NOT_CONFIRMED"}
                           ref={inputReasonRef}
                           submitted={submitted}
                           as="textarea"
                           name="reason"
                           type="text"
                           placeholder="Tell us the reason why you can't attend this auction"
                           onChange={(e) =>
                              setFormState({
                                 ...formState,
                                 [e.target.name]: e.target.value,
                              })
                           }
                           value={formState.reason}
                        />
                     </Form.Group>
                     <ButtonActionGroup className="m-0">
                        {!submitted ? (
                           <>
                              <Buttonaction
                                 disabled={
                                    !formState.reason || error.length > 0 || formState.status !== "NOT_CONFIRMED"
                                 }
                                 type="submit"
                                 label="Submit"
                                 blueOcean
                                 onClick={submitNotAttend}
                              />
                              {console.log(formState)}
                              {/* <Buttonaction
                                 type="reset"
                                 label="Cancel"
                                 borderblueOcean
                                 onClick={() => window.close()}
                              /> */}
                           </>
                        ) : (
                           <>
                              <Buttonaction
                                 disabled
                                 type="submit"
                                 label="Submit"
                                 style={{ pointerEvents: "none" }}
                                 gray
                                 borderless
                              />
                              {/* <Buttonaction disabled label="Cancel" style={{ pointerEvents: "none" }} gray borderless /> */}
                           </>
                        )}
                     </ButtonActionGroup>
                  </Form>
               </div>
            </InputWrapper>
         </OuterWrapper>
         <SubmitModal.ModalContainer passedInRef={modalNotAttend} icon={NotAttendLogo} withCloseButton={false}>
            <SubmitModal.ModalButtonActions>
               <Col className="d-flex justify-content-end mt-4">
                  <Buttonaction
                     borderblueOcean
                     type="button"
                     label="Close"
                     large
                     onClick={() => modalNotAttend.current.hide()}
                  />
               </Col>
            </SubmitModal.ModalButtonActions>
         </SubmitModal.ModalContainer>
      </>
   )
}

const OuterWrapper = styled.div`
   display: grid;
   place-items: center;
   font-size: 62.5%;
   height: 100vh;
   padding: 10.4em;
`

const InputWrapper = styled.div`
   display: grid;
   place-items: center;
   border-radius: 12px;
   box-shadow: 0px 32px 100px 0px #00000014;
   width: 100%;
   padding: 4em;
`

const Logo = styled.img`
   margin-bottom: 5.8em;
`

const Label = styled(Form.Label)`
   color: #909090;
   font-size: 1.2em !important;
`

const InputField = styled(Form.Control)`
   border: 0.1em solid #c4c4c4;
   background-color: ${(props) => (props.submitted ? "#F3F5F4 !important" : "")};
   height: ${(props) => (props.name === "reason" ? "9em" : "auto")};
   resize: ${(props) => (props.name === "reason" ? "none" : "")};
   border-radius: ${(props) => (props.name === "reason" ? ".5em" : "")};
   padding: 1em 1.4em;
   font-size: 1.2em !important;

   &:focus {
      outline: none;
   }
`

const ButtonActionGroup = styled.div`
   display: flex;
   align-items: center;
   justify-content: flex-end;
   column-gap: 2.4em;
`
