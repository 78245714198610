import React, { useEffect, useRef, useState } from "react"
import toast from "react-hot-toast"
import { Col, Row } from "react-bootstrap"
import { useIsVisible } from "react-is-visible"
import { connect, useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router"

// Constant
import Constant from "../../../../constant"

// Components
import Stepper from "../../../components/Stepper"
import Tipscard from "../../../components/Tipscard"
import Pagetitle from "../../../components/Pagetitle"
import InitiationInfoform from "../InitiationInfoform"
import Statuslabel from "../../../components/Statuslabel"
import SubmitModal from "../../../components/Submitmodal"
import Buttonaction from "../../../components/Buttonaction"
import InitiationAuctionEvaluationForm from "./InitiationAuctionEvaluationform"
import ProcurementItemList from "../../buyer-auction/unit-price/ProcurementItemList"
import SubmitProcurementIcon from "../../../assets/images/FolderAlert.svg"
import AuctionParticipantList from "../../buyer-auction/unit-price/AuctionParticipantList"

// Redux
import {
   getInitialPrice,
   getInitialPriceByUnit,
   saveAuctionSessionAsDraft,
   getAuctionStatus,
   resetAuctionStatus,
} from "../../../redux/action/auctionAction"
import { getProcurement } from "../../../redux/action/procurementAction"
import StringUtil from "../../../services/utils/StringUtil"

function InitiationAuctionForm({ isAppLoading, dataProcurement, auctionStatus }) {
   const history = useHistory()
   const dispatch = useDispatch()
   const { procurementId } = useParams()

   // Component State
   const [initialPrice, setInitialPrice] = useState(0)
   const [expandVendor, setExpandVendor] = useState(false)
   const [selectedItemIds, setSelectedItemIds] = useState([])
   const [expandEvaluation, setExpandEvaluation] = useState(false)
   const [expandProcurement, setExpandProcurement] = useState(false)
   const [evaluationFormData, setEvaluationFormData] = useState({
      procurementId,
      auctionType: "",
      auctionModel: "",
      periodType: "",
      startTime: null,
      endTime: null,
      minimalDecrement: null,
      duration: null,
      timeExtension: null,
      injuryTimeBeforeEnd: null,
      maxSessionExtension: null,
   })

   // Component Ref
   const submitModalRef = useRef()
   const evaluationFormRef = useRef()
   const participantListRef = useRef()
   const VendorListAuctionRef = useRef()
   const procurementItemListRef = useRef()
   const procurementInfoAuctionRef = useRef()
   const EvaluationScoreAuctionRef = useRef()

   // #
   const vendorListAuctionViewSection = useIsVisible(VendorListAuctionRef)
   const procurementInfoAuctionViewSection = useIsVisible(procurementInfoAuctionRef)
   const evaluationScoreAuctionViewSection = useIsVisible(EvaluationScoreAuctionRef)

   const auctionId = localStorage.getItem("auctionId")

   useEffect(() => {
      dispatch(resetAuctionStatus())

      if (evaluationFormData?._id) {
         const auctionStatus = evaluationFormData.status

         const payload = {
            from: auctionStatus,
            auction: { ...evaluationFormData },
         }

         // get auction status step
         dispatch(getAuctionStatus(payload), (res) => {})
      }
   }, [evaluationFormData])

   const handleBack = () => {
      localStorage.removeItem("auctionId")
      history.push(`/initiation/form/${procurementId}`)

      // Not fully working when user never fill evaluation method
      dispatch(
         saveAuctionSessionAsDraft({
            ...evaluationFormData, 
            minimalDecrement: typeof evaluationFormData.minimalDecrement === "string" 
               ? StringUtil.convertStringInputNumberToFloat(evaluationFormData.minimalDecrement) 
               : evaluationFormData.minimalDecrement
            }, () => {
               localStorage.removeItem("auctionId")
               history.push(`/initiation/form/${procurementId}`)
            }
         ),
      )
   }

   const RenderActionButton = ({ data }) => {
      return (
         <>
            <Buttonaction className="mr-2" type="button" borderblueOcean onClick={handleBack} label="Back" />

            {data.length < 1 ? (
               <Buttonaction
                  className="ml-2"
                  type="button"
                  disabled={isAppLoading}
                  blueOcean
                  onClick={beforeCreateSession}
                  label="Create Session"
               />
            ) : (
               data.map((v, index) => (
                  <Buttonaction
                     key={index}
                     blueOcean={v.to.description == "NOT_STARTED" ? true : false}
                     label={v.label}
                     large
                     style={{ marginRight: "24px" }}
                     onClick={() => {
                        v.to.description === "NOT_STARTED"
                           ? // ? setAuctionStatusChangeTo(v.to.description)
                             beforeCreateSession()
                           : ""
                     }}
                  />
               ))
            )}
         </>
      )
   }

   const beforeCreateSession = () => {
      submitModalRef.current.show({
         title: "Create this session?",
         modalTitle: "",
         description: "You will create the session and the registered vendors will be notified. Are you sure?",
      })
   }

   const [isCreating, setIsCreating] = useState(false)
   const createSession = () => {
      const vendorIds = participantListRef.current.getSelectedVendorIds()

      if (vendorIds.length < 2) {
         toast.error("Please select at least 2 vendors to create an auction", {
            duration: 5000,
            style: { minWidth: "fit-content" },
         })
         submitModalRef.current.hide()
      } else {
         if (dataProcurement.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE) {
            // const itemIds = procurementItemListRef.current.getSelectedItemIds()
            const itemIds = selectedItemIds
            evaluationFormRef.current.submitUnit(
               (_auctionData) => {
                  if (_auctionData) {
                     history.push(`/buyer-auction/${procurementId}/${_auctionData._id}/not-started`)
                  }
               },
               itemIds,
               vendorIds,
            )
         } else {
            evaluationFormRef.current.submitBulk((_auctionData) => {
               if (_auctionData) {
                  history.push(`/buyer-auction/${procurementId}/${_auctionData._id}/not-started`)
               }
            }, vendorIds)
         }
      }
   }

   const handleExpand = ({ expand }) => {
      expand === "procurement"
         ? (setExpandProcurement(!expandProcurement), setExpandEvaluation(false), setExpandVendor(false))
         : expand === "evaluation"
         ? (setExpandProcurement(false), setExpandEvaluation(!expandEvaluation), setExpandVendor(false))
         : expand === "vendor" && (setExpandProcurement(false), setExpandEvaluation(false), setExpandVendor(!expandLog))
   }

   useEffect(() => {
      window.scrollTo(0, 0)
      dispatch(getProcurement({ procurementId }))

      dispatch(
         getInitialPrice({ procurementId, includeAllSession: "yes" }, (res) => setInitialPrice(res[0].totalOffer)),
      )

      if (!evaluationFormData.status) {
         setEvaluationFormData({ ...evaluationFormData, status: "DRAFT" })
      }

      // do cleanup when user leave the page
      return () => {
         localStorage.removeItem("auctionId")
      }
   }, [])

   useEffect(() => {
      if (dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE) {
         if (selectedItemIds.length > 0) {
            const itemId = selectedItemIds[0]

            dispatch(
               getInitialPriceByUnit({ procurementId, itemId, includeAllSession: "yes" }, (res) => {
                  setInitialPrice(res[0].totalOffer)
               }),
            )
         }
      }
   }, [selectedItemIds])

   const fnHandleSelectedItems = (itemIds) => {
      setSelectedItemIds(itemIds)
   }

   return (
      <>
         <Row>
            <Col lg={10}>
               <Row>
                  <Col className="mb-4">
                     <Pagetitle
                        title="New Auction"
                        onBackButtonClicked={
                           () => history.push(`/initiation/form/${procurementId}`)
                           // handleBack()
                        }
                        withBackButton={true}
                        lcomp={<Statuslabel label="DRAFT" status="DRAFT" />}
                        // lcomp={
                        //   <Statuslabel
                        //     label={dataProcurement?.status}
                        //     status={dataProcurement?.status}
                        //   />
                        // }
                     />
                  </Col>
               </Row>

               <InitiationInfoform
                  readOnly={
                     dataProcurement?.status === "OPEN" ||
                     dataProcurement?.status === "CANCELED" ||
                     dataProcurement?.status === "CLOSED"
                  }
               />

               <InitiationAuctionEvaluationForm
                  passedInRef={evaluationFormRef}
                  initialPrice={initialPrice}
                  evaluationFormData={evaluationFormData}
                  setEvaluationFormData={setEvaluationFormData}
                  auctionId={auctionId}
                  dataProcurement={dataProcurement}
                  setIsCreating={setIsCreating}
                  submitModalRef={submitModalRef}
               />

               <ProcurementItemList
                  passedInRef={procurementItemListRef}
                  getSelectedItems={(selectedItems) => setSelectedItemIds(selectedItems)}
                  fnHandleSelectedItems={fnHandleSelectedItems}
               />

               <AuctionParticipantList passedInRef={participantListRef} selectedItemIds={selectedItemIds} />

               <Row>
                  <Col className="my-4 d-flex justify-content-center align-items-center">
                     {auctionStatus && <RenderActionButton data={auctionStatus} />}
                  </Col>
               </Row>
            </Col>

            <Col lg={2} className="position-relative">
               <div
                  className="flex-column"
                  style={{
                     position: "fixed",
                     zIndex: 1000,
                     paddingTop: "45px",
                     fontWeight: "700",
                  }}
               >
                  <Stepper
                     active={{ expandProcurement }}
                     href="#procurement"
                     onClick={() => handleExpand({ expand: "procurement" })}
                  >
                     Procurement Info
                  </Stepper>
                  <Stepper
                     active={{ expandEvaluation }}
                     href="#evaluation"
                     onClick={() => handleExpand({ expand: "evaluation" })}
                  >
                     Evaluation Methods
                  </Stepper>
                  <Stepper active={{ expandVendor }} href="#vendor" onClick={() => handleExpand({ expand: "vendor" })}>
                     Participant List
                  </Stepper>
               </div>
               <Tipscard
                  page="createAuction"
                  content={
                     procurementInfoAuctionViewSection
                        ? Constant.TIPS_MESSAGE.PROCUREMENT_INFO_AUCTION
                        : evaluationScoreAuctionViewSection
                        ? Constant.TIPS_MESSAGE.EVALUATION_SCORE_AUCTION
                        : vendorListAuctionViewSection && Constant.TIPS_MESSAGE.VENDOR_LIST_AUCTION
                  }
               />
            </Col>
         </Row>

         <SubmitModal.ModalContainer passedInRef={submitModalRef} icon={SubmitProcurementIcon}>
            <SubmitModal.ModalButtonActions>
               <div className="d-flex mt-4 justify-content-end">
                  <div className="mr-3">
                     <Buttonaction
                        borderblueOcean
                        type="button"
                        label="No"
                        large
                        onClick={() => submitModalRef.current.hide()}
                     />
                  </div>
                  <Buttonaction
                     blueOcean
                     type="button"
                     label="Yes"
                     large
                     loading={isCreating}
                     onClick={createSession}
                  />
               </div>
            </SubmitModal.ModalButtonActions>
         </SubmitModal.ModalContainer>
      </>
   )
}

const mapStateToProps = (state) => {
   return {
      isAppLoading: state.appReducer.isAppLoading,
      dataProcurement: state.procurementReducer.dataProcurement,
      auctionStatus: state.auctionReducer.auctionStatus,
   }
}

export default connect(mapStateToProps)(InitiationAuctionForm)
