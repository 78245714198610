import { faTimes, faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import styled from 'styled-components';
import Buttonaction from './Buttonaction';
import ArrowUpLeftIcon from "../assets/images/ArrowUpLeft.svg"

/**
 * rcomp is any children that will placed on the 
 * right side
 * 
 * @param {*} param0 
 */
export default function Pagetitle({ 
    title, rcomp, lcomp, 
    
    withBorderBottom = false, 
    withBackButton = false, 
    withCloseButton = false,

    onBackButtonClicked,
    onCloseButtonClicked
}) {
    return (
        <>
            <Wrapper className={`${withBorderBottom ? 'with-border-bottom' : ''} ${withBackButton ? 'with-back-button' : ''}`}>
                {withBackButton &&
                    <>
                        <Buttonaction style={{color: '#909090', fontSize: 12}} outline small borderless hoverstay customIcon={ArrowUpLeftIcon} label="Back" onClick={() => {
                            if (onBackButtonClicked) {
                                onBackButtonClicked()
                            }
                        }} />
                        <div style={{ height: '20px', width: '1px', marginRight: 15, backgroundColor: '#d9d9d9' }}></div>
                    </>
                }
                <LeftComponent><Title>{title}</Title>{lcomp}</LeftComponent>
                <RightComponent>{rcomp}
                    {withCloseButton &&
                        <>
                            <Buttonaction
                                style={{ marginRight: "5px", marginLeft: "5px" }}
                                faIcon={faTimes}
                                outline
                                gray
                                small
                                onClick={() => {
                                    if (onCloseButtonClicked) {
                                        onCloseButtonClicked()
                                    }
                                }}
                            />
                        </>}
                </RightComponent>
            </Wrapper>
        </>
    )
}

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding-left: 12px;
    padding-right: 12px;

    &.with-border-bottom {
        border-bottom: 4px solid #F3F5F4;
        border-radius: 10px;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }

    &.with-back-button {
        justify-content: flex-start;
        align-items: center;
    }
`

const Title = styled.span`
    font-size: ${props => props.theme.fonts.sizes.h1};
    font-weight: bold;
    color: ${props => props.theme.colors.greyDarken};
    margin-right: 10px;
`
const LeftComponent = styled.div`
    display: flex;
    align-items: center;
    flex: 10;
`

const RightComponent = styled.div`
    display: flex;
    align-items: center;
`