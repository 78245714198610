const MAXIMUM_SHOW_PARTICIPANT = 3
const ROOM_SOCKET = {
   NEGOTIATION: "NEGOTIATION",
   CHAT: "CHAT",
   SPECTATOR: "SPECTATOR",
}

const STATUS = {
   DRAFT: "DRAFT",
   ON_GOING: "ON_GOING",
   DONE: "DONE",
   DELETE: "DELETE",
   CANCEL: "CANCEL",
   CANCELED: "CANCELED",
   NOT_STARTED: "NOT_STARTED",
   NOT_SCHEDULED: "NOT_SCHEDULED",
}

const MODULES = {
   NEGOTIATION: "NEGOTIATION",
}

const ROLE = {
   BUYER_AUCTION: "buyer_auction",
   SELLER_AUCTION: "seller_auction",
   SPECTATOR_AUCTION: "spectator_auction",
   ADMIN: "admin",
   SUPER_ADMIN: "super_admin"
}

const ENTITIES = {
   COMPANIES: "COMPANIES",
   PROCUREMENT_VENDOR: "PROCUREMENT_VENDOR",
}

const socketEvents = {
   JOIN_SPECTATOR_ROOM: "JOIN_SPECTATOR_ROOM",
   JOIN_CHAT_ROOM: "JOIN_CHAT_ROOM",
   MAKE_NEGO_OFFER_ITEMS: "MAKE_NEGO_OFFER_ITEMS",
   JOIN_NEGOTIATION_ROOM: "JOIN_NEGOTIATION_ROOM",
   RESPONSE_JOIN_CHAT_ROOM: "RESPONSE_JOIN_CHAT_ROOM",
   RESPONSE_JOIN_NEGOTIATION_ROOM: "RESPONSE_JOIN_NEGOTIATION_ROOM",
   RESPONSE_JOIN_SPECTATOR_ROOM: "RESPONSE_JOIN_SPECTATOR_ROOM",
   RESPONSE_LIST_SPECTATORS: "RESPONSE_LIST_SPECTATORS",
   RESPONSE_NEGOTIATION_LAST_OFFER: "RESPONSE_NEGOTIATION_LAST_OFFER",
   NEW_MESSAGE: "NEW_MESSAGE",
   BUYER_MAKE_NEGO_OFFER: "BUYER_MAKE_NEGO_OFFER",
   VENDOR_MAKE_NEGO_OFFER: "VENDOR_MAKE_NEGO_OFFER",
   RESPONSE_MESSAGE: "RESPONSE_MESSAGE",
   RESPONSE_NEGO_OFFER: "RESPONSE_NEGO_OFFER",
   PING_SOCKET: "PING_SOCKET",
   PONG_SOCKET: "PONG_SOCKET",
   BUYER_JOINED: "BUYER_JOINED",
   VENDOR_ATTEND: "VENDOR_ATTEND",
   VENDOR_MAKE_OFFER: "VENDOR_MAKE_OFFER",
   VENDOR_MAKE_OFFER_ITEM: "VENDOR_MAKE_OFFER_ITEM",
   VENDOR_MAKE_OFFER_ERROR: "VENDOR_MAKE_OFFER_ERROR",
   VENDOR_MAKE_OFFER_SUCCESS: "VENDOR_MAKE_OFFER_SUCCESS",
   VENDOR_MAKE_OFFER_ITEM_SUCCESS: "VENDOR_MAKE_OFFER_ITEM_SUCCESS",
   VENDOR_JOINED: "VENDOR_JOINED",
   VENDOR_REQ_FOR_DISQUALIFICATION: "VENDOR_REQ_FOR_DISQUALIFICATION",
   AUCTION_LEADERBOARD: "AUCTION_LEADERBOARD",
   AUCTION_COUNTDOWN_TIMER: "AUCTION_COUNTDOWN_TIMER",
   REQUEST_LIST_SPECTATOR: "REQUEST_LIST_SPECTATOR",
   AUCTION_COUNTDOWN_BEGIN_TIMER: "AUCTION_COUNTDOWN_BEGIN_TIMER",
   AUCTION_STARTED: "AUCTION_STARTED",
   AUCTION_STOPPED: "AUCTION_STOPPED",
   UPDATE_SINGLE_OFFER: "UPDATE_SINGLE_OFFER",
   AUCTION_VENDOR_OFFERS: "AUCTION_VENDOR_OFFERS",
   BUYER_DISQUALIFY_OFFER: "BUYER_DISQUALIFY_OFFER",
   FETCH_VENDOR_OFFERS: "FETCH_VENDOR_OFFERS",
   AUCTION_UPDATE_COUNTDOWN_TIMER: "AUCTION_UPDATE_COUNTDOWN_TIMER",
   FETCH_REQUEST_DISQUALIFY_OFFERS: "FETCH_REQUEST_DISQUALIFY_OFFERS",
   REFRESH_BUYER_AUCTION_VENDOR_LIST: "REFRESH_BUYER_AUCTION_VENDOR_LIST",
   USERS_IN_ROOM: "USERS_IN_ROOM",
   USERS_LEFT_IN_ROOM: "USERS_LEFT_IN_ROOM",
   BEGIN_ONLINE_NEGOTIATION: "BEGIN_ONLINE_NEGOTIATION",
   RESPONSE_BEGIN_ONLINE_NEGOTIATION: "RESPONSE_BEGIN_ONLINE_NEGOTIATION",
   CANCEL_ONLINE_NEGOTIATION: "CANCEL_ONLINE_NEGOTIATION",
   RESPONSE_CANCEL_ONLINE_NEGOTIATION: "RESPONSE_CANCEL_ONLINE_NEGOTIATION",
   END_ONLINE_NEGOTIATION: "END_ONLINE_NEGOTIATION",
   RESPONSE_END_ONLINE_NEGOTIATION: "RESPONSE_END_ONLINE_NEGOTIATION",
   CANCEL_AUCTION: "CANCEL_AUCTION",
   RESULT_CANCEL_AUCTION: "RESULT_CANCEL_AUCTION",
   AUCTION_FORCE_STOPPED: "AUCTION_FORCE_STOPPED",
   SET_EMPTY_USER_ONLINE_LIST: "SET_EMPTY_USER_ONLINE_LIST",
   VALIDATE_VENDOR_CLIENT_TIME: "VALIDATE_VENDOR_CLIENT_TIME",
   CHECKING_VENDOR_CLIENT_TIME: "CHECKING_VENDOR_CLIENT_TIME"
}

const procurementStatus = {
   DRAFT: "DRAFT",
   OPEN: "OPEN",
   ON_GOING: "ON_GOING",
   ON_NEGOTIATION: "ON_NEGOTIATION",
   CANCELED: "CANCELED",
   CLOSED: "CLOSED",
}

const auctionStatus = {
   DRAFT: "DRAFT",
   NOT_STARTED: "NOT_STARTED",
   CANCEL: "CANCEL",
   ON_GOING: "ON_GOING",
   DONE: "DONE",
}

const AUCTION_MODEL = {
   REALTIME: "REALTIME",
   SIMULATION: "SIMULATION",
}

const PROCUREMENT_BIDDING_TYPE = {
   TOTAL_PRICE: "TOTAL_PRICE",
   TOTAL_PRICE_BY_UNIT: "TOTAL_PRICE_BY_UNIT",
   UNIT_PRICE: "UNIT_PRICE",
}

const NEGOTIATION_STATUS_OFFER = {
   OFFER: "OFFER",
   OFFER_LAST_PRICE: "OFFER_LAST_PRICE",
   ACCEPT: "ACCEPT",
   CANCELLED: "CANCELLED",
   DEAL: "DEAL",
   NOT_DEAL: "NOT_DEAL",
}

const EVALUATION_METHODS = {
   PASSING_GRADE: "PASSING_GRADE",
   MERIT_POINT: "MERIT_POINT",
   LEAST_COST_SYSTEM: "LEAST_COST_SYSTEM",
}

const TIPS_MESSAGE = {
   ITEM_LIST: `A list of items that are submitted for auction process. Use the "Add Item" button to add a new additional item.`,
   PROCUREMENT_INFO:
      "This card is about general information that relates to aution stage. Define the flow and evaluation methods will be used.",
   VENDOR_LIST: `On this card, you can see a list of vendors who are participating in this auction. You can add or invite a new vendor by clicking the "Invite Add Vendors" button.`,
   EVALUATION_SCORE:
      "This section shows the latest score for each vendor as of theirs auction session bidding submittion.",
   AUCTION_SESSION:
      "Containing a list of auction sessions and providing the auction status for each session. You are not allowed to add a new session while the provious session is still on progress.",
   NEGOTIATION: "Containing a list of negotiation sessions and providing the negotiation status for each session. ",
   LOG_REPORT: "Generating auction session information which the auction status is already done.",
   PROCUREMENT_INFO_AUCTION:
      "This card is about general information that relates to aution stage. Define the flow and evaluation methods will be used",
   EVALUATION_SCORE_AUCTION:
      "Define the auction session process, set the rules and define start time of auction session",
   VENDOR_LIST_AUCTION: "List of participants that are able to join in this auction session",
}

const DOCUMENT_TYPE = {
   LETTER_OF_ATTOURNEY: "LETTER_OF_ATTOURNEY",
}

const WINNER_TYPE = {
   SINGLE_WINNER: "SINGLE_WINNER",
   MULTI_WINNER: "MULTI_WINNER",
}

const AUCTION_TYPE = {
   OPEN: "OPEN",
   CLOSE: "CLOSE",
}

const NOT_AVAILABLE = "N/A"

const NEGOTIATION_TYPE = {
   REVERSE_AUCTION: "REVERSE_AUCTION",
   FORWARD_AUCTION: "FORWARD_AUCTION",
   NEGOTIATION: "NEGOTIATION"
}

const NEGO_TYPE = {
   ONLINE: "ONLINE",
   OFFLINE: "OFFLINE",
}

const ATTENDANCE_STATUS = {
   ATTEND: "ATTEND",
   NOT_CONFIRMED: "NOT_CONFIRMED"
}

const PERIOD_TYPE = {
    LIMITED_TIME: "LIMITED_TIME",
    INJURY_TIME: "INJURY_TIME"
}

const REFERENCE = {
   SEARCH_ENGINE: "SEARCH_ENGINE",
   SOCIAL_MEDIA: "SOCIAL_MEDIA",
   MMI_SALES: "MMI_SALES",
   SALES_AGENT: "SALES_AGENT",
}

const masterData = {
   companyType: [
      {
         label: "PT",
         value: "PT",
      },
      {
         label: "CV",
         value: "CV",
      },
   ],
   phonePrefix: [
      {
         label: "+62",
         value: "+62",
      },
   ],
   evaluationMethod: [
      {
         label: "Passing Grade",
         value: "PASSING_GRADE",
      },

      {
         label: "Least Cost System",
         value: "LEAST_COST_SYSTEM",
      },

      {
         label: "Merit Point",
         value: "MERIT_POINT",
      },
   ],
   winnerType: [
      {
         label: "Single Winner",
         value: "SINGLE_WINNER",
      },
      {
         label: "Multi Winner",
         value: "MULTI_WINNER",
      },
   ],
   biddingType: [
      {
         label: "Total Bulk Price",
         value: "TOTAL_PRICE",
      },
      {
         label: "Total Price by Unit Price Item",
         value: "TOTAL_PRICE_BY_UNIT",
      },
      {
         label: "Unit Price",
         value: "UNIT_PRICE",
      },
   ],
   negotiationType: [
      {
         label: "Reverse Auction",
         value: "REVERSE_AUCTION",
      },
      {
        label: "Forward Auction",
        value: "FORWARD_AUCTION",
      },
      {
         label: "Negotiation",
         value: "NEGOTIATION",
      },
   ],
   currencyType: [
      {
         label: "Single Currency",
         value: "SINGLE_CURRENCY",
      },
      {
        label: "Multi Currency",
        value: "MULTI_CURRENCY",
      },
   ],
   auctionType: [
      { label: "Open", value: "OPEN" },
      { label: "Close", value: "CLOSE" },
   ],
   auctionModel: [
      { label: "Real Time", value: "REALTIME" },
      { label: "Simulation", value: "SIMULATION" },
   ],
   periodType: [
      { label: "Injury Time", value: "INJURY_TIME" },
      { label: "Limited Time", value: "LIMITED_TIME" },
   ],
   procurementStatus: [
      { label: "Draft", value: "DRAFT" },
      { label: "Open", value: "OPEN" },
      { label: "On Going", value: "ON_GOING" },
      { label: "On Negotiation", value: "ON_NEGOTIATION" },
      { label: "Canceled", value: "CANCELED" },
      { label: "Closed", value: "CLOSED" },
   ],
   negoType: [
      { label: "Online Negotiation", value: "ONLINE" },
      { label: "Offline Negotiation", value: "OFFLINE" },
   ],
}

const EVALUATION_SCORE = {
   ADMINSITRATION_VALUE: "administrationValue",
   TECHNICAL_VALUE: "technicalValue",
   PRICE_VALUE: "priceValue",
}

const CURRENCY_TYPE = {
   SINGLE_CURRENCY: "SINGLE_CURRENCY",
   MULTI_CURRENCY: "MULTI_CURRENCY"
}

const CURRENCY = {
   IDR: "IDR",
   USD: "USD"
}

const Constant = {
   masterData,
   MAXIMUM_SHOW_PARTICIPANT,
   MODULES,
   procurementStatus,
   auctionStatus,
   socketEvents,
   AUCTION_MODEL,
   ENTITIES,
   PROCUREMENT_BIDDING_TYPE,
   TIPS_MESSAGE,
   DOCUMENT_TYPE,
   EVALUATION_METHODS,
   EVALUATION_SCORE,
   WINNER_TYPE,
   NEGOTIATION_STATUS_OFFER,
   NEGOTIATION_TYPE,
   ROOM_SOCKET,
   ROLE,
   STATUS,
   AUCTION_TYPE,
   NOT_AVAILABLE,
   NEGO_TYPE,
   ATTENDANCE_STATUS,
   PERIOD_TYPE,
   CURRENCY_TYPE,
   CURRENCY,
   REFERENCE
}

export default Constant
