import Apiservice from "../services/Apiservice";

const API_URL = Apiservice.url.AUCTION_URL + "/api/secured/procurement-vendor";

function ProcurementVendorService(){
    this.publicFindById = function(id, callback){
        return async () => {
            const resp = await Apiservice.invokeGet(API_URL+"/public/"+id);
            if (resp.status == 200) {
                if (callback) {
                    callback(resp.data.data);
                }
            }
        }
    }
    this.findById = function(id, callback, errCallback){
        return async () => {
            const resp = await Apiservice.invokeGet(API_URL + "/" + id)
            if(resp.status === 200){
                if(callback){
                    callback(resp.data.data);
                }
            } else {
                errCallback()
            }
        }
    }
    this.findByAccountId = function(accountId, callback){
        return async () => {
            const resp = await Apiservice.invokeGet(API_URL+"/find-by-account/"+accountId);
            if (resp.status == 200) {
                if (callback) {
                    callback(resp.data.data);
                }
            }
        }
    }
}

export default ProcurementVendorService
