import Constant from "../../../constant"
import Apiservice from "../../services/Apiservice"

export function fetchMasterData(language = "US", callback, errCallback) {
   return async (dispatch) => {
      // const resp = await fetch("/masterData.json", {
      //     'Content-Type': 'application/json',
      //     'Accept': 'application/json'
      // })

      const resp = await Apiservice.invokeGet(Apiservice.url.AUCTION_URL + "/api/masters")
      console.log({resp: resp.data})
      if (resp.status === 200) {
         // PAKE INI KALO MASTER UDAH DIPINDAH KE DB SEMUA
         // if (callback) {
         //    callback(resp.data.data)
         // }

         //   let masters = resp.data.data
         //   let masterData = {}
         //   for (const object in masters) {
         //      const _master = masters[object].map((data) => {
         //            return { ...data, label: language === "US" ? data.label.en : data.label.id }
         //         }),
         //         masters = { ...masters, ..._master }

         //      masterData[object] = _master
         //   }
         // =============

         // SEMENTARA
         const evaluationMethods = resp.data.data.evaluationMethods
         const currencies = resp.data.data.currencies
         const currencyTypes = resp.data.data.currencyTypes
         const biddingTypes = resp.data.data.biddingTypes
         const negotiationTypes = resp.data.data.negotiationTypes
         const winnerTypes = resp.data.data.winnerTypes

         const auctionTypes = resp.data.data.auctionTypes
         const auctionModels = resp.data.data.auctionModels
         const periodTypes = resp.data.data.periodTypes
         const references = resp.data.data.references
         let masters = {
            ...Constant.masterData,
            evaluationMethods,
            currencyTypes,
            currencies,
            biddingTypes,
            negotiationTypes,
            winnerTypes,
            auctionTypes,
            auctionModels,
            periodTypes,
            references
         }
         let masterData = {}
         for (const object in masters) {
            const _master = masters[object].map((data) => {
               return {
                  ...data,
                  label:
                     typeof data.label == "object" && language == "US"
                        ? data.label.en
                        : typeof data.label == "object" && language == "ID"
                        ? data.label.id
                        : data.label,
               }
            })

            masterData[object] = _master
         }
         if (callback) {
            callback(masterData)
         }
         // ============
         await dispatch({
            type: "FETCH_MASTER_DATA",
            payload: masterData,
         })
      } else {
         if (errCallback) {
            errCallback()
         }
      }
   }
}

export function fetchCompanyTypes() {
   return async (dispatch) => {
      // const resp = await fetch("./companyType.json", {
      //     headers: {
      //         'Content-Type': 'application/json',
      //         'Accept': 'application/json'
      //     }
      // })

      // const json = await resp.json()
      await dispatch({
         type: "FETCH_COMPANY_TYPES",
         payload: Constant.masterData.companyType,
      })
   }
}

export function fetchPhonePrefixes() {
   return async (dispatch) => {
      // const resp = await fetch("./phonePrefix.json", {
      //     headers: {
      //         'Content-Type': 'application/json',
      //         'Accept': 'application/json'
      //     }
      // })

      // const json = await resp.json()
      await dispatch({
         type: "FETCH_PHONE_PREFIXES",
         payload: Constant.masterData.phonePrefix,
      })
   }
}

export function fetchProvinces() {
   return async (dispatch) => {
      // const resp = await Apiservice.invokeGet(Apiservice.url.MASTERDATA_URL + "/address/province")
      const resp = await Apiservice.invokeGet(Apiservice.url.AUCTION_URL + "/api/master-data/address/province")
      if (resp.status == 200 || resp.status == 201) {
         const { data } = resp

         await dispatch({
            type: "FETCH_PROVINCES",
            payload: data.data.map((v) => ({
               label: v.name,
               value: v.code,
            })),
         })
      }
   }
}

export function fetchCities({ provinceId }) {
   return async (dispatch) => {
      // const resp = await Apiservice.invokeGet(Apiservice.url.MASTERDATA_URL + "/address/city?province=" + provinceId)
      const resp = await Apiservice.invokeGet(
         Apiservice.url.AUCTION_URL + `/api/master-data/address/city/${provinceId}`,
      )
      if (resp.status == 200 || resp.status == 201) {
         const { data } = resp

         await dispatch({
            type: "FETCH_CITIES",
            payload: data.data.map((v) => ({
               label: v.name,
               cityId: v.cityId,
               value: v.code,
               postalCode: v.postalCode,
            })),
         })
      }
   }
}

export function fetchDistrict({ cityId }) {
   return async (dispatch) => {
      // const resp = await Apiservice.invokeGet(Apiservice.url.MASTERDATA_URL + "/address/district?city=" + cityId)
      const resp = await Apiservice.invokeGet(
         Apiservice.url.AUCTION_URL + `/api/master-data/address/district/${cityId}`,
      )
      if (resp.status == 200 || resp.status == 201) {
         const { data } = resp

         await dispatch({
            type: "FETCH_DISTRICTS",
            payload: data.data.map((v) => ({
               label: v.name,
               value: v.code,
            })),
         })
      }
   }
}
