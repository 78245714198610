import React from "react";
import { Bar } from "react-chartjs-2";

export default function BarChart({ data }) {
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <>
      <Bar data={data} options={options} />
    </>
  );
}
