import React, { useEffect, useImperativeHandle, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { connect } from "react-redux"
import { Col, Form, Row } from "react-bootstrap"
import { useParams } from "react-router-dom"

// Components
import ModalForm from "../../../../../../components/Modalform"

// Redux
import { fetchProcurementVendorItems } from "../../../../../../redux/action/negotiationAction"
import { fetchProcurementItems } from "../../../../../../redux/action/procurementAction"
import { findLastOfferByNegotiationId } from "../../../../../../redux/action/negotiationOfferAction"
import { findByNegotiationId } from "../../../../../../redux/action/negotiationItemAction"

// Children Components
import NegotiationVendorTotalPrice from "../component/negotiation-vendor/NegotiationVendorTotalPrice"
import NegotiationVendorTotalPriceByUnit from "../component/negotiation-vendor/NegotiationVendorTotalPriceByUnit"
import NegotiationVendorUnitPrice from "../component/negotiation-vendor/NegotiationVendorUnitPrice"

import Constant from "../../../../../../../constant"
import { GreyCard } from "../../../../../../components/styled/Generalcard"
import Dateinfo from "../../../../../../components/Dateinfo"

import warningIcon from "../../../../../../assets/images/WarningIcon.svg"

function NegotiationOffer(props) {
   const { passedInRef, dataProcurement, dataProcurementVendorItems, negotiationBatch, fnShowOffer } = props
   const dispatch = useDispatch()

   // Component state
   // const [addItem, setAddItem] = useState(false)
   // const [vendorId, setVendorId] = useState(null)
   // const [isLoading, setIsLoading] = useState(false)
   const [lastOffer, setLastOffer] = useState()
   const [items, setItems] = useState([])
   const [negotiation, setNegotiation] = useState(null)

   // Component refs
   const negotiationOfferRef = useRef()

   useImperativeHandle(passedInRef, () => ({
      show: (negotiation) => {
         setNegotiation(negotiation)
         showModal(negotiation)
      },

      hide: () => {
         closeModal()
      },
   }))

   const getLastOffer = (_negotiation) => {
      return new Promise((resolve, reject) => {
         dispatch(
            findLastOfferByNegotiationId(
               _negotiation._id,
               (res) => {
                  setLastOffer(res)
                  resolve(res)
               },
               () => {
                  reject()
               },
            ),
         )
      })
   }

   const getItems = (_negotiation) => {
      return new Promise((resolve, reject) => {
         dispatch(
            findByNegotiationId(
               _negotiation._id,
               (res) => {
                  setItems(res)
                  resolve(res)
               },
               () => {
                  reject()
               },
            ),
         )
      })
   }

   const getItemsAndLastOffer = (negotiation) => {
      return Promise.all([getItems(negotiation), getLastOffer(negotiation)])
   }

   const showModal = (negotiation) => {
      getItemsAndLastOffer(negotiation).then((res) => {
         // if all promise return success then:
         negotiationOfferRef.current.show()
      })
   }

   const closeModal = (isRefresh = false) => {
      if (isRefresh) {
         fnShowOffer(negotiation, true)
      }

      negotiationOfferRef.current.hide()
   }

   const handleClose = (isRefresh = false, closeModalOffer = false) => {
      if (isRefresh && closeModalOffer) {
         fnShowOffer(negotiation, true, true)
      } else {
         fnShowOffer(negotiation)
      }
   }

   return (
      <ModalForm passedInRef={negotiationOfferRef} title={"Negotiation Price"} closeButton={true} width="1000px">
         {negotiation && (
            <>
               {negotiation.status === Constant.STATUS.CANCELED && (
                  <Row className="mx-4 mb-3">
                     <Col className="px-0">
                        <div
                           className="d-flex align-items-center px-3"
                           style={{
                              backgroundColor: "#fde9ef",
                              color: "#909090",
                              fontSize: "12px",
                              fontWeight: "400",
                              borderRadius: "5px",
                              height: "40px",
                           }}
                        >
                           <img src={warningIcon} className="mr-2" />
                           <p>
                              This session canceled by <strong> {negotiation.canceledBy} </strong>
                              at
                              <strong>
                                 {" "}
                                 <Dateinfo
                                    date={negotiation.updatedAt}
                                    format="dd/mm/yyyy HH:MM"
                                    style={{ all: "unset" }}
                                 />
                              </strong>{" "}
                              The cancelation reason is{" "}
                              <strong
                                 style={{ display: "inline-block", lineHeight: "0px" }}
                                 dangerouslySetInnerHTML={{
                                    __html: negotiation.cancelReason,
                                 }}
                              />
                           </p>
                        </div>
                     </Col>
                  </Row>
               )}
            </>
         )}

         <GreyCard style={{ padding: "32px 18px" }} className="mx-4 mb-5">
            <Row>
               <Col lg={10}>
                  <p style={{ fontSize: "18px", fontWeight: "500" }}>Information</p>
               </Col>
            </Row>
            <hr style={{ borderColor: "#C4C4C4", marginTop: "18px" }} />

            <Row className="mb-3">
               <Col lg={2}>
                  <p>Procurement Name</p>
               </Col>
               <Col>
                  <p>{dataProcurement?.procurementName}</p>
               </Col>
            </Row>

            <Row>
               <Col lg={2}>
                  <p>Vendor</p>
               </Col>
               <Col>
                  <p>{negotiation?.vendorName}</p>
               </Col>
            </Row>
         </GreyCard>
         {/* {dataProcurementVendorItems?.length > 0 &&
                  dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE ? (
                     <NegotiationVendorTotalPrice
                        setAddItem={setAddItem}
                        vendorId={vendorId}
                        addItemVendorRef={addItemVendorRef}
                     />
                  ) : dataProcurementVendorItems?.length > 0 &&
                    dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE_BY_UNIT ? (
                     <NegotiationVendorTotalPriceByUnit
                        setAddItem={setAddItem}
                        vendorId={vendorId}
                        addItemVendorRef={addItemVendorRef}
                     />
                  ) : (
                     dataProcurementVendorItems?.length > 0 &&
                     dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE && (
                        <NegotiationVendorUnitPrice
                           setAddItem={setAddItem}
                           vendorId={vendorId}
                           addItemVendorRef={addItemVendorRef}
                        />
                     )
                  )} */}
         {negotiation &&
            items.length > 0 &&
            lastOffer !== undefined &&
            dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE && (
               <NegotiationVendorTotalPrice
                  lastOffer={lastOffer}
                  negotiation={negotiation}
                  negotiationBatch={negotiationBatch}
                  negotiationItems={items}
                  fnHandleClose={handleClose}
                  dataProcurement={dataProcurement}
               />
            )}
         {negotiation &&
            items.length > 0 &&
            lastOffer !== undefined &&
            dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE_BY_UNIT && (
               <NegotiationVendorTotalPriceByUnit
                  lastOffer={lastOffer}
                  negotiation={negotiation}
                  negotiationBatch={negotiationBatch}
                  negotiationItems={items}
                  fnHandleClose={handleClose}
                  dataProcurement={dataProcurement}
                  />
                  )}
         {negotiation &&
            items.length > 0 &&
            lastOffer !== undefined &&
            dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE && (
               <NegotiationVendorUnitPrice
                  lastOffer={lastOffer}
                  negotiation={negotiation}
                  negotiationBatch={negotiationBatch}
                  negotiationItems={items}
                  fnHandleClose={handleClose}
                  dataProcurement={dataProcurement}
               />
            )}
         {/* </div>
            </Row> */}
      </ModalForm>
   )
}

const mapStateToProps = (state) => ({
   dataProcurement: state.procurementReducer.dataProcurement,
   dataProcurementVendorItems: state.procurementReducer.dataProcurementVendorItems,
})

export default connect(mapStateToProps)(NegotiationOffer)
