import React, { useEffect, useState } from "react";
import { getProcurement } from "../../redux/action/procurementAction";
import { connect, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Pagetitle from "../../components/Pagetitle";
import Chip from "../../components/Chip";
import Breadcrumbs from "../../components/Breadcrumbs";
import { GreyCard } from "../../components/styled/Generalcard";
import { SelectPicker } from "rsuite";
import Datatable from "../../components/Datatable";
import Dateinfo from "../../components/Dateinfo";
import IconTrophy from "../../assets/images/Icon-Trophy.svg";
import Buttonaction from "../../components/Buttonaction";

const VendorAuctionHistory = ({ dataProcurement }) => {
  const dispatch = useDispatch();
  const { procurementId } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (procurementId) {
      dispatch(
        getProcurement({ procurementId }, (res) => {
          console.log(res);
        })
      );
    }
  }, []);

  const [negotiationDataList, setNegotiationDataList] = useState([
    {
      sessionNumber: "Test",
      offerTime: "01 July 2021, 18:20:00",
      offerPrice: "996.960.000.00 IDR",
      status: "valid",
      disqualifyingTime: "",
      picDisqualifier: "Arina pramudita",
    },
    {
      sessionNumber: "Test",
      offerTime: "01 July 2021, 18:20:00",
      offerPrice: "996.960.000.00 IDR",
      status: "disqualified",
      disqualifyingTime: "01 July 2021, 18:20:00",
      picDisqualifier: "Arina pramudita",
    },
  ]);

  const columns = [
    {
      name: "Session Number",
      sortable: true,
      grow: 2,
      cell: (row) => (
        <div className="d-flex align-items-center">
          <span>{row.sessionNumber}</span>
        </div>
      ),
    },
    {
      name: "Offer Time",
      sortable: true,
      grow: 2,
      cell: (row) => (
        <div className="d-flex align-items-center">
          <Dateinfo date={row.offerTime} showtime={true} />
        </div>
      ),
    },
    {
      name: "Offer Price",
      sortable: true,
      grow: 2,
      cell: (row) => (
        <div className="d-flex align-items-center">{row.offerPrice}</div>
      ),
    },
    {
      name: "Status",
      sortable: true,
      grow: 1.3,
      cell: (row) => (
        <div className="d-flex align-items-center justify-content-between w-100">
          <Chip kind={row.status}>{row.status}</Chip>
          <img src={IconTrophy} />
        </div>
      ),
    },
    {
      name: "Disqualifying Time",
      sortable: true,
      grow: 2,
      cell: (row) => (
        <div className="d-flex align-items-center">
          {!row.disqualifyingTime ? (
            "-"
          ) : (
            <Dateinfo date={row.disqualifyingTime} showtime={true} />
          )}
        </div>
      ),
    },
    {
      name: "PIC Disqualifier",
      sortable: true,
      grow: 2,
      cell: (row) => (
        <div className="d-flex align-items-center">
          {/* <Statuslabel className="m-0" label={row.status} status={row.status} /> */}
          {row.picDisqualifier}
        </div>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col lg={12}>
          <>
            <Row>
              <Col>
                <Breadcrumbs
                  data={["Dashboard", "Procurement", "Initiation"]}
                />
              </Col>
            </Row>

            <Row>
              <Col className="mb-4">
                <Pagetitle
                  title={dataProcurement?.procurementNumber}
                  onBackButtonClicked={() =>
                    history.push(`/initiation/form/${procurementId}`)
                  }
                  withBackButton={true}
                  lcomp={
                    <Chip kind={dataProcurement?.status.toLowerCase()}>
                      <p>
                        {dataProcurement?.status.charAt(0) +
                          dataProcurement?.status.slice(1).toLowerCase()}
                      </p>
                    </Chip>
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col className="mb-5">
                <GreyCard>
                  <Row>
                    <Col className="d-flex justify-content-between align-items-center px-4 mt-3 mb-1">
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#454545",
                        }}
                      >
                        Information
                      </p>
                    </Col>
                  </Row>

                  <hr style={{ background: "#C4C4C4" }} />

                  <Row className="d-flex align-items-center mb-3 px-4">
                    <Col lg={2}>Vendor</Col>
                    <Col>PT Agung Sejahtera</Col>
                  </Row>

                  <Row className="d-flex align-items-center mb-3 px-4">
                    <Col lg={2}>Session</Col>
                    <Col>
                      <SelectPicker style={{ width: 224 }} />
                    </Col>
                  </Row>
                </GreyCard>
              </Col>
            </Row>

            <Row>
              <Col className="mb-5">
                <GreyCard style={{ paddingBottom: "34px" }}>
                  <Row>
                    <Col className="d-flex justify-content-between align-items-center px-4 mt-3">
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#454545",
                        }}
                      >
                        Bidding History
                      </p>
                    </Col>
                  </Row>
                  <hr style={{ background: "#C4C4C4" }} />

                  <Datatable.Table
                    data={negotiationDataList}
                    columns={columns}
                    showHeader={true}
                    // style={{ width: "100%" }}
                    customStyles={{
                      // table: {
                      //   style: {
                      //     minWidth: "100vw",
                      //     maxWidth: "150vw",
                      //   },
                      // },
                      headRow: {
                        style: {
                          background: "#D0D6E0",
                        },
                      },
                      rows: {
                        style: {
                          cursor: "pointer",
                          background: "#F3F5F4",
                          "&:hover": {
                            background: "white",
                            borderBottom: "none",
                            ".rdt_TableCell": {
                              background: "white",
                            },
                          },
                          width: "100%",
                        },
                      },
                      cells: {
                        style: {
                          background: "#f3f5f4",
                          "&:hover": {
                            background: "white",
                          },
                        },
                      },
                    }}
                  />
                </GreyCard>
              </Col>
            </Row>

            <Row>
              <Col className="d-flex justify-content-center mb-5">
                <Buttonaction
                  borderblueOcean
                  label="Back to List"
                  style={{ marginRight: "24px" }}
                  onClick={() =>
                    history.goBack()
                  }
                />
                <Buttonaction blueOcean label="Save price" />
              </Col>
            </Row>
          </>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  dataProcurement: state.procurementReducer.dataProcurement,
});

export default connect(mapStateToProps)(VendorAuctionHistory);
