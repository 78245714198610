import React from "react";
import styled from "styled-components";
import dateFormat from "dateformat";

export default function Dateinfo({
  date,
  style,
  format = "",
  showtime = false,
  slashed = false,
  ...props
}) {
  let time = new Date(date ? date : null);
  return (
    <>
      {format ? (
        <Datetitle {...props} style={style}>
          {dateFormat(date, format)}
        </Datetitle>
      ) : slashed ? (
        <Datetitle {...props} style={style}>
          {dateFormat(date, "dd/mm/yy")}
        </Datetitle>
      ) : (
        <Datetitle {...props} style={style}>
          {dateFormat(date, "dddd, mmmm dS yyyy")}
        </Datetitle>
      )}
      {showtime && (
        <Datetitle {...props} style={{ ...style, marginRight: "0" }}>
          {time.toLocaleString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: true,
          })}
        </Datetitle>
      )}
    </>
  );
}

const Datetitle = styled.span`
  color: #030303;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin-right: 12px;
`;
