import Apiservice from "../../services/Apiservice"
import { setAppLoading } from "./appAction"

export function saveCompanyInfo({ companyType, companyName, companyCode, companyPicName, companyEmail, companyPhonePrefix, companyPhone }, callback, errorCallback) {
    return async (dispatch) => {
        dispatch(setAppLoading(true))

        try {
            const resp = await Apiservice.invokePost(Apiservice.url.AUCTION_URL + "/api/secured/company", {
                companyType,
                companyCode,
                companyName,
                companyPicName,
                companyEmail,
                companyPhone: `${companyPhonePrefix}${companyPhone}`,
                companyPhonePrefix
            })

            if (resp.status === 200) {
                callback(resp.data.data)
            } else {
                errorCallback(resp.data.errors)
            }
        } catch (e) {

        } finally {
            dispatch(setAppLoading(false))
        }
    }
}

export function saveCompanyAddress({ countryName,
    provinceCode,
    provinceName,
    cityCode,
    cityName,
    districtCode,
    districtName,
    zipCode,
    addressDetail }, callback, errorCallback) {
    return async (dispatch) => {
        dispatch(setAppLoading(true))

        try {
            const resp = await Apiservice.invokePost(Apiservice.url.AUCTION_URL + "/api/secured/company-address", {
                countryCode: "ID",
                countryName,
                provinceCode,
                provinceName,
                cityCode,
                cityName,
                districtCode,
                districtName,
                zipCode,
                addressDetail
            })

            if (resp.status === 200) {
                callback()
            } else {
                errorCallback(resp.data.errors)
            }
        } catch (e) {

        } finally {
            dispatch(setAppLoading(false))
        }
    }
}

export function saveCompanyLegal({ npwp, idNumber, npwpAttachments, idNumberAttachments }, callback, errorCallback) {
    return async (dispatch) => {
        dispatch(setAppLoading(true))

        try {
            let formData = new FormData()

            formData.append("npwp", npwp)
            formData.append("idNumber", idNumber)

            for (const file of npwpAttachments) {
                formData.append("npwpAttachments", file)
            }
            
            for (const file of idNumberAttachments) {
                formData.append("idNumberAttachments", file)
            }            

            const resp = await Apiservice.invokeMultipartPost(Apiservice.url.AUCTION_URL + "/api/secured/company/legal-info", formData)

            if (resp.status === 200) {
                callback()
            } else {
                errorCallback(resp.data.errors)
            }
        } catch (e) {
            console.log(e)
        } finally {
            dispatch(setAppLoading(false))
        }
    }
}

export function setIsOnboarding(callback, errorCallback) {
    return async (dispatch) => {
        dispatch(setAppLoading(true))

        try {
            const resp = await Apiservice.invokePatch(Apiservice.url.AUCTION_URL + '/api/secured/company/onboarding')

            if (resp.status === 200) {
                callback(resp)
            } else {
                errorCallback(resp.data.errors)
            }
        } catch (e) {
            console.log(e)
        } finally {
            dispatch(setAppLoading(false))
        }
    }
}