import React from "react";
import { Col, Row } from "react-bootstrap";
import { GreyCard } from "../../components/styled/Generalcard";

const InitiationFormHidden = ({ title }) => {
  return (
    <Row>
      <Col className="mb-5">
        <GreyCard>
          <Row>
            <Col className="d-flex justify-content-between align-items-center px-4 mt-3">
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "#454545",
                }}
              >
                {title}
              </p>
            </Col>
          </Row>
        </GreyCard>
      </Col>
    </Row>
  );
};

export default InitiationFormHidden;
