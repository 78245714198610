import React, { useEffect, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { DatePicker, Input } from "rsuite"
import Buttonaction from "../../../../../../../components/Buttonaction"
import { GreyCard } from "../../../../../../../components/styled/Generalcard"
import { getBniNegotiationReport, getGenerateReportNegoLogData } from "../../../../../../../redux/action/reportAction"
import { triggerBase64Download } from "common-base64-downloader-react"
import { getMyProfile } from "../../../../../../../redux/action/accountAction"
import { useDispatch } from "react-redux"
import { format } from "date-fns"
import toast from "react-hot-toast"
import DatePickerInput from "../../../../../../../components/DatePickerInput"

export default function NegotiationReport(props) {
   const { negotiationId, finalNegotiationPrice } = props
   const { procurementId, batchId } = useParams()
   const dispatch = useDispatch()

   const [downloading, setDownloading] = useState(false)
   const [companyId, setCompanyId] = useState()
   const [payload, setPayload] = useState({
      letterNumber: null,
      letterDate: new Date(),
      vendorOfferLetterNumber: null,
      vendorOfferLetterDate: new Date(),
      negotiationMedia: null,
      offlineLocation: "",
   })
   const [dataGenerateReport, setDataGenerateReport] = useState({})

   const getGenerateReportData = () => {
      dispatch(
         getGenerateReportNegoLogData(negotiationId, (res) => {
            res &&
               setDataGenerateReport((prev) => ({
                  ...prev,
                  ...res,
                  letterDate: new Date(res.letterDate),
                  vendorOfferLetterDate: new Date(res.vendorOfferLetterDate),
               }))
         }),
      )
   }
   useEffect(() => {
      dispatch(
         getMyProfile((res) => {
            const companyId = res.account.companyId
            setCompanyId(companyId)
         }),
      )

      getGenerateReportData()
   }, [])

   const handleGenerateReport = () => {
      let _payload
      if (Object.entries(dataGenerateReport).length > 0) {
         _payload = dataGenerateReport
      } else {
         _payload = payload
      }
      console.log("PAYLOAD", { _payload, companyId, batchId, negotiationId, procurementId, finalNegotiationPrice })
      new Promise((resolve, reject) => {
         setDownloading(true)
         dispatch(
            getBniNegotiationReport(
               procurementId,
               companyId,
               batchId,
               negotiationId,
               { ..._payload, finalNegotiationPrice },
               (resp) => {
                  // docx
                  resolve(
                     `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${resp.data}`,
                  )

                  // pdf
                  // resolve(`data:application/pdf;base64,${resp.data}`)
               },
               () => {
                  reject(new Error("Failed to download"))
               },
            ),
         )
      })
         .then((base64) => {
            triggerBase64Download(
               base64,
               "BERITA ACARA KLARIFIKASI DAN NEGOSIASI " + format(new Date(Date.now()), "dd-MMMM-yyyy kk:mm:ss"),
            )
            getGenerateReportData()
         })
         .catch((err) => {
            console.log(err)
            toast.error("Failed to generate & download report")
         })
         .finally(() => {
            setDownloading(false)
         })
   }

   return (
      <GreyCard style={{ padding: "32px 18px", marginTop: 48 }} className="mx-4 mb-5">
         <Row>
            <Col className="d-flex justify-content-between align-items-center px-3">
               <p style={{ fontSize: "18px", fontWeight: "500" }}>Official Report</p>
            </Col>
         </Row>
         <hr style={{ borderColor: "#C4C4C4", marginTop: "18px" }} />
         <Row className="d-flex align-items-center" style={{ marginBottom: 18 }}>
            <Col className="px-3">Letter Number</Col>
            <Col lg={9}>
               {dataGenerateReport.letterNumber ? (
                  <p>{dataGenerateReport.letterNumber}</p>
               ) : (
                  <Form.Control
                     placeholder="01/NEG-02/2021"
                     size="lg"
                     style={{ width: "39%" }}
                     onChange={(e) => setPayload((prev) => ({ ...prev, letterNumber: e.target.value }))}
                  />
               )}
            </Col>
         </Row>
         <Row className="d-flex align-items-center" style={{ marginBottom: 18 }}>
            <Col className="px-3">Letter Date</Col>
            <Col lg={9}>
               {dataGenerateReport.letterDate ? (
                  <p>{format(new Date(dataGenerateReport.letterDate), "dd MMMM yyyy")}</p>
               ) : (
                  <DatePickerInput
                     // format="dd/MM/yyyy HH:mm"
                     placeholder="dd/mm/yyyy"
                     value={payload.letterDate}
                     //   disabledDate={handleDisabledDate}
                     onChange={(v) => setPayload((prev) => ({ ...prev, letterDate: v }))}
                     style={{ width: 260 }}
                  />
               )}
            </Col>
         </Row>
         <Row className="d-flex align-items-center" style={{ marginBottom: 18 }}>
            <Col className="px-3">Vendor Offer Letter Number</Col>
            <Col lg={9}>
               {dataGenerateReport.vendorOfferLetterNumber ? (
                  <p>{dataGenerateReport.vendorOfferLetterNumber}</p>
               ) : (
                  <Form.Control
                     placeholder="01/NEG-02/2021"
                     size="lg"
                     style={{ width: "39%" }}
                     onChange={(e) => setPayload((prev) => ({ ...prev, vendorOfferLetterNumber: e.target.value }))}
                  />
               )}
            </Col>
         </Row>
         <Row className="d-flex align-items-center" style={{ marginBottom: 18 }}>
            <Col className="px-3">Vendor Offer Letter Date</Col>
            <Col lg={9}>
               {dataGenerateReport.vendorOfferLetterDate ? (
                  <p>{format(new Date(dataGenerateReport.letterDate), "dd MMMM yyyy")}</p>
               ) : (
                  <DatePickerInput
                     placeholder="dd/mm/yyyy"
                     // disabledDate={payload}
                     value={payload.vendorOfferLetterDate}
                     onChange={(v) => setPayload((prev) => ({ ...prev, vendorOfferLetterDate: v }))}
                     style={{ width: 260 }}
                  />
               )}
            </Col>
         </Row>
         <Row className="d-flex align-items-center" style={{ marginBottom: 18 }}>
            <Col className="px-3">Negotiation Media</Col>
            <Col lg={9}>
               {dataGenerateReport.negotiationMedia ? (
                  <p>{dataGenerateReport.negotiationMedia}</p>
               ) : (
                  <>
                     <Form.Group className="d-flex mb-0" controlId="negotiationMedia">
                        <Form.Check
                           type="radio"
                           name="media"
                           id="online"
                           label="Online"
                           value="online"
                           className="mr-3"
                           onClick={(v) =>
                              setPayload((prev) => ({ ...prev, negotiationMedia: v.target.value, offlineLocation: "" }))
                           }
                        />
                        <Form.Check
                           type="radio"
                           name="media"
                           id="offline"
                           label="Offline"
                           value="offline"
                           onClick={(v) => setPayload((prev) => ({ ...prev, negotiationMedia: v.target.value }))}
                        />
                     </Form.Group>
                  </>
               )}
            </Col>
         </Row>
         <Row
            className={`d-flex ${
               dataGenerateReport.negotiationMedia === "offline" ? "align-items-start" : "align-items-center"
            }`}
            style={{ marginBottom: 18 }}
         >
            <Col className="px-3">Offline Location</Col>
            <Col lg={9}>
               {dataGenerateReport.negotiationMedia === "online" ? (
                  <p>-</p>
               ) : dataGenerateReport.negotiationMedia === "offline" ? (
                  <p style={{ width: "39%" }}>{dataGenerateReport.offlineLocation}</p>
               ) : (
                  <Form.Control
                     as="textarea"
                     type="text"
                     value={payload.offlineLocation}
                     size="lg"
                     style={{ width: "39%", fontSize: "12px" }}
                     disabled={payload.negotiationMedia === "online"}
                     onChange={(e) => setPayload((prev) => ({ ...prev, offlineLocation: e.target.value }))}
                  />
               )}
            </Col>
         </Row>
         <Row className="d-flex align-items-center">
            <Col className="px-3">Official Report</Col>
            <Col lg={9}>
               {!Object.entries(dataGenerateReport).length > 0 ? (
                  <Buttonaction
                     style={{ width: "39%" }}
                     disabled={
                        !payload.letterDate ||
                        !payload.letterNumber ||
                        !payload.vendorOfferLetterNumber ||
                        !payload.vendorOfferLetterDate ||
                        !payload.negotiationMedia ||
                        (payload.negotiationMedia == "offline" && !payload.offlineLocation) ||
                        downloading
                     }
                     blueOcean
                     label="Generate & Download Report"
                     loadingLabel="Generating Report..."
                     loading={downloading}
                     onClick={handleGenerateReport}
                  />
               ) : (
                  <Buttonaction
                     style={{ width: "39%" }}
                     blueOcean
                     label="Generate & Download Report"
                     loadingLabel="Generating Report..."
                     loading={downloading}
                     onClick={handleGenerateReport}
                  />
               )}
            </Col>
         </Row>
         <Row className="d-flex align-items-center mt-1">
            <Col className="px-3"></Col>
            <Col lg={9}>
               <span style={{ fontSize: 12, color: "#8F8F8F" }}>
                  This files include Official Report ( Berita Acara )
               </span>
            </Col>
         </Row>
      </GreyCard>
   )
}
