import { faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from "styled-components"

export function Editlink({ children, ...rest }) {
    return (
        <Style href="javascript:void();" {...rest}>{children} &nbsp;<FontAwesomeIcon icon={faPencilAlt} /></Style>
    )
}

const Style = styled.a`
    color: #0772B6;
    font-size: 12px;
`
