import React, { useMemo } from "react";
import { useDropzone } from "react-dropzone";
import TrashIcon from "../assets/images/TrashIcon.svg";
import CloudIcon from "../assets/images/CloudUpload.svg";
import styled from "styled-components";
import Buttonaction from "./Buttonaction";

export default function Dropzone({ onDrop, maxFiles, acceptedFiles, children }) {
  const {
    isDragActive,
    isDragAccept,
    isDragReject,
    fileRejections,
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: acceptedFiles ? acceptedFiles : "image/jpeg, image/png, application/pdf",
    onDrop: onDrop,
    maxFiles: maxFiles
  });

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 1,
    borderRadius: 5,
    borderColor: "#0722B6",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    fontSize: "11.41px",
    cursor: "pointer",
  };

  const activeStyle = {
    borderColor: "#2196f3",
  };

  const acceptStyle = {
    borderColor: "#0772B6",
  };

  const rejectStyle = {
    borderColor: "#ff1744",
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <>
      <div {...getRootProps({ className: "dropzone" })} style={style}>
        <input data-npwp={true} {...getInputProps()} />
        <input type="hidden" id="type" value="npwp" />
        <div
          className="d-flex align-items-center"
          style={{ columnGap: "20px" }}
        >
          <img src={CloudIcon} />
          <p className="text-center" style={{ color: "#707070" }}>
            Drag and drop file to upload or
          </p>
          <BrowseButton onClick={(e) => e.preventDefault()}>Browse File</BrowseButton>
        </div>
      </div>
      {children}
    </>
  );
}

const BrowseButton = styled.button`
  background-color: #0772b6;
  border-radius: 4px;
  color: #ffffff;
  height: 24px;
  width: 106.76px;
  transition: all 0.3s ease;

  &:hover {
    background-color: #ffffff;
    border: 1px solid #0772b6;
    color: #0772b6;
  }
`;
