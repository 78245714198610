import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"
import { connect, useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { Dropdown, Nav, Sidenav } from "rsuite"
import styled, { keyframes } from "styled-components"
import DashboardIcon1 from "../assets/images/SquaresFourGrey.svg"
import DashboardIcon2 from "../assets/images/SquaresFourWhite.svg"
import AuctionBanner from "../assets/images/AuctionBanner.png"
import AuctionLogo from "../assets/images/AuctionLogo.png"
import ProcurementIcon1 from "../assets/images/FileSearchGrey.svg"
import ProcurementIcon2 from "../assets/images/FileSearchWhite.svg"
import { expandSidemenu } from "../redux/action/appAction"
import Constant from "../../constant"

function Sidemenu({ dataProfile, isSidemenuExpanded, menuList = [] }) {
   const dispatch = useDispatch()

   const handleMinimizerClicked = () => dispatch(expandSidemenu())

   return (
      <ContainerStyle
         style={{
            width: isSidemenuExpanded ? 200 : 56,
            transition: "ease-in-out .3s all",
         }}
      >
         <Sidenav expanded={isSidemenuExpanded}>
            <Sidenav.Header>
               {!isSidemenuExpanded ? (
                  <div style={{ marginLeft: "-10px" }}>
                     <LogoMinimizedStyle src={AuctionLogo} />
                  </div>
               ) : (
                  <ExpandedLogoContainer>
                     <LogoStyle src={AuctionBanner} />
                  </ExpandedLogoContainer>
               )}
               <MinimizerStyle
                  className={`d-flex align-items-center justify-content-center ${
                     !isSidemenuExpanded ? "minimized" : ""
                  }`}
                  onClick={handleMinimizerClicked}
               >
                  <FontAwesomeIcon
                     style={!isSidemenuExpanded ? { marginRight: "-2px" } : { marginLeft: "-2px" }}
                     icon={!isSidemenuExpanded ? faChevronRight : faChevronLeft}
                  />
               </MinimizerStyle>
            </Sidenav.Header>
            <Sidenav.Body>
               <SidemenuItem dataProfile={dataProfile} />
            </Sidenav.Body>
         </Sidenav>
      </ContainerStyle>
   )
}

function SidemenuItem({ dataProfile }) {
   const history = useHistory()
   const [activePage, setActivePage] = useState("")

   return (
      <>
         <Nav>
            {dataProfile?.account?.role !== Constant.ROLE.SUPER_ADMIN && (
               <>
                  <SidemenuItemNav
                     onHandleClick={() => {
                        history.push("/dashboard")
                     }}
                     logos={[DashboardIcon1, DashboardIcon2]}
                     title="Dashboard"
                     activePage={activePage}
                     setActivePage={setActivePage}
                  >
                     Dashboard
                  </SidemenuItemNav>
                  <SidemenuItemNav
                     onHandleClick={() => {
                        history.push("/initiation")
                     }}
                     logos={[ProcurementIcon1, ProcurementIcon2]}
                     title="Initiation"
                     activePage={activePage}
                     setActivePage={setActivePage}
                  >
                     Initiation
                  </SidemenuItemNav>
               </>
            )}
            {dataProfile?.account?.role === Constant.ROLE.SUPER_ADMIN && (
               <NavDropdownStyle
                  eventKey="3"
                  title="Master"
                  icon={
                     <IconStyle>
                        <img src={DashboardIcon1} />
                     </IconStyle>
                  }
               >
                  <Dropdown.Item eventKey="3-1">Auction Models</Dropdown.Item>
                  <Dropdown.Item eventKey="3-1">Auction Types</Dropdown.Item>
                  <Dropdown.Item eventKey="3-1">Bidding Types</Dropdown.Item>
                  <Dropdown.Item eventKey="3-3">Currencies</Dropdown.Item>
                  <Dropdown.Item eventKey="3-2">Currency Types</Dropdown.Item>
                  <Dropdown.Item eventKey="3-2">Evaluation Methods</Dropdown.Item>
                  <Dropdown.Item eventKey="3-2">Negotiation Types</Dropdown.Item>
                  <Dropdown.Item eventKey="3-2">Negotiation Medias</Dropdown.Item>
                  <Dropdown.Item eventKey="3-2">Period Types</Dropdown.Item>
                  <Dropdown.Item eventKey="3-2">Procurement Bidding Types</Dropdown.Item>
                  <Dropdown.Item eventKey="3-2">Winner Types</Dropdown.Item>
               </NavDropdownStyle>
            )}
         </Nav>
      </>
   )
}

function SidemenuItemNav({ logos, title, children, onHandleClick, activePage, setActivePage, ...rest }) {
   const { isSidemenuExpanded } = useSelector((state) => state.appReducer)
   const [hovered, setHovered] = useState(false)

   const handleClick = (title) => {
      onHandleClick(), setActivePage(title)
   }

   return (
      <div
         onClick={() => handleClick(title)}
         onMouseEnter={() => setHovered(true)}
         onMouseLeave={() => setHovered(false)}
      >
         <NavItemStyle
            {...rest}
            className={isSidemenuExpanded ? "" : "minimized"}
            activePage={activePage}
            title={title}
            icon={
               <IconStyle>
                  <img
                     src={
                        location.pathname.split("/").includes(title.toLowerCase()) ||
                        activePage.toLowerCase() === title.toLowerCase() ||
                        hovered
                           ? logos[1]
                           : logos[0]
                     }
                  />
               </IconStyle>
            }
         >
            <span>{children}</span>
         </NavItemStyle>
      </div>
   )
}

const mapStateToProps = (state) => {
   return {
      dataProfile: state.accountReducer.dataProfile,
      isSidemenuExpanded: state.appReducer.isSidemenuExpanded,
   }
}

export default connect(mapStateToProps)(Sidemenu)

const animateLogo = keyframes`
  0% { width: 0px };
  70% { width: 20px }
  100% { width: 200px }
`

// Styled component
const ContainerStyle = styled.div`
   background-color: #f3f5f4;
   min-height: 100vh;
   align-items: flex-start;
   position: fixed;
`
const LogoStyle = styled.img`
   width: 100%;
   height: 95px;
   padding: 2rem;
   border-bottom: 2px solid #04446c;
   margin-bottom: 22px;
   animation-name: ${animateLogo};
   animation-duration: 0.3s;
   animation-fill-mode: forwards;
`

const LogoMinimizedStyle = styled.img`
   padding: 2rem;
   border-bottom: 2px solid #04446c;
   margin-bottom: 22px;
   position: relative;
   right: 10px;
`

const MinimizerStyle = styled.a`
   position: absolute;
   top: 82px;
   border: 2px solid #04446c;
   border-radius: 50%;
   width: 25px;
   height: 25px;
   text-align: center;
   right: -11px;
   background-color: #f3f5f4;
   cursor: pointer;

   svg {
      font-size: 13px;
      position: relative;
      color: #04446c;
   }

   &.minimized {
      right: -18px;
      top: 83px;
   }
`

const IconStyle = styled.div`
   padding: 5px;
   margin-right: 10px;
   display: flex;
   align-items: center;
`
const NavItemStyle = styled(Nav.Item)`
   padding-left: 24px !important;
   display: flex !important;
   align-items: center;
   // justify-content: flex-start !important;
   background: ${({ activePage, title }) =>
      location.pathname.split("/").includes(title.toLowerCase()) || activePage.toLowerCase() === title.toLowerCase()
         ? "#04446c !important"
         : ""};

   span {
      overflow: hidden;
      width: 100%;
      transition: ease-in-out 0.3s all;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      color: ${({ activePage, title }) =>
         location.pathname.split("/").includes(title.toLowerCase()) || activePage.toLowerCase() === title.toLowerCase()
            ? "white !important"
            : "#909090"};
   }

   img {
      position: relative;
      right: 0;
   }

   &:hover {
      background-color: #04446c !important;

      span {
         color: white;
      }
   }

   &.minimized {
      /* position: relative;
        right: 10px; */
      img {
         position: relative;
         right: 10px;
      }

      span {
         // display: none;
         width: 0%;
      }
   }
`

const ExpandedLogoContainer = styled.div`
   width: 200px;
   height: 100%;
`

const NavDropdownStyle = styled(Dropdown)`
   button {
      display: flex !important;
      padding-left: 23px !important;
      font-family: Roboto !important;
      font-style: normal !important;
      font-weight: bolder !important;
      font-size: 14px !important;
      color: #909090 !important;
      align-items: center;

      &:hover {
         background-color: #04446c !important;
         color: white !important;
      }
   }
`
