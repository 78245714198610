import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useDispatch } from "react-redux"
import Statuslabel from "../../components/Statuslabel"
import VendorAuctionStyles from "./VendorAuctionStyles"

function VendorAuctionMeritPoint({
   accountId,
   dataAuction,
   dataProcurement,
   participant = [],
   meritPointData,
   vendorAdministrationPassed,
}) {
   return (
      <VendorAuctionStyles.MeritPointWrapper>
         <VendorAuctionStyles.SectionTitle>Merit Point</VendorAuctionStyles.SectionTitle>
         <VendorAuctionStyles.Divider />

         <Row style={{ fontSize: "12px" }}>
            <Col lg={7} sm={6}>
               Administration Score
               <span>{` (${dataProcurement?.evaluationScoring.administrationValue})`}</span>%
            </Col>
            <Col lg={5} sm={6}>
               {vendorAdministrationPassed && vendorAdministrationPassed ? (
                  <Statuslabel label={"Passed"} status={"PASSED"} className="mx-0" />
               ) : (
                  <Statuslabel label={"Not Passed"} status={"NOT_PASSED"} className="mx-0" />
               )}
            </Col>
         </Row>
         <Row className="mt-3" style={{ fontSize: "12px" }}>
            <Col lg={7} sm={6}>
               Technical Score
               <span>{` (${dataProcurement?.evaluationScoring.technicalValue})`}</span>%
            </Col>
            <Col lg={5} sm={6}>
               {meritPointData.technicalScore && meritPointData.technicalScore.toFixed(2)}
            </Col>
         </Row>
         <Row className="mt-3" style={{ fontSize: "12px" }}>
            <Col lg={7} sm={6}>
               Price Score
               <span>{` (${dataProcurement?.evaluationScoring.priceValue})`}</span>%
            </Col>
            <Col lg={5} sm={6}>
               {meritPointData.priceScore && meritPointData.priceScore.toFixed(2)}
            </Col>
         </Row>
      </VendorAuctionStyles.MeritPointWrapper>
   )
}

export default VendorAuctionMeritPoint
