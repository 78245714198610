import React, { useEffect, useRef, useState } from "react"
import { Checkbox, Loader } from "rsuite"
import { toast } from "react-hot-toast"
import { Col, Row } from "react-bootstrap"
import { useParams } from "react-router-dom"
import { orderBy as _orderBy } from "lodash"
import { connect, useDispatch } from "react-redux"
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons"

// Assets
import VendorIcon from "../../../assets/images/Users.svg"
import TrashIcon from "../../../assets/images/Trash.svg"

// Components
import Datatable from "../../../components/Datatable"
import Submitmodal from "../../../components/Submitmodal"
import InitiationVendorform from "./InitiationVendorform"
import Buttonaction from "../../../components/Buttonaction"
import { GreyCard } from "../../../components/styled/Generalcard"
import { AnnouncementInfo } from "../../../components/Announcement"

// Redux
import {
   deleteProcurementVendor,
   fetchProcurementVendors,
   setProcurementVendorsState,
} from "../../../redux/action/procurementAction"

// MUI Library
import MuiDataTable from "../../../components/MuiDataTable"
import {
   TableHead,
   TableBody,
   TableRow,
   TableCell,
   Checkbox as MuiCheckbox,
   TableSortLabel,
   TablePagination,
} from "@mui/material"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import Constant from "../../../../constant"

function InitiationVendorList({
   dataProcurement,
   // dataProcurementVendors,
   isAppLoading,
   readOnly = false,
   passedInRef,
   handleExpand,
   getTotalVendor,
   isMeritPoint,
   totalItem,
}) {
   const dispatch = useDispatch()
   const { procurementId } = useParams()

   // Component state
   const [dataState, setDataState] = useState({
      page: 0,
      limit: 5,
      query: "",
      selectedRows: [],
   })
   const [dataProcurementVendors, setDataProcurementVendors] = useState({
      filteredData: [],
      total: 0,
   })
   const [isLoading, setIsLoading] = useState(false)

   // Component Refs
   const modalVendorRef = useRef()
   const modalSubmitDeleteVendor = useRef()
   const modalSuccessDelete = useRef()

   const viewDetail = async (row) => {
      modalVendorRef.current.show({
         opMode: readOnly ? "viewNoSave" : "view",
         procurementVendorId: row._id,
      })
   }

   useEffect(() => {
      new Promise((resolve, reject) => {
         setIsLoading(true)
         dispatch(
            fetchProcurementVendors(
               {
                  procurementId,
                  page: 0,
                  limit: dataState.limit,
               },
               (res) => {
                  resolve(res)
               },
               (err) => {
                  reject(err)
               },
            ),
         )
      })
         .then((res) => {
            setDataProcurementVendors(res)
            createVendorsChecked(res.filteredData)
         })
         .catch(() => {})
         .finally(() => {
            setIsLoading(false)
         })
   }, [])

   useEffect(() => {
      if (getTotalVendor && dataProcurementVendors) {
         getTotalVendor(dataProcurementVendors.total)
      }
   }, [dataProcurementVendors])

   /**
    * Table handling
    * ----------------
    */
   const tableColumns = ["Company Name", "Email", "Phone", "City", "PIC Vendor"]

   // Table state
   const [order, setOrder] = React.useState("asc")
   const [orderBy, setOrderBy] = React.useState("")
   const [allChecked, setAllChecked] = useState(false)
   const [vendorsChecked, setVendorsChecked] = useState([])
   const [indeterminate, setIndeterminate] = useState(false)
   const [emptySearchResult, setEmptySearchResult] = useState(false)
   const [selectedVendors, setSelectedVendors] = useState({
      procurementId: procurementId,
      procurementVendorIds: [],
   })

   const handleRequestSort = (column) => {
      const _order = order === "asc" ? "desc" : "asc"
      setOrderBy(column)
      setOrder(_order)

      const forColumn =
         column === "Company Name"
            ? "name"
            : column === "Email"
            ? "email"
            : column === "Phone"
            ? "phone"
            : column === "City"
            ? "city"
            : column === "PIC Vendor" && "picVendor"

      let result = []
      result = _orderBy(dataProcurementVendors.filteredData, [forColumn], [_order])

      setDataProcurementVendors({
         ...dataProcurementVendors,
         filteredData: result,
      })
   }

   const handleRowsPerPageChange = (v, { props }) => {
      const { value } = props
      dispatch(
         fetchProcurementVendors(
            {
               ...dataState,
               procurementId,
               limit: value,
            },
            (res) => {
               setDataProcurementVendors(res)
               setDataState({
                  ...dataState,
                  limit: value,
               })
               createItemsChecked(res.filteredData)
               setAllDefault()
            },
         ),
      )
   }

   const handlePageChange = (v, newPage) => {
      dispatch(
         fetchProcurementVendors(
            {
               ...dataState,
               procurementId,
               page: newPage + 1,
            },
            (res) => {
               setDataProcurementVendors(res)
               setDataState({ ...dataState, page: newPage })
               createItemsChecked(res.filteredData)
               setAllDefault()
            },
         ),
      )
   }

   const createVendorsChecked = (items) => {
      let vendorsChecked = []

      items.map((item) => {
         const hasStatus = item.status
         if (hasStatus) {
            item.status !== "DRAFT" && vendorsChecked.push({ checked: false, id: item._id })
         } else {
            vendorsChecked.push({ checked: false, id: item._id })
         }
      })

      setVendorsChecked(vendorsChecked)
   }

   const findIndeterminate = () => {
      const itemSelected = selectedVendors.procurementVendorIds.length
      const totalItemInView = dataProcurementVendors.filteredData.length
      if (itemSelected != 0 && itemSelected < totalItemInView) {
         setIndeterminate(true)
      } else {
         setIndeterminate(false)
      }
   }

   // Always run when select / unselect vendor
   useEffect(() => {
      findIndeterminate()
   }, [selectedVendors])

   const handleCheckAll = (e) => {
      const isChecked = e.target.checked
      if (isChecked) {
         const itemSelectedIds = dataProcurementVendors.filteredData.map((vendor) => {
            return vendor._id
         })
         setSelectedVendors({ ...selectedVendors, procurementVendorIds: itemSelectedIds })
      } else {
         setSelectedVendors({ ...selectedVendors, procurementVendorIds: [] })
      }
   }

   const handleSelectVendor = (v) => {
      const checked = v.target.checked

      if (checked) {
         const isSelected = selectedVendors.procurementVendorIds.includes(v.target.value)
         if (!isSelected) {
            setSelectedVendors({
               ...selectedVendors,
               procurementVendorIds: [...selectedVendors.procurementVendorIds, v.target.value],
            })
         }
      }

      if (!checked) {
         const newSelectedVendors = selectedVendors.procurementVendorIds.filter((id) => id != v.target.value)
         setSelectedVendors({
            ...selectedVendors,
            procurementVendorIds: [...newSelectedVendors],
         })
      }
   }

   const search = () => {
      setAllDefault()
      new Promise((resolve, reject) => {
         setIsLoading(true)
         dispatch(
            fetchProcurementVendors(
               { ...dataState, procurementId },
               (res) => {
                  resolve(res)
               },
               (err) => {
                  reject(err)
               },
            ),
         )
      })
         .then((res) => {
            res.total === 0
               ? (setEmptySearchResult(true), createVendorsChecked(res.filteredData), setDataProcurementVendors(res))
               : setDataProcurementVendors(res),
               createVendorsChecked(res.filteredData)
         })
         .catch(() => {})
         .finally(() => {
            setIsLoading(false)
         })
   }

   const handleRemoveVendor = () => {
      if (selectedVendors.procurementVendorIds.length > 0) {
         modalSubmitDeleteVendor.current.show({
            modalTitle: "",
            title: "Delete Vendor?",
            description: (
               <p>
                  Do you want to <span style={{ color: "#0772B6", fontWeight: 500 }}>Delete</span> the selected
                  vendor(s)?
               </p>
            ),
         })
      } else {
         toast.error("No Vendors Selected")
      }
   }

   const removeProcurementVendor = () => {
      dispatch(
         deleteProcurementVendor(
            selectedVendors,
            () => {
               dispatch(
                  fetchProcurementVendors({ ...dataState, procurementId }, (res) => {
                     res.filteredData.length < 1
                        ? (createVendorsChecked(res.filteredData),
                          setEmptySearchResult(false),
                          setDataProcurementVendors(res))
                        : setDataProcurementVendors(res)

                     setAllDefault()
                  }),
               )

               modalSuccessDelete.current.show({
                  modalTitle: "",
                  title: "Vendor has been deleted",
                  description: <p>Selected vendor has been deleted</p>,
               })
               // wait 2 seconds then close the modal
               setTimeout(() => {
                  modalSuccessDelete.current.hide()
               }, 2500)
            },
            () => {
               modalSuccessDelete.current.show({
                  modalTitle: "",
                  title: "Delete failed",
                  description: <p>Selected vendor failed to be deleted</p>,
               })
               // wait 2 seconds then close the modal
               setTimeout(() => {
                  modalSuccessDelete.current.hide()
               }, 2500)
            },
         ),
      )

      modalSubmitDeleteVendor.current.hide()
   }

   const setAllDefault = () => {
      setAllChecked(false)
      setIndeterminate(false)
      setSelectedVendors({ ...selectedVendors, procurementVendorIds: [] })
   }
   /**
    * end
    * ----------------
    */

   return (
      <div ref={passedInRef} id="vendor" onMouseEnter={() => handleExpand({ expand: "vendor" })}>
         <Row>
            <Col className="mb-5">
               <GreyCard>
                  <Row>
                     <Col className="d-flex justify-content-between align-items-center px-4 mt-3">
                        <p
                           style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              color: "#454545",
                           }}
                        >
                           Vendor List
                        </p>
                        {!readOnly && (
                           <Buttonaction
                              disabled={isMeritPoint && totalItem < 1}
                              blueOcean
                              label="Add Vendor"
                              faIcon={faPlusCircle}
                              onClick={() =>
                                 modalVendorRef.current.show({
                                    opMode: "edit",
                                    procurementId: undefined,
                                 })
                              }
                           />
                        )}
                     </Col>
                  </Row>
                  <hr style={{ background: "#C4C4C4" }} />

                  {dataProcurement?.status !== "OPEN" &&
                     (dataProcurement.negotiationType === Constant.NEGOTIATION_TYPE.NEGOTIATION ? (
                        <>
                           <AnnouncementInfo
                              withBody={dataProcurementVendors.totalProcurementVendors == 0}
                              text="Invite a minimum of one vendor to submit this procurement"
                              icon={VendorIcon}
                              textUnderIcon={
                                 <>
                                    No list available,
                                    <br />
                                    Let’s try to add one!
                                 </>
                              }
                           />
                        </>
                     ) : (
                        <>
                           <AnnouncementInfo
                              withBody={dataProcurementVendors.totalProcurementVendors == 0}
                              text="Invite a minimum of two vendors to submit this procurement"
                              icon={VendorIcon}
                              textUnderIcon={
                                 <>
                                    No list available,
                                    <br />
                                    Let’s try to add one!
                                 </>
                              }
                           />
                        </>
                     ))}

                  {dataProcurementVendors.totalProcurementVendors > 0 && (
                     <MuiDataTable.CustomTable
                        showHeader={true}
                        headerComponent={
                           <MuiDataTable.Filter
                              withSearchBar={true}
                              handleSearch={(v) => setDataState({ ...dataState, query: v.target.value })}
                              onKeyUp={(v) => v.keyCode === 13 && search()}
                           />
                        }
                        itemsSelectedComponent={
                           !readOnly ? (
                              <MuiDataTable.ItemsSelected
                                 showDeleteBtn={selectedVendors.procurementVendorIds.length > 0}
                                 selectedItems={selectedVendors.procurementVendorIds.length}
                                 onClickDelete={handleRemoveVendor}
                              />
                           ) : (
                              <></>
                           )
                        }
                        footerComponent={
                           <TablePagination
                              sx={{ background: "#ffffff", borderBottom: "none" }}
                              page={dataState.page}
                              count={dataProcurementVendors.total}
                              rowsPerPage={dataState.limit}
                              rowsPerPageOptions={[1, 2, 5, 10, 15, 20, { label: "All", value: -1 }]}
                              onPageChange={handlePageChange}
                              onRowsPerPageChange={handleRowsPerPageChange}
                              // ActionsComponent={TablePaginationActions}
                              className="d-flex justify-content-end"
                              // sx={{ width: "100%", display: "flex" }}
                           />
                        }
                     >
                        <TableHead>
                           <TableRow>
                              {!readOnly && (
                                 <TableCell size="small">
                                    <MuiCheckbox
                                       className="p-0"
                                       size="small"
                                       checked={
                                          selectedVendors.procurementVendorIds.length ==
                                          dataProcurementVendors.filteredData.length
                                       }
                                       indeterminate={indeterminate}
                                       onChange={handleCheckAll}
                                    />
                                 </TableCell>
                              )}
                              {tableColumns.map((column, index) => (
                                 <TableCell key={index} className="py-2">
                                    <TableSortLabel
                                       active={orderBy === column}
                                       direction={order}
                                       onClick={() => handleRequestSort(column)}
                                       IconComponent={ExpandLessIcon}
                                    >
                                       {column}
                                    </TableSortLabel>
                                 </TableCell>
                              ))}
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {isLoading && (
                              <TableRow sx={{ background: "#F3F5F4" }}>
                                 <TableCell
                                    colSpan={
                                       dataProcurement.status == Constant.procurementStatus.DRAFT
                                          ? tableColumns.length + 1
                                          : tableColumns.length
                                    }
                                    align="center"
                                    sx={{ fontWeight: 500 }}
                                 >
                                    <Loader speed="fast" content="Getting vendors..." />
                                 </TableCell>
                              </TableRow>
                           )}
                           {dataProcurementVendors.filteredData.length > 0 && !isLoading ? (
                              // && vendorsChecked.length > 0
                              dataProcurementVendors.filteredData.map((vendor, index) => (
                                 <TableRow
                                    key={index}
                                    sx={{
                                       background: "#F3F5F4",
                                       "&:hover": {
                                          background: "transparent",
                                          cursor: "pointer",
                                       },
                                    }}
                                    onClick={(v) => {
                                       v.target.type !== "checkbox" && viewDetail(vendor)
                                    }}
                                 >
                                    {!readOnly && (
                                       <TableCell size="small">
                                          <MuiCheckbox
                                             className="px-0 py-2"
                                             size="small"
                                             onChange={handleSelectVendor}
                                             value={vendor._id}
                                             //  checked={vendorsChecked[index] && vendorsChecked[index].checked}
                                             checked={selectedVendors.procurementVendorIds.includes(vendor._id)}
                                          />
                                       </TableCell>
                                    )}
                                    <TableCell sx={{ minWidth: "200px" }} className="py-2">
                                       {vendor.name}
                                    </TableCell>
                                    <TableCell sx={{ minWidth: "150px" }} className="py-2">
                                       {vendor.email}
                                    </TableCell>
                                    <TableCell className="py-2">{vendor.phone}</TableCell>
                                    <TableCell className="py-2">{vendor.city}</TableCell>
                                    <TableCell className="py-2">{vendor.picVendor}</TableCell>
                                 </TableRow>
                              ))
                           ) : !isLoading ? (
                              <TableCell colSpan={9} align="center" sx={{ fontWeight: 500, background: "#F3F5F4" }}>
                                 No records to display
                              </TableCell>
                           ) : (
                              <></>
                           )}
                        </TableBody>
                     </MuiDataTable.CustomTable>
                  )}
               </GreyCard>
            </Col>
         </Row>

         <InitiationVendorform
            passedInRef={modalVendorRef}
            dataState={dataState}
            createVendorsChecked={createVendorsChecked}
            setDataProcurementVendors={setDataProcurementVendors}
         />

         <Submitmodal.ModalContainer passedInRef={modalSubmitDeleteVendor} icon={TrashIcon}>
            <Submitmodal.ModalButtonActions>
               <div className="mt-4 d-flex justify-content-end">
                  <div className="mr-3">
                     <Buttonaction
                        borderblueOcean
                        type="button"
                        label="No"
                        large
                        onClick={() => modalSubmitDeleteVendor.current.hide()}
                     />
                  </div>
                  <Buttonaction blueOcean type="button" label="Yes" large onClick={removeProcurementVendor} />
               </div>
            </Submitmodal.ModalButtonActions>
         </Submitmodal.ModalContainer>

         {/* Success delete modal */}
         <Submitmodal.ModalContainer passedInRef={modalSuccessDelete} icon={TrashIcon} withCloseButton={false} />
      </div>
   )
}

const mapStateToProps = (state) => {
   return {
      dataProcurement: state.procurementReducer.dataProcurement,
      // dataProcurementVendors: state.procurementReducer.dataProcurementVendors,
      isAppLoading: state.appReducer.isAppLoading,
   }
}

export default connect(mapStateToProps)(InitiationVendorList)
