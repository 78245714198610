import React from 'react'
import styled from 'styled-components'
import Dateinfo from './Dateinfo'

export default function ChatItem({ username, offer, createdAt }) {
    return (
        <WrapperStyle>
            <NameStyle>{username}<span style={{marginLeft: 5}}><Dateinfo date={createdAt} /></span></NameStyle>
            <OfferStyle>
                <span>{offer}</span>
            </OfferStyle>
        </WrapperStyle>
    )
}

const WrapperStyle = styled.div`
    margin-top: 10px;
    display: block;
`

const NameStyle = styled.span`
    font-size: 14px;
    font-weight: bolder;
    position: relative;
    display: block;
`

const OfferStyle = styled.div`
    font-size: 14px;
    font-weight: bolder;
    display: block;
    padding: 10px 0;
    margin-top: 5px;

    span {
        padding: 10px;
        background-color: aliceblue;
        border-radius: 24px;
    }
`

