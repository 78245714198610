import React, { useEffect, useState } from "react"

export default function ButtonTimer({ onClick, countdown = 10, waitingText = "Available in", children }) {
    const [timer, setTimer] = useState(0)

    const handleClick = (e) => {
        e.preventDefault()
        onClick()

        let seconds = countdown
        setTimer(seconds)
        let timerInterval = setInterval(() => {
            if (seconds <= 0) {
                return clearInterval(timerInterval)
            } else {
                seconds--
                setTimer(seconds)
            }

        }, 1000)
    }

    useEffect(() => {

    }, [timer])

    return (
        <>
            {timer <= 0 && <a href="" onClick={handleClick}>
                {children}
            </a>
                ||
                <>
                    <span>{waitingText} {timer}</span>
                </>}
        </>
    )
}