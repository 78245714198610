import React from 'react'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { useImperativeHandle } from 'react'
import { useState } from 'react'
import { prefix } from '@fortawesome/free-solid-svg-icons'

const defaultMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 15, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
  keepCharPositions: true
}

const CurrencyInput = ({ passedInRef, onValueChange, onComponentBlur, maskOptions, ...inputProps }) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  })

  const [realValue, setRealValue] = useState(undefined)

  const handleChange = (e) => {
    if (inputProps.prefix) {
      const a = e.target.value.split(" ")[1]
      const b = new String(a).replaceAll(defaultMaskOptions.thousandsSeparatorSymbol, "")
      setRealValue(b)
      if (onValueChange) {
        onValueChange(b)
      }
    } else {
      const b = new String(e.target.value).replaceAll(defaultMaskOptions.thousandsSeparatorSymbol, "")
      setRealValue(b)
      if (onValueChange) {
        onValueChange(b)
      }
    }
    
  }
  
  const handleBlur = (e) => {
    onComponentBlur(e)
  }

  useImperativeHandle(passedInRef, () => ({

    getValue: () => {
      return realValue
    }

  }));

  return <MaskedInput style={{zIndex: 1}} onChange={handleChange} onBlur={onComponentBlur ? handleBlur : null} mask={currencyMask} {...inputProps} />
}

CurrencyInput.defaultProps = {
  inputMode: 'numeric',
  maskOptions: {},
}

CurrencyInput.propTypes = {
  inputmode: PropTypes.string,
  maskOptions: PropTypes.shape({
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    includeThousandsSeparator: PropTypes.boolean,
    thousandsSeparatorSymbol: PropTypes.string,
    allowDecimal: PropTypes.boolean,
    decimalSymbol: PropTypes.string,
    decimalLimit: PropTypes.string,
    requireDecimal: PropTypes.boolean,
    allowNegative: PropTypes.boolean,
    allowLeadingZeroes: PropTypes.boolean,
    integerLimit: PropTypes.number,
  }),
}

export default CurrencyInput