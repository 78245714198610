import React, { createContext, useContext } from "react"
import InfoGraySmall from "../assets/images/InfoGraySmall.svg"
import InfoRedSmall from "../assets/images/InfoRedSmall.svg"

const InputAlertContext = createContext()

function Container({ type = "light", children }) {
   return <InputAlertContext.Provider value={{ type }}>{children}</InputAlertContext.Provider>
}

function Icon({ style, children }) {
   const { type } = useContext(InputAlertContext)
   return (
      <>
         <img src={type === "danger" ? InfoRedSmall : InfoGraySmall} style={{ ...style }} />
         {children}
      </>
   )
}

function Text({ text, style, children }) {
   const { type } = useContext(InputAlertContext)
   return (
      <p
         style={{
            fontSize: ".6rem",
            color: `${type === "danger" ? "#F44437" : "#8F8F8F"}`,
            marginLeft: ".6rem",
            // lineHeight: ".9rem",
            ...style,
         }}
      >
         {children || text}
      </p>
   )
}

const InputAlert = {
   Container,
   Icon,
   Text,
}

export default InputAlert
