import React, { useEffect, useRef, useState } from "react"
import Constant from "../../../constant"
import { useDispatch } from "react-redux"
import MuiDataTable from "../../components/MuiDataTable"
import { TableBody, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material"
import NegotiationOfferService from "../../services/NegotiationOfferService"
import NegotiationOfferItemService from "../../services/NegotiationOfferItemService"
import CurrencyFormat from "react-currency-format"
import moment from "moment"
import { io } from "socket.io-client"
import { orderBy as _orderBy } from "lodash"
import parse, { domToReact } from "html-react-parser"
import styles from "../../styles/NegotiationOffer.module.css"
import NegotiationOfferDetail from "./negotiation-offer-detail.module"
import NegotiationService from "../../services/NegotiationService"
import { GreyCard } from "../../components/styled/Generalcard"
import DocumentLarge from "../../assets/images/document-lg.svg"
import { Col, Row } from "react-bootstrap"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { getProcurement, getProcurementPublic } from "../../redux/action/procurementAction"
import Formservice from "../../services/Formservice"
import ModalForm from "../../components/Modalform"

const NegotiationOfferAsListModule = (params) => {
   const { negotiationId, username, _setOffers } = params
   const dispatch = useDispatch()
   const [offers, setOffers] = useState([])
   const [offer, setOffer] = useState()
   const [negotiation, setNegotiation] = useState()
   const [isOpenDetail, setIsOpenDetail] = useState(false)
   const [dataProcurement, setDataProcurement] = useState({})
   const [lastOffer, setLastOffer] = useState(null)
   const [negoOfferItems, setOfferItems] = useState(null)

   const negotiationOfferService = new NegotiationOfferService()
   const negotiationOfferItemService = new NegotiationOfferItemService()
   const negotiationService = new NegotiationService()

   const socketRefNegotiation = useRef()
   const modalNegoOfferLogRef = useRef()
   const ENDPOINT = import.meta.env.VITE_SERVER_URL

   const setupSocketNegotiation = () => {
      socketRefNegotiation.current = io(ENDPOINT + `/negotiation-${negotiationId}`)
      socketRefNegotiation.current.emit(Constant.socketEvents.JOIN_NEGOTIATION_ROOM, {
         roomId: Constant.ROOM_SOCKET.NEGOTIATION + "-" + negotiationId,
         username: username,
      })

      socketRefNegotiation.current.on(Constant.socketEvents.RESPONSE_NEGO_OFFER, (resp) => {
         setOffers((prevOffer) => {
            const newOffers = [resp, ...prevOffer]
            return newOffers
         })
         _setOffers((prevOffer) => {
            const newOffers = [resp, ...prevOffer]
            return newOffers
         })
      })

      return () => {
         socketRefNegotiation.current.disconnect
      }
   }
   const tableColumnsUnitPrice = ["From", "Price", "Capability", "Action", "Date"]
   const tableColumns = ["From", "Price", "Action", "Date"]

   useEffect(() => {
      findOffersByNegotiationId()
      setupSocketNegotiation()
      getNegotiation(negotiationId)
      dispatch(
         negotiationOfferService.findLastOfferByNegotiationId(negotiationId, (res) => {
            setLastOffer(res)
         }),
      )
   }, [])

   const handleGetOfferItems = (offerId) => {
      dispatch(
         negotiationOfferItemService.findByNegotiationOffer(offerId, (res) => {
            setOfferItems(res)
         }),
      )
   }

   const findOffersByNegotiationId = () => {
      dispatch(
         negotiationOfferService.findOfferByNegotiationId(negotiationId, (res) => {
            setOffers(res)
            _setOffers(res)
         }),
      )
   }

   const getNegotiation = (negotiationId) => {
      dispatch(
         negotiationService.findById(negotiationId, (res) => {
            setNegotiation(res)
            if (dataProcurement.length > 0) {
               dispatch(
                  getProcurement({ procurementId: res.procurementVendor.procurementId }, (res) => {
                     setDataProcurement(res)
                  }),
               )
            } else {
               dispatch(
                  getProcurementPublic({ procurementId: res.procurementVendor.procurementId }, (res) => {
                     setDataProcurement(res)
                  }),
               )
            }
         }),
      )
   }

   const generateHTMLStatus = (status) => {
      if (status.toUpperCase() === Constant.NEGOTIATION_STATUS_OFFER.DEAL) {
         return '<div class="offerDealStatus"></div>'
      } else if (status.toUpperCase() === Constant.NEGOTIATION_STATUS_OFFER.OFFER) {
         return '<div class="offerOfferStatus"></div>'
      } else if (status.toUpperCase() === Constant.NEGOTIATION_STATUS_OFFER.NOT_DEAL) {
         return '<div class="offerNotDealStatus"></div>'
      }

      return ""
   }

   const optRenderHTML = {
      replace: ({ attribs, children }) => {
         if (!attribs) {
            return
         }

         if (attribs.class === "offerDealStatus") {
            return <div className={`${styles.offerStatus} ${styles.offerDealStatus}`}>Deal</div>
         }

         if (attribs.class === "offerOfferStatus") {
            return <div className={`${styles.offerStatus} ${styles.offerOfferStatus}`}>Offer</div>
         }

         if (attribs.class === "offerNotDealStatus") {
            return <div className={`${styles.offerStatus} ${styles.offerNotDealStatus}`}>Not Deal</div>
         }
      },
   }

   const openModalDetailLog = () => {
      if (negotiation?.biddingType !== Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE) {
         if (isOpenDetail) {
            setOffer(undefined)
            setIsOpenDetail(false)
         } else {
            setIsOpenDetail(true)
         }
      }
   }

   const handleClickOffer = (offer) => {
      setOffer(offer)
   }

   const generateOfferFrom = (offer) => {
      if (offer.fromAccountId === negotiation?.fromAccountId) {
         return "Buyer"
      } else if (offer.fromAccountId === negotiation?.toAccountId) {
         return "Vendor"
      } else {
         return Constant.NOT_AVAILABLE
      }
   }

   return (
      <>
         <GreyCard className="mx-0">
            <Row>
               <Col className="d-flex justify-content-between align-items-center ml-3 mt-2">
                  <p
                     style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        color: "#454545",
                        marginTop: "10px",
                     }}
                  >
                     Negotiation Price
                  </p>
               </Col>
            </Row>

            <hr style={{ background: "#C4C4C4" }} />

            {offers.length > 0 ? (
               <MuiDataTable.CustomTable
                  stickyHeader={true}
                  containerStyles={{
                     maxHeight: "240px",
                     "&::-webkit-scrollbar": {
                        height: "8px",
                        width: "0",
                     },
                     "&::-webkit-scrollbar-thumb": {
                        background: "#C4C4C4",
                     },
                     scrollbarWidth: "thin",
                     scrollbarColor: "#C4C4C4",
                  }}
               >
                  <TableHead>
                     <TableRow>
                        {dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE
                           ? tableColumnsUnitPrice.map((column, index) => (
                                <TableCell
                                   size="small"
                                   key={index}
                                   className="py-2"
                                   sx={{
                                      whiteSpace: index !== 4 && "nowrap",
                                      background: "#D0D6E0",
                                   }}
                                   //   align={index === 1 ? "right" : ""}
                                >
                                   <TableSortLabel
                                      // active={orderBy === column}
                                      // direction={order}
                                      onClick={() => handleRequestSort(column)}
                                      IconComponent={ExpandLessIcon}
                                   >
                                      <div>{column}</div>
                                   </TableSortLabel>
                                </TableCell>
                             ))
                           : tableColumns.map((column, index) => (
                                <TableCell
                                   size="small"
                                   key={index}
                                   className="py-2"
                                   sx={{
                                      whiteSpace: index !== 4 && "nowrap",
                                      background: "#D0D6E0",
                                   }}
                                   //   align={index === 1 ? "right" : ""}
                                >
                                   <TableSortLabel
                                      // active={orderBy === column}
                                      // direction={order}
                                      onClick={() => handleRequestSort(column)}
                                      IconComponent={ExpandLessIcon}
                                   >
                                      <div>{column}</div>
                                   </TableSortLabel>
                                </TableCell>
                             ))}
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {offers?.map((item, index) => (
                        <TableRow
                           sx={{
                              whiteSpace: "nowrap",
                              background: "#F3F5F4",
                              "&:hover": {
                                 background: "transparent",
                              },
                           }}
                           key={index}
                        >
                           <TableCell
                              style={{
                                 fontWeight:
                                    dataProcurement?.biddingType ===
                                       Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE_BY_UNIT && "500",
                                 color:
                                    dataProcurement?.biddingType ===
                                       Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE_BY_UNIT && "#454545",
                                 textDecoration:
                                    dataProcurement?.biddingType ===
                                       Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE_BY_UNIT && "underline",
                                 cursor:
                                    dataProcurement?.biddingType ===
                                       Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE_BY_UNIT && "pointer",
                              }}
                              onClick={() => {
                                 if (
                                    dataProcurement?.biddingType ===
                                    Constant.PROCUREMENT_BIDDING_TYPE.TOTAL_PRICE_BY_UNIT
                                 ) {
                                    modalNegoOfferLogRef.current.show(), handleGetOfferItems(item._id)
                                 }
                              }}
                              className="py-2"
                           >
                              {generateOfferFrom(item)}
                           </TableCell>
                           <TableCell className="py-2">
                              {Formservice.CurrencyFormatter(item.offer, {
                                 currency: dataProcurement?.currency,
                              })}
                           </TableCell>
                           {dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE && (
                              <TableCell className="py-2">{offers[0].capableQty}</TableCell>
                           )}
                           <TableCell className="py-2">
                              {parse(generateHTMLStatus(item.status), optRenderHTML)}
                           </TableCell>
                           <TableCell className="py-2">
                              {moment(item.createdAt).format("DD MMMM YYYY HH:mm:ss")}
                           </TableCell>
                        </TableRow>
                     ))}
                  </TableBody>
               </MuiDataTable.CustomTable>
            ) : (
               <Row>
                  <Col className="d-flex justify-content-center align-items-center m-5 flex-column">
                     <img style={{ marginLeft: "-10px" }} src={DocumentLarge} />
                     <span
                        className="mt-3"
                        style={{
                           textAlign: "center",
                           color: "#909090",
                           fontWeight: "bold",
                        }}
                     >
                        No log available
                        <br />
                        until negotiation will be started
                     </span>
                  </Col>
               </Row>
            )}

            {offer && negotiation?.biddingType !== Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE && (
               <>
                  <NegotiationOfferDetail
                     isOpen={isOpenDetail}
                     offer={offer}
                     fnCloseAndOpenModal={openModalDetailLog}
                  ></NegotiationOfferDetail>
               </>
            )}
         </GreyCard>
         <ModalForm passedInRef={modalNegoOfferLogRef} title={"Price Detail"} closeButton={true} width="700px">
            {/* <Row className="mx-0"> */}
            <div style={{ padding: "0 26px", overflow: "auto", height: "fit-content" }}>
               <div className="mb-5">
                  <MuiDataTable.CustomTable
                     stickyHeader={true}
                     containerStyles={{
                        maxHeight: "240px",
                        "&::-webkit-scrollbar": {
                           height: "8px",
                           width: "0",
                        },
                        "&::-webkit-scrollbar-thumb": {
                           background: "#C4C4C4",
                        },
                        scrollbarWidth: "thin",
                        scrollbarColor: "#C4C4C4",
                     }}
                  >
                     <TableHead>
                        <TableRow>
                           <TableCell
                              className="py-2"
                              size="small"
                              sx={{ whiteSpace: "nowrap", background: "#D0D6E0" }}
                           >
                              <TableSortLabel
                                 // active={orderBy === "Item Code"}
                                 // direction={order}
                                 onClick={(v) => handleRequestSort(v.target.outerText)}
                                 IconComponent={ExpandLessIcon}
                              >
                                 Item Code
                              </TableSortLabel>
                           </TableCell>
                           <TableCell
                              className="py-2"
                              size="small"
                              sx={{
                                 whiteSpace: "nowrap",
                                 background: "#D0D6E0",
                              }}
                           >
                              <TableSortLabel
                                 // active={orderBy === "Item Code"}
                                 // direction={order}
                                 onClick={(v) => handleRequestSort(v.target.outerText)}
                                 IconComponent={ExpandLessIcon}
                              >
                                 Item Name
                              </TableSortLabel>
                           </TableCell>
                           <TableCell
                              className="py-2"
                              size="small"
                              align="right"
                              sx={{ whiteSpace: "nowrap", background: "#D0D6E0" }}
                           >
                              <TableSortLabel
                                 // active={orderBy === "Item Name"}
                                 // direction={order}
                                 onClick={(v) => handleRequestSort(v.target.outerText)}
                                 IconComponent={ExpandLessIcon}
                              >
                                 Needed Quantity
                              </TableSortLabel>
                           </TableCell>
                           <TableCell
                              className="py-2"
                              size="small"
                              align="right"
                              sx={{
                                 whiteSpace: "nowrap",
                                 background: "#D0D6E0",
                              }}
                           >
                              <TableSortLabel
                                 // active={orderBy === "Last Price"}
                                 // direction={order}
                                 onClick={(v) => handleRequestSort(v.target.outerText)}
                                 IconComponent={ExpandLessIcon}
                              >
                                 Offer
                              </TableSortLabel>
                           </TableCell>
                           <TableCell
                              className="py-2"
                              size="small"
                              align="right"
                              sx={{
                                 whiteSpace: "nowrap",
                                 background: "#D0D6E0",
                              }}
                           >
                              <TableSortLabel
                                 // active={orderBy === "Last Price"}
                                 // direction={order}
                                 onClick={(v) => handleRequestSort(v.target.outerText)}
                                 IconComponent={ExpandLessIcon}
                              >
                                 Sub Total Price
                              </TableSortLabel>
                           </TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {negoOfferItems?.map((item, index) => (
                           <TableRow
                              key={index}
                              sx={{
                                 background: "#F3F5F4",
                                 "&:hover": {
                                    background: "transparent",
                                    cursor: "pointer",
                                 },
                              }}
                           >
                              <TableCell>{item.negotiationItem.procurementItem.itemCode}</TableCell>
                              <TableCell>{item.negotiationItem.procurementItem.itemName}</TableCell>
                              <TableCell className="py-2" align="right">
                                 {item.negotiationItem.procurementItem.quantity}
                              </TableCell>

                              <TableCell className="py-2" align="right" sx={{ whiteSpace: "nowrap" }}>
                                 {Formservice.CurrencyFormatter(item.offer, {
                                    currency: dataProcurement?.currency,
                                 })}
                              </TableCell>
                              <TableCell className="py-2" align="right" sx={{ whiteSpace: "nowrap" }}>
                                 {Formservice.CurrencyFormatter(item.capableQty * item.offer, {
                                    currency: dataProcurement?.currency,
                                 })}
                              </TableCell>
                           </TableRow>
                        ))}
                     </TableBody>
                  </MuiDataTable.CustomTable>
               </div>
            </div>
            {/* </Row> */}
         </ModalForm>
      </>
   )
}

export default NegotiationOfferAsListModule
