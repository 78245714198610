
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'

export default function ButtonExpand({ isExpand = false, ...props }) {

    return (
        <ButtonStyle {...props}><FontAwesomeIcon icon={isExpand ? faChevronUp : faChevronDown} /></ButtonStyle>
    )
}

const ButtonStyle = styled.button`
    border: 2px solid #909090;
    background-color: white;
    border-radius: 50%;
    color: #909090;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: bolder;
    width: 25px;
    height: 25px;
`