import React from "react";
import { useHistory } from "react-router-dom";
import { Col, Row, Card, Badge } from "react-bootstrap";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import Pagetitle from "../../../../../components/Pagetitle";
import YouTubeEmbed from "../../../../../components/YouTubeEmbed";
import ReportHeader from "../../../../../assets/images/ReportHeader.svg";
import PDFIcon from "../../../../../assets/images/pdf-icon.svg";

const Report = () => {
  const history = useHistory();
  const relatedVideo = [
    { title: "Report, Generate, dll", link: "/help/report" },
  ];

  return (
    <>
      <Pagetitle
        title="Report, Generate, dll"
        withBackButton={true}
        onBackButtonClicked={() => {
          history.push("/help/buyer");
        }}
      />
      <div className="ml-3">
        <div className="mt-2">
          <YouTubeEmbed embedId="pLkmLT6mmLk" />
        </div>
        <Row style={{ margin: "10px 0 20px 0" }}>
          <Badge
            style={{
              border: "1px solid #0772B6",
              borderRadius: "50px",
              color: "#0772B6",
              fontSize: "14px",
              fontWeight: "500",
              marginRight: "10px",
              padding: "10px",
            }}
          >
            Initiation
          </Badge>
          <Badge
            style={{
              border: "1px solid #0772B6",
              borderRadius: "50px",
              color: "#0772B6",
              fontSize: "14px",
              fontWeight: "500",
              marginRight: "10px",
              padding: "10px",
            }}
          >
            Procurement Process
          </Badge>
          <Badge
            style={{
              border: "1px solid #0772B6",
              borderRadius: "50px",
              color: "#0772B6",
              fontSize: "14px",
              fontWeight: "500",
              marginRight: "10px",
              padding: "10px",
            }}
          >
            Purchase Request
          </Badge>
          <Badge
            style={{
              border: "1px solid #0772B6",
              borderRadius: "50px",
              color: "#0772B6",
              fontSize: "14px",
              fontWeight: "500",
              marginRight: "10px",
              padding: "10px",
            }}
          >
            Vendor
          </Badge>
        </Row>
        <div style={{ color: "#454545", fontSize: "24px", fontWeight: "bold" }}>
          Report, Generate, dll
        </div>
        <p
          className="mt-2"
          style={{ fontSize: "14px", fontWeight: "400", maxWidth: "850px" }}
        >
          Proses terakhir pengadaan di aplikasi auction adalah melakukan
          generate dan download semua sesi log yang dilakukan di sesi auction
          maupun negotiation untuk kebutuhan dokumentasi fisik, pembubuhan tanda
          tangan basah, dan lain-lain. Dokumen session log yang dapat digenerate
          hanya session yang telah selesai dengan status Done. Dokumen yang
          dapat digenerate antara lain Auction Log Report, Negotiation Result
          Auction, dan Report Result Auction.
        </p>
        <Card
          className="mt-3"
          style={{ border: "1px solid #C4C4C4", width: "30%" }}
        >
          <Card.Body>
            <Row className="ml-1">
              <img src={PDFIcon} />
              <Col className="ml-1">
                <a
                  href="https://dimasyudha.s3.ap-southeast-1.amazonaws.com/User+Manual+Ruang+Auction+-+Buyer.pdf"
                  download
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "#0772B6",
                    textDecoration: "underline",
                  }}
                >
                  User Guide Buyer.pdf
                </a>
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "#909090",
                  }}
                >
                  875 Kb
                </p>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <hr style={{ borderColor: "#C4C4C4", marginTop: "18px" }} />
        <div style={{ fontSize: "24px", fontWeight: "bold" }}>
          Related Video
        </div>
        <Row
          xs={1}
          md={4}
          className="g-4"
          style={{ margin: "15px 0px 50px -8px" }}
        >
          {relatedVideo.map((v, index) => (
            <Col key={index}>
              <Card className="h-100">
                <div style={{ width: "auto" }}>
                  <Card.Img
                    variant="top"
                    src={ReportHeader}
                    style={{ backgroundSize: "cover" }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      top: "24%",
                      left: "5%",
                      fontSize: "24px",
                      fontWeight: "bold",
                      color: "#FFFFFF",
                    }}
                  >
                    {v.title}
                  </div>
                </div>
                <Card.Body>
                  <Card.Title
                    style={{
                      fontWeight: "600",
                      fontSize: "15px",
                      margin: "-5px 0 30px -5px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      history.push(v.link);
                    }}
                  >
                    {v.title}
                  </Card.Title>
                  <Row style={{ fontSize: "14px", fontWeight: "normal" }}>
                    <div
                      className="d-flex align-items-center"
                      style={{ margin: "0 25px 0 10px" }}
                    >
                      <FontAwesomeIcon icon={faClock} />
                      <div className="ml-2">01:27</div>
                    </div>
                    <div className="d-flex align-items-center">
                      <FontAwesomeIcon icon={faEye} />
                      <div className="ml-2">4 Views</div>
                    </div>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};

export default Report;

const FileName = styled.a`
  color: #0772b6;
  font-weight: 600;
  text-decoration: underline;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
