import { faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import React, { useEffect } from "react"
import { Col, InputGroup, Row } from "react-bootstrap"
import Buttonaction from "../../../../../../../components/Buttonaction"
import { GreyCard } from "../../../../../../../components/styled/Generalcard"
import NotebookBlueIcon from "../../../../../../../assets/images/NotebookBlueIcon.svg"
import MuiDataTable from "../../../../../../../components/MuiDataTable"
import { TableHead, TableRow, Checkbox as MuiCheckbox, TableCell, TableSortLabel, TableBody } from "@mui/material"
import { useState } from "react"
import CurrencyInput from "../../../../../../../components/CurrencyInput"
import Formservice from "../../../../../../../services/Formservice"
import { useDispatch } from "react-redux"
import {
   getLogPerNegotiation,
   createOrUpdateLog,
   deleteLog,
} from "../../../../../../../redux/action/negotiationLogAction"
import { toast } from "react-hot-toast"
import InputAlert from "../../../../../../../components/InputAlert"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"

export default function NegotiationLog(props) {
   const dispatch = useDispatch()
   const { negotiationId, finalNegotiationPrice, dataProcurement } = props

   const [order, setOrder] = useState("asc")
   const [orderBy, setOrderBy] = useState("")
   const [inEdit, setInEdit] = useState(true)
   const [dataNegoLog, setDataNegoLog] = useState([])
   const [checkedLog, setCheckedLog] = useState([])
   const [addNegotiationLog, setAddNegotiationLog] = useState(false)
   const [initialAdd, setInitialAdd] = useState(false)

   const tableColumns = ["Buyer Price", "Vendor Price"]

   const handleAddNegotiationLog = () => {
      setInEdit(true)
      setInitialAdd(true)
      setAddNegotiationLog(true)
      setDataNegoLog([...dataNegoLog, { buyerOffer: 0, vendorOffer: 0 }])
   }

   const handleAddMore = () => {
      setInEdit(true)
      setDataNegoLog([...dataNegoLog, { buyerOffer: 0, vendorOffer: 0 }])
   }

   useEffect(() => {
      console.log({ checkedLog })
   }, [checkedLog])

   const handleCheckAll = (e) => {
      const isChecked = e.target.checked
      isChecked ? setCheckedLog(dataNegoLog?.map((v, k) => k)) : setCheckedLog([])
   }

   const handleSelectLog = (checked, value) => {
      checked ? setCheckedLog([...checkedLog, value]) : setCheckedLog(checkedLog.filter((v) => v != value))
   }

   const handleRemoveLog = () => {
      const _selectedLogs = []
      for (const [index, log] of Object.entries(dataNegoLog)) {
         for (const checkedIndex of checkedLog) {
            if (index == checkedIndex) {
               _selectedLogs.push(log)
            }
         }
      }

      // get selected log Id
      const logIds = _selectedLogs.filter((log) => {
         return log._id
      })

      console.log("LOGIDS", logIds)

      if (logIds.length > 0) {
         new Promise((resolve, reject) => {
            dispatch(
               deleteLog(
                  logIds,
                  (res) => resolve(res),
                  (err) => reject(err),
               ),
            )
               .then(() => {
                  toast.success("Success delete log(s)")
                  dispatch(
                     getLogPerNegotiation(negotiationId, (res) => {
                        setDataNegoLog(res.data)
                        if (res.data < 1) {
                           setInEdit(false)
                        }
                     }),
                  )
               })
               .catch(() => {})
               .finally(() => {})
         })

         setDataNegoLog(
            dataNegoLog?.filter((v, k) => {
               if (v._id) {
                  return true
               } else {
                  return !checkedLog.includes(k)
               }
            }),
         )
      } else {
         setDataNegoLog(dataNegoLog?.filter((v, k) => !checkedLog.includes(k)))
      }

      setCheckedLog([])
   }

   useEffect(() => {
      if (dataNegoLog.length < 1) {
         setAddNegotiationLog(false)
      }
   }, [dataNegoLog])

   const handleChangeLog = (index, propName, value) => {
      const passedValue = value > 0 ? value : null
      setDataNegoLog(
         dataNegoLog.map((v, k) => {
            if (index === k && propName == "buyer-offer") {
               if (passedValue <= finalNegotiationPrice) {
                  return {
                     ...v,
                     buyerOffer: passedValue,
                     vendorOffer: v.vendorOffer,
                     isHigherThanFinalPrice: false,
                  }
               } else {
                  return {
                     ...v,
                     buyerOffer: passedValue,
                     vendorOffer: v.vendorOffer,
                     isHigherThanFinalPrice: true,
                  }
               }
            } else if (index === k && propName == "vendor-offer") {
               if (passedValue >= finalNegotiationPrice) {
                  return {
                     ...v,
                     buyerOffer: v.buyerOffer,
                     vendorOffer: passedValue,
                     isLowerThanFinalPrice: false,
                  }
               } else {
                  return {
                     ...v,
                     buyerOffer: v.buyerOffer,
                     vendorOffer: passedValue,
                     isLowerThanFinalPrice: true,
                  }
               }
            } else {
               return dataNegoLog[k]
            }
         }),
      )
   }

   const [isUpdating, setIsUpdating] = useState(false)
   const handleSaveChange = () => {
      const _dataNegoLog = dataNegoLog.map((log) => ({
         ...log,
         buyerOffer: parseFloat(log.buyerOffer),
         vendorOffer: parseFloat(log.vendorOffer),
         negotiationId,
      }))
      setIsUpdating(true)
      new Promise((resolve, reject) => {
         dispatch(
            createOrUpdateLog(
               _dataNegoLog,
               (res) => {
                  resolve(res)
               },
               () => {
                  reject()
               },
            ),
         )
      })
         .then((res) => {
            setDataNegoLog(res)
            setInEdit(false)
            setAddNegotiationLog(false)
            toast.success("Data has been saved")
         })
         .catch()
         .finally(() => setIsUpdating(false))
   }

   const getLogNegotiation = () => {
      dispatch(
         getLogPerNegotiation(negotiationId, (res) => {
            console.log("RESULT LOG PER NEGO", res)
            setDataNegoLog(res.data)
         }),
      )
   }

   const handleCancelEditLog = () => {
      setInEdit(false)
      setAddNegotiationLog(false)
      getLogNegotiation()
   }

   useEffect(() => {
      getLogNegotiation()

      return setInEdit(false)
   }, [])

   const [isCreating, setIsCreating] = useState(false)
   const handleCreateNegotiationLog = () => {
      const _dataNegoLog = dataNegoLog.map((log) => ({ ...log, negotiationId }))
      console.log({ _dataNegoLog })
      setIsCreating(true)
      new Promise((resolve, reject) => {
         dispatch(
            createOrUpdateLog(
               _dataNegoLog,
               (res) => {
                  resolve(res)
               },
               () => {
                  reject()
               },
            ),
         )
      })
         .then((res) => {
            setDataNegoLog(res)
            if (res.length > 0) {
               setInitialAdd(false)
            }
            setInEdit(false)
            setAddNegotiationLog(false)
            toast.success("Data has been saved")
         })
         .catch()
         .finally(() => setIsCreating(false))
   }

   const [disable, setDisable] = useState(false)
   useEffect(() => {
      if (dataNegoLog.length > 0) {
         dataNegoLog.filter((log) => log.isHigherThanFinalPrice || log.isLowerThanFinalPrice).length > 0
            ? setDisable(true)
            : setDisable(false)
      }
   }, [dataNegoLog])

   return (
      <GreyCard style={{ padding: "32px 18px", marginTop: 48 }} className="mx-4 mb-5">
         <Row>
            <Col className="d-flex justify-content-between align-items-center px-3">
               <p style={{ fontSize: "18px", fontWeight: "500" }}>Negotiation Log</p>
               {inEdit && dataNegoLog.filter((log) => log._id).length > 0 ? (
                  <div className="d-flex">
                     <Buttonaction
                        borderblueOcean
                        small
                        label="Cancel"
                        style={{ marginRight: 24 }}
                        onClick={handleCancelEditLog}
                     />
                     <Buttonaction
                        blueOcean
                        small
                        label="Save Change"
                        onClick={handleSaveChange}
                        loading={isUpdating}
                        loadingLabel="Saving Changes..."
                        disabled={isUpdating || disable}
                     />
                  </div>
               ) : (
                  <></>
               )}
               {dataNegoLog.length > 0 && !inEdit && !initialAdd ? (
                  <Buttonaction blueOcean small label="Edit Log" onClick={() => setInEdit(true)} />
               ) : (
                  <></>
               )}

               {addNegotiationLog && (
                  <Buttonaction
                     blueOcean
                     loading={isCreating}
                     small
                     label="Save"
                     loadingLabel="Saving Logs"
                     onClick={handleCreateNegotiationLog}
                     disabled={isCreating || disable}
                  />
               )}
            </Col>
         </Row>
         <hr style={{ borderColor: "#C4C4C4", marginTop: "18px" }} />
         {(dataNegoLog?.length == 0 && (
            <div className="d-flex justify-content-center">
               <img src={NotebookBlueIcon} />
            </div>
         )) || (
            <MuiDataTable.CustomTable
               // showHeader={true}
               headerComponent={
                  <MuiDataTable.Filter
                  //    withSearchBar={true}
                  // handleSearch={(v) => setDataState({ ...dataState, query: v.target.value })}
                  // onKeyUp={(v) => v.keyCode === 13 && search()}
                  />
               }
               itemsSelectedComponent={
                  inEdit && (
                     <MuiDataTable.ItemsSelected
                        showDeleteBtn={checkedLog?.length > 0}
                        selectedItems={checkedLog?.length}
                        onClickDelete={handleRemoveLog}
                     />
                  )
               }
            >
               <TableHead>
                  <TableRow>
                     {inEdit || addNegotiationLog ? (
                        <TableCell size="small">
                           <MuiCheckbox
                              className="p-0"
                              size="small"
                              checked={checkedLog?.length == dataNegoLog?.length}
                              indeterminate={checkedLog.length > 0 && checkedLog.length < dataNegoLog.length}
                              onChange={handleCheckAll}
                           />
                        </TableCell>
                     ) : (
                        <></>
                     )}
                     {tableColumns.map((column, index) => (
                        <TableCell key={index} className="py-2" align="right">
                           <TableSortLabel
                              active={orderBy === column}
                              direction={order}
                              //   onClick={() => handleRequestSort(column)}
                              IconComponent={ExpandLessIcon}
                           >
                              {column}
                           </TableSortLabel>
                        </TableCell>
                     ))}
                  </TableRow>
               </TableHead>
               <TableBody>
                  {dataNegoLog?.map((v, k) => {
                     return (
                        <TableRow
                           key={k}
                           sx={{
                              background: "#F3F5F4",
                              "&:hover": {
                                 background: "transparent",
                                 cursor: "pointer",
                              },
                           }}
                        >
                           {inEdit || addNegotiationLog ? (
                              <TableCell size="small">
                                 <MuiCheckbox
                                    className="px-0 py-2"
                                    size="small"
                                    onChange={(e) => handleSelectLog(e.target.checked, k)}
                                    value={k}
                                    checked={checkedLog.includes(k)}
                                 />
                              </TableCell>
                           ) : (
                              <></>
                           )}
                           <TableCell align="right" sx={{ minWidth: "200px" }} className="py-2">
                              {inEdit || addNegotiationLog ? (
                                 <>
                                    <InputGroup className="mb-2">
                                       <CurrencyInput
                                          id="buyer-offer"
                                          value={v?.buyerOffer}
                                          onValueChange={(val) => {
                                             handleChangeLog(k, "buyer-offer", val)
                                          }}
                                          className="form-control"
                                       />
                                       <InputGroup.Text
                                          style={{
                                             marginLeft: "-3px",
                                             fontSize: "12px",
                                             zIndex: 0,
                                          }}
                                       >
                                          {dataProcurement?.currency}
                                       </InputGroup.Text>
                                    </InputGroup>
                                    {v?.isHigherThanFinalPrice && v?.isHigherThanFinalPrice && (
                                       <div>
                                          <InputAlert.Container type="danger">
                                             <div className="d-flex align-items-start mb-2">
                                                <InputAlert.Icon />
                                                <InputAlert.Text text="Value must be equal or lower than negotiation final" />
                                             </div>
                                          </InputAlert.Container>
                                       </div>
                                    )}
                                    {/* <ErrorField errors={errors} fieldName="pricePerUnit" /> */}
                                 </>
                              ) : (
                                 Formservice.CurrencyFormatter(v?.buyerOffer, { currency: dataProcurement?.currency })
                              )}
                           </TableCell>
                           <TableCell align="right" sx={{ minWidth: "200px" }} className="py-2">
                              {inEdit || addNegotiationLog ? (
                                 <>
                                    <InputGroup className="mb-2">
                                       <CurrencyInput
                                          id="vendor-offer"
                                          value={v?.vendorOffer}
                                          onValueChange={(val) => handleChangeLog(k, "vendor-offer", val)}
                                          className="form-control"
                                       />
                                       <InputGroup.Text
                                          style={{
                                             marginLeft: "-3px",
                                             fontSize: "12px",
                                             zIndex: 0,
                                          }}
                                       >
                                          {dataProcurement?.currency}
                                       </InputGroup.Text>
                                    </InputGroup>
                                    {v?.isLowerThanFinalPrice && v?.isLowerThanFinalPrice && (
                                       <div>
                                          <InputAlert.Container type="danger">
                                             <div className="d-flex align-items-start mb-2">
                                                <InputAlert.Icon />
                                                <InputAlert.Text text="Value must be equal or higher than negotiation final" />
                                             </div>
                                          </InputAlert.Container>
                                       </div>
                                    )}
                                    {/* <ErrorField errors={errors} fieldName="pricePerUnit" /> */}
                                 </>
                              ) : (
                                 Formservice.CurrencyFormatter(v?.vendorOffer, { currency: dataProcurement?.currency })
                              )}
                           </TableCell>
                        </TableRow>
                     )
                  })}
               </TableBody>
            </MuiDataTable.CustomTable>
         )}
         <div className="d-flex justify-content-center">
            {dataNegoLog?.length == 0 && (
               <Buttonaction
                  // className={`w-100`}
                  style={{ marginTop: 18 }}
                  blueOcean
                  // disabled
                  label="Add Negotiation Log"
                  faIcon={faPlusCircle}
                  onClick={handleAddNegotiationLog}
               />
            )}
            {dataNegoLog?.length > 0 && (
               <Buttonaction
                  className={`w-100`}
                  style={{ marginTop: 18 }}
                  blueOcean
                  // disabled
                  label="Add More"
                  // faIcon={faPlusCircle}
                  onClick={handleAddMore}
               />
            )}
         </div>
      </GreyCard>
   )
}
