import React, { useState } from "react"
import { Col, Row } from "react-bootstrap"
import { CountdownCircleTimer } from "react-countdown-circle-timer"
import Chip from "../../components/Chip"
import Statuslabel from "../../components/Statuslabel"

export default function BuyerAuctionTimer({ stopTimer, dataAuction, auctionDuration, durationLeft }) {
   useState(() => {}, [durationLeft, status])
   return (
      <Row>
         <Col lg={12} className="d-flex justify-content-center">
            <div className="d-flex justify-content-center">
               {durationLeft > 0 && durationLeft != null && !stopTimer && (
                  <CountdownCircleTimer
                     isPlaying={durationLeft > 0}
                     duration={auctionDuration}
                     initialRemainingTime={durationLeft}
                     colors={[["#4DAF51", 0.33], ["#FFB301", 0.33], ["#F44437"]]}
                     strokeWidth={17}
                     size={180}
                  >
                     {({ remainingTime }) => {
                        const hours = Math.floor(remainingTime / 3600)
                        const minutes = Math.floor((remainingTime % 3600) / 60)
                        const seconds = remainingTime % 60

                        return (
                           <div className="d-flex flex-column align-items-center">
                              <p
                                 style={{
                                    color: "#909090",
                                    fontSize: "18px",
                                    marginBottom: "10px",
                                 }}
                              >
                                 Timer
                              </p>
                              <p
                                 className="mt-0 mb-3"
                                 style={{
                                    fontSize: "24px",
                                 }}
                              >{`${hours.toString().length === 1 ? `0${hours}` : hours} : ${
                                 minutes.toString().length === 1 ? `0${minutes}` : minutes
                              } : ${seconds.toString().length === 1 ? `0${seconds}` : seconds}`}</p>
                              <Statuslabel status={dataAuction.status} label={dataAuction.status} />
                           </div>
                        )
                     }}
                  </CountdownCircleTimer>
               )}

               {(durationLeft == 0 || dataAuction?.status === "DONE" || dataAuction?.status === "CANCELED" || stopTimer) && (
                  <CountdownCircleTimer
                     isPlaying={false}
                     duration={0}
                     initialRemainingTime={0}
                     colors={[["#4DAF51", 0.33], ["#FFB301", 0.33], ["#F44437"]]}
                     strokeWidth={17}
                     size={180}
                  >
                     {({ remainingTime }) => {
                        const hours = Math.floor(remainingTime / 3600)
                        const minutes = Math.floor((remainingTime % 3600) / 60)
                        const seconds = remainingTime % 60

                        return (
                           <div className="d-flex flex-column align-items-center">
                              <p
                                 style={{
                                    color: "#909090",
                                    fontSize: "18px",
                                    marginBottom: "10px",
                                 }}
                              >
                                 Timer
                              </p>
                              <p
                                 className="mt-0 mb-3"
                                 style={{
                                    color: "#F44437",
                                    fontSize: "24px",
                                 }}
                              >{`${hours.toString().length === 1 ? `0${hours}` : hours} : ${
                                 minutes.toString().length === 1 ? `0${minutes}` : minutes
                              } : ${seconds.toString().length === 1 ? `0${seconds}` : seconds}`}</p>
                              <Chip kind="canceled">End</Chip>
                           </div>
                        )
                     }}
                  </CountdownCircleTimer>
               )}
            </div>
         </Col>
      </Row>
   )
}
