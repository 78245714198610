import React, { useEffect, useImperativeHandle, useRef, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { connect, useDispatch } from "react-redux"
import { useParams } from "react-router"
import { SelectPicker } from "rsuite"
import { GreyCard } from "../../../../../../../components/styled/Generalcard"
import { fetchMasterData } from "../../../../../../../redux/action/masterDataAction"
import {
   changeAuctionStatus,
   getProcurement,
   fetchProcurementItems,
   getProcurementAuction,
} from "../../../../../../../redux/action/procurementAction"
import ErrorField from "../../../../../../../components/Errorfield"
import { differenceInMinutes } from "date-fns"
import DatePickerUtil from "../../../../../../../services/utils/DatePickerUtil"
import { createAuctionVendor } from "../../../../../../../redux/action/auctionAction"
import moment from "moment"
import Constant from "../../../../../../../../constant"
import Dateinfo from "../../../../../../../components/Dateinfo"
import DatePickerInput from "../../../../../../../components/DatePickerInput"

function NegotiationInfo({
   dataMasters,
   passedInRef,
   evaluationFormData,
   setEvaluationFormData,
   dataProcurement,
   readOnly = false,
   setNegoBatchPayload,
   negotiationBatch,
}) {
   const dispatch = useDispatch()
   const { procurementId } = useParams()
   const [procurementData, setProcurementData] = useState({})
   const [numberOfItems, setNumberOfItems] = useState(0)
   const [errors, setErrors] = useState([])
   const [formState, setFormState] = useState({
      auctionType: "",
      auctionModel: "",
      periodType: "",
      startTime: new Date(),
      endTime: new Date(),
      minimalDecrement: 0,
      duration: null,
      timeExtension: 0,
      injuryTimeBeforeEnd: 0,
      maxSessionExtension: 0,
   })

   useEffect(() => {
      dispatch(fetchMasterData())
      dispatch(
         getProcurement(
            { procurementId },
            (result) => {
               setProcurementData(result)
            },
            () => {
               toast.error("Data not found")
            },
         ),
      )

      dispatch(
         fetchProcurementItems(
            {
               procurementId,
            },
            (res) => {
               setNumberOfItems(res.total)
            },
         ),
      )
   }, [])

   const handleDisabledDate = (date) => {
      return DatePickerUtil.disabledDate(date, dataProcurement.startDate, dataProcurement.endDate)
   }

   return (
      <div id="evaluation">
         <Row>
            <Col className="mb-5">
               <GreyCard>
                  <Row>
                     <Col className="d-flex justify-content-between align-items-center px-4 mt-3 mb-1">
                        <p
                           style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              color: "#454545",
                           }}
                        >
                           Negotiation Info
                        </p>
                     </Col>
                  </Row>

                  <hr style={{ background: "#C4C4C4" }} />

                  <Row>
                     <Col className="px-4 mt-1">
                        <Form>
                           <Form.Group
                              as={Row}
                              className="d-flex align-items-center mb-3"
                              controlId="formHorizontalEmail"
                           >
                              <Form.Label column xs={3} xxl={2}>
                                 <p style={{ fontSize: "12px" }}>Negotiation Type</p>
                              </Form.Label>
                              <Col sm={4} className="d-flex flex-column">
                                 {readOnly ? (
                                    <p>
                                       {negotiationBatch?.negotiationType.charAt(0).toUpperCase() +
                                          negotiationBatch?.negotiationType.slice(1).toLowerCase()}{" "}
                                       Negotiation
                                    </p>
                                 ) : (
                                    <SelectPicker
                                       placeholder="Choose One"
                                       size="lg"
                                       value={negotiationBatch?.negotiationType}
                                       data={dataMasters?.negoType}
                                       onChange={(s) =>
                                          setNegoBatchPayload((prev) => ({ ...prev, negotiationType: s }))
                                       }
                                       searchable={false}
                                    />
                                 )}
                                 <ErrorField errors={errors} fieldName="negotiationType" />
                              </Col>
                           </Form.Group>

                           <Form.Group
                              as={Row}
                              className="d-flex align-items-center mb-3"
                              controlId="formHorizontalEmail"
                           >
                              <Form.Label column xs={3} xxl={2}>
                                 <p style={{ fontSize: "12px" }}>Schedule</p>
                              </Form.Label>
                              <Col sm={4} className="d-flex flex-column justify-content-center">
                                 {readOnly ? (
                                    <p>{<Dateinfo date={negotiationBatch?.startDate} format="dd mmmm yyyy HH:MM" />}</p>
                                 ) : (
                                    <>
                                       <DatePickerInput
                                          placeholder="dd/mm/yy 00:00"
                                          size="lg"
                                          style={{ width: "100%" }}
                                          format="dd-MM-yyyy HH:mm"
                                          // value={new Date(negotiationBatch?.startDate)}
                                          disabledDate={handleDisabledDate}
                                          onChange={(v) => setNegoBatchPayload((prev) => ({ ...prev, startDate: v }))}
                                       />
                                    </>
                                 )}
                                 <ErrorField errors={errors} fieldName="startDate" />
                              </Col>
                           </Form.Group>
                        </Form>
                     </Col>
                  </Row>
               </GreyCard>
            </Col>
         </Row>
      </div>
   )
}

const mapStateToProps = (state) => {
   return {
      isAppLoading: state.appReducer.isAppLoading,
      dataMasters: state.masterDataReducer.dataMasters,
      dataProcurement: state.procurementReducer.dataProcurement,
      dataProcurementAuction: state.procurementReducer.dataProcurementAuction,
   }
}

export default connect(mapStateToProps)(NegotiationInfo)
