import Apiservice from "./Apiservice";

const API_URL = Apiservice.url.AUCTION_URL + "/api/secured/negotiation-offer-item";

function NegotiationOfferItemService(){
    this.findByNegotiationOffer = function(offerId, callback){
        return async () => {
            const resp = await Apiservice.invokeGet(API_URL + "/find-by-offer/" + offerId);
            if (resp.status == 200) {
                if (callback) {
                    callback(resp.data.data);
                }
            }
        }
    }

    this.submitOffer = function(negotiation, negotiationOffer = null, negotiationOfferItems, fromAccountId, toAccountId, status, callback, errCallback){
        return async () => {
            const resp = await Apiservice.invokePost(API_URL + "/submit-offer" , {
                negotiation: negotiation,
                negotiationOffer: negotiationOffer,
                negotiationOfferItems: negotiationOfferItems,
                fromAccountId: fromAccountId,
                toAccountId: toAccountId,
                status
            });
            if (resp.status == 200) {
                if (callback) {
                    callback(resp.data.data);
                }
            } else {
                if(errCallback) {
                    errCallback(resp)
                }
            }
        }
    }
}

export default NegotiationOfferItemService;