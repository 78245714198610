import React, { useState } from "react"
import { Form } from "react-bootstrap"
import { connect, useDispatch } from "react-redux"
import { useHistory } from "react-router"
import { Link } from "react-router-dom"
import Buttonaction from "../../components/Buttonaction"
import ErrorField from "../../components/Errorfield"
import KeyIcon from "../../assets/images/Key.svg"
import EnvelopeIcon from "../../assets/images/Envelope.svg"
import ArrowLeft from "../../assets/images/ArrowLeft.svg"
import { resetPassword } from "../../redux/action/authAction"
import InputAlert from "../../components/InputAlert"
import { useEffect } from "react"

function ForgotPass({ isAppLoading }) {
   const history = useHistory()
   const dispatch = useDispatch()

   const [email, setEmail] = useState("")
   const [isEmailFormatAccepted, setIsEmailFormatAccepted] = useState(false)
   const [isSending, setIsSending] = useState(false)
   const [mailSent, setMailSent] = useState(false)
   const [error, setError] = useState({
      error: false,
      message: "",
   })

   const validateInputEmail = () => {
      const pattern = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/
      if (pattern.test(email)) {
         setIsEmailFormatAccepted(true)
      } else {
         setIsEmailFormatAccepted(false)
      }
   }

   useEffect(() => {
      setError((prev) => ({ ...prev, error: false }))
      if (email) {
         validateInputEmail()
      }
   }, [email])

   const handleResetPassword = (e) => {
      e.preventDefault()
      const pattern = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/
      if (pattern.test(email)) {
         setIsSending(true)
         new Promise((resolve, reject) => {
            dispatch(
               resetPassword(
                  { email },
                  () => resolve(),
                  (error) => reject(error[0]),
               ),
            )
         })
            .then(() => {
               setTimeout(() => {
                  setMailSent(true)
               }, 2000)
            })
            .catch((error) => {
               setTimeout(() => {
                  setError((prev) => ({ ...prev, error: true, message: error.msg }))
               }, 2000)
            })
            .finally(() => {
               setTimeout(() => {
                  setIsSending(false)
               }, 2000)
            })
      } else {
         setError((prev) => ({ ...prev, error: true, message: "Please input a corrent email address" }))
      }
   }

   return (
      <div style={{ display: "grid", placeItems: "center", height: "100vh" }}>
         <div
            className="container-fluid no-gutters d-flex flex-column align-items-center"
            style={{
               boxShadow: "0px 32px 100px rgba(0, 0, 0, 0.08)",
               width: "36%",
               height: "fit-content",
               padding: "32px",
               borderRadius: "12px",
            }}
         >
            {/* Render this is mailSent state is true */}
            {mailSent && (
               <>
                  <img src={KeyIcon} className="mb-4" />
                  <h3 style={{ color: "#0772B6", fontSize: "24px" }}>New Password created</h3>
                  <p className="text-center" style={{ fontSize: "18px", width: "80%", margin: "18px 0", fontWeight: 500 }}>
                     We have sent a new password to your email, please check your inbox
                  </p>
                  <span>{email}</span>

                  <div className="mt-5">
                     <Link to="/buyer-login">
                        <img src={ArrowLeft} />
                     </Link>
                     <Link
                        style={{
                           fontWeight: "bold",
                           fontSize: 14,
                           color: "#0772B6",
                           marginLeft: "16px",
                           textDecoration: "underline",
                        }}
                        to="/buyer-login"
                     >
                        Back to login
                     </Link>
                  </div>
               </>
            )}

            {/* Render this is mailSent state is false */}
            {!mailSent && (
               <>
                  <img src={KeyIcon} className="mb-4" />
                  <h3 style={{ color: "#0772B6", fontSize: "24px" }}>Forgot Password?</h3>
                  <p className="text-center" style={{ fontSize: "18px", width: "80%", margin: "18px 0" }}>
                     Please enter the email address associated with your account
                  </p>
                  <Form className="w-100" onSubmit={handleResetPassword}>
                     <Form.Group className="mb-3" controlId="formEmail">
                        <Form.Label style={{ color: "#909090" }}>Email</Form.Label>
                        <Form.Control
                           style={{ height: "44px" }}
                           type="email"
                           placeholder="Type email address"
                           onChange={(e) => setEmail(e.target.value)}
                        />
                        {error.error && (
                           <div className="d-flex mt-2">
                              <InputAlert.Container type="danger">
                                 <InputAlert.Icon />
                                 <InputAlert.Text text={error.message} />
                              </InputAlert.Container>
                           </div>
                        )}
                     </Form.Group>

                     <Form.Group className="mb-3 text-center" controlId="formButton">
                        <Buttonaction
                           blueOcean
                           type="submit"
                           label="Reset My Password"
                           style={{ width: "100%" }}
                           loadingLabel="Generating new password..."
                           loading={isSending}
                           disabled={!isEmailFormatAccepted || !email || isSending}
                        />
                     </Form.Group>

                     <Form.Group className="text-center mb-0" controlId="formButton">
                        <Link to="/buyer-login">
                           <img src={ArrowLeft} />
                        </Link>
                        <Link
                           style={{
                              fontWeight: "bold",
                              fontSize: 14,
                              color: "#0772B6",
                              marginLeft: "16px",
                              textDecoration: "underline",
                           }}
                           to="/buyer-login"
                        >
                           Back to login
                        </Link>
                     </Form.Group>
                  </Form>
               </>
            )}
         </div>
      </div>
   )
}

export default connect((state) => ({
   isAppLoading: state.appReducer.isAppLoading,
}))(ForgotPass)
