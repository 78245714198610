import Apiservice from "../Apiservice"
const API_URL = import.meta.env.VITE_SERVER_URL

const Register = async(payload) => {
  const apiResult = await Apiservice.invokePost(`${API_URL}/api/authentication/register`, payload)

  return apiResult
}

const Login = async(payload) => {
  const apiResult = await Apiservice.invokePost(`${API_URL}/api/authentication/login`, payload)

  return apiResult
}

const Authentication = {
  Register,
  Login
}

export default Authentication