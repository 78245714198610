import React, { useState } from "react"
import { useParams } from "react-router-dom"
import { orderBy as _orderBy } from "lodash"
import { connect, useDispatch } from "react-redux"

// MUI library
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel } from "@mui/material"

// Redux
import { fetchProcurementItems, sortProcuremenItems } from "../../../../redux/action/procurementAction"

// Components
import MuiDataTable from "../../../../components/MuiDataTable"
import { useEffect } from "react"

function ByItem({ modalVendorsPerItemRef, dataProcurementItems }) {
   const dispatch = useDispatch()
   const { procurementId } = useParams()

   const [order, setOrder] = useState("asc")
   const [orderBy, setOrderBy] = useState("")
   const [dataState, setDataState] = useState({ procurementId, page: 0, limit: 5, query: "" })
   const columns = ["Item Code", "Item Name", "PR Line", "Qty", "Minimum Capable Qty", "UOM"]

   useEffect(() => {
      dispatch(fetchProcurementItems(dataState, (res) => {}))
   }, [])

   const handleRequestSortItem = (column) => {
      const _order = order === "asc" ? "desc" : "asc"
      setOrderBy(column)
      setOrder(_order)

      const forColumn =
         column === "Item Code"
            ? "itemCode"
            : column === "Item Name"
            ? "itemName"
            : column === "PR Line"
            ? "prLine"
            : column === "Qty"
            ? "quantity"
            : column === "Minimum Capable Qty"
            ? "minCapableQty"
            : column === "UOM"
            ? "uom"
            : ""

      let result = []
      result = _orderBy(dataProcurementItems.filteredData, [forColumn], [_order])

      dispatch(sortProcuremenItems(result))
   }

   const search = () => {
      dispatch(fetchProcurementItems({ ...dataState }, (res) => {}))
   }

   const handleRowsPerPageChange = (v, { props }) => {
      const { value } = props
      dispatch(
         fetchProcurementItems({ procurementId, page: 1, limit: value, quuery: "" }, (res) => {
            setDataState({ ...dataState, limit: value })
         }),
      )
   }

   const handlePageChangeItems = (v, newPage) => {
      dispatch(
         fetchProcurementItems({ ...dataState, page: newPage + 1 }, (res) => {
            setDataState({ ...dataState, page: newPage })
         }),
      )
   }

   return (
      <>
         <MuiDataTable.CustomTable
            showHeader={true}
            headerComponent={
               <MuiDataTable.Filter
                  withSearchBar={true}
                  handleSearch={(v) =>
                     setDataState({
                        ...dataState,
                        query: v.target.value,
                     })
                  }
                  onKeyUp={(v) => v.keyCode === 13 && search(v)}
               />
            }
            footerComponent={
               <TablePagination
                  sx={{ background: "#ffffff", borderBottom: "none" }}
                  page={dataState.page}
                  count={dataProcurementItems?.total}
                  rowsPerPage={dataState.limit}
                  rowsPerPageOptions={[1, 2, 5, 10, 15, 20, { label: "All", value: -1 }]}
                  onPageChange={handlePageChangeItems}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  className="d-flex justify-content-end"
               />
            }
         >
            <TableHead>
               <TableRow>
                  {columns.map((column, index) => (
                     <TableCell className="py-2" key={index} align={index === 4 ? "right" : ""}>
                        <TableSortLabel
                           active={orderBy === column}
                           direction={order}
                           onClick={() => handleRequestSortItem(column)}
                           IconComponent={ExpandLessIcon}
                        >
                           {column}
                        </TableSortLabel>
                     </TableCell>
                  ))}
               </TableRow>
            </TableHead>
            <TableBody>
               {dataProcurementItems?.filteredData.length > 0 ? (
                  dataProcurementItems.filteredData.map((item, index) => (
                     <TableRow
                        key={index}
                        sx={{
                           background: "#F3F5F4",
                           "&:hover": {
                              background: "transparent",
                              cursor: "pointer",
                           },
                        }}
                        onClick={() => modalVendorsPerItemRef.current.show(item._id)}
                     >
                        <TableCell
                           className="py-2"
                           sx={{
                              "&:hover": {
                                 fontWeight: "500",
                                 color: "#0772B6",
                                 cursor: "pointer",
                                 textDecoration: "underline",
                              },
                           }}
                        >
                           {item.itemCode}
                        </TableCell>
                        <TableCell className="py-2">{item.itemName}</TableCell>
                        <TableCell className="py-2">{item.prLine}</TableCell>
                        <TableCell className="py-2">{item.quantity}</TableCell>
                        <TableCell className="py-2" align="right">
                           {item.minCapableQty}
                        </TableCell>
                        <TableCell className="py-2">{item.uom}</TableCell>
                     </TableRow>
                  ))
               ) : (
                  <TableRow sx={{ background: "#F3F5F4" }}>
                     <TableCell colSpan={columns.length} align="center" sx={{ fontWeight: 500 }}>
                        No records to display
                     </TableCell>
                  </TableRow>
               )}
            </TableBody>
         </MuiDataTable.CustomTable>
      </>
   )
}

const mapStateToProps = (state) => ({
   dataProcurementItems: state.procurementReducer.dataProcurementItems,
})

export default connect(mapStateToProps)(ByItem)
