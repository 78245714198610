// React
import React, { useEffect, useImperativeHandle, useLayoutEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { connect, useDispatch } from "react-redux"
import { useParams } from "react-router"

// Redux
import {
   fetchProcurementItemsPublic,
   getProcurement,
   getProcurementVendorsByProcurementItemId,
   getVendorsWithValidTechScore,
} from "../../../redux/action/procurementAction"

// Assets
import WarningBlue from "../../../assets/images/warning-blue.svg"

// Components
import MuiDataTable from "../../../components/MuiDataTable"
import { GreyCard } from "../../../components/styled/Generalcard"

// MUI
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { Checkbox, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material"

import Formservice from "../../../services/Formservice"
import { orderBy as _orderBy, remove as _remove } from "lodash"
import Constant from "../../../../constant"
import styled from "styled-components"

function ProcurementItemList({
   dataProcurement,
   passedInRef,
   getSelectedItems,
   procurementVendors,
   setNegoBatchPayload,
   negotiationBatch,
   readOnly = false,
   fnHandleSelectedItems,
   selectedItems,
}) {
   const dispatch = useDispatch()
   const { procurementId } = useParams()

   // Comoponent state
   const [order, setOrder] = useState("asc")
   const [orderBy, setOrderBy] = useState("")
   const [emptySearch, setEmptySearch] = useState(false)
   const [items, setItems] = useState([])
   const [selectedValue, setSelectedValue] = useState("")
   const [selectedItemIds, setSelectedItemIds] = useState([])
   const [selectedItemsIds, setSelectedItemsIds] = useState([])
   const [dataProcurementItems, setDataProcurementItems] = useState({
      filteredData: [],
   })
   const [dataState, setDataState] = useState({
      page: 0,
      limit: 5,
      query: "",
   })

   const handleSelectItem = (e) => {
      const checked = e.target.checked
      const value = e.target.value

      let copySelectedItems, filteredSelectedItems
      if (dataProcurement.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE) {
         filteredSelectedItems = [value]
         setSelectedItemsIds(filteredSelectedItems)
      } else {
         if (checked) {
            copySelectedItems = selectedItemIds
            filteredSelectedItems = [...value, copySelectedItems]
            setSelectedItemsIds(filteredSelectedItems)
         } else if (!checked) {
            copySelectedItems = selectedItemIds
            filteredSelectedItems = _remove(copySelectedItems, function (n) {
               return n === value
            })
            setSelectedItemsIds(filteredSelectedItems)
         }
      }

      const { value: itemId } = e.target
      const meritPoint = dataProcurement.evaluationMethod === Constant.EVALUATION_METHODS.MERIT_POINT

      setNegoBatchPayload && setNegoBatchPayload((prev) => ({ ...prev, procurementItemIds: [itemId] }))
      // setSelectedValue(itemId)

      // const filtered = selectedItemsIds.map()
      // setSelectedItemIds()
      // setSelectedItemIds([...itemId])
      handleSendOutputSelectedItems(filteredSelectedItems)

      if (selectedValue !== itemId) {
         if (meritPoint) {
            dispatch(getVendorsWithValidTechScore(itemId))
         } else if (!meritPoint) {
            dispatch(getProcurementVendorsByProcurementItemId(itemId))
         }
      }
   }

   useEffect(() => {
      if (getSelectedItems) {
         // callback to return selectedItem state to parent component who calls this component while selecting an item
         getSelectedItems(selectedItemIds)
         handleSendOutputSelectedItems(selectedItemIds)
      }
   }, [selectedItemIds])

   useImperativeHandle(passedInRef, () => ({
      getSelectedItemIds: () => {
         return selectedItemIds
      },
   }))

   const handleSetSelectedItems = (procurementItems) => {
      const arr = []
      if (dataProcurement.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE) {
         if (selectedItems) {
            for (const selectedItem of selectedItems) {
               for (const procurementItem of procurementItems) {
                  if (selectedItem._id === procurementItem._id) {
                     arr.push(procurementItem._id)
                  }
               }
            }
         } else {
            arr.push(procurementItems[0]._id)
         }
      } else {
         for (const procItem of procurementItems) {
            arr.push(procItem._id)
         }
      }
      setSelectedItemsIds(arr)
      return arr
   }

   const handleAllowSelectableItems = (items) => {
      const selItems = handleSetSelectedItems(items)
      if (selItems) {
         handleSendOutputSelectedItems(selItems)
      }
   }

   useLayoutEffect(() => {
      dispatch(
         fetchProcurementItemsPublic({ procurementId }, (res) => {
            const itemId = res.filteredData[0]._id
            const procurementItems = res.filteredData
            // setSelectedItemIds([itemId])
            setItems(res.filteredData)
            handleAllowSelectableItems(res.filteredData)
            setDataProcurementItems(res)

            dispatch(
               getProcurement({ procurementId }, (procurement) => {
                  const { biddingType, evaluationMethod } = procurement

                  const unitPrice = biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE
                  const meritPoint = evaluationMethod === Constant.EVALUATION_METHODS.MERIT_POINT

                  if (unitPrice && setNegoBatchPayload) {
                     setNegoBatchPayload((prev) => ({ ...prev, procurementItemIds: [procurementItems[0]._id] }))
                  } else if (setNegoBatchPayload) {
                     const procurementItemIds = procurementItems.map((item) => item._id)
                     setNegoBatchPayload((prev) => ({ ...prev, procurementItemIds }))
                  }

                  if (unitPrice && meritPoint) {
                     dispatch(getVendorsWithValidTechScore(itemId))
                  } else if (unitPrice && !meritPoint) {
                     dispatch(getProcurementVendorsByProcurementItemId(itemId))
                  }
               }),
            )
         }),
      )
   }, [])

   const getTableColumns = (dataProcurement) => {
      let isForNegoBatch = negotiationBatch
      let isUnitPrice = dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE

      if (isForNegoBatch && !isUnitPrice) {
         return ["Item Name", "PR Line", "Qty", "Minimum Capable Qty", "UOM", "Estimated Price Per Unit", "Subtotal"]
      } else if (isForNegoBatch && isUnitPrice) {
         return ["", "Item Name", "PR Line", "Qty", "Minimum Capable Qty", "UOM", "Estimated Price Per Unit", "Subtotal"]
      } else if(!isForNegoBatch && isUnitPrice) {
         return ["", "Item Name", "PR Line", "Qty", "Minimum Capable Qty", "UOM"]
      } else {
         return ["Item Name", "PR Line", "Qty", "Minimum Capable Qty", "UOM"]
      }
   }

   const tableColumnsUnitPrice = [
      "",
      "Item Name",
      "PR Line",
      "Qty",
      "Minimum Capable Qty",
      "UOM",
      "Estimated Price Per Unit",
      "Subtotal",
   ]

   const handleSendOutputSelectedItems = (itemIds) => {
      if (fnHandleSelectedItems) {
         fnHandleSelectedItems(itemIds)
      }
   }

   const handleRequestSort = (column) => {
      const _order = order === "asc" ? "desc" : "asc"
      setOrderBy(column)
      setOrder(_order)

      const forColumn =
         column === "Item Name"
            ? "itemName"
            : column === "PR Line"
            ? "prLine"
            : column === "Qty"
            ? "quantity"
            : column === "Minimum Capable Qty"
            ? "minCapableQty"
            : column === "UOM"
            ? "uom"
            : column === "Estimated Price Per Unit"
            ? "pricePerUnit"
            : column === "Subtotal" && "subTotal"

      let result = []
      result = _orderBy(dataProcurementItems.filteredData, [forColumn], [_order])

      setDataProcurementItems({ ...dataProcurementItems, filteredData: result })
   }

   const search = ({ target }) => {
      const { value } = target
      dispatch(
         fetchProcurementItemsPublic(
            {
               procurementId,
               page: 1,
               limit: dataState.limit,
               query: value,
            },
            (res) =>
               res.total === 0 ? (setDataProcurementItems(res), setEmptySearch(true)) : setDataProcurementItems(res),
         ),
      )
      setDataState((state) => ({
         ...state,
         query: value,
      }))
   }

   // Variables
   const isUnitPrice = dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE

   const validateChecked = (item) => {
      if (selectedItemsIds.length > 0) {
         for (let i = 0; i < selectedItemsIds.length; i++) {
            if (selectedItemsIds[i] === item._id) {
               return true
            }
         }
      }

      return false
   }

   return (
      <>
         <Row>
            <Col className="mb-5">
               <GreyCard>
                  <Row>
                     <Col className="d-flex justify-content-between align-items-center px-4 mt-3 mb-1">
                        <p
                           style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              color: "#454545",
                           }}
                        >
                           {negotiationBatch ? "Negotiation Item" : "Item List"}
                        </p>
                     </Col>
                  </Row>

                  <hr style={{ background: "#C4C4C4" }} />

                  <Info className="mb-4">
                     <img src={WarningBlue} style={{ position: "absolute", top: "0", left: "0" }} />
                     <p style={{ fontSize: "12px", zIndex: "99" }} className="ml-5">
                        {negotiationBatch
                           ? "Selected item will be included into Negotiation Session."
                           : "Selected item will be included into Auction Session."}
                     </p>
                  </Info>

                  <MuiDataTable.CustomTable
                     stickyHeader={true}
                     containerStyles={{
                        maxHeight: "240px",
                        "&::-webkit-scrollbar": {
                           height: "8px",
                           width: "0",
                        },
                        "&::-webkit-scrollbar-thumb": {
                           background: "#C4C4C4",
                        },
                        scrollbarWidth: "thin",
                        scrollbarColor: "#C4C4C4",
                     }}
                     showHeader={true}
                     headerComponent={
                        <MuiDataTable.Filter
                           withSearchBar={true}
                           handleSearch={(v) =>
                              setDataState({
                                 ...dataState,
                                 query: v.target.value,
                              })
                           }
                           onKeyUp={(v) => v.keyCode === 13 && search(v)}
                        />
                     }
                     withGrandTotal={negotiationBatch ? true : false}
                     grandTotalCurrency={dataProcurement?.currency}
                     grandTotal={dataProcurementItems?.grandTotal}
                     totalColumn={!negotiationBatch?.status ? getTableColumns(dataProcurement).length + 1 : getTableColumns(dataProcurement).length}
                  >
                     <TableHead>
                        <TableRow>
                           {!readOnly &&
                              dataProcurement?.biddingType !== Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE && (
                                 <TableCell size="small" sx={{ background: "#D0D6E0" }}>
                                    <Checkbox size="small" className="px-0 py-2" checked={true} disabled={true} />
                                 </TableCell>
                              )}
                           {dataProcurement?.biddingType !== Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE
                              ? getTableColumns(dataProcurement).map((column, index) => (
                                   <TableCell
                                      size="small"
                                      key={index}
                                      className="py-2"
                                      sx={{
                                         whiteSpace: index !== 4 && "nowrap",
                                         background: "#D0D6E0",
                                      }}
                                      align={index === 3 || index === 5 || index === 6 ? "right" : ""}
                                   >
                                      <TableSortLabel
                                         active={orderBy === column}
                                         direction={order}
                                         onClick={() => handleRequestSort(column)}
                                         IconComponent={ExpandLessIcon}
                                      >
                                         <div>{column}</div>
                                      </TableSortLabel>
                                   </TableCell>
                                ))
                              : dataProcurement?.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE &&
                                getTableColumns(dataProcurement).map((column, index) => (
                                   <TableCell
                                      size="small"
                                      key={index}
                                      className="py-2"
                                      sx={{
                                         whiteSpace: index !== 4 && "nowrap",
                                         background: "#D0D6E0",
                                      }}
                                      align={index === 4 || index === 6 || index === 7 ? "right" : ""}
                                   >
                                    
                                    {console.log({column})}
                                      {index !== 0 && (
                                         <TableSortLabel
                                            active={orderBy === column}
                                            direction={order}
                                            onClick={() => handleRequestSort(column)}
                                            IconComponent={ExpandLessIcon}
                                         >
                                            <div>{column}</div>
                                         </TableSortLabel>
                                      )}
                                   </TableCell>
                                ))}
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {dataProcurementItems.filteredData.length > 0 &&
                           dataProcurementItems.filteredData.map((item, index) => (
                              <TableRow
                                 sx={{
                                    whiteSpace: "nowrap",
                                    background: "#F3F5F4",
                                    "&:hover": {
                                       background: "transparent",
                                    },
                                 }}
                                 key={index}
                              >
                                 {!readOnly && (
                                    <TableCell size="small">
                                       <Checkbox
                                          size="small"
                                          className="px-0 py-2"
                                          value={item._id}
                                          disabled={!isUnitPrice ? true : false}
                                          checked={validateChecked(item)}
                                          // !isUnitPrice
                                          //    ? true
                                          //    : selectedValue
                                          //    ? selectedValue === item._id
                                          //    : index === 0
                                          // }
                                          onChange={handleSelectItem}
                                       />
                                    </TableCell>
                                 )}

                                 <TableCell className="py-2">{item.itemName}</TableCell>
                                 <TableCell className="py-2">{item.prLine}</TableCell>
                                 <TableCell>{item.quantity}</TableCell>
                                 <TableCell className="py-2" align="right" style={{ minWidth: "140px" }}>
                                    {item.minCapableQty}
                                 </TableCell>
                                 <TableCell className="py-2">{item.uom}</TableCell>

                                 {/* Only render this column in negoatiation page */}
                                 {negotiationBatch && (
                                    <>
                                       <TableCell className="py-2" align="right">
                                          {Formservice.CurrencyFormatter(item.pricePerUnit, {
                                             currency: dataProcurement?.currency,
                                          })}
                                       </TableCell>
                                       <TableCell className="py-2" align="right">
                                          {Formservice.CurrencyFormatter(item.subTotal, {
                                             currency: dataProcurement?.currency,
                                          })}
                                       </TableCell>
                                    </>
                                 )}
                              </TableRow>
                           ))}
                     </TableBody>
                  </MuiDataTable.CustomTable>
               </GreyCard>
            </Col>
         </Row>
      </>
   )
}

const mapStateToProps = (state) => {
   return {
      dataProcurement: state.procurementReducer.dataProcurement,
      procurementVendors: state.procurementReducer.procurementVendors,
   }
}

export default connect(mapStateToProps)(ProcurementItemList)

const Info = styled.div`
   background-color: #ecf5fb;
   border-radius: 5px;
   font-weight: 600;
   height: 40px;
   display: flex;
   align-items: center;
   position: relative;
   margin-bottom: 18px;
`
