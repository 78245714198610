import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useRef } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import styled from "styled-components";
import GreySearchIcon from "../assets/images/GreySearchIconMedium.svg";
import { ButtonIconactionNew } from "./Buttonaction";

const defaultStyles = {
  inputgroup: {
    position: "relative",
  },

  input: {
    paddingLeft: 40,
    fontWeight: "bold",
    fontSize: 12,
    color: "#000000",
    height: '44px'
  },
};

export default function Searchfield({
  onKeyUp,
  placeholder = "Search",
  icon,
  onChange,
  styles = {
    inputgroup: {},
    input: {},
    ButtonSearch: {},
  },
}) {
  const fieldRef = useRef();

  return (
    <>
      <InputGroup style={{ ...defaultStyles.inputgroup, ...styles.inputgroup }}>
        <SearchIconStyle >
          <FontAwesomeIcon icon={faSearch} />
        </SearchIconStyle>
        <FormControl
          className="searchbar"
          style={{ ...defaultStyles.input, ...styles.input }}
          type="text"
          placeholder={placeholder}
          onChange={onChange}
          onKeyUp={onKeyUp}
        />
        {/* <FieldInput style={styles.input} type="text" placeholder={placeholder} onChange={onChange} 
                    onBlur={() => fieldRef.current.classList.remove('hovered')}
                    onFocus={() => fieldRef.current.classList.add('hovered')} />
                <Field ref={fieldRef}>
                    <ButtonSearch style={styles.ButtonSearch} type='button' borderless gray icon={GreySearchIcon} onClick={onSearchButtonClicked} />
                </Field> */}
      </InputGroup>
    </>
  );
}

const SearchIconStyle = styled.div`
  position: absolute;
  color: #c4c4c4;
  z-index: 10;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  left: 10px;
`;

const ButtonSearch = styled(ButtonIconactionNew)`
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-left: 0px solid white !important;
  background-color: white;
  border-color: ${(props) => props.theme.colors.whiteDarken30};
`;

const FieldInput = styled(FormControl)`
  border-right-color: transparent !important;
  border-right-width: 0px;
  border-color: ${(props) => props.theme.colors.whiteDarken30};
  height: ${(props) => (props.small ? "34px" : "44px")};
`;

const Field = styled(InputGroup.Append).attrs((props) => {
  className: props.className;
})`
  border-left-color: transparent;
  margin-left: 0 !important;

  &.hovered {
    button {
      border-color: #d9d9d9 !important;
    }
  }

  span {
    background-color: ${(props) => props.theme.colors.white};
    border-left-color: transparent;
    box-shadow: none;
  }
`;
