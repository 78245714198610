import React from "react";
import { Col, Row, Form, Card, FormGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import Pagetitle from "../../../../components/Pagetitle";
import Searchfield from "../../../../components/Searchfield";
import GeneralHeader from "../../../../assets/images/GeneralHeader.svg";
import ProcInitiationHeader from "../../../../assets/images/ProcInitiationHeader.svg";
import AuctionSessionHeader from "../../../../assets/images/AuctionSessionHeader.svg";
import NegotiationHeader from "../../../../assets/images/NegotiationHeader.svg";
import ReportHeader from "../../../../assets/images/ReportHeader.svg";

const BuyerHelpPage = () => {
  const history = useHistory();
  const general = [
    { title: "Log In Auction", link: "/help/login-auction" },
    { title: "Registrasi", link: "/help/registration" },
  ];
  const procInitiation = [
    { title: "Membuat Inisiasi Baru", link: "/help/create-initiation" },
    { title: "Mengakhiri Pengadaan", link: "/help/end-initiation" },
  ];
  const auctionSession = [
    {
      title: "Membuat Sesi Auction",
      link: "/help/create-auction",
    },
  ];
  const negotiation = [
    { title: "Melakukan Proses Negosiasi", link: "/help/negotiation-process" },
    {
      title: "Melakukan Proses Negosiasi Ulang",
      link: "/help/renegotiation-process",
    },
  ];
  const report = [{ title: "Report, Generate, dll", link: "/help/report" }];

  return (
    <>
      <Pagetitle
        title="Help - Buyer"
        withBackButton={true}
        onBackButtonClicked={() => history.goBack()}
      />
      <div className="ml-4">
        <Searchfield
          styles={{
            input: {
              height: "60px",
              boxShadow: "0px 0px 10px 0px #0772B61A",
            },
            inputgroup: {
              display: "flex",
              alignItems: "flex-end",
              marginTop: "24px",
              marginLeft: "-10px",
              position: "initial !important",
            },
          }}
          placeholder="Search modul name or title"
        />

        <Form.Group style={{ marginTop: "30px" }}>
          <Form.Label>
            <p
              style={{
                fontSize: "18px",
                fontWeight: "600",
                margin: "0 0 10px -9px",
              }}
            >
              General
            </p>
          </Form.Label>
          <Row xs={1} md={3} className="g-4">
            {general.map((v, index) => (
              <Col key={index}>
                <Card className="h-100">
                  <div
                    style={{ width: "auto" }}
                    className="d-flex align-items-center"
                  >
                    <Card.Img
                      variant="top"
                      src={GeneralHeader}
                      style={{ backgroundSize: "cover" }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        left: "14px",
                        fontSize: "24px",
                        fontWeight: "bold",
                        color: "#FFFFFF",
                        maxWidth: "70%",
                      }}
                    >
                      {v.title}
                    </div>
                  </div>{" "}
                  <Card.Body className="d-flex flex-column justify-content-between">
                    <Card.Title
                      style={{
                        fontWeight: "600",
                        fontSize: "15px",
                        margin: "-5px 0 30px -5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        history.push(v.link);
                      }}
                    >
                      {v.title}
                    </Card.Title>
                    <Row style={{ fontSize: "14px", fontWeight: "normal" }}>
                      <div
                        className="d-flex align-items-center"
                        style={{ margin: "0 25px 0 10px" }}
                      >
                        <FontAwesomeIcon icon={faClock} />
                        <div className="ml-2">01:27</div>
                      </div>
                      <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faEye} />
                        <div className="ml-2">4 Views</div>
                      </div>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Form.Group>
        <Form.Group style={{ marginTop: "40px" }}>
          <Form.Label>
            <p
              style={{
                fontSize: "18px",
                fontWeight: "600",
                margin: "0 0 15px -9px",
              }}
            >
              Procurement Initiation
            </p>
          </Form.Label>
          <Row xs={1} md={3} className="g-4">
            {procInitiation.map((v, index) => (
              <Col key={index}>
                <Card className="h-100">
                  <div
                    style={{ width: "auto" }}
                    className="d-flex align-items-center"
                  >
                    <Card.Img
                      variant="top"
                      src={ProcInitiationHeader}
                      style={{ backgroundSize: "cover" }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        left: "14px",
                        fontSize: "24px",
                        fontWeight: "bold",
                        color: "#FFFFFF",
                        maxWidth: "70%",
                      }}
                    >
                      {v.title}
                    </div>
                  </div>{" "}
                  <Card.Body className="d-flex flex-column justify-content-between">
                    <Card.Title
                      style={{
                        fontWeight: "600",
                        fontSize: "15px",
                        margin: "-5px 0 30px -5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        history.push(v.link);
                      }}
                    >
                      {v.title}
                    </Card.Title>
                    <Row style={{ fontSize: "14px", fontWeight: "normal" }}>
                      <div
                        className="d-flex align-items-center"
                        style={{ margin: "0 25px 0 10px" }}
                      >
                        <FontAwesomeIcon icon={faClock} />
                        <div className="ml-2">01:27</div>
                      </div>
                      <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faEye} />
                        <div className="ml-2">4 Views</div>
                      </div>
                    </Row>{" "}
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Form.Group>
        <Form.Group style={{ marginTop: "40px" }}>
          <Form.Label>
            <p
              style={{
                fontSize: "18px",
                fontWeight: "600",
                margin: "0 0 15px -9px",
              }}
            >
              Auction Session
            </p>
          </Form.Label>
          <Row xs={1} md={3} className="g-4">
            {auctionSession.map((v, index) => (
              <Col key={index}>
                <Card className="h-100">
                  <div
                    style={{ width: "auto" }}
                    className="d-flex align-items-center"
                  >
                    <Card.Img
                      variant="top"
                      src={AuctionSessionHeader}
                      style={{ backgroundSize: "cover" }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        left: "14px",
                        fontSize: "24px",
                        fontWeight: "bold",
                        color: "#FFFFFF",
                        maxWidth: "70%",
                      }}
                    >
                      {v.title}
                    </div>
                  </div>{" "}
                  <Card.Body className="d-flex flex-column justify-content-between">
                    <Card.Title
                      style={{
                        fontWeight: "600",
                        fontSize: "15px",
                        margin: "-5px 0 30px -5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        history.push(v.link);
                      }}
                    >
                      {v.title}
                    </Card.Title>
                    <Row style={{ fontSize: "14px", fontWeight: "normal" }}>
                      <div
                        className="d-flex align-items-center"
                        style={{ margin: "0 25px 0 10px" }}
                      >
                        <FontAwesomeIcon icon={faClock} />
                        <div className="ml-2">01:27</div>
                      </div>
                      <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faEye} />
                        <div className="ml-2">4 Views</div>
                      </div>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Form.Group>
        <Form.Group style={{ marginTop: "40px" }}>
          <Form.Label>
            <p
              style={{
                fontSize: "18px",
                fontWeight: "600",
                margin: "0 0 15px -9px",
              }}
            >
              Negotiation
            </p>
          </Form.Label>
          <Row xs={1} md={3} className="g-4">
            {negotiation.map((v, index) => (
              <Col key={index}>
                <Card className="h-100">
                  <div
                    style={{ width: "auto" }}
                    className="d-flex align-items-center"
                  >
                    <Card.Img
                      variant="top"
                      src={NegotiationHeader}
                      style={{ backgroundSize: "cover" }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        left: "14px",
                        fontSize: "24px",
                        fontWeight: "bold",
                        color: "#FFFFFF",
                        maxWidth: "70%",
                      }}
                    >
                      {v.title}
                    </div>
                  </div>{" "}
                  <Card.Body className="d-flex flex-column justify-content-between">
                    <Card.Title
                      style={{
                        fontWeight: "600",
                        fontSize: "15px",
                        margin: "-5px 0 30px -5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        history.push(v.link);
                      }}
                    >
                      {v.title}
                    </Card.Title>
                    <Row style={{ fontSize: "14px", fontWeight: "normal" }}>
                      <div
                        className="d-flex align-items-center"
                        style={{ margin: "0 25px 0 10px" }}
                      >
                        <FontAwesomeIcon icon={faClock} />
                        <div className="ml-2">01:27</div>
                      </div>
                      <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faEye} />
                        <div className="ml-2">4 Views</div>
                      </div>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Form.Group>
        <Form.Group style={{ margin: "40px 0 50px 0" }}>
          <Form.Label>
            <p
              style={{
                fontSize: "18px",
                fontWeight: "600",
                margin: "0 0 15px -9px",
              }}
            >
              Report
            </p>
          </Form.Label>
          <Row xs={1} md={3} className="g-4">
            {report.map((v, index) => (
              <Col key={index}>
                <Card className="h-100">
                  <div
                    style={{ width: "auto" }}
                    className="d-flex align-items-center"
                  >
                    <Card.Img
                      variant="top"
                      src={ReportHeader}
                      style={{ backgroundSize: "cover" }}
                    />
                    <div
                      style={{
                        position: "absolute",
                        left: "14px",
                        fontSize: "24px",
                        fontWeight: "bold",
                        color: "#FFFFFF",
                        maxWidth: "70%",
                      }}
                    >
                      {v.title}
                    </div>
                  </div>{" "}
                  <Card.Body className="d-flex flex-column justify-content-between">
                    <Card.Title
                      style={{
                        fontWeight: "600",
                        fontSize: "15px",
                        margin: "-5px 0 30px -5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        history.push(v.link);
                      }}
                    >
                      {v.title}
                    </Card.Title>
                    <Row style={{ fontSize: "14px", fontWeight: "normal" }}>
                      <div
                        className="d-flex align-items-center"
                        style={{ margin: "0 25px 0 10px" }}
                      >
                        <FontAwesomeIcon icon={faClock} />
                        <div className="ml-2">01:27</div>
                      </div>
                      <div className="d-flex align-items-center">
                        <FontAwesomeIcon icon={faEye} />
                        <div className="ml-2">4 Views</div>
                      </div>
                    </Row>{" "}
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Form.Group>
      </div>
    </>
  );
};

export default BuyerHelpPage;

const FilterContent = styled.div`
  display: flex;

  div {
    margin: 0 5px;
  }
`;
