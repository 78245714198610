import { faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import React, { useEffect, useImperativeHandle, useRef, useState } from "react"
import { Col, Row } from "react-bootstrap"
import styled from "styled-components"
import Constant from "../../constant"
import smallRedUnion from "../assets/images/Icon-smallRedUnion.svg"
import warningIcon from "../assets/images/WarningIcon.svg"
import Formservice from "../services/Formservice"
import Buttonaction from "./Buttonaction"
import Dateinfo from "./Dateinfo"
import ModalForm from "./Modalform"

export default function VendorAuctionOfferBubble({
   passedInRef,
   idx,
   data,
   accountId,
   componentId,
   handleReqDisqualify,
   canReqDisqualify,
   reqDisqualify,
   setReqDisqualify,
   auctionStatus,
   currency,
}) {
   const detailsRef = useRef()
   // const [reqDisqualify, setReqDisqualify] = useState(false)
   const [disqualified, setDisqualified] = useState(false)
   const [disqualifyData, setDisqualifyData] = useState([
      {
         id: "2323",
         sessionNumber: "Sesi-01/Auction",
         participant: "PT Agung Sejahtera",
         item: "Pengadaan OS License dan Annual Support Tahun 2020",
         offerTime: "05/03/2021 14:28",
         disqualifiedPrice: "100.000.00",
         reason: "Salah input harga",
         disqualifiedBy: "Ariana Grande",
         disqualifiedTime: "09/09/2021 - 07:59 PM",
      },
   ])
   const [index, setIndex] = useState(0)

   const handleDetails = (id) => {
      const indexDisqualifyData = disqualifyData.findIndex((v) => v.id === id)
      if (indexDisqualifyData >= 0) {
         setIndex(indexDisqualifyData)
         detailsRef.current.show()
      }
   }

   const handleRequestDisqualify = () => {
      setReqDisqualify(true)

      if (handleReqDisqualify) handleReqDisqualify({ ...data, index: idx })
      // setReqDisqualify(false)
   }

   useEffect(() => {
      if (data && data.reqForDisqualification) {
         setReqDisqualify(true)
      } else {
         setReqDisqualify(false)
      }

      if (data && data.priceDisqualified) {
         setDisqualified(true)
      } else {
         setDisqualified(false)
      }

      console.log(data)
   }, [])

   useImperativeHandle(passedInRef, () => ({
      doneReqDisqualifyData: () => {
         setReqDisqualify(false)
      },

      updateReqDisqualifyStatus: (_isRequesting) => {
         setReqDisqualify(_isRequesting)
      },

      disqualifyOffer: () => {
         setReqDisqualify(false)
         setDisqualified(true)
      },
   }))

   return (
      <>
         {data.accountId === accountId ? (
            <>
               {data.priceTooLow && (
                  <PriceToLowMessage className="mt-3">
                     <Col className="mt-2" style={{ paddingTop: "3px" }} lg={12}>
                        <img src={warningIcon} className="mr-2" />
                        Your price is too low
                     </Col>
                  </PriceToLowMessage>
               )}
               <OfferBubble
                  id={componentId}
                  className="mt-3 text-white"
                  priceTooLow={data.priceTooLow}
                  disqualify={disqualified}
               >
                  <Col lg={6} className="p-0">
                     <VendorName title={data.vendorName}>{data.vendorName} (You)</VendorName>
                  </Col>
                  <Col lg={6} className="p-0">
                     <p className="d-flex justify-content-end">
                        <Dateinfo
                           date={data.createdAt}
                           format="dd/mm/yyyy"
                           style={{
                              fontSize: "12px",
                              color: disqualified ? "#454545" : "#ffffff",
                           }}
                        />
                        <span style={{ fontSize: "12px" }}>
                           {data.createdAt &&
                              new Date(data.createdAt).toLocaleString("en-US", {
                                 hour: "2-digit",
                                 minute: "2-digit",
                                 second: "2-digit",
                                 hour12: false,
                              })}
                        </span>
                     </p>
                  </Col>
                  <Col lg={12} className="p-0">
                     <TotalOffer>{Formservice.CurrencyFormatter(data.totalOffer, { currency })}</TotalOffer>
                  </Col>
                  {canReqDisqualify && !disqualified && (
                     <Col lg={12} className="mt-3 d-flex justify-content-end p-0">
                        <Buttonaction
                           accentRed={(canReqDisqualify && !disqualified) || auctionStatus === Constant.STATUS.ON_GOING}
                           xsmall
                           disabled={reqDisqualify || disqualified || auctionStatus !== Constant.STATUS.ON_GOING}
                           faIcon={faTimesCircle}
                           label="Request Disqualify"
                           onClick={handleRequestDisqualify}
                        />
                     </Col>
                  )}
                  {disqualified && (
                     <>
                        <Col lg={10} className="d-flex justify-content-end p-0"></Col>
                        <Col lg={2} className="mt-3 d-flex align-items-end justify-content-end p-0">
                           <Details>Details</Details>
                           <img src={smallRedUnion} alt="" className="mr-2" />
                           <Disqualified>Disqualified</Disqualified>
                        </Col>
                     </>
                  )}
               </OfferBubble>
            </>
         ) : (
            <OfferBubbleOtherVendor id={componentId} className="mt-3">
               <Col lg={6} className="p-0">
                  <VendorName>Other Vendor</VendorName>
               </Col>
               <Col lg={6} className="p-0">
                  <p className="d-flex justify-content-end" style={{ fontSize: "12px" }}>
                     <Dateinfo date={data.createdAt} format="dd/mm/yyyy" />
                     <span style={{ fontSize: "12px" }}>
                        {data.createdAt &&
                           new Date(data.createdAt).toLocaleString("en-US", {
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                              hour12: false,
                           })}
                     </span>
                  </p>
               </Col>
               <Col lg={10} className="p-0">
                  <TotalOffer>{Formservice.CurrencyFormatter(data.totalOffer, { currency })}</TotalOffer>
               </Col>
               {disqualified && (
                  <Col lg={2} className="d-flex align-items-center justify-content-end p-0">
                     <img src={smallRedUnion} alt="" className="mr-2" />
                     <Disqualified>Disqualified</Disqualified>
                  </Col>
               )}
            </OfferBubbleOtherVendor>
         )}
      </>
   )
}

const OfferBubble = styled(Row)`
   border-radius: ${({ priceTooLow }) => (priceTooLow ? "0 0 5px 4px" : "5px 5px 5px 4px")};
   background-color: ${({ disqualify }) => (disqualify ? "#FDE9EF" : "#0772b6")};
   ${({ disqualify }) => (disqualify ? "color: #454545 !important" : "")};
   height: auto;
   margin-right: 0;
   padding: 10px;
   width: 90%;
   margin-left: 10%;
   position: relative;

   // &::after {
   //   content: "";
   //   background-color: red;
   //   height: 10px;
   //   width: 10px;
   //   position: absolute;
   //   right: 0;
   //   bottom: 0;
   //   border-radius: 1px;
   //   transform: matrix(0.75, -0.65, -0.67, -0.75, 0, 0);
   // }
`

const OfferBubbleOtherVendor = styled(OfferBubble)`
   background-color: #ffffff;
   border: 1px solid #c4c4c4;
   margin-left: 0;
`

const PriceToLowMessage = styled(Row)`
   border-radius: 5px 5px 0 0;
   font-size: 12px;
   color: #c71822;
   margin-left: 10%;
   background-color: #fde9ef;
   margin-right: 0;
   margin-bottom: -15px;
   height: 40px;
`

const Disqualified = styled.p`
   font-size: 12px;
   color: #f44437;
   font-weight: 700;
`

const Details = styled.a`
   cursor: pointer;
   color: #454545;
   font-size: 12px;
   font-weight: 600;
   padding-right: 10px;
   padding-top: 1.5px;
   text-decoration-line: underline;

   &:hover {
      color: #454545;
   }
`

const VendorName = styled.p`
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
   font-size: 12px;
   margin-bottom: 8px;
   cursor: default;
`

const TotalOffer = styled.p`
   font-size: 16px;
   font-weight: 500;
`

const TextArea = styled.textarea`
   height: 90px;
   border: 1px solid #c4c4c4;
   border-radius: 5px;
   padding: 10px 14px !important;

   &:focus {
      outline: none;
   }
`
