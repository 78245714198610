import Apiservice from "../services/Apiservice";

const API_URL = Apiservice.url.AUCTION_URL + "/api/secured/negotiation-batch-item";

function NegotiationBatchItemService(){
    this.findByBatchId = function(id, callback){
        return async () => {
            const resp = await Apiservice.invokeGet(API_URL+ "/find-by-negotiation-batch/" + id);
            if (resp.status == 200) {
                if (callback) {
                    callback(resp.data.data);
                }
            }
        }
    }
    this.saveAsDraft = function(negotiationBatch, itemIds, callback){
        return async () => {
            const resp = await Apiservice.invokePost(API_URL+ "/save-as-draft", {
                negotiationBatch: negotiationBatch,
                itemIds: itemIds
            });
            if (resp.status == 200) {
                if (callback) {
                    callback(resp.data.data);
                }
            }
        }
    }
}

export default NegotiationBatchItemService
