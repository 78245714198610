import React from 'react';
import styled from "styled-components";
import Constant from '../../constant';

export default function Statuslabel({ label, ...props }) {
    const handleLabelText = (text) => {
        return text.trim().toLowerCase().replace(/_/g, " ").replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
    }

    return (
        <>
            <Status {...props}>
                {(label == "DRAFT" && "Draft") ||
                    (label == "APPROVED" && "Approved") ||
                    (label == "REJECTED" && "Rejected") ||
                    (label == "PENDING_APPROVAL" && "Pending Approval") ||
                    (label == "WAITING_FOR_PO" && "Approved") ||
                    (label == "WAITING_FOR_QUOTATION" && "Waiting Quotation") ||
                    (label == "WAITING" && "Waiting") ||
                    (label == "QUOTATION_READY" && "Quotation Ready") ||
                    (label == "PUBLISHED" && "Published") ||
                    (label == "REQUIREMENT" && "Requirement") ||
                    (label == "CATALOGUE" && "Catalogue") ||
                    (label == "NEW" && "New") ||
                    (label == "PROCESS" && "Process") ||
                    (label == "RFQ_DONE" && "RFQ Done") ||
                    (label == "DONE" && "Done") ||
                    (label == "SUCCESS" && "Success") ||
                    (label == "CANCEL" && "Cancel") ||
                    (label == "CANCELED" && "Canceled") ||
                    (label == "OPEN" && "Open") ||
                    (label == "NOT_CONFIRMED" && "Not Confirmed") ||
                    (label == "NOT_STARTED" && "Not Started") ||
                    (label == "ON_GOING" && "On Going") ||
                    (label == "PURCHASE_ORDER" && "Purchase Order") || 
                    (label == "ATTEND" && "Attend") || 
                    (label?.toUpperCase() === Constant.STATUS.NOT_SCHEDULED && handleLabelText(Constant.STATUS.NOT_SCHEDULED)) || 
                    (label == "NOT_ATTEND" && "Not Attend") || label
                }
            </Status>
        </>
    )
}

const Status = styled.span`
    height: 20px;
    border-radius: ${props => props.theme.borderRadius.normal};
    background-color: ${props => props.status == "DRAFT" ? props.theme.colors.whiteDarken30 :
        props.status == "PENDING_APPROVAL" ? props.theme.colors.yellow :
            // props.status == "PASSED" ? props.theme.colors.blue :
                props.status == "QUOTATION_READY" || props.status == 'APPROVED' ? '#40E28F' :
                    props.status == "REJECTED" || props.status == "CANCELED" || props.status == "CANCEL" 
                                        || props.status == "CLOSED" || props.status == "NOT_PASSED" 
                                        || props.status == "END" || props.status == "NOT_ATTEND" ? props.theme.colors.red :
                        props.status == Constant.STATUS.NOT_SCHEDULED ? "#FFB301" :
                        props.status == "PUBLISHED" ? props.theme.colors.blue :
                            props.status == "OPEN" ? props.theme.colors.blueOcean :
                                props.status == "REQUIREMENT" ? props.theme.colors.blue :
                                    props.status == "NEW" ? '#20A4F8' :
                                        props.status == "PROCESS" ? props.theme.colors.yellow :
                                            props.status == "SUCCESS" || props.status == "ATTEND" ? props.theme.colors.green :
                                                props.status == "CATALOGUE" ? '#E4B2F6' :
                                                    props.status == "WAITING" ? props.theme.colors.yellow :
                                                        props.status == "RFQ_DONE" || props.status == "RFQ DONE" ? "#40E28F" :
                                                            props.status == "DONE" || props.status == "PASSED" ? "#4DAF51" :
                                                                props.status == "PURCHASE_ORDER" ? props.theme.colors.purple :
                                                                    props.status == "ON_GOING" ? "#BB6BD9" :
                                                                        props.status == "NOT_STARTED" ? props.theme.colors.whiteDarken30 :
                                                                            props.status == "WAITING_FOR_QUOTATION" ? props.theme.colors.yellow : props.theme.colors.whiteDarken30
    };
    font-size: 9px;
    // cursor: pointer;
    padding: 0 8px;
    line-height: 0;
    display: grid;
    place-items: center;
    color: ${props => props.status == "CATALOGUE" ? 'black' : props.status == "WAITING" ? 'black' : props.status == "ITEM_LABEL" ? "black" : 'white'};
    margin-left: 10px;
    margin-right: 10px;
    // text-transform: uppercase;
    width: fit-content;
    font-family: Roboto;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
`