import { dispatch } from "react-hot-toast"
import Constant from "../../../constant"
import Apiservice from "../../services/Apiservice"
import { setAppLoading } from "./appAction"

export function createProcurement(
   {
      procurementName,
      negotiationType,
      evaluationMethod,
      winnerType,
      biddingType,
      currencyType,
      priceIncludeTax,
      currency,
   },
   callback,
   errorCallback,
) {
   return async (dispatch) => {
      dispatch(setAppLoading(true))
      const resp = await Apiservice.invokePost(Apiservice.url.AUCTION_URL + "/api/secured/procurement", {
         procurementName,
         negotiationType,
         evaluationMethod,
         winnerType,
         biddingType,
         currencyType,
         priceIncludeTax,
         currency,
      })
      dispatch(setAppLoading(false))
      if (resp.status == 200) {
         callback(resp.data.data)
      } else {
         errorCallback(resp.data.errors)
      }
   }
}

export function createProcurementItem(
   { procurementId, itemCode, itemName, minCapableQty, uom, pricePerUnit, quantity, subTotal, specification, images },
   callback,
   errorCallback,
) {
   return async (dispatch) => {
      dispatch(setAppLoading(true))
      const resp = await Apiservice.invokePost(Apiservice.url.AUCTION_URL + "/api/secured/procurement-item", {
         procurementId,
         itemCode,
         itemName,
         minCapableQty,
         uom,
         pricePerUnit,
         quantity,
         subTotal,
         specification,
         images,
      })
      dispatch(setAppLoading(false))

      if (resp.status == 200) {
         await dispatch(fetchProcurementItems({ procurementId, page: 1, limit: 10, query: "" }))

         callback()
      } else {
         errorCallback(resp.data.errors)
      }
   }
}

export function createProcurementVendor(
   { procurementId, name, email, phone, phonePrefix, city, picVendor },
   callback,
   errorCallback,
) {
   return async (dispatch) => {
      dispatch(setAppLoading(true))
      const resp = await Apiservice.invokePost(`${Apiservice.url.AUCTION_URL}/api/secured/procurement-vendor`, {
         procurementId,
         name,
         email,
         phone: `${phonePrefix}${phone}`,
         phonePrefix,
         city,
         picVendor,
      })
      dispatch(setAppLoading(false))
      if (resp.status == 200) {
         await dispatch(fetchProcurementVendors({ procurementId, page: 1, limit: 10 }))
         callback(resp.data.data)
      } else {
         errorCallback(resp.data.errors)
      }
   }
}

export function changeAuctionStatus(payload, callback, errorCallback) {
   return async (dispatch) => {
      dispatch(setAppLoading(true))
      const resp = await Apiservice.invokePost(
         `${Apiservice.url.AUCTION_URL}/api/secured/auction/change-status`,
         payload,
      )

      dispatch(setAppLoading(false))
      if (resp.status == 200) {
         if (callback) callback(resp.data.data)
      } else {
         errorCallback(resp.data.errors)
      }
   }
}

export function updateProcurementInfo(
   {
      procurementId,
      procurementName,
      negotiationType,
      evaluationMethod,
      biddingType,
      winnerType,
      currencyType,
      priceIncludeTax,
   },
   callback,
   errorCallback,
) {
   return async (dispatch) => {
      const resp = await Apiservice.invokePatch(
         `${Apiservice.url.AUCTION_URL}/api/secured/procurement/${procurementId}`,
         {
            procurementId,
            procurementName,
            negotiationType,
            evaluationMethod,
            biddingType,
            winnerType,
            currencyType,
            priceIncludeTax,
         },
      )

      if (resp.status != 200) {
         errorCallback(resp.data.errors)
      } else {
         await dispatch(getProcurement({ procurementId }))

         callback()
      }
   }
}

export function updateProcurementItem(
   {
      procurementId,
      procurementItemId,
      itemCode,
      itemName,
      minCapableQty,
      uom,
      pricePerUnit,
      quantity,
      subTotal,
      specification,
      images,
      deletedImageIds,
   },
   callback,
   errorCallback,
) {
   return async (dispatch) => {
      dispatch(setAppLoading(true))
      const resp = await Apiservice.invokePatch(
         `${Apiservice.url.AUCTION_URL}/api/secured/procurement-item/${procurementItemId}`,
         {
            procurementId,
            itemCode,
            itemName,
            minCapableQty,
            uom,
            pricePerUnit,
            quantity,
            subTotal,
            specification,
            images,
            deletedImageIds,
         },
      )
      dispatch(setAppLoading(false))

      if (resp.status != 200) {
         errorCallback(resp.data.errors)
      } else {
         await dispatch(fetchProcurementItems({ procurementId }))

         callback()
      }
   }
}

export function updateProcurementVendor(
   { procurementVendorId, procurementId, name, email, phone, phonePrefix, city, picVendor },
   callback,
   errorCallback,
) {
   return async (dispatch) => {
      dispatch(setAppLoading(true))
      const resp = await Apiservice.invokePatch(
         `${Apiservice.url.AUCTION_URL}/api/secured/procurement-vendor/${procurementVendorId}`,
         {
            procurementId,
            name,
            email,
            phone: `${phonePrefix}${phone}`,
            phonePrefix,
            city,
            picVendor,
         },
      )
      dispatch(setAppLoading(false))

      if (resp.status == 200) {
         // await dispatch(fetchProcurementVendors({ procurementId }));
         callback()
      } else {
         errorCallback(resp.data.errors)
      }
   }
}

export function updateProcurementScoring(
   { procurementId, administrationValue, technicalValue, priceValue },
   callback,
   errorCallback,
) {
   return async (dispatch) => {
      dispatch(setAppLoading(true))
      const resp = await Apiservice.invokePatch(
         `${Apiservice.url.AUCTION_URL}/api/secured/procurement/${procurementId}/evaluation-score`,
         {
            administrationValue,
            technicalValue,
            priceValue,
         },
      )
      dispatch(setAppLoading(false))

      if (resp.status != 200) {
         errorCallback(resp.data.errors)
      } else {
         await dispatch(getProcurement({ procurementId }))

         callback()
      }
   }
}

export function updateProcurementVendorAdministrationScore(
   { procurementId, procurementVendorId, passed },
   callback,
   errorCallback,
) {
   return async (dispatch) => {
      dispatch(setAppLoading(true))
      const resp = await Apiservice.invokePatch(
         `${Apiservice.url.AUCTION_URL}/api/secured/procurement-vendor/${procurementVendorId}/administration-score`,
         {
            procurementId,
            passed,
         },
      )
      dispatch(setAppLoading(false))

      if (resp.status != 200) {
         errorCallback(resp.data.errors)
      } else {
         await dispatch(fetchProcurementVendors({ procurementId, page: 1, limit: 10 }))

         if (callback) callback()
      }
   }
}

export function updateProcurementVendorTechnicalScore(
   { procurementId, procurementVendorId, score },
   callback,
   errorCallback,
) {
   return async (dispatch) => {
      dispatch(setAppLoading(true))
      const resp = await Apiservice.invokePatch(
         `${Apiservice.url.AUCTION_URL}/api/secured/procurement-vendor/${procurementVendorId}/technical-score`,
         {
            procurementId,
            score,
         },
      )
      dispatch(setAppLoading(false))

      if (resp.status != 200) {
         errorCallback(resp.data.errors)
      } else {
         await dispatch(fetchProcurementVendors({ procurementId, page: 1, limit: 10 }))

         if (callback) callback()
      }
   }
}

export function submitProcurement({ procurementId }, callback, errorCallback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokePost(
         `${Apiservice.url.AUCTION_URL}/api/secured/procurement/${procurementId}/submit`,
      )
      if (resp.status == 200) {
         await dispatch(fetchProcurements({ page: 1, limit: 10 }))

         if (callback) callback(resp.data.data)
      } else {
         errorCallback(resp.data.errors)
      }
   }
}

export function deleteProcurement({ procurementIds }, callback, errCallback) {
   return async (dispatch) => {
      dispatch(setAppLoading(true))
      const resp = await Apiservice.invokeDel(`${Apiservice.url.AUCTION_URL}/api/secured/procurement`, {
         procurementIds,
      })

      dispatch(setAppLoading(false))

      if (resp.status == 200) {
         if (callback) {
            callback(resp)
         }
      } else {
         if (errCallback) {
            errCallback(resp)
         }
      }
   }
}

export function deleteProcurementItem({ procurementId, procurementItemIds }, callback, errorCallback) {
   return async (dispatch) => {
      dispatch(setAppLoading(true))
      const resp = await Apiservice.invokeDel(`${Apiservice.url.AUCTION_URL}/api/secured/procurement-item`, {
         procurementId,
         procurementItemIds,
      })
      dispatch(setAppLoading(false))

      if (resp.status == 200) {
         callback()
      } else {
         errorCallback(resp.data.errors)
      }
   }
}

export function deleteProcurementVendor({ procurementId, procurementVendorIds }, callback, errorCallback) {
   return async (dispatch) => {
      dispatch(setAppLoading(true))
      const resp = await Apiservice.invokeDel(`${Apiservice.url.AUCTION_URL}/api/secured/procurement-vendor`, {
         procurementId,
         procurementVendorIds,
      })
      dispatch(setAppLoading(false))

      if (resp.status == 200) {
         callback()
      } else {
         errorCallback(resp.data.errors)
      }
   }
}

export function fetchProcurementItems({ procurementId, page = 1, limit = 10, query = "" }, callback, errCallback) {
   return async (dispatch) => {
      dispatch(setAppLoading(true))
      const resp = await Apiservice.invokeGet(
         `${Apiservice.url.AUCTION_URL}/api/secured/procurement-item?procurementId=${procurementId}&page=${page}&limit=${limit}&query=${query}`,
      )
      dispatch(setAppLoading(false))

      if (resp.status == 200) {
         if (callback) {
            callback(resp.data.data)
         }

         await dispatch({
            type: "FETCH_PROCUREMENT_ITEMS",
            payload: resp.data.data,
         })
      } else {
         errCallback()
      }
   }
}

export function fetchProcurementUnitItems({ auctionId, page = 1, limit = 99999, query = "" }, callback) {
   return async (dispatch) => {
      dispatch(setAppLoading(true))
      const resp = await Apiservice.invokeGet(
         `${Apiservice.url.AUCTION_URL}/api/secured/auction-item/${auctionId}/find-by-auction`,
      )
      dispatch(setAppLoading(false))

      if (resp.status == 200) {
         const result = resp.data.data.filteredData
         for (const each of result) {
            each.offer = 0
            each.totalOffer = 0
         }
         callback(result)
         await dispatch({
            type: "FETCH_PROCUREMENT_UNIT_ITEMS",
            payload: result,
         })
      }
   }
}

export function fetchProcurementItemsPublic({ procurementId, page = 1, limit = 10, query = "" }, callback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokeGet(
         `${Apiservice.url.AUCTION_URL}/api/procurement-item?procurementId=${procurementId}&page=${page}&limit=${limit}&query=${query}`,
      )

      if (resp.status == 200) {
         await dispatch({
            type: "FETCH_PROCUREMENT_ITEMS",
            payload: resp.data.data,
         })

         if (callback) {
            callback(resp.data.data)
         }
      }
   }
}

export function rebuildIndex(callback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokePost(`${Apiservice.url.AUCTION_URL}/api/secured/procurement/rebuild-index`)
      if (resp.status === 200) {
         if (callback) {
            callback(resp.data.data)
         }
      }
   }
}

export function searchProcurement(keyword, callback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokeGet(
         `${Apiservice.url.AUCTION_URL}/api/secured/procurement/search?keyword=${keyword}`,
      )
      if (resp.status === 200) {
         if (callback) {
            callback(resp.data.data)
         }
      }
   }
}

export function fetchProcurements(
   { page = 1, limit = 1, query = "", postDateFrom, postDateTo, status, biddingType },
   callback,
) {
   return async (dispatch) => {
      dispatch(setAppLoading(true))
      let url = `${Apiservice.url.AUCTION_URL}/api/secured/procurement?page=${page}&limit=${limit}&query=${query}`
      if (postDateFrom && postDateTo) {
         url += `&postDateFrom=${postDateFrom}&postDateTo=${postDateTo}`
      }

      if (status && status.length > 0) {
         url += `&status=${status}`
      }

      if (biddingType && biddingType.length > 0) {
         url += `&biddingType=${biddingType}`
      }

      const resp = await Apiservice.invokeGet(url)
      dispatch(setAppLoading(false))

      if (resp.status == 200) {
         await dispatch({
            type: "FETCH_PROCUREMENTS",
            payload: resp.data.data,
         })

         if (callback) {
            callback(resp.data.data)
         }
      }
   }
}

export function fetchProcurementVendors({ procurementId, page = 1, limit = 5, query = "" }, callback, errCallback) {
   return async (dispatch) => {
      dispatch(setAppLoading(true))
      const resp = await Apiservice.invokeGet(
         `${Apiservice.url.AUCTION_URL}/api/secured/procurement-vendor?procurementId=${procurementId}&page=${page}&limit=${limit}&query=${query}`,
      )
      dispatch(setAppLoading(false))

      if (resp.status == 200) {
         await dispatch({
            type: "FETCH_PROCUREMENT_VENDORS",
            payload: resp.data.data,
         })

         if (callback) {
            callback(resp.data.data)
         }

         if (errCallback) {
            errCallback()
         }
      }
   }
}

export function fetchParticipantByAuction({ auctionId }, callback, errCallback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokeGet(
         `${Apiservice.url.AUCTION_URL}/api/procurement-vendor/participant/by-auction/${auctionId}`,
      )

      if (resp.status === 200) {
         if (callback) {
            callback(resp.data)
         }
      } else {
         if (errCallback) {
            errCallback()
         }
      }
   }
}

export function fetchProcurementVendorsWithoutPagination({ procurementId }, callback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokeGet(
         `${Apiservice.url.AUCTION_URL}/api/procurement-vendor/participant/${procurementId}`,
      )

      if (resp.status == 200) {
         // await dispatch({
         //   type: "FETCH_PROCUREMENT_VENDORS",
         //   payload: resp.data.data,
         // });

         if (callback) {
            callback(resp.data)
         }
      }
   }
}

export function fetchProcurementVendorsSimulationWithoutPagination({ procurementId }, callback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokeGet(
         `${Apiservice.url.AUCTION_URL}/api/procurement-vendor/simulation/participant/${procurementId}`,
      )

      if (resp.status == 200) {
         // await dispatch({
         //   type: "FETCH_PROCUREMENT_VENDORS",
         //   payload: resp.data.data,
         // });

         if (callback) {
            callback(resp.data)
         }
      }
   }
}

export function fetchProcurementAuctions({ procurementId, page = 1, limit = 10, query = "" }, callback, errorCallback) {
   return async (dispatch) => {
      dispatch(setAppLoading(true))
      const resp = await Apiservice.invokeGet(
         `${Apiservice.url.AUCTION_URL}/api/secured/auction?procurementId=${procurementId}&page=${page}&limit=${limit}&query=${query}`,
      )
      dispatch(setAppLoading(false))

      if (resp.status == 200) {
         await dispatch({
            type: "FETCH_PROCUREMENT_AUCTIONS",
            payload: resp.data.data,
         })

         if (callback) {
            callback(resp.data.data)
         }
      } else {
         if (errorCallback) {
            errorCallback()
         }
      }
   }
}

export function fetchAuctionInvitedVendors({ auctionId, page = 1, limit = 10, query = "" }, callback, errCallback) {
   return async (dispatch) => {
      dispatch(setAppLoading(true))
      const resp = await Apiservice.invokeGet(
         `${Apiservice.url.AUCTION_URL}/api/secured/auction/fetch-invitation-by-auction/${auctionId}?page=${page}&limit=${limit}&query=${query}`,
      )
      dispatch(setAppLoading(false))

      if (resp.status == 200) {
         await dispatch({
            type: "FETCH_AUCTION_INVITED_VENDORS",
            payload: resp.data.data,
         })

         if (callback) {
            callback(resp.data.data)
         }
      } else {
         errCallback()
      }
   }
}

export function resetDataProcurement() {
   return (dispatch) => {
      dispatch({
         type: "RESET_DATA_PROCUREMENT",
      })
   }
}

export function getProcurement({ procurementId }, callback, errorCallback, masterData) {
   return async (dispatch) => {
      dispatch(setAppLoading(true))
      const resp = await Apiservice.invokeGet(`${Apiservice.url.AUCTION_URL}/api/secured/procurement/${procurementId}`)
      dispatch(setAppLoading(false))
      if (resp.status == 200) {
         let procurementData = resp.data.data
         if (masterData) {
            // kondisi untuk mengakomodir data lama
            if (!procurementData.currencyTypeId) {
               // langsung ambil data lama
               procurementData.currencyType = procurementData.currencyType
            } else {
               procurementData.currencyType = masterData.currencyTypes.find(
                  (data) => data._id == procurementData.currencyTypeId,
               ).value
            }
            if (procurementData.currencyType === Constant.CURRENCY_TYPE.SINGLE_CURRENCY) {
               // kondisi untuk mengakomodir data lama
               if (!procurementData.currencyId) {
                  // set semua ke IDR
                  procurementData.currency = "IDR"
               } else {
                  procurementData.currency = masterData.currencies.find(
                     (data) => data._id == procurementData.currencyId,
                  ).value
               }
               // ==================================
            }
         }

         if (callback) callback(procurementData)

         await dispatch({
            type: "GET_PROCUREMENT",
            payload: procurementData,
         })
      } else {
         if (errorCallback) errorCallback()
      }
   }
}

export function getProcurementPublic({ procurementId }, callback, errorCallback, masterData) {
   return async (dispatch) => {
      dispatch(setAppLoading(true))
      const resp = await Apiservice.invokeGet(`${Apiservice.url.AUCTION_URL}/api/procurement/${procurementId}`)
      dispatch(setAppLoading(false))
      if (resp.status == 200) {
         let procurementData = resp.data.data
         if (masterData) {
            // kondisi untuk mengakomodir data lama
            if (!procurementData.currencyTypeId) {
               // langsung ambil data lama
               procurementData.currencyType = procurementData.currencyType
            } else {
               procurementData.currencyType = masterData.currencyTypes.find(
                  (data) => data._id == procurementData.currencyTypeId,
               ).value
            }
            if (procurementData.currencyType === Constant.CURRENCY_TYPE.SINGLE_CURRENCY) {
               // kondisi untuk mengakomodir data lama
               if (!procurementData.currencyId) {
                  // set semua ke IDR
                  procurementData.currency = "IDR"
               } else {
                  procurementData.currency = masterData.currencies.find(
                     (data) => data._id == procurementData.currencyId,
                  ).value
               }
               // ==================================
            }
         }

         if (callback) callback(procurementData)
      } else {
         if (errorCallback) errorCallback()
      }
   }
}

export function getProcurementItem({ procurementItemId }, callback, errorCallback) {
   return async (dispatch) => {
      dispatch(setAppLoading(true))
      const resp = await Apiservice.invokeGet(
         `${Apiservice.url.AUCTION_URL}/api/secured/procurement-item/${procurementItemId}`,
      )
      dispatch(setAppLoading(false))
      if (resp.status == 200) {
         callback(resp.data.data)

         await dispatch({
            type: "GET_PROCUREMENT_ITEM",
            payload: resp.data.data,
         })
      } else {
         errorCallback()
      }
   }
}

export function getProcurementVendor({ procurementVendorId }, callback, errorCallback) {
   return async (dispatch) => {
      dispatch(setAppLoading(true))
      const resp = await Apiservice.invokeGet(
         `${Apiservice.url.AUCTION_URL}/api/secured/procurement-vendor/${procurementVendorId}`,
      )
      dispatch(setAppLoading(false))
      if (resp.status == 200) {
         callback(resp.data.data)

         await dispatch({
            type: "GET_PROCUREMENT_VENDOR",
            payload: resp.data.data,
         })
      } else {
         errorCallback()
      }
   }
}

export function getProcurementVendorPublic({ procurementVendorId }, callback, errorCallback) {
   return async (dispatch) => {
      dispatch(setAppLoading(true))
      const resp = await Apiservice.invokeGet(
         `${Apiservice.url.AUCTION_URL}/api/procurement-vendor/public/${procurementVendorId}`,
      )
      dispatch(setAppLoading(false))
      if (resp.status == 200) {
         callback(resp.data.data)
      } else {
         errorCallback()
      }
   }
}

export function getVendorsWithValidTechScorePublic({ procurementItemId }, callback, errorCallback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokeGet(
         `${Apiservice.url.AUCTION_URL}/api/procurement-vendor/${procurementItemId}/find-by-procurement-item-with-valid-tech-score`,
      )

      if (resp.status == 200) {
         callback(resp.data.data)
      } else {
         errorCallback()
      }
   }
}

export function getProcurementAuction({ auctionId }, callback, errorCallback) {
   return async (dispatch) => {
      dispatch(setAppLoading(true))
      const resp = await Apiservice.invokeGet(`${Apiservice.url.AUCTION_URL}/api/secured/auction/${auctionId}`)
      dispatch(setAppLoading(false))

      if (resp.status == 200) {
         await dispatch({
            type: "GET_PROCUREMENT_AUCTION",
            payload: resp.data.data,
         })

         if (callback) {
            callback(resp.data.data)
         }
      } else {
         errorCallback()
      }
   }
}

export function getProcurementAuctionPublic({ auctionId }, callback, errorCallback) {
   return async (dispatch) => {
      dispatch(setAppLoading(true))
      const resp = await Apiservice.invokeGet(`${Apiservice.url.AUCTION_URL}/api/auction/${auctionId}`)
      dispatch(setAppLoading(false))

      if (resp.status == 200) {
         if (callback) callback(resp.data.data)
      } else {
         if (errorCallback) errorCallback()
      }
   }
}

export function resetProcurementState(callback) {
   return async (dispatch) => {
      await dispatch({
         type: "RESET_STATE",
      })

      callback()
   }
}

export function cancelProcurement({ procurementId, reason }, callback, errCallback) {
   return async (dispatch) => {
      dispatch(setAppLoading(true))
      const res = await Apiservice.invokePost(
         `${Apiservice.url.AUCTION_URL}/api/secured/procurement/${procurementId}/cancel`,
         { reason },
      )

      dispatch(setAppLoading(false))

      if (res.status == 200 || res.status == 201) {
         callback(res.data.data)
      } else {
         errCallback(res.data.error)
      }
   }
}

export function endProcurement({ procurementId }, callback, errCallback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokePost(
         `${Apiservice.url.AUCTION_URL}/api/secured/procurement/${procurementId}/close`,
      )

      if (resp.status == 200) {
         if (callback) {
            callback(resp)
         }
      } else {
         if (errCallback) {
            errCallback(resp.data)
         }
      }
   }
}

export function setProcurementItemsState(data, callback) {
   return (dispatch) => {
      dispatch({
         type: "SET_PROCUREMENT_ITEMS",
         payload: data,
      })

      if (callback) {
         callback()
      }
   }
}

export function setProcurementVendorsState(data, callback) {
   return (dispatch) => {
      dispatch({
         type: "SET_PROCUREMENT_VENDORS",
         payload: data,
      })

      if (callback) {
         callback()
      }
   }
}

export function resetProcurementItemsState(callback) {
   return (dispatch) => {
      dispatch({
         type: "RESET_PROCUREMENT_ITEMS",
      })

      callback()
   }
}

export function getVendorsByProcurementItemId(procurementItemId, callback, errCallback) {
   return async (dispatch) => {
      dispatch({
         type: "GET_VENDORS_BY_PROCUREMENT_ITEM_ID_PENDING",
      })

      const resp = await Apiservice.invokeGet(
         `${Apiservice.url.AUCTION_URL}/api/secured/procurement-vendor-item/find-by-procurement-item/${procurementItemId}`,
      )

      if (resp.status == 200) {
         dispatch({
            type: "GET_VENDORS_BY_PROCUREMENT_ITEM_ID_FULFILLED",
            payload: resp.data.data,
         })
         if (callback) {
            callback(resp.data.data)
         }
      } else {
         dispatch({
            type: "GET_VENDORS_BY_PROCUREMENT_ITEM_ID_REJECTED",
         })
         errCallback()
      }
   }
}

export function getVendorsByProcurementItemIdAndAuctionId(procurementItemId, auctionId, callback, errCallback) {
   return async (dispatch) => {
      dispatch({
         type: "GET_VENDORS_BY_PROCUREMENT_ITEM_AND_AUCTION_PENDING",
      })

      const resp = await Apiservice.invokeGet(
         `${Apiservice.url.AUCTION_URL}/api/secured/procurement-vendor-item/find-by-procurement-item-and-auction/${procurementItemId}/${auctionId}`,
      )

      if (resp.status == 200) {
         dispatch({
            type: "GET_VENDORS_BY_PROCUREMENT_ITEM_AND_AUCTION_FULFILLED",
            payload: resp.data.data,
         })
         if (callback) {
            callback(resp.data.data)
         }
      } else {
         dispatch({
            type: "GET_VENDORS_BY_PROCUREMENT_ITEM_AND_AUCTION_REJECTED",
         })
         errCallback()
      }
   }
}

export function getVendorsWithValidTechScore(procurementItemId, callback, errCallback) {
   return async (dispatch) => {
      dispatch({
         type: "GET_VALID_VENDORS_BY_PROCUREMENT_ITEM_PENDING",
      })

      const resp = await Apiservice.invokeGet(
         `${Apiservice.url.AUCTION_URL}/api/secured/procurement-vendor/${procurementItemId}/find-by-procurement-item-with-valid-tech-score`,
      )

      if (resp.status == 200) {
         await dispatch({
            type: "GET_VALID_VENDORS_BY_PROCUREMENT_ITEM_FULFILLED",
            payload: resp.data.data,
         })

         if (callback) {
            callback(resp)
         }
      } else {
         dispatch({
            type: "GET_VALID_VENDORS_BY_PROCUREMENT_ITEM_REJECTED",
         })
         errCallback()
      }
   }
}

export function getProcurementVendorsByProcurementItemId(procurementItemId, callback, errCallback) {
   return async (dispatch) => {
      dispatch({
         type: "GET_PROCUREMENT_VENDORS_BY_PROCUREMENT_ITEM_ID_PENDING",
      })

      const resp = await Apiservice.invokeGet(
         `${Apiservice.url.AUCTION_URL}/api/secured/procurement-vendor/${procurementItemId}/find-by-procurement-item`,
      )

      if (resp.status == 200) {
         await dispatch({
            type: "GET_PROCUREMENT_VENDORS_BY_PROCUREMENT_ITEM_ID_FULFILLED",
            payload: resp.data.data,
         })

         if (callback) {
            callback(resp)
         }
      } else {
         dispatch({
            type: "GET_PROCUREMENT_VENDORS_BY_PROCUREMENT_ITEM_ID_REJECTED",
         })
         errCallback()
      }
   }
}

export function addItemsVendor(payload, callback, errCallback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokePost(
         `${Apiservice.url.AUCTION_URL}/api/secured/procurement-vendor-item/find-or-create`,
         payload,
      )

      if (resp.status == 200) {
         if (callback) {
            callback(resp)
         }
      } else {
         errCallback()
      }
   }
}

export function removeVendorItemByProcurementItemId(procurementItemId, callback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokeDel(
         `${Apiservice.url.AUCTION_URL}/api/secured/procurement-vendor-item/many/by-proc-item-id/${procurementItemId}`,
      )

      if (resp.status == 200) {
         callback(resp)
      }
   }
}

export function removeVendorItem(procurementVendorItemIds, callback, errCallback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokeDel(
         `${Apiservice.url.AUCTION_URL}/api/secured/procurement-vendor-item/many`,
         {
            procurementVendorItemIds,
         },
      )

      if (resp.status == 200) {
         if (callback) {
            callback(resp)
         }
      } else {
         errCallback()
      }
   }
}

export function sortProcurementVendorItems(sortedProcurementVendorItems, callback) {
   return (dispatch) => {
      dispatch({
         type: "SORT_PROCUREMENT_VENDOR_ITEMS",
         payload: sortedProcurementVendorItems,
      })

      callback()
   }
}

export function sortProcuremenItems(sortedProcurementItems, callback) {
   return (dispatch) => {
      dispatch({
         type: "SORT_PROCUREMENT_ITEMS",
         payload: sortedProcurementItems,
      })

      callback()
   }
}

export function sortProcurementVendors(sortedProcurementVendors, callback) {
   return (dispatch) => {
      dispatch({
         type: "SORT_PROCUREMENT_VENDORS",
         payload: sortedProcurementVendors,
      })

      callback()
   }
}

export function updateProcurementVendorItem(payload, callback, errCallback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokePatch(
         `${Apiservice.url.AUCTION_URL}/api/secured/procurement-vendor-item`,
         payload,
      )

      if (resp.status == 200) {
         if (callback) {
            callback(resp)
         }
      } else {
         errCallback()
      }
   }
}

export function getProcurementVendorByIds(procurementVendorIds, callback, errCallback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokePost(
         `${Apiservice.url.AUCTION_URL}/api/secured/procurement-vendor/find-by/procurement-vendor-ids/`,
         { procurementVendorIds },
      )

      if (resp.status == 200) {
         if (callback) {
            callback(resp.data.data)
         }
      } else {
         errCallback()
      }
   }
}
