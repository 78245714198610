import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import { Col, Row } from "react-bootstrap"

export function AnnouncementInfo({ text, icon, textUnderIcon, withBody = true }) {
   return (
      <>
         <Row>
            <Col
               className="d-flex align-items-center mx-3 py-3 px-3"
               style={{
                  background: "#E9F5FE",
                  borderRadius: 5,
               }}
            >
               <span className="mr-3" style={{ color: "#2296F3" }}>
                  <FontAwesomeIcon icon={faInfoCircle} style={{ fontSize: "15px" }} />
               </span>
               <span style={{ fontWeight: 500 }}>{text}</span>
            </Col>
         </Row>
         {withBody && (
            <Row>
               <Col className="d-flex justify-content-center align-items-center m-5 flex-column">
                  <img src={icon} />
                  <span className="mt-3" style={{ textAlign: "center", color: "#909090", fontWeight: "bold" }}>
                     {/* No list available,<br /> */}
                     {textUnderIcon}
                  </span>
               </Col>
            </Row>
         )}
      </>
   )
}
