import React from 'react';
import { Breadcrumb } from 'rsuite';
import styled from 'styled-components';

export default function Breadcrumbs({data}) {
    
    return (
        <>
            <BreadcrumbStyle>
                {data.map((v, k) => {
                    return <Breadcrumb.Item active key={`bc-${k}`} style={{ color: `${k === data.length - 1 && '#04446C'}` }}>{v}</Breadcrumb.Item>
                })}
            </BreadcrumbStyle>
        </>
    )
}

const BreadcrumbStyle = styled(Breadcrumb)`
    background: transparent;
    margin-left: 23px;
    .breadcrumb {
        background: transparent;
        font-size: 12px;
        margin-bottom: 0;
        padding-bottom: 5px;

        .breadcrumb-item {
            &:not(:first-child) {
                &::before {
                    content: ">"
                }
            }

            a {
                color: #909090;
            }
        }
    }
`