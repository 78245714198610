import React, { useImperativeHandle, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { Col, Row } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import ModalForm from "../../../../components/Modalform";
import Buttonaction from "../../../../components/Buttonaction";
import { createSession } from "../../../../redux/action/negotiationAction";
import DatePickerUtil from "../../../../services/utils/DatePickerUtil";
import DatePickerInput from "../../../../components/DatePickerInput";

const CreateSession = ({ dataProcurement, passedInRef }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { procurementId } = useParams();
  const createNegotiationSessionModalRef = useRef();
  const [schedule, setSchedule] = useState({ startDate: null, endDate: null });

  useImperativeHandle(passedInRef, () => ({
    show: () => {
      createNegotiationSessionModalRef.current.show();
    },
  }));

  const handleCreateSession = () => {
    dispatch(
      createSession(
        {
          procurementId,
          startDate: new Date(schedule.startDate),
          endDate: new Date(schedule.endDate),
        },
        (res) => {
          const batchId = res._id;
          history.push(`/negotiation/session/${procurementId}/${batchId}`);
        }
      )
    );
  };

  const handleDisabledDate = (date) => {
    const startDate = dataProcurement.startDate;
    // const endDate = dataProcurement.endDate;
    if (startDate && endDate) {
      return DatePickerUtil.disabledDate(date, startDate, dataProcurement.endDate);
    }
  };

  return (
    <ModalForm
      passedInRef={createNegotiationSessionModalRef}
      title="Set Schedule"
    >
      <div style={{ padding: "0 34px", overflow: "auto" }}>
        <Row className="mb-4">
          <Col>Procurement Name</Col>
          <Col lg={9}>{dataProcurement?.procurementName}</Col>
        </Row>

        <Row className="d-flex align-items-center mb-4">
          <Col>Start Time</Col>
          <Col lg={9}>
            <DatePickerInput
              format="dd/MM/yyyy HH:mm"
              placeholder="dd/mm/yyyy 00:00"
              disabledDate={handleDisabledDate}
              onChange={(v) => setSchedule({ ...schedule, startDate: v })}
              style={{ width: 260 }}
            />{" "}
          </Col>
        </Row>

        <Row className="d-flex align-items-center mb-4">
          <Col>End Time</Col>
          <Col lg={9}>
            <DatePickerInput
              format="dd/MM/yyyy HH:mm"
              placeholder="dd/mm/yyyy 00:00"
              disabledDate={handleDisabledDate}
              onChange={(v) => setSchedule({ ...schedule, endDate: v })}
              style={{ width: 260 }}
            />{" "}
          </Col>
        </Row>

        <div
          className="d-flex justify-content-end mb-5"
          style={{ marginTop: "34px" }}
        >
          <Buttonaction
            label="Cancel"
            gray
            borderless
            style={{ marginRight: "24px" }}
            onClick={() => createNegotiationSessionModalRef.current.hide()}
          />
          {schedule.startDate && schedule.endDate ? (
            <Buttonaction
              label="Create Session"
              blueOcean
              onClick={handleCreateSession}
            />
          ) : (
            <Buttonaction disabled label="Create Session" blueOcean />
          )}
        </div>
      </div>
    </ModalForm>
  );
};

const mapStateToProps = (state) => ({
  dataProcurement: state.procurementReducer.dataProcurement,
});

export default connect(mapStateToProps)(CreateSession);
