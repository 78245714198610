import React from "react"
import { Col, Row } from "react-bootstrap"
import { Loader } from "rsuite"
import { GreyCard } from "./styled/Generalcard"
import styled from "styled-components"
import Formservice from "../services/Formservice"
import { format } from "date-fns"
import Constant from "../../constant"

function AuctionInfoCard(props) {
   const { auction, procurement, isLoading, numberOfItems, children } = props

   const capitalizeEachWord = (text) => {
      if (text) {
         let splitText = []
         text.includes("_") ? (splitText = text.replace(/_/g, " ").split(" ")) : (splitText = text.split())

         const arrText = splitText.map((word) => {
            return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
         })
         return arrText.join(" ")
      }
   }

   return (
      <GreyCard style={{ padding: "32px 18px" }}>
         <Row>
            <Col lg={10}>
               <p style={{ fontSize: "18px", fontWeight: "500" }}>Auction Info</p>
            </Col>
         </Row>
         <hr style={{ borderColor: "#C4C4C4", marginTop: "18px" }} />

         {isLoading ? (
            <div className="d-flex justify-content-center">
               <Loader speed="fast" content="Getting auction info..." vertical />
            </div>
         ) : (
            <>
               <AuctionInfoList>
                  <Col lg={5} sm={6} style={{ fontSize: "12px" }}>
                     <p>Start Date Session</p>
                  </Col>
                  <Col lg={6} sm={6} style={{ fontSize: "12px" }}>
                     <p>{auction && auction.startTime && format(new Date(auction.startTime), "dd MMMM yyyy")}</p>
                  </Col>
               </AuctionInfoList>

               <AuctionInfoList>
                  <Col lg={5} sm={6} style={{ fontSize: "12px" }}>
                     <p>End Date Session</p>
                  </Col>
                  <Col lg={6} sm={6} style={{ fontSize: "12px" }}>
                     <p>{auction && auction.endTime && format(new Date(auction.endTime), "dd MMMM yyyy")}</p>
                  </Col>
               </AuctionInfoList>

               <AuctionInfoList>
                  <Col lg={5} sm={6} style={{ fontSize: "12px" }}>
                     <p>Start Time Session</p>
                  </Col>
                  <Col lg={6} sm={6} style={{ fontSize: "12px" }}>
                     <p>
                        {auction?.startTime &&
                           new Date(auction?.startTime).toLocaleString("en-US", {
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                              hour12: false,
                           })}
                     </p>
                  </Col>
               </AuctionInfoList>

               <AuctionInfoList>
                  <Col lg={5} sm={6} style={{ fontSize: "12px" }}>
                     <p>End Time Session</p>
                  </Col>
                  <Col lg={6} sm={6} style={{ fontSize: "12px" }}>
                     <p>
                        {auction?.startTime &&
                           new Date(auction?.endTime).toLocaleString("en-US", {
                              hour: "2-digit",
                              minute: "2-digit",
                              second: "2-digit",
                              hour12: false,
                           })}
                     </p>
                  </Col>
               </AuctionInfoList>

               <AuctionInfoList>
                  <Col lg={5} sm={6} style={{ fontSize: "12px" }}>
                     <p>Auction Type</p>
                  </Col>
                  <Col lg={6} sm={6} style={{ fontSize: "12px" }}>
                     <p>
                        {
                           capitalizeEachWord(auction?.auctionType)
                           //    auction.auctionType.charAt(0) + auction.auctionType.slice(1).toLowerCase()
                        }
                     </p>
                  </Col>
               </AuctionInfoList>

               <AuctionInfoList>
                  <Col lg={5} sm={6} style={{ fontSize: "12px" }}>
                     <p>Initial Price</p>
                  </Col>
                  {auction && (
                     <Col lg={6} sm={6} style={{ fontSize: "12px" }}>
                        {
                           <span>
                              <p>
                                 {Formservice.CurrencyFormatter(auction?.initialPrice, {
                                    currency: procurement?.currency,
                                 })}
                              </p>
                           </span>
                        }
                     </Col>
                  )}
               </AuctionInfoList>

               <AuctionInfoList>
                  <Col lg={5} sm={6} style={{ fontSize: "12px" }}>
                     <p>Minimal Decrement</p>
                  </Col>
                  <Col lg={6} sm={6} style={{ fontSize: "12px" }}>
                     <p>
                        {Formservice.CurrencyFormatter(auction?.minimalDecrement && auction?.minimalDecrement, {
                           currency: procurement?.currency,
                        })}
                     </p>
                  </Col>
               </AuctionInfoList>

               <AuctionInfoList>
                  <Col lg={5} sm={6} style={{ fontSize: "12px" }}>
                     <p>Auction Model</p>
                  </Col>
                  <Col lg={6} sm={6} style={{ fontSize: "12px" }}>
                     <p>{capitalizeEachWord(auction?.auctionModel)}</p>
                  </Col>
               </AuctionInfoList>

               <AuctionInfoList>
                  <Col lg={5} sm={6} style={{ fontSize: "12px" }}>
                     <p>Evaluation Method</p>
                  </Col>
                  <Col lg={6} sm={6} style={{ fontSize: "12px" }}>
                     <p>{capitalizeEachWord(procurement?.evaluationMethod)}</p>
                  </Col>
               </AuctionInfoList>

               {auction?.stopReason && (
                  <AuctionInfoList>
                     <Col lg={5} sm={6} style={{ fontSize: "12px" }}>
                        <p>Stop Reason</p>
                     </Col>
                     <Col lg={6} sm={6} style={{ fontSize: "12px" }}>
                        <p dangerouslySetInnerHTML={{ __html: auction?.stopReason }} />
                     </Col>
                  </AuctionInfoList>
               )}

               {children}
            </>
         )}
      </GreyCard>
   )
}

export default AuctionInfoCard

// Styled component
const AuctionInfoList = styled(Row)`
   margin-bottom: 16px;
`
