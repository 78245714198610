import Apiservice from "../services/Apiservice";

const API_URL = Apiservice.url.AUCTION_URL + "/api/secured/negotiation-batch";

function NegotiationBatchService(){
    this.findById = function(batchId, callback){
        return async () => {
            const resp = await Apiservice.invokeGet(API_URL + "/" + batchId);
            if (resp.status == 200) {
                if (callback) {
                    callback(resp.data.data);
                }
            }
        }
    }
    this.getStatus = function(negotiationBatch, from, callback){
        return async () => {
            const resp = await Apiservice.invokePost(API_URL + "/get-status", {
                negotiationBatch: negotiationBatch,
                from: from
            })
            if (resp.status == 200) {
                if (callback) {
                    callback(resp.data.data);
                }
            }
        }
    }
    this.changeStatus = function(negotiationBatch, status, callback, errCallback){
        return async () => {
            const resp = await Apiservice.invokePost(API_URL + "/change-status", {
                negotiationBatch: negotiationBatch,
                status: status
            });
            if (resp.status == 200) {
                if (callback) {
                    callback(resp.data.data);
                }
            }else{
                if(errCallback){
                    errCallback(resp)
                }
            }
        }
    }

    this.delete = function(batchId, callback){
        return async () => {
            const resp = await Apiservice.invokeDel(API_URL + "/", {
                negotiationBatchId: batchId
            });
            if (resp.status == 200) {
                if (callback) {
                    callback(resp.data.data);
                }
            }
        }
    }

    this.validateCreateSession = function (procurementId, callback) {
        return async () => {
            const resp = await Apiservice.invokeGet(API_URL + "/validate-create-session/" + procurementId)

            if (callback) {
                callback(resp.data.data)
            }

        }
    }
}

export default NegotiationBatchService