const initialState = {
   dataProcurements: {
      filteredData: [],
      total: 0,
   },
   dataProcurementItems: {
      filteredData: [],
      total: 0,
   },
   dataProcurementUnitItems: {
      auctionId: "",
      username: "",
      offerItems: [],
   },
   dataProcurementVendors: {
      filteredData: [],
      total: 0,
      totalProcurementVendors: 0,
   },
   dataProcurementAuctions: {
      filteredData: [],
      total: 0,
      totalAuctions: 0,
   },
   dataAuctionInvitedVendors: {
      filteredData: [],
      total: 0,
      totalInvitedVendors: 0,
   },
   dataProcurement: {
      status: "DRAFT",
   },
   dataProcurementItem: undefined,
   dataProcurementVendor: undefined,
   dataProcurementVendorItems: [],
   vendorsPerItem: { isLoading: false, isError: false, data: [] },
   procurementVendors: { isLoading: false, isError: false, data: [] },
}

const procurementReducer = (state = initialState, action) => {
   switch (action.type) {
      case "FETCH_PROCUREMENTS":
         return {
            ...state,
            dataProcurements: action.payload,
         }
      case "FETCH_PROCUREMENT_ITEMS":
         return {
            ...state,
            dataProcurementItems: action.payload,
         }
      case "FETCH_PROCUREMENT_UNIT_ITEMS":
         return {
            ...state,
            dataProcurementUnitItems: action.payload,
         }
      case "FETCH_PROCUREMENT_VENDORS":
         return {
            ...state,
            dataProcurementVendors: action.payload,
         }
      case "FETCH_PROCUREMENT_AUCTIONS":
         return {
            ...state,
            dataProcurementAuctions: action.payload,
         }
      case "FETCH_AUCTION_INVITED_VENDORS":
         return {
            ...state,
            dataAuctionInvitedVendors: action.payload,
         }
      case "RESET_DATA_PROCUREMENT":
         return {
            ...state,
            dataProcurement: {
               status: "DRAFT",
            },
         }
      case "GET_PROCUREMENT":
         return {
            ...state,
            dataProcurement: { ...state.dataProcurement, ...action.payload, isNegotiation: false },
         }
      case "GET_PROCUREMENT_ITEM":
         return {
            ...state,
            dataProcurementItem: action.payload,
         }
      case "GET_PROCUREMENT_VENDOR":
         return {
            ...state,
            dataProcurementVendor: action.payload,
         }
      case "RESET_STATE":
         return initialState
      case "SET_PROCUREMENT_ITEMS":
         return {
            ...state,
            dataProcurementItems: { ...state.dataProcurementItems, filteredData: action.payload },
         }
      case "SET_PROCUREMENT_VENDORS":
         return {
            ...state,
            dataProcurementVendors: { ...state.dataProcurementItems, filteredData: action.payload },
         }
      case "RESET_PROCUREMENT_ITEMS":
         return {
            ...state,
            dataProcurementItems: {
               filteredData: [],
               total: 0,
            },
         }
      case "GET_PROCUREMENT_VENDOR_ITEMS":
         return {
            ...state,
            dataProcurementVendorItems: action.payload,
         }
      case "SORT_PROCUREMENT_VENDOR_ITEMS":
         return {
            ...state,
            dataProcurementVendorItems: action.payload,
         }
      case "SORT_PROCUREMENT_ITEMS":
         return {
            ...state,
            dataProcurementItems: { ...state.dataProcurementItems, filteredData: action.payload },
         }
      case "SORT_PROCUREMENT_VENDORS":
         return {
            ...state,
            dataProcurementVendors: { ...state.dataProcurementVendors, filteredData: action.payload },
         }
      case "GET_VALID_VENDORS_BY_PROCUREMENT_ITEM_PENDING":
         return {
            ...state,
            procurementVendors: { ...state.procurementVendors, isLoading: true, isError: false },
         }
      case "GET_VALID_VENDORS_BY_PROCUREMENT_ITEM_FULFILLED":
         return {
            ...state,
            procurementVendors: {
               ...state.procurementVendors,
               isLoading: false,
               isError: false,
               data: action.payload,
            },
         }
      case "GET_VALID_VENDORS_BY_PROCUREMENT_ITEM_REJECTED":
         return {
            ...state,
            procurementVendors: { ...state.procurementVendors, isLoading: false, isError: true },
         }
      case "GET_VENDORS_BY_PROCUREMENT_ITEM_ID_PENDING":
         return {
            ...state,
            vendorsPerItem: { ...state.vendorsPerItem, isLoading: true, isError: false },
         }
      case "GET_VENDORS_BY_PROCUREMENT_ITEM_ID_FULFILLED":
         return {
            ...state,
            vendorsPerItem: { ...state.vendorsPerItem, isLoading: false, isError: false, data: action.payload },
         }
      case "GET_VENDORS_BY_PROCUREMENT_ITEM_ID_REJECTED":
         return {
            ...state,
            vendorsPerItem: { ...state.vendorsPerItem, isLoading: false, isError: true },
         }
      case "GET_PROCUREMENT_VENDORS_BY_PROCUREMENT_ITEM_ID_PENDING":
         return {
            ...state,
            procurementVendors: { ...state.procurementVendors, isLoading: true, isError: false },
         }
      case "GET_PROCUREMENT_VENDORS_BY_PROCUREMENT_ITEM_ID_FULFILLED":
         return {
            ...state,
            procurementVendors: {
               ...state.procurementVendors,
               isLoading: false,
               isError: false,
               data: action.payload,
            },
         }
      case "GET_PROCUREMENT_VENDORS_BY_PROCUREMENT_ITEM_ID_REJECTED":
         return {
            ...state,
            procurementVendors: { ...state.procurementVendors, isLoading: false, isError: true },
         }
      default:
         return state
   }
}

export default procurementReducer
