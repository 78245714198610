import Apiservice from "../services/Apiservice";

const API_URL = Apiservice.url.AUCTION_URL + "/api/secured/negotiation";

function NegotiationService(){
    this.findById = function (negoId, callback) {
        return async () => {
            const resp = await Apiservice.invokeGet(API_URL + "/" + negoId);
            if (resp.status == 200) {
                if (callback) {
                    callback(resp.data.data);
                }
            }
        }
    }
    this.findStatus = function(negotiation, status, callback, errCallback) {
        return async () => {
            const resp = await Apiservice.invokePost(API_URL + "/get-status", {
                negotiation: negotiation,
                status: status
            });
            if (resp.status == 200) {
                if (callback) {
                    callback(resp.data.data);
                }
            } else {
                errCallback()
            }
        }
    }

    this.changeStatus = function(negotiation, status, callback){
        return async () => {
            const resp = await Apiservice.invokePost(API_URL + "/change-status", {
                negotiation: negotiation,
                status: status
            });
            if (resp.status == 200) {
                if (callback) {
                    callback(resp.data.data);
                }
            }
        }
    }

    this.saveAsDraft = function(negotiationBatch, vendorIds, callback){
        return async () => {
            const resp = await Apiservice.invokePost(API_URL + "/save-as-draft", {
                negotiationBatch: negotiationBatch,
                vendorIds: vendorIds
            });
            if (resp.status == 200) {
                if (callback) {
                    callback(resp.data.data);
                }
            }
        }
    }

    this.findByBatchId = function(batchId , callback){
        return async () => {
            const resp = await Apiservice.invokeGet(API_URL + "/find-by-batch/" + batchId)
            if (resp.status == 200) {
                if (callback) {
                    callback(resp.data.data);
                }
            }
        }
    }

    this.submitSchedule = function(negotiation, callback, errCallback){
        return async () => {
            const resp = await Apiservice.invokePost(API_URL + "/submit-schedule", negotiation)
            if (resp.status == 200) {
                if (callback) {
                    callback(resp.data.data);
                }
            } else {
                errCallback(resp.data)
            }
        }
    }
}

export default NegotiationService