import React, { useRef, useState } from "react"
import { Form } from "react-bootstrap"
import { connect, useDispatch } from "react-redux"
import { useHistory } from "react-router"
import { Link } from "react-router-dom"
import Buttonaction from "../../components/Buttonaction"
import ErrorField from "../../components/Errorfield"
import KeyIcon from "../../assets/images/Key.svg"
import EnvelopeIcon from "../../assets/images/Envelope.svg"
import Eye from "../../assets/images/Eye.svg"
import EyeSlash from "../../assets/images/EyeSlash.svg"
import { changePassword } from "../../redux/action/authAction"
import InputAlert from "../../components/InputAlert"
import { useEffect } from "react"
import { getMyProfile } from "../../redux/action/accountAction"

function ChangePass({ isAppLoading, dataProfile }) {
   const history = useHistory()
   const dispatch = useDispatch()

   const [email, setEmail] = useState("")
   const [password, setPassword] = useState("")
   const [confirmPassword, setConfirmPassword] = useState("")
   const [isChanging, setIsChanging] = useState(false)
   const [passwordChanged, setPasswordChanged] = useState(false)

   const [showPassword, setShowPassword] = useState("password")
   const [showConfirmPassword, setShowConfirmPassword] = useState("password")

   const passwordInputRef = useRef()
   const confirmPasswordInputRef = useRef()

   const [error, setError] = useState({
      error: false,
      param: "",
      message: "",
   })

   useEffect(() => {
      dispatch(
         getMyProfile((res) => {
            setEmail(res.account.email)
         }),
      )
   }, [])

   //    useEffect(() => {
   //       console.log(passwordInputRef.current.type = "text")
   //    }, [password])

   const handleChangePassword = (e) => {
      e.preventDefault()

      if (password && confirmPassword) {
         setIsChanging(true)
         new Promise((resolve, reject) => {
            dispatch(
               changePassword(
                  { email, password, confirmPassword },
                  () => resolve(),
                  (error) => reject(error[0]),
               ),
            )
         })
            .then(() => {
               setTimeout(() => {
                  setPasswordChanged(true)
               }, 2000)
            })
            .catch((error) => {
               setTimeout(() => {
                  setError((prev) => ({ ...prev, error: true, message: error.msg, param: error.param }))
               }, 2000)
            })
            .finally(() => {
               setTimeout(() => {
                  setIsChanging(false)
               }, 2000)
            })
      }
   }

   return (
      <div style={{ display: "grid", placeItems: "center", height: "100vh" }}>
         <div
            className="container-fluid no-gutters d-flex flex-column align-items-center"
            style={{
               boxShadow: "0px 32px 100px rgba(0, 0, 0, 0.08)",
               width: "60%",
               height: "fit-content",
               padding: "32px",
               borderRadius: "12px",
            }}
         >
            {/* Render this is passwordChanged state is true */}
            {passwordChanged && (
               <>
                  <img src={KeyIcon} className="mb-4" />
                  <h3 style={{ color: "#0772B6", fontSize: "24px" }}>New Password created</h3>
                  <p className="text-center" style={{ fontSize: "18px", width: "80%", margin: "18px 0" }}>
                     Successfully change password, start login with your new password
                  </p>

                  <Form.Group className="mt-5 text-center" controlId="formButton">
                     <Buttonaction
                        blueOcean
                        type="button"
                        label="Go to dashboard"
                        style={{ width: "100%" }}
                        onClick={() => history.push("/dashboard")}
                     />
                  </Form.Group>
               </>
            )}

            {/* Render this is passwordChanged state is false */}
            {!passwordChanged && (
               <>
                  <img src={KeyIcon} className="mb-4" />
                  <h3 style={{ color: "#0772B6", fontSize: "24px" }}>Create New Password</h3>
                  <p className="text-center" style={{ fontSize: "18px", width: "80%", margin: "18px 0" }}>
                     Please enter your new password
                  </p>
                  <Form className="w-100" onSubmit={handleChangePassword}>
                     <Form.Group className="mb-3" controlId="formEmail">
                        <Form.Label style={{ color: "#909090" }}>New Password</Form.Label>
                        <div style={{ position: "relative" }}>
                           <Form.Control
                              style={{ height: "44px", paddingRight: "50px" }}
                              type={showPassword}
                              placeholder="Fill Password"
                              onChange={(e) => setPassword(e.target.value)}
                           />
                           <div
                              className="d-flex align-items-center"
                              style={{ position: "absolute", right: 0, top: 0, bottom: 0, height: "100%" }}
                           >
                              <img
                                 src={showPassword == "password" ? EyeSlash : Eye}
                                 style={{ height: "20px", width: "50px", cursor: "pointer" }}
                                 onClick={() =>
                                    setShowPassword((prev) => {
                                       if (prev == "text") {
                                          return "password"
                                       } else {
                                          return "text"
                                       }
                                    })
                                 }
                              />
                           </div>
                        </div>
                        {error.error && error.param === "password" && (
                           <div className="d-flex mt-2">
                              <InputAlert.Container type="danger">
                                 <InputAlert.Icon />
                                 <InputAlert.Text text={error.message} />
                              </InputAlert.Container>
                           </div>
                        )}
                     </Form.Group>

                     <Form.Group className="mb-4" controlId="formEmail">
                        <Form.Label style={{ color: "#909090" }}>Retype New Password</Form.Label>
                        <div style={{ position: "relative" }}>
                           <Form.Control
                              style={{ height: "44px" }}
                              type={showConfirmPassword}
                              placeholder="Confirm Password"
                              onChange={(e) => setConfirmPassword(e.target.value)}
                           />
                           <div
                              className="d-flex align-items-center"
                              style={{ position: "absolute", right: 0, top: 0, bottom: 0, height: "100%" }}
                           >
                              <img
                                 src={showConfirmPassword == "password" ? EyeSlash : Eye}
                                 style={{ height: "20px", width: "50px", cursor: "pointer" }}
                                 onClick={() =>
                                    setShowConfirmPassword((prev) => {
                                       if (prev == "text") {
                                          return "password"
                                       } else {
                                          return "text"
                                       }
                                    })
                                 }
                              />
                           </div>
                        </div>
                        {error.error && error.param === "confirmPassword" && (
                           <div className="d-flex mt-2">
                              <InputAlert.Container type="danger">
                                 <InputAlert.Icon />
                                 <InputAlert.Text text={error.message} />
                              </InputAlert.Container>
                           </div>
                        )}
                     </Form.Group>

                     <Form.Group className="mb-3 text-center" controlId="formButton">
                        <Buttonaction
                           blueOcean
                           type="submit"
                           label="Save My Password"
                           style={{ width: "100%" }}
                           loadingLabel="Changing password..."
                           loading={isChanging}
                           disabled={!password || !confirmPassword || isChanging}
                        />
                     </Form.Group>
                  </Form>
               </>
            )}
         </div>
      </div>
   )
}

export default connect((state) => ({
   isAppLoading: state.appReducer.isAppLoading,
   isAppLoading: state.accountReducer.isAppLoading,
   dataProfile: state.accountReducer.dataProfile,
}))(ChangePass)
