import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import sessionReducer from "../redux/reducer/sessionReducer";
import roomReducer from "../redux/reducer/roomReducer";
import appReducer from "../redux/reducer/appReducer";
import masterDataReducer from "../redux/reducer/masterDataReducer";
import accountReducer from "../redux/reducer/accountReducer";
import procurementReducer from "../redux/reducer/procurementReducer";
import auctionReducer from "../redux/reducer/auctionReducer";
import negotiationReducer from "../redux/reducer/negotiationReducer";
import reportReducer from "../redux/reducer/reportReducer";

const rootReducer = combineReducers({
  sessionReducer,
  roomReducer,
  appReducer,
  masterDataReducer,
  accountReducer,
  procurementReducer,
  auctionReducer,
  negotiationReducer,
  reportReducer,
});

let store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
