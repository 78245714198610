import React from "react"
import { Col, Row } from "react-bootstrap"
import Dateinfo from "./Dateinfo"
import { GreyCard } from "./styled/Generalcard"
import styled from "styled-components"

function BuyerAuctionTimerCard(props) {
   const { auction, children } = props
   return (
      <GreyCard className="w-100">
         <Row>
            <Col lg={3} md={1} className="mb-3">
               Date
            </Col>
            <Col lg={9} md={11}>
               <Dateinfo date={auction?.startTime} format="dd mmmm yyyy" />
            </Col>
         </Row>

         <Row>
            <Col lg={3} md={1}>
               Time
            </Col>
            <Col lg={9} md={11}>
               {auction?.startTime &&
                  new Date(auction?.startTime).toLocaleString("en-US", {
                     hour: "2-digit",
                     minute: "2-digit",
                     second: "2-digit",
                     hour12: false,
                  })}
            </Col>
         </Row>
         <Divider />
         {children}
      </GreyCard>
   )
}

export default BuyerAuctionTimerCard

const Divider = styled.hr`
   background-color: #c4c4c4;
   margin: 18px 0;
`
