import React, { useEffect, useRef } from "react"
import { useState } from "react"
import { useDispatch } from "react-redux"
import { io } from "socket.io-client"
import Constant from "../../../constant"
import AccountService from "../../services/AccountService"
import { Button, Badge } from "react-bootstrap"
import SpectatorAddModule from "./spectator-add.module"
import { GreyCard } from "../../components/styled/Generalcard"
import styles from "../../styles/Spectator.module.css"
import randomColor from "randomcolor"

const SpectatorModule = (params) => {
   const { roomId, userId, permitAdd, fromAccountId, toAccountId, link, module, foreignKey } = params
   const dispatch = useDispatch()
   const socketRefSpectator = useRef()
   const accountService = new AccountService()
   const [spectators, setSpectators] = useState([])
   const [modalAdd, setModalAdd] = useState(false)
   const ENDPOINT = import.meta.env.VITE_SERVER_URL
   const [isVendorPage, setIsVendorPage] = useState(false)

   const setupSocketSpectator = () => {
      socketRefSpectator.current = io(ENDPOINT + `/spectator-${roomId}`)
      socketRefSpectator.current.on(Constant.socketEvents.RESPONSE_JOIN_SPECTATOR_ROOM, (res) => {
         handleSpectatorList()
      })

      socketRefSpectator.current.on(Constant.socketEvents.RESPONSE_LIST_SPECTATORS, (resp) => {
         setSpectators(resp)
      })

      return () => {
         socketRefSpectator.current.disconnect
      }
   }

   useEffect(() => {
      setupSocketSpectator()
      handleJoin()
   }, [])

   const openAddModal = () => {
      if (!modalAdd) {
         setModalAdd(true)
      }
   }

   const handleJoin = () => {
      dispatch(
         accountService.findById(userId, (res) => {
            socketRefSpectator.current.emit(Constant.socketEvents.JOIN_SPECTATOR_ROOM, {
               roomId: roomId,
               username: res.email,
               userId: res._id,
               role: res.role,
            })
         }),
      )
   }

   const handleSpectatorList = () => {
      socketRefSpectator.current.emit(Constant.socketEvents.REQUEST_LIST_SPECTATOR, {
         roomId: roomId,
      })
   }

   const handleOpenOrCloseModalAdd = () => {
      setModalAdd(false)
   }

   const getInitialEmail = (email) => {
      return email.charAt(0).toUpperCase()
   }

   const showParticipantLeft = () => {
      if (spectators.length > Constant.MAXIMUM_SHOW_PARTICIPANT) {
         return "+" + (spectators.length - Constant.MAXIMUM_SHOW_PARTICIPANT)
      }
      return ""
   }

   useEffect(() => {
      location.pathname.includes("/negotiation/session-online/vendor/") && setIsVendorPage(true)
   }, [])

   return (
      <>
         <GreyCard className={`w-100 ${!isVendorPage && "mx-0"}`} style={{ padding: "10px 18px 10px 18px" }}>
            <ul className={styles.listSpectator}>
               {spectators?.map((item, index) => {
                  if (index < Constant.MAXIMUM_SHOW_PARTICIPANT) {
                     return (
                        <li
                           style={{
                              backgroundColor: randomColor({ luminosity: "dark", format: "rgb" }),
                           }}
                        >
                           <Badge pill>
                              <div style={{ fontSize: "24px", fontWeight: "500" }}>{getInitialEmail(item.email)}</div>
                           </Badge>
                        </li>
                     )
                  }
               })}
               <li className="mt-1 ml-1" style={{ fontSize: "14px", fontWeight: "500", color: "#909090" }}>
                  {showParticipantLeft()}
               </li>
            </ul>
            {permitAdd && fromAccountId && toAccountId && (
               <>
                  <div className={styles.btnInvite} style={{ float: "right" }}>
                     <Button size="sm mt-2" onClick={openAddModal}>
                        <p style={{ fontSize: "12px", fontWeight: "500", padding: "3px" }}>Invite Participant</p>
                     </Button>
                     <SpectatorAddModule
                        fromAccountId={fromAccountId}
                        toAccountId={toAccountId}
                        isOpen={modalAdd}
                        link={link}
                        module={module}
                        foreignKey={foreignKey}
                        fnCloseModalAdd={handleOpenOrCloseModalAdd}
                     ></SpectatorAddModule>
                  </div>
               </>
            )}
            <div style={{ clear: "both" }}></div>
         </GreyCard>
      </>
   )
}

export default SpectatorModule
