import React, { useState } from "react"
import styled from "styled-components"
import { useParams } from "react-router-dom"
import { orderBy as _orderBy } from "lodash"
import { connect, useDispatch } from "react-redux"

// MUI library
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import { TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel } from "@mui/material"

// Redux
import { fetchProcurementVendors, sortProcurementVendors } from "../../../../redux/action/procurementAction"

// Components
import { Editlink } from "../../../../components/Editlink"
import Statuslabel from "../../../../components/Statuslabel"
import MuiDataTable from "../../../../components/MuiDataTable"

// Assets
import WarningBlue from "../../../../assets/images/warning-blue.svg"

// #
import Constant from "../../../../../constant"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons"

function ByVendor({ dataProcurement, dataProcurementVendors, modalScoringAdministrationRef, modalAddItemVendorRef }) {
   const dispatch = useDispatch()
   const { procurementId } = useParams()

   const [order, setOrder] = useState("asc")
   const [orderBy, setOrderBy] = useState("")
   const columns =
      dataProcurement?.evaluationMethod == Constant.EVALUATION_METHODS.MERIT_POINT
         ? ["Vendor Name", "Administration Evaluation"]
         : ["Vendor Name"]
   const [dataState, setDataState] = useState({
      procurementId,
      page: 0,
      limit: 5,
      query: "",
      selectedRows: [],
   })

   const handleRequestSort = (column) => {
      const _order = order === "asc" ? "desc" : "asc"
      setOrderBy(column)
      setOrder(_order)

      const forColumn =
         column === "Vendor Name" ? "name" : column === "Administration Evaluation" ? "administrationPassed" : ""

      let result = []
      result = _orderBy(dataProcurementVendors.filteredData, [forColumn], [_order])
      dispatch(sortProcurementVendors(result))
   }

   const search = ({ target }) => {
      const { value } = target
      dispatch(fetchProcurementVendors({ procurementId, limit: dataState.limit, query: value }, (res) => {}))
   }

   const handlePageChange = (v, newPage) => {
      dispatch(
         fetchProcurementVendors({ ...dataState, page: newPage + 1 }, (res) => {
            setDataState({ ...dataState, page: newPage })
         }),
      )
   }

   const handleRowsPerPageChange = (v, { props }) => {
      const { value } = props
      dispatch(
         fetchProcurementVendors({ procurementId, page: 1, limit: value, quuery: "" }, (res) => {
            setDataState({ ...dataState, limit: value })
         }),
      )
   }

   return (
      <>
         {dataProcurement.evaluationMethod == Constant.EVALUATION_METHODS.MERIT_POINT && (
            <Info className="mt-4">
               <img src={WarningBlue} style={{ position: "absolute", top: "0", left: "0" }} />
               <p style={{ fontSize: "12px", zIndex: "99" }} className="ml-5">
                  Give score to the Administration and Technical evaluation for Item and Vendor
               </p>
            </Info>
         )}
         <MuiDataTable.CustomTable
            showHeader={true}
            headerComponent={
               <MuiDataTable.Filter withSearchBar={true} onKeyUp={(v) => v.keyCode === 13 && search(v)} />
            }
            footerComponent={
               <TablePagination
                  sx={{ background: "#ffffff", borderBottom: "none" }}
                  page={dataState.page}
                  count={dataProcurementVendors.total}
                  rowsPerPage={dataState.limit}
                  rowsPerPageOptions={[1, 2, 5, 10, 15, 20, { label: "All", value: -1 }]}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  className="d-flex justify-content-end"
               />
            }
         >
            <TableHead>
               <TableRow>
                  {columns.map((column, index) => (
                     <TableCell className="py-2" key={index} align={index === 3 ? "right" : ""}>
                        <TableSortLabel
                           active={orderBy === column}
                           direction={order}
                           onClick={() => handleRequestSort(column)}
                           IconComponent={ExpandLessIcon}
                        >
                           {column}
                        </TableSortLabel>
                     </TableCell>
                  ))}
               </TableRow>
            </TableHead>

            <TableBody>
               {dataProcurementVendors.filteredData.length > 0 ? (
                  dataProcurementVendors.filteredData.map((vendor, index) => (
                     <TableRow
                        key={index}
                        sx={{
                           background: "#F3F5F4",
                           "&:hover": {
                              background: "transparent",
                           },
                        }}
                     >
                        <TableCell
                           className="py-2"
                           sx={{
                              fontWeight: "500",
                              width: "70%",
                              textDecoration:
                                 dataProcurement?.status === Constant.procurementStatus.DRAFT ? "underline" : "",
                              "&:hover": {
                                 color: "#0772B6",
                                 cursor: "pointer",
                                 textDecoration: "underline",
                              },
                           }}
                           onClick={() => modalAddItemVendorRef.current.show(vendor._id)}
                        >
                           <span className="mr-2">{vendor.name}</span>
                           {dataProcurement?.status === Constant.procurementStatus.DRAFT && (
                              <FontAwesomeIcon icon={faPencilAlt} style={{ color: "#0772B6" }} />
                           )}
                        </TableCell>
                        {dataProcurement.evaluationMethod == Constant.EVALUATION_METHODS.MERIT_POINT && (
                           <TableCell className="py-2">
                              {vendor.administrationPassed == null &&
                              dataProcurement?.status === Constant.procurementStatus.DRAFT ? (
                                 <Editlink
                                    style={{ fontWeight: "500" }}
                                    onClick={() =>
                                       modalScoringAdministrationRef.current.show({
                                          vendorName: vendor.name,
                                          procurementNumber: dataProcurement?.procurementNumber,
                                          procurementVendorId: vendor._id,
                                       })
                                    }
                                 >
                                    Give Score
                                 </Editlink>
                              ) : vendor.administrationPassed ? (
                                 <Statuslabel label={"Passed"} status={"PASSED"} className="mx-0" />
                              ) : (
                                 <Statuslabel label={"Not Passed"} status={"NOT_PASSED"} className="mx-0" />
                              )}
                           </TableCell>
                        )}
                     </TableRow>
                  ))
               ) : (
                  <TableRow sx={{ background: "#F3F5F4" }}>
                     <TableCell colSpan={columns.length} align="center" sx={{ fontWeight: 500 }}>
                        No records to display
                     </TableCell>
                  </TableRow>
               )}
            </TableBody>
         </MuiDataTable.CustomTable>
      </>
   )
}

const mapStateToProps = (state) => ({
   dataProcurement: state.procurementReducer.dataProcurement,
   dataProcurementVendors: state.procurementReducer.dataProcurementVendors,
})

export default connect(mapStateToProps)(ByVendor)

const Info = styled.div`
   background-color: #ecf5fb;
   border-radius: 5px;
   font-weight: 600;
   height: 40px;
   display: flex;
   align-items: center;
   position: relative;
   margin-bottom: 18px;
`
