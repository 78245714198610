import React, { useEffect, useRef, useState } from "react"
import Modal from "react-modal"
import toast from "react-hot-toast"
import styled from "styled-components"
import { Checkbox } from "@mui/material"
import { connect, useDispatch } from "react-redux"
import { Card, Col, Form, Row } from "react-bootstrap"
import InfoOutlineIcon from "@rsuite/icons/InfoOutline"
import { useHistory, useParams } from "react-router-dom"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { Input, InputGroup, Tooltip, Whisper } from "rsuite"

// #
import Constant from "../../../constant"

// Assets
import TrashIcon from "../../assets/images/Trash.svg"
import DocumentIcon from "../../assets/images/Document.svg"
import UserChecklist from "../../assets/images/198-user.svg"
import TrashSimple from "../../assets/images/TrashSimple.svg"
import YellowAlert from "../../assets/images/yellow-alert.svg"
import WarningYellow from "../../assets/images/CarbonWarning.svg"
import IconAttendEmail from "../../assets/images/attend-icon-email.svg"

// Components
import Dropzone from "../../components/Dropzone"
import Dateinfo from "../../components/Dateinfo"
import ModalForm from "../../components/Modalform"
import Submitmodal from "../../components/Submitmodal"
import Statuslabel from "../../components/Statuslabel"
import Buttonaction from "../../components/Buttonaction"
import { GreyCard } from "../../components/styled/Generalcard"
import AttendanceConfirmationItemList from "./AttendanceConfirmationItemList"

// Redux
import {
   getProcurementAuctionPublic,
   getProcurementPublic,
   getProcurementVendorPublic,
   updateProcurementVendorItem,
} from "../../redux/action/procurementAction"
import {
   attendInvitation,
   checkInvitationVerificationCode,
   resendJoinAuctionEmail,
   uploadLetterOfAttorney,
   deleteDocument,
} from "../../redux/action/procurementAuctionAction"
import { getMyProfile } from "../../redux/action/accountAction"
import { getAuctionItems } from "../../redux/action/auctionAction"
import { fetchByVendorAndItem } from "../../redux/action/negotiationAction"

function AttendanceConfirmation({ isAppLoading }) {
   const dispatch = useDispatch()
   const { procurementId, verificationCode } = useParams()

   // States
   const [item, setItem] = useState([])
   const [itemId, setItemId] = useState(null)
   const [checked, setChecked] = useState(false)
   const [vendorItem, setVendorItem] = useState([])
   const [dataAuction, setDataAuction] = useState({})
   const [dataProcurement, setDataProcurement] = useState({})
   const [selectedDocument, setSelectedDocument] = useState({})
   const [deleteDocumentToDb, setDeleteDocumentToDb] = useState(false)
   const [modalConfirmAttend, setModalConfirmAttend] = useState(false)
   const [modalNotAttendForm, setModalNotAttendForm] = useState(false)
   const [procurementVendorId, setProcurementVendorId] = useState(null)
   const [dataProcurementVendors, setDataProcurementVendors] = useState({})
   const [disableInputCapableQty, setDisableInputCapableQty] = useState([])
   const [modalNotAttendConfirm, setModalNotAttendConfirm] = useState(false)
   const [existingLetterOfAttorney, setExistingLetterOfAttorney] = useState([])
   const [dataAuctionInvitation, setDataAuctionInvitation] = useState({
      letterOfAttorneys: [],
   })
   const [pageState, setPageState] = useState({
      hasError: false,
      isAttending: false,
      wontAttending: false,
      reasonNotAttend: "",
   })
   const [letterOfAttorney, setLetterOfAttorney] = useState({
      attachments: [],
   })
   // ===============================================================

   // Component Refs
   const capableQtyRef = useRef()
   const termsConditionsRef = useRef()
   const modalSuccessDeleteRef = useRef()
   const modalConfirmDeleteRef = useRef()
   const attendanceConfirmationItemListRef = useRef()

   const modaAdditemStyle = {
      overlay: {
         zIndex: "1060",
         background: "rgba(0,0,0,.7)",
      },
      content: {
         top: "50%",
         left: "50%",
         right: "auto",
         bottom: "auto",
         marginRight: "-50%",
         width: "573px",
         transform: "translate(-50%, -50%)",
         borderRadius: "12px",
         transition: "all .3s ease",
         backgroundColor: "white",
         paddingRight: "0",
         paddingLeft: "0",
         paddingBottom: "0",
         overflow: "hidden",
         height: "214px",
         zIndex: "1060",
      },
   }

   const modalNotAttendReasonStyle = {
      overlay: {
         zIndex: "1060",
         background: "rgba(0,0,0,.7)",
      },
      content: {
         top: "50%",
         left: "50%",
         right: "auto",
         bottom: "auto",
         marginRight: "-50%",
         width: "511px",
         transform: "translate(-50%, -50%)",
         borderRadius: "12px",
         transition: "all .3s ease",
         backgroundColor: "white",
         padding: "32px 30px",
         overflow: "hidden",
         // height: "415px",
         zIndex: "1060",
      },
   }

   const handleChange = (idx, value) => {
      let _vendorItem
      _vendorItem = [...vendorItem]
      _vendorItem[idx].capableQty = parseInt(value)
      setVendorItem(_vendorItem)
   }

   const handleInputCapableQty = (item) => {
      for (const disabledItem of disableInputCapableQty) {
         if (disabledItem.procurementItemId === item.procurementItemId) {
            return disabledItem.disabled
         }
      }
   }

   const getProcurementVendorDataPublic = (procurementVendorId) => {
      dispatch(
         getProcurementVendorPublic(
            {
               procurementVendorId: procurementVendorId,
            },
            (res) => {
               setDataProcurementVendors(res)

               const _existingLetterOfAttorney = []
               if (res.documents && res.documents.length > 0) {
                  res.documents.map((document) => {
                     const documentType = document.documentType
                     if (documentType === Constant.DOCUMENT_TYPE.LETTER_OF_ATTOURNEY) {
                        _existingLetterOfAttorney.push(document)
                     }
                  })
                  setExistingLetterOfAttorney(_existingLetterOfAttorney)
               } else {
                  setExistingLetterOfAttorney([])
               }
            },
         ),
      )
   }

   const handleDisabledInputCapableQty = (vendorItem) => {
      if (vendorItem.length > 0) {
         const arr = []
         for (const _vendorItem of vendorItem) {
            if (_vendorItem.capableQty > 0) {
               arr.push({ procurementItemId: _vendorItem.procurementItemId, disabled: true })
            } else {
               arr.push({ procurementItemId: _vendorItem.procurementItemId, disabled: false })
            }
         }
         setDisableInputCapableQty(arr)
      }
   }

   const checkCapableQty = () => {
      for (const _vendorItem of vendorItem) {
         if (
            _vendorItem.capableQty < _vendorItem.item.minCapableQty ||
            _vendorItem.capableQty > _vendorItem.item.quantity
         ) {
            return false
         }
      }
      return true
   }

   const attend = () => {
      const attachments = letterOfAttorney.attachments
      const hasDocuments = existingLetterOfAttorney.length > 0
      const attachNewDocuments = attachments.length > 0
      const isValidCapableQty = checkCapableQty()

      if (
         (dataProcurement.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE && isValidCapableQty) ||
         dataProcurement.biddingType !== Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE
      ) {
         if (hasDocuments && !attachNewDocuments) {
            dispatch(
               attendInvitation(
                  dataAuctionInvitation._id,
                  true,
                  "",
                  (_successData) => {
                     setModalConfirmAttend(true)
                     setPageState((v) => ({
                        ...v,
                        isAttending: true,
                     }))
                  },
                  (_error) => {
                     toast.error(_error[0]?.msg)
                  },
               ),
            )
            for (const _vendorItem of vendorItem) {
               dispatch(updateProcurementVendorItem(_vendorItem))
            }
         } else if ((hasDocuments && attachNewDocuments) || (!hasDocuments && attachNewDocuments)) {
            dispatch(
               attendInvitation(
                  dataAuctionInvitation._id,
                  true,
                  "",
                  (_successData) => {
                     // Upload letter of attorney
                     dispatch(
                        uploadLetterOfAttorney(dataAuctionInvitation._id, letterOfAttorney.attachments, () => {
                           dispatch(
                              checkInvitationVerificationCode(verificationCode, (_auctionInvitationData) => {
                                 setProcurementVendorId(_auctionInvitationData.procurementVendorId)
                                 getProcurementVendorDataPublic(_auctionInvitationData.procurementVendorId)
                              }),
                           )
                        }),
                     )

                     setModalConfirmAttend(true)
                     setPageState((v) => ({
                        ...v,
                        isAttending: true,
                     }))
                  },
                  (_error) => {
                     toast.error(_error[0]?.msg)
                  },
               ),
            )
            for (const _vendorItem of vendorItem) {
               dispatch(updateProcurementVendorItem(_vendorItem))
            }
         }
         // else if (!hasDocuments && !attachNewDocuments) {
         //    toast.error("Please upload letter of attorney(s)")
         // }
         else {
            dispatch(
               attendInvitation(
                  dataAuctionInvitation._id,
                  true,
                  "",
                  (_successData) => {
                     setModalConfirmAttend(true)
                     setPageState((v) => ({
                        ...v,
                        isAttending: true,
                     }))
                  },
                  (_error) => {
                     toast.error(_error[0]?.msg)
                  },
               ),
            )
            for (const _vendorItem of vendorItem) {
               dispatch(updateProcurementVendorItem(_vendorItem))
            }
         }
      } else {
         toast.error(
            `Your input must be above or equal to ${item.filteredData[0].minCapableQty} and below or equal to ${item.filteredData[0].qty}`,
         )
      }
   }

   const notAttend = () => {
      setModalNotAttendForm(false)
      dispatch(
         attendInvitation(
            dataAuctionInvitation._id,
            false,
            pageState.reasonNotAttend,
            () => {
               setModalNotAttendConfirm(true)
               setPageState((v) => ({
                  ...v,
                  isAttending: false,
                  wontAttending: true,
               }))
            },
            (_error) => {
               toast.error(_error[0]?.msg)
            },
         ),
      )
   }

   useEffect(() => {
      // get profile
      dispatch(getMyProfile())
      const email = location.href.split("?")[1].split("=")[1]

      localStorage.setItem("verificationCode", verificationCode)
      localStorage.setItem("email", email)

      // check login
      const accessToken = localStorage.getItem("access_token")
      if (!accessToken) {
         const currentUrl = location.href
         // redirect when user is not logged in
         location.href = "/vendor-login?prev=" + currentUrl
      }

      // check verification vode
      dispatch(
         checkInvitationVerificationCode(
            verificationCode,
            (_auctionInvitationData) => {
               getProcurementVendorDataPublic(_auctionInvitationData.procurementVendorId)

               setDataAuctionInvitation(_auctionInvitationData)
               setProcurementVendorId(_auctionInvitationData.procurementVendorId)

               dispatch(
                  getAuctionItems({ auctionId: _auctionInvitationData.auctionId }, (res) => {
                     const procItemId = []
                     if (res.filteredData.length > 0) {
                        for (const item of res.filteredData) {
                           procItemId.push(item.procurementItemId)
                        }
                        dispatch(
                           fetchByVendorAndItem(_auctionInvitationData.procurementVendorId, procItemId, (res2) => {
                              handleDisabledInputCapableQty(res2)
                              if (dataProcurement?.winnerType === Constant.WINNER_TYPE.SINGLE_WINNER) {
                                 for (const vendorItem of res2) {
                                    if (vendorItem.capableQty === 0) {
                                       for (const item of res.filteredData) {
                                          if (item.procurementItemId === vendorItem.procurementItemId) {
                                             vendorItem.capableQty = item.qty
                                          }
                                       }
                                    }
                                 }
                              }
                              setVendorItem(res2)
                           }),
                        )
                     }
                     setItem(res)
                  }),
               )
               setPageState((v) => ({
                  ...v,
                  isAttending: _auctionInvitationData.status === "ATTEND",
                  wontAttending: _auctionInvitationData.status === "NOT_ATTEND",
                  reasonNotAttend: _auctionInvitationData.reasonNotAttend ? _auctionInvitationData.reasonNotAttend : "",
               }))

               // get procurement info
               dispatch(
                  getProcurementPublic(
                     { procurementId },
                     (_data) => {
                        setDataProcurement(_data)
                     },
                     (_err) => {},
                  ),
               )

               // get auction info
               dispatch(
                  getProcurementAuctionPublic(
                     { auctionId: _auctionInvitationData?.auctionId },
                     (_data) => {
                        setDataAuction(_data)
                     },
                     (_err) => {},
                  ),
               )
            },
            (_errors) => {
               const errMessage = _errors[0]?.msg
               if (errMessage === "Link expired") {
                  // location.href="/vendor-auction/attendance-expired"
               }

               toast.error(errMessage)

               setPageState((_state) => ({
                  hasError: true,
               }))
            },
         ),
      )
   }, [itemId])

   useEffect(() => {
      console.log({ selectedDocument, deleteDocumentToDb })
   }, [selectedDocument, deleteDocumentToDb])

   const handleBeforeDelete = (file, deleteDocumentToDB) => {
      setDeleteDocumentToDb(deleteDocumentToDB)
      setSelectedDocument((prev) => ({ ...prev, ...file }))
      modalConfirmDeleteRef.current.show({
         modalTitle: "",
         title: "Delete Uploaded Document?",
         description: (
            <p>
               Do you want to <span style={{ color: "#0772B6", fontWeight: 500 }}>Delete</span>{" "}
               {deleteDocumentToDB ? file.fileName : file.path}?
            </p>
         ),
      })
   }

   const removeAttachment = () => {
      if (deleteDocumentToDb) {
         // handle delete LOA in DB
         dispatch(
            deleteDocument(
               "letterOfAttorney",
               selectedDocument._id,
               procurementVendorId,
               (res) => {
                  getProcurementVendorDataPublic(procurementVendorId)
                  modalSuccessDeleteRef.current.show({
                     modalTitle: "",
                     title: "Document has been deleted",
                     description: <p>Selected document has been deleted</p>,
                  })
               },
               (err) => {
                  modalSuccessDeleteRef.current.show({
                     modalTitle: "",
                     title: "Delete failed",
                     description: <p>Selected document failed to be deleted</p>,
                  })
               },
            ),
         )
         setDeleteDocumentToDb(false)
      } else {
         // only delete LOA in client view
         const deletedIndex = letterOfAttorney.attachments.indexOf(selectedDocument)
         const newAttachments = letterOfAttorney.attachments
         newAttachments.splice(deletedIndex, 1)
         setLetterOfAttorney((v) => ({ ...v, attachments: newAttachments }))
      }

      modalConfirmDeleteRef.current.hide()

      setTimeout(() => {
         modalSuccessDeleteRef.current.hide()
      }, 2500)
   }

   const capitalizeFirstLetterEachWord = (sentence = "") => {
      return sentence
         .split("_")
         .map((word) => {
            return word[0] + word.substring(1).toLowerCase()
         })
         .join(" ")
   }

   const [isSending, setIsSending] = useState(false)
   const resendAuctionEmail = () => {
      setIsSending(true)
      new Promise((resolve, reject) => {
         dispatch(
            resendJoinAuctionEmail(
               procurementVendorId,
               dataAuctionInvitation.auctionId,
               () => {
                  resolve()
               },
               () => {
                  reject()
               },
            ),
         )
      })
         .then(() => {})
         .catch(() => {})
         .finally(() => {
            setIsSending(false)
         })
   }

   return (
      <>
         {!isAppLoading && (
            <Col className="mb-5" lg={12} style={{ paddingRight: "60px", paddingLeft: "30px" }}>
               <Row className="mt-5 d-flex justify-content-center">
                  <Col lg={10} className="mt-5">
                     <p
                        className="mb-3"
                        style={{
                           marginLeft: "1.5%",
                           fontSize: "24px",
                           fontWeight: "500",
                        }}
                     >
                        {/* PROCINI/001/2020 */}
                        {dataProcurement?.procurementNumber}
                     </p>
                     <GreyCard className="pb-4">
                        <p style={{ fontSize: "18px", fontWeight: "500" }}>Procurement Info</p>
                        <hr
                           style={{
                              height: "1px",
                              color: "#C4C4C4",
                              backgroundColor: "#C4C4C4",
                           }}
                        />
                        <Row className="mt-3" style={{ fontSize: "12px" }}>
                           <Col lg={2}>
                              <p>Procurement Number</p>
                           </Col>
                           <Col lg={10}>
                              <p>{dataProcurement?.procurementNumber}</p>
                           </Col>
                        </Row>
                        <Row className="mt-3" style={{ fontSize: "12px" }}>
                           <Col lg={2}>
                              <p>Post Date</p>
                           </Col>
                           <Col lg={10}>
                              <p>
                                 <Dateinfo showtime={true} date={dataProcurement?.createdAt} />
                              </p>
                           </Col>
                        </Row>
                        <Row className="mt-3" style={{ fontSize: "12px" }}>
                           <Col lg={2}>
                              <p>Procurement Name</p>
                           </Col>
                           <Col lg={10}>
                              <p>{dataProcurement?.procurementName}</p>
                           </Col>
                        </Row>
                        <Row className="mt-3" style={{ fontSize: "12px" }}>
                           <Col lg={2}>
                              <p>Negotiation Type</p>
                           </Col>
                           <Col lg={10}>
                              <p>{capitalizeFirstLetterEachWord(dataProcurement?.negotiationType)}</p>
                           </Col>
                        </Row>
                        <Row className="mt-3" style={{ fontSize: "12px" }}>
                           <Col lg={2}>
                              <p>Evaluation Method</p>
                           </Col>
                           <Col lg={10}>
                              <p>{capitalizeFirstLetterEachWord(dataProcurement?.evaluationMethod)}</p>
                           </Col>
                        </Row>
                        <Row className="mt-3" style={{ fontSize: "12px" }}>
                           <Col lg={2}>
                              <p>Bidding Type</p>
                           </Col>
                           <Col lg={10}>
                              <p>{capitalizeFirstLetterEachWord(dataProcurement?.biddingType)}</p>
                           </Col>
                        </Row>
                        <Row className="mt-3" style={{ fontSize: "12px" }}>
                           <Col lg={2}>
                              <p>Winner Type</p>
                           </Col>
                           <Col lg={10}>
                              <p>{capitalizeFirstLetterEachWord(dataProcurement?.winnerType)}</p>
                           </Col>
                        </Row>
                        <Row className="mt-3" style={{ fontSize: "12px" }}>
                           <Col lg={2}>
                              <p>Currency Type</p>
                           </Col>
                           <Col lg={10}>
                              <p>{capitalizeFirstLetterEachWord(dataProcurement?.currencyType)}</p>
                           </Col>
                        </Row>
                        <Row className="mt-3" style={{ fontSize: "12px" }}>
                           <Col lg={2}>
                              <p>Price Include TAX</p>
                           </Col>
                           <Col lg={10}>
                              <p>{dataProcurement?.priceIncludeTax ? "Yes" : "No"}</p>
                           </Col>
                        </Row>
                        <Row className="mt-3" style={{ fontSize: "12px" }}>
                           <Col lg={2}>
                              <p>Letter of Attorney</p>
                           </Col>
                           <Col xl={6}>
                              {/* dataAuctionInvitation.letterOfAttorneys < 1 ? ( */}
                              {!pageState.isAttending && (
                                 <form method="post" encType="multipart/form-data">
                                    <Dropzone
                                       maxFiles={5}
                                       acceptedFiles={
                                          "application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/jpg, image/jpeg"
                                       }
                                       onDrop={(acceptedFiles) =>
                                          letterOfAttorney.attachments.length < 5 &&
                                          acceptedFiles.length + letterOfAttorney.attachments.length < 6 &&
                                          setLetterOfAttorney((v) => ({
                                             ...v,
                                             attachments: acceptedFiles, //letterOfAttorney.attachments.concat(
                                             // acceptedFiles.map((file) =>
                                             //   Object.assign(file, {
                                             //     preview: URL.createObjectURL(file),
                                             //   })
                                             // )
                                             //),
                                          }))
                                       }
                                    >
                                       <p
                                          style={{
                                             fontSize: "12px",
                                             fontWeight: "500",
                                             marginTop: "8px",
                                          }}
                                       >
                                          Accept Maximum 5 files with format .pdf .doc .docx .jpg .jpeg
                                       </p>
                                       {letterOfAttorney.attachments.map((file, index) => (
                                          <Card
                                             key={index}
                                             style={{
                                                border: "1px solid #C4C4C4",
                                                width: "100%",
                                             }}
                                             className={`${
                                                index === 0 && letterOfAttorney.attachments < 2 ? "mt-3" : "mt-2"
                                             }`}
                                          >
                                             <Card.Body>
                                                <Row className="d-flex align-items-center">
                                                   <Col
                                                      lg={5}
                                                      className="d-flex align-items-center"
                                                      style={{
                                                         color: "#0772B6",
                                                         fontSize: "12px",
                                                      }}
                                                   >
                                                      <img
                                                         src={DocumentIcon}
                                                         alt="document icon"
                                                         style={{ marginRight: "8px" }}
                                                      />
                                                      <FileName href={file.preview} target="_blank" title={file.path}>
                                                         {file.path}
                                                      </FileName>
                                                   </Col>
                                                   <Col lg={4} className="text-muted" style={{ fontSize: "12px" }}>
                                                      {Math.floor(file.size * 0.001)} Kb
                                                   </Col>
                                                   <Col lg={3} className="d-flex justify-content-end">
                                                      <Delete
                                                         className="d-flex align-items-center"
                                                         onClick={() => handleBeforeDelete(file, false)}
                                                      >
                                                         <img
                                                            src={TrashSimple}
                                                            alt="trash icon"
                                                            style={{ marginRight: "10px" }}
                                                         />
                                                         Delete
                                                      </Delete>
                                                   </Col>
                                                </Row>
                                             </Card.Body>
                                          </Card>
                                       ))}
                                    </Dropzone>
                                 </form>
                              )}

                              {existingLetterOfAttorney.length > 0 &&
                                 existingLetterOfAttorney.map((file, index) => (
                                    <>
                                       <Card
                                          key={index}
                                          style={{
                                             border: "1px solid #C4C4C4",
                                             width: "100%",
                                          }}
                                          className="mt-2"
                                       >
                                          <Card.Body>
                                             <Row className="d-flex align-items-center">
                                                <Col
                                                   lg={5}
                                                   className="d-flex align-items-center"
                                                   style={{
                                                      color: "#0772B6",
                                                      fontSize: "12px",
                                                   }}
                                                >
                                                   <img
                                                      src={DocumentIcon}
                                                      alt="document icon"
                                                      style={{ marginRight: "8px" }}
                                                   />
                                                   <FileName href={file.url} target="_blank" title={file.fileName}>
                                                      {file.fileName}
                                                   </FileName>
                                                </Col>
                                                <Col lg={4} className="text-muted" style={{ fontSize: "12px" }}>
                                                   {Math.floor(file.size * 0.001)} Kb
                                                </Col>
                                                <Col lg={3} className="d-flex justify-content-end">
                                                   {pageState.isAttending || pageState.wontAttending ? (
                                                      <></>
                                                   ) : (
                                                      <Delete
                                                         className="d-flex align-items-center"
                                                         onClick={() => handleBeforeDelete(file, true)}
                                                      >
                                                         <img
                                                            src={TrashSimple}
                                                            alt="trash icon"
                                                            style={{ marginRight: "10px" }}
                                                         />
                                                         Delete
                                                      </Delete>
                                                   )}
                                                </Col>
                                             </Row>
                                          </Card.Body>
                                       </Card>
                                    </>
                                 ))}
                           </Col>
                        </Row>
                     </GreyCard>
                  </Col>
               </Row>

               <Row className="mt-5 d-flex justify-content-center">
                  <Col lg={10}>
                     <GreyCard>
                        <p style={{ fontSize: "18px", fontWeight: "500" }}>Item List</p>
                        <hr
                           style={{
                              height: "1px",
                              color: "#C4C4C4",
                              backgroundColor: "#C4C4C4",
                           }}
                        />
                        {Object.entries(dataProcurement).length > 0 && (
                           <AttendanceConfirmationItemList
                              passedInRef={attendanceConfirmationItemListRef}
                              dataProcurement={dataProcurement}
                              item={item}
                              auctionInvitationData={dataAuctionInvitation}
                              setItemId={setItemId}
                           />
                        )}

                        {dataProcurement.biddingType === Constant.PROCUREMENT_BIDDING_TYPE.UNIT_PRICE && (
                           <>
                              <p style={{ fontSize: "18px", fontWeight: "500", marginTop: "40px" }}>
                                 Capability Quantity
                              </p>
                              <hr
                                 style={{
                                    height: "1px",
                                    color: "#C4C4C4",
                                    backgroundColor: "#C4C4C4",
                                 }}
                              />
                              <Info>
                                 <img src={WarningYellow} style={{ position: "absolute", top: "0", left: "0" }} />
                                 <p style={{ fontSize: "12px", zIndex: "99", color: "#454545" }} className="ml-5">
                                    Your minimum capable quantity must be equal or higher than requested minimal capable
                                    quantity.
                                 </p>
                              </Info>
                              <Form>
                                 <Form.Group as={Row} className="mb-2 align-items-center" controlId="capable_qty">
                                    <Form.Label column xs={3} className="ml-2">
                                       <p style={{ fontSize: "14px", fontWeight: "400", color: "#454545" }}>
                                          Your Minimum Capable Quantity
                                       </p>
                                    </Form.Label>
                                    <Col>
                                       <InputGroup inside style={{ width: "200px" }}>
                                          {vendorItem?.map((item, index) => (
                                             <Input
                                                ref={capableQtyRef}
                                                disabled={handleInputCapableQty(item) || pageState.isAttending}
                                                value={item.capableQty}
                                                type="number"
                                                placeholder="0"
                                                onChange={(v) => {
                                                   handleChange(index, v)
                                                }}
                                                onClick={(e) => {
                                                   parseInt(e.target.value) === 0 && capableQtyRef.current.select()
                                                }}
                                             />
                                          ))}
                                          <InputGroup.Addon>
                                             <Whisper
                                                placement="top"
                                                speaker={
                                                   <Tooltip>
                                                      Jumlah minimal yang dapat Anda penuhi. Jumlah ini minimal harus
                                                      sama atau lebih dari jumlah minimal yang diminta oleh Buyer.
                                                   </Tooltip>
                                                }
                                             >
                                                <InfoOutlineIcon />
                                             </Whisper>
                                          </InputGroup.Addon>
                                       </InputGroup>
                                    </Col>
                                 </Form.Group>
                              </Form>
                           </>
                        )}
                     </GreyCard>
                  </Col>
               </Row>

               <Row className="mt-5 d-flex justify-content-center">
                  <Col lg={10}>
                     <GreyCard>
                        <p style={{ fontSize: "18px", fontWeight: "500" }}>Auction Schedule</p>
                        <hr
                           style={{
                              height: "1px",
                              color: "#C4C4C4",
                              backgroundColor: "#C4C4C4",
                           }}
                        />
                        <Row className="mt-3" style={{ fontSize: "12px" }}>
                           <Col lg={2}>
                              <p>Auction Number</p>
                           </Col>
                           <Col lg={10}>
                              <p>{dataAuction?.auctionNumber}</p>
                           </Col>
                        </Row>
                        <Row className="mt-3" style={{ fontSize: "12px" }}>
                           <Col lg={2}>
                              <p>Auction Time</p>
                           </Col>
                           <Col lg={10}>
                              <p>
                                 <Dateinfo style={{ all: "unset" }} date={dataAuction?.startTime} format="dd/mm/yyyy" />{" "}
                                 <span style={{ fontSize: "12px" }}>
                                    {dataAuction?.startTime &&
                                       new Date(dataAuction.startTime).toLocaleString("en-US", {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          hour12: false,
                                       })}
                                 </span>
                                 <span className="mx-1"> - </span>
                                 <Dateinfo
                                    style={{ all: "unset" }}
                                    date={dataAuction?.endTime}
                                    format="dd/mm/yyyy"
                                 />{" "}
                                 <span style={{ fontSize: "12px" }}>
                                    {dataAuction?.endTime &&
                                       new Date(dataAuction.endTime).toLocaleString("en-US", {
                                          hour: "2-digit",
                                          minute: "2-digit",
                                          hour12: false,
                                       })}
                                 </span>
                              </p>
                           </Col>
                        </Row>
                        <Row className="mt-3" style={{ fontSize: "12px" }}>
                           <Col lg={2}>
                              <p>Auction Type</p>
                           </Col>
                           <Col lg={10}>
                              <p>
                                 {dataAuction.auctionType
                                    ? dataAuction?.auctionType.toLowerCase() === "open"
                                       ? "Open Auction"
                                       : dataAuction?.auctionType.toLowerCase() === "close"
                                       ? "Close Auction"
                                       : dataAuction?.auctionType
                                    : ""}
                              </p>
                           </Col>
                        </Row>
                        <Row className="mt-3" style={{ fontSize: "12px" }}>
                           <Col lg={2}>
                              <p>Status</p>
                           </Col>
                           <Col lg={10} className="p-0">
                              <Statuslabel
                                 style={{ marginLeft: "1.4%" }}
                                 label={dataAuction?.status}
                                 status={dataAuction?.status}
                              />
                           </Col>
                        </Row>
                        <hr
                           style={{
                              height: "1px",
                              color: "#C4C4C4",
                              backgroundColor: "#C4C4C4",
                           }}
                        />
                        <div
                           className="d-flex align-items-center mb-2"
                           style={{ backgroundColor: "#F9F1DA", height: "40px", borderRadius: "5px" }}
                        >
                           <img className="mb-2" src={YellowAlert} />
                           <p className="ml-3" style={{ color: "#454545", fontWeight: "400", fontSize: "12px" }}>
                              Please accept the term proceed.
                           </p>
                        </div>
                        <div className="d-flex align-items-center">
                           <Checkbox
                              defaultChecked={pageState?.isAttending || pageState?.wontAttending}
                              disabled={pageState?.isAttending || pageState?.wontAttending}
                              className="px-0"
                              size="small"
                              onClick={(e) => setChecked(e.target.checked)}
                           />
                           <div style={{ fontWeight: "400", fontSize: "14px" }}>
                              <p className="ml-3" style={{ color: "#909090" }}>
                                 I agree to the{" "}
                                 <span
                                    style={{ color: "#0772B6", textDecoration: "underline", cursor: "pointer" }}
                                    onClick={() => termsConditionsRef.current.show()}
                                 >
                                    Terms & Conditions
                                 </span>
                              </p>
                           </div>
                        </div>
                        <Row
                           className="mt-3"
                           style={{
                              borderRadius: "5px",
                              padding: "15px",
                              backgroundColor: "#E9F5FE",
                              marginLeft: "0",
                              marginRight: "0",
                           }}
                        >
                           <Col lg={1}>
                              <img src={UserChecklist} alt="" />
                           </Col>
                           <Col lg={7} style={{ fontSize: "12px", color: "#0772B6" }}>
                              <p className="mt-3">
                                 Confirm your auction participation before{" "}
                                 <b>
                                    <Dateinfo
                                       style={{
                                          fontSize: 12,
                                          fontWeight: "bold",
                                          color: "#0772B6",
                                       }}
                                       date={dataAuction?.startTime}
                                       format="mmmm dd, yyyy"
                                    />
                                    <span style={{ fontSize: "12px" }}>
                                       {dataAuction?.startTime &&
                                          new Date(dataAuction.startTime).toLocaleString("en-US", {
                                             hour: "2-digit",
                                             minute: "2-digit",
                                             hour12: false,
                                          })}
                                    </span>
                                 </b>
                              </p>
                              <p>
                                 If your are not confirm your attendance, you are declared not to follow the auction
                                 session
                              </p>
                           </Col>

                           {!pageState.isAttending && !pageState.wontAttending && (
                              <>
                                 <Col lg={2} className="mt-3 d-flex justify-content-end">
                                    {!pageState.hasError && (
                                       <Buttonaction
                                          borderblueOcean
                                          small
                                          label="Not Attend"
                                          onClick={() => setModalNotAttendForm(true)}
                                       />
                                    )}
                                 </Col>
                                 <Col lg={2} className="mt-3">
                                    {" "}
                                    {!pageState.hasError && (
                                       <Buttonaction
                                          blueOcean
                                          small
                                          label="Attend"
                                          onClick={attend}
                                          disabled={checked == false}
                                       />
                                    )}
                                 </Col>
                              </>
                           )}

                           {pageState.isAttending && !pageState.wontAttending && (
                              <>
                                 <Col lg={2} className="mt-3 d-flex justify-content-end">
                                    <Buttonaction disabled gray small label="Not Attend" />
                                 </Col>
                                 <Col lg={2} className="mt-3">
                                    <Buttonaction
                                       blueOcean
                                       small
                                       label="Attend"
                                       faIcon={faCheck}
                                       style={{
                                          pointerEvents: "none",
                                       }}
                                    />
                                 </Col>
                              </>
                           )}

                           {!pageState.isAttending && pageState.wontAttending && (
                              <>
                                 <Col lg={2} className="mt-3 d-flex justify-content-end">
                                    <Buttonaction
                                       blueOcean
                                       small
                                       label="Not Attend"
                                       faIcon={faCheck}
                                       style={{
                                          pointerEvents: "none",
                                       }}
                                    />
                                 </Col>
                                 <Col lg={2} className="mt-3">
                                    <Buttonaction disabled gray small label="Attend" />
                                 </Col>
                              </>
                           )}
                        </Row>
                     </GreyCard>
                  </Col>
               </Row>
            </Col>
         )}

         {/* modal confirm attend */}
         <Modal isOpen={modalConfirmAttend} ariaHideApp={false} style={modaAdditemStyle} contentLabel="Success">
            <Row style={{ marginTop: "25px", marginLeft: "30px" }}>
               <Col lg={2}>
                  <img src={IconAttendEmail} alt="" />
               </Col>
               <Col lg={10}>
                  <p style={{ fontSize: "24px", fontWeight: "500" }}>Thank you for your confirmation</p>
                  <p style={{ fontSize: "12px", marginTop: "5px", maxWidth: "380px" }}>
                     Auction link has been sent to
                     <a
                        href=""
                        style={{
                           fontWeight: "700",
                           color: "#0772B6",
                           textDecoration: "underline",
                           margin: "0 5px",
                        }}
                     >
                        {dataAuctionInvitation?.vendorEmail}
                     </a>
                     Please follow the auction schedule for join the session.
                  </p>
               </Col>
            </Row>

            <Row>
               <div className="mt-4 d-flex justify-content-end w-100 px-5">
                  <div className="mr-3">
                     <Buttonaction borderblueOcean label="Close" large onClick={() => setModalConfirmAttend(false)} />
                  </div>
                  <Buttonaction blueOcean label="Resend Email" large loading={isSending} onClick={resendAuctionEmail} />
               </div>
            </Row>
         </Modal>

         {/* modal not attend reason */}
         <Modal
            isOpen={modalNotAttendForm}
            ariaHideApp={false}
            style={modalNotAttendReasonStyle}
            contentLabel="Success"
         >
            <Row>
               <Col lg={12}>
                  <p style={{ fontSize: "18px", fontWeight: "700" }}>Confirmation Reason</p>
                  <hr style={{ borderColor: "#C4C4C4" }} />
                  <p style={{ fontSize: "12px" }}>
                     You are diclared to not attend the auction session, please fulfill the reason to submit
                  </p>
                  <Form.Group className="mt-3 mb-3">
                     <Form.Label style={{ color: "#909090" }}>Email</Form.Label>
                     <Form.Control type="text" placeholder="email" value={dataProcurementVendors.email} disabled />
                  </Form.Group>
                  <Form.Group className="mt-3">
                     <Form.Label style={{ color: "#909090" }}>Reason</Form.Label>
                     <Form.Control
                        as="textarea"
                        placeholder="your reason"
                        style={{ resize: "none", height: "90px" }}
                        onChange={(v) =>
                           setPageState({
                              ...pageState,
                              reasonNotAttend: v.target.value,
                           })
                        }
                     />
                  </Form.Group>
               </Col>
            </Row>

            <Row className="mx-0" style={{ marginTop: "16px" }}>
               <Col lg={9} className="d-flex justify-content-end px-0">
                  <Buttonaction
                     disabled={!pageState.reasonNotAttend}
                     blueOcean
                     label="Submit"
                     large
                     onClick={notAttend}
                  />
               </Col>
               <Col className="d-flex justify-content-end px-0">
                  <Buttonaction borderblueOcean label="Cancel" large onClick={() => setModalNotAttendForm(false)} />
               </Col>
            </Row>
         </Modal>

         {/* modal not attend confirm */}
         <Modal isOpen={modalNotAttendConfirm} ariaHideApp={false} style={modaAdditemStyle} contentLabel="Success">
            <Row style={{ marginTop: "25px", marginLeft: "30px" }}>
               <Col lg={2}>
                  <img src={IconAttendEmail} alt="" />
               </Col>
               <Col lg={10}>
                  <p style={{ fontSize: "24px", fontWeight: "500" }}>Thank you for your confirmation</p>
                  <p style={{ fontSize: "12px", marginTop: "5px", maxWidth: "380px" }}>See you in another auction!</p>
               </Col>
            </Row>

            <Row style={{ marginLeft: "420px", marginTop: "26px" }}>
               <Col lg={5}>
                  <Buttonaction borderblueOcean label="Close" large onClick={() => setModalNotAttendConfirm(false)} />
               </Col>
            </Row>
         </Modal>

         {/* Modal Terms & Conditions */}
         <ModalForm passedInRef={termsConditionsRef} title={"Terms & Conditions"} closeButton={true} width="1000px">
            <p className="ml-4 mb-5" style={{ fontWeight: "400", fontSize: "14px", color: "#909090" }}>
               Please read these Terms and Conditions carefully before submitting the refutation form.
               <br />
               <br />
               Your participation in refute that you will submit is conditioned on your acceptance of and compliance
               with these Terms. These Terms apply to all vendors who are not the winner of the procurement.
               <br />
               <br />
               By saving or submitting the refutation form you agree to be bound by these Terms. If you disagree with
               any part of the terms then you may not use the Refutation Service.
               <br />
               <br />
               <br />
               <p style={{ fontSize: "16px", fontWeight: "700", color: "#454545" }}>About</p>
               If you have any questions about these Terms, please contact us.
               <br />
               <br />
               <br />
               <p style={{ fontSize: "16px", fontWeight: "700", color: "#454545" }}>Terms</p>
               If you have any questions about these Terms, please contact us.
               <br />
               <br />
               <br />
               <p style={{ fontSize: "16px", fontWeight: "700", color: "#454545" }}>Contact Us</p>
               If you have any questions about these Terms, please contact us.
            </p>
         </ModalForm>

         <Submitmodal.ModalContainer passedInRef={modalConfirmDeleteRef} icon={TrashIcon}>
            <Submitmodal.ModalButtonActions>
               <div className="mt-4 d-flex justify-content-end">
                  <div className="mr-3">
                     <Buttonaction
                        borderblueOcean
                        type="button"
                        label="No"
                        large
                        onClick={() => modalConfirmDeleteRef.current.hide()}
                     />
                  </div>
                  <Buttonaction blueOcean type="button" label="Yes" large onClick={removeAttachment} />
               </div>
            </Submitmodal.ModalButtonActions>
         </Submitmodal.ModalContainer>

         {/* Success delete modal */}
         <Submitmodal.ModalContainer passedInRef={modalSuccessDeleteRef} icon={TrashIcon} withCloseButton={false} />
      </>
   )
}

const mapStateToProps = (state) => {
   return {
      isAppLoading: state.appReducer.isAppLoading,
      dataProcurementItems: state.procurementReducer.dataProcurementItems,
      dataProfile: state.accountReducer.dataProfile,
   }
}

export default connect(mapStateToProps)(AttendanceConfirmation)

const FileName = styled.a`
   color: #0772b6;
   font-weight: 600;
   text-decoration: underline;
   width: 100%;
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
`

const Delete = styled.a`
   color: #909090;
   cursor: pointer;

   &:hover {
      color: #909090;
      text-decoration: none;
   }
`

const Info = styled.div`
   background-color: #fff9e6;
   border-radius: 5px;
   font-weight: 600;
   height: 40px;
   display: flex;
   align-items: center;
   position: relative;
   margin-bottom: 18px;
   margin-left: 10px;
   margin-right: 10px;
`
