import styled from "styled-components";
import { Col, InputGroup } from "react-bootstrap";
import { GreyCard } from "../../components/styled/Generalcard";

const OuterWrapper = styled(Col)`
  padding: 5% 60px 0 60px;
  position: relative;
`;

const ProcurementName = styled.p`
  font-size: 14px;
  font-weight: 700;
`;

const AuctionRoom = styled.p`
  font-size: 12px;
`;

const Divider = styled.hr`
  border-color: #c4c4c4;
`;

const AuctionInfoWrapper = styled(GreyCard)`
  padding-left: 20px;
  width: 110%;
  // height: 100%;
`;
const MeritPointWrapper = styled(GreyCard)`
  padding-left: 20px;
  width: 100%;
  height: 100%;
  // margin-left: 11.5%;
`;

const SectionTitle = styled.p`
  font-size: 18px;
  font-weight: 600;
`;

const OfferLogsWrapper = styled(GreyCard)`
  padding-left: 20px;
  width: 110%;
  height: 527px;
`;

const LogWrapper = styled(Col)`
  background-color: #f3f5f4;
  height: 410px;
  border-radius: 5px;
  /* padding-left: 30px; */
  padding-top: 10px;
  padding-bottom: 25px;
  overflow-y: scroll;
`;

const ItemListWrapper = styled(GreyCard)`
  padding-left: 20px;
  height: 527px;
  margin-left: 5.5%;
  position: relative;
`;

const InputGroupText = styled(InputGroup.Text)`
  margin-left: -3px;
  font-size: 12px;
  z-index: 0;
`;

const RankWrapper = styled(GreyCard)`
  padding-left: 20px;
  width: 110%;
  // margin-left: 11.5%;
  height: auto;
`;

const VendorAuctionStyles = {
  OuterWrapper,
  ProcurementName,
  AuctionRoom,
  Divider,
  AuctionInfoWrapper,
  SectionTitle,
  OfferLogsWrapper,
  LogWrapper,
  ItemListWrapper,
  InputGroupText,
  RankWrapper,
  MeritPointWrapper,
};

export default VendorAuctionStyles;
