import Apiservice from "../../services/Apiservice"
import { setAppLoading } from "./appAction"

export function getCompanyByCompanyEmail(email, callback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokeGet(
         `${Apiservice.url.AUCTION_URL}/api/secured/company/find-by/email?email=${email}`,
      )

      if (resp.status === 200) {
         callback(resp.data.data)
      }
   }
}

export function updateCompany(payload, callback, errCallback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokePost(`${Apiservice.url.AUCTION_URL}/api/secured/company/update/${payload._id}`, payload)

      if (resp.status === 200) {
         if (callback) {
            callback(resp.data.data)
         }
      } else {
         if (errCallback) {
            errCallback()
         }
      }
   }
}
