import React from "react";
import styled from "styled-components";

export default function Chip({ children, onClick, kind }) {
  const handleOnClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <ChipStyle kind={kind} onClick={handleOnClick}>
      {children}
    </ChipStyle>
  );
}

const ChipStyle = styled.a`
  // min-width: 48px;
  width: fit-content;
  height: 18px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  padding: 4px 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-color: ${(props) =>
    props.kind == "canceled" ||
    props.kind == "not_attend" ||
    props.kind == "disqualified"
      ? "#F44437"
      : props.kind == "ongoing"
      ? "#BB6BD9"
      : props.kind == "onnegotiation"
      ? "#FFB301"
      : props.kind == "draft" || props.kind == "not_confirmed" || props.kind == "not_joined"
      ? "#909090"
      : props.kind == "closed" || props.kind == "close"
      ? "#03D4B4"
      : props.kind == "open"
      ? "#0772B6"
      : props.kind == "attend" || props.kind == "valid" || props.kind == "joined"
      ? "#4DAF51"
      : "#000000"};
  border-radius: 50px;
  &:hover {
    color: #ffffff;
    text-decoration: none;
  }
  white-space: nowrap
`;
