import React from "react"
import { Row } from "react-bootstrap"
import styled from "styled-components"

// Components
import { GreyCard } from "../../components/styled/Generalcard"

function VendorAuctionHeader({ dataProcurement, dataAuction }) {
   return (
      <GreyCard className="w-100" style={{ paddingLeft: "30px" }}>
         <Row>
            <ProcurementName>{dataProcurement?.procurementName}</ProcurementName>
         </Row>
         <Row className="mt-1">
            <AuctionRoom>Auction Room [ {dataAuction?.auctionNumber} ]</AuctionRoom>
         </Row>
      </GreyCard>
   )
}

export default VendorAuctionHeader

const ProcurementName = styled.p`
   font-size: 14px;
   font-weight: 700;
`

const AuctionRoom = styled.p`
   font-size: 12px;
`
