import React from "react"
import { Col, Row } from "react-bootstrap"
import { useParams } from "react-router"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import { createSession } from "../../../redux/action/negotiationAction"
import Buttonaction from "../../../components/Buttonaction"
import DollarAddIcon from "../../../assets/images/DollarAdd.svg"
import { GreyCard } from "../../../components/styled/Generalcard"
import CaretCircleRightIcon from "../../../assets/images/CaretCircleRight.svg"

import { Tooltip } from "@mui/material"

const ContinueNegotiation = ({ passedInRef, handleExpand, procurementHasEnded }) => {
   const { procurementId } = useParams()
   const history = useHistory()
   const dispatch = useDispatch()
   const handleClickNegotiation = () => {
      history.push(`/negotiation/session/${procurementId}/new`)
   }

   return (
      <div ref={passedInRef} id="nego" onMouseEnter={() => handleExpand({ expand: "nego" })}>
         <Row>
            <Col className="mb-5">
               <GreyCard style={{ paddingBottom: "34px" }}>
                  <Row>
                     <Col className="d-flex justify-content-between align-items-center px-4 mt-3">
                        <p
                           style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                              color: "#454545",
                           }}
                        >
                           Negotiation Session
                        </p>
                     </Col>
                  </Row>

                  <hr style={{ background: "#C4C4C4" }} />

                  <Row style={{ marginTop: "44px" }}>
                     <Col className="d-flex flex-column align-items-center">
                        <img src={DollarAddIcon} style={{ marginBottom: "18px", width: "72px" }} />
                        {procurementHasEnded ? (
                           <Tooltip
                              title="Can't create negotiation due to procurement has ended"
                              arrow
                              placement="bottom"
                              sx={{ whiteSpace: "nowrap", width: "50px" }}
                           >
                              <Buttonaction disabled label="Continue to negotiation" blueOcean>
                                 <img src={CaretCircleRightIcon} className="ml-3" />
                              </Buttonaction>
                           </Tooltip>
                        ) : (
                           <Buttonaction label="Continue to negotiation" blueOcean onClick={handleClickNegotiation}>
                              <img src={CaretCircleRightIcon} className="ml-3" />
                           </Buttonaction>
                        )}
                     </Col>
                  </Row>
               </GreyCard>
            </Col>
         </Row>
      </div>
   )
}

export default ContinueNegotiation
