import React, { useImperativeHandle, useState } from "react";
import styled from "styled-components";
import CaretRight from "../assets/images/CaretRight.svg";
import Checks from "../assets/images/Checks.svg";

export default function Stepper({ passedInRef, children, ...rest }) {
  const [complete, setComplete] = useState(false);

  useImperativeHandle(passedInRef, () => ({
    setComplete: () => {
      setComplete(true);
    },
  }));

  return (
    <StepList complete={complete} {...rest}>
      {children}
    </StepList>
  );
}

const StepList = styled.a`
  color: ${({
    active,
    generalInfo,
    companyAddress,
    legalInfo,
    itemList,
    procurementInfo,
    vendorList,
    evaluationScore,
    auctionSession,
    negotiation,
    logReport,
  }) =>
    active.expandGeneral ||
    generalInfo ||
    active.expandAddress ||
    companyAddress ||
    active.expandLegal ||
    legalInfo ||
    active.expandItem ||
    itemList ||
    active.expandProcurement ||
    procurementInfo ||
    active.expandVendor ||
    vendorList ||
    active.expandEvaluation ||
    evaluationScore ||
    active.expandAuction ||
    auctionSession ||
    active.expandNego ||
    negotiation ||
    active.expandLog ||
    logReport
      ? "#04446C"
      : "#909090"};
  display: flex;
  align-items: center;
  font-size: 12px;
  margin: 15px 32px 20px;
  width: 172px;
  position: relative;

  &:hover {
    color: #04446c;
  }

  ${({
    active,
    generalInfo,
    companyAddress,
    legalInfo,
    itemList,
    procurementInfo,
    vendorList,
    evaluationScore,
    auctionSession,
    negotiation,
    logReport,
  }) =>
    generalInfo ||
    companyAddress ||
    legalInfo ||
    itemList ||
    procurementInfo ||
    vendorList ||
    evaluationScore ||
    auctionSession ||
    negotiation ||
    logReport
      ? `
    font-weight: 500;

    &::before {
      content: url(${Checks});
      position: absolute;
      left: -30px;
    }
    `
      : active.expandGeneral ||
        active.expandAddress ||
        active.expandLegal ||
        active.expandItem ||
        active.expandProcurement ||
        active.expandVendor ||
        active.expandEvaluation ||
        active.expandAuction ||
        active.expandNego ||
        active.expandLog
      ? `
        font-weight: 700;
        text-decoration: underline;
  
        &::before {
          content: url(${CaretRight});
          position: absolute;
          left: -25px;
          top: 2px
        }
          `
      : ""}
`;
