import { dispatch } from "react-hot-toast"
import Apiservice from "../../services/Apiservice"
import { setAppLoading } from "./appAction"

export function createSession(body, callback) {
   const { procurementId, startDate, endDate } = body

   return async (dispatch) => {
      const resp = await Apiservice.invokePost(
         `${Apiservice.url.AUCTION_URL}/api/secured/negotiation-batch/start-negotiation`,
         { procurementId, startDate, endDate },
      )

      if (resp.status === 200) {
         if (callback) {
            callback(resp.data.data)
         }
      }
   }
}

export function getNegotiationBatch(batchId, callback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokeGet(`${Apiservice.url.AUCTION_URL}/api/secured/negotiation-batch/${batchId}`)

      if (resp.status === 200) {
         await dispatch({
            type: "GET_NEGOTIATION_BATCH",
            payload: resp.data.data,
         })

         if (callback) {
            callback(resp.data.data)
         }
      }
   }
}

export function fetchAllNegotiationBatch(procurementId, callback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokeGet(
         `${Apiservice.url.AUCTION_URL}/api/secured/negotiation-batch/${procurementId}/by-procurement`,
      )

      if (resp.status === 200) {
         await dispatch({
            type: "FETCH_NEGOTIATION_BATCHES",
            payload: resp.data.data,
         })

         if (callback) {
            callback(resp.data.data)
         }
      }
   }
}

export function fetchAllVendorScores(batchId, callback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokeGet(
         `${Apiservice.url.AUCTION_URL}/api/secured/negotiation/find-by-batch/${batchId}`,
      )

      if (resp.status === 200) {
         dispatch({
            type: "FETCH_VENDOR_SCORES",
            payload: resp.data.data,
         })

         if (callback) {
            callback(resp.data.data)
         }
      }
   }
}

export function setNegotiationSchedulePerVendor(payload, callback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokePost(
         `${Apiservice.url.AUCTION_URL}/api/secured/negotiation/submit-schedule`,
         payload,
      )

      if (resp.status === 200) {
         console.log(resp)

         if (callback) {
            callback(resp.data.data)
         }
      }
   }
}

export function setFinalNegotiationPricePerVendor(payload, callback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokePost(
         `${Apiservice.url.AUCTION_URL}/api/secured/negotiation/submit-price`,
         payload,
      )

      if (resp.status === 200) {
         console.log(resp)

         if (callback) {
            callback(resp.data.data)
         }
      }
   }
}

export function getStatus(payload, callback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokePost(
         `${Apiservice.url.AUCTION_URL}/api/secured/negotiation-batch/get-status`,
         { ...payload },
      )

      if (resp.status === 200) {
         dispatch({
            type: "GET_NEGOTIATION_STATUS",
            payload: resp.data.data,
         })

         if (callback) {
            callback(resp.data.data)
         }
      }
   }
}

export function updateStatus(payload, callback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokePost(
         `${Apiservice.url.AUCTION_URL}/api/secured/negotiation-batch/change-status`,
         payload,
      )

      if (resp.status === 200) {
         if (callback) {
            callback(resp.data.data)
         }
      }
   }
}

export function fetchProcurementVendorItems(vendorId, callback, errCallback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokeGet(
         `${Apiservice.url.AUCTION_URL}/api/secured/procurement-vendor-item/${vendorId}/by-vendor`,
      )

      if (resp.status === 200) {
         dispatch({
            type: "GET_PROCUREMENT_VENDOR_ITEMS",
            payload: resp.data.data,
         })
         if (callback) {
            callback(resp.data.data)
         }
      } else {
         errCallback()
      }
   }
}

export function fetchByVendorAndItem(procVendorId, ItemIds, callback, errCallback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokePost(
         `${Apiservice.url.AUCTION_URL}/api/secured/procurement-vendor-item//find-by-vendor-and-item`,
         { procVendorId: procVendorId, itemIds: ItemIds },
      )

      if (resp.status === 200) {
         dispatch({
            type: "GET_PROCUREMENT_VENDOR_ITEMS",
            payload: resp.data.data,
         })
         if (callback) {
            callback(resp.data.data)
         }
      } else {
         errCallback()
      }
   }
}

export function getDataByNegotiationId(negotiationId, callback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokeGet(
         `${Apiservice.url.AUCTION_URL}/api/secured/negotiation-item/find-by-negotiation/${negotiationId}`,
      )

      if (resp.status === 200) {
         dispatch({
            type: "GET_NEGOTIATION_ITEMS",
            payload: resp.data.data,
         })

         if (callback) {
            callback(resp.data.data)
         }
      }
   }
}

export function fetchNegotiation(callback) {
   return async (dispatch) => {
      const resp = await Apiservice.invokeGet(`${Apiservice.url.AUCTION_URL}/api/secured/negotiation`)

      if (resp.status === 200) {
         callback(resp.data.data)
      }
   }
}

export function submitNegoTotalByUnit(payload, callback) {
   return async () => {
      const resp = await Apiservice.invokePost(
         `${Apiservice.url.AUCTION_URL}/api/secured/negotiation-item/submit-price`,
         payload,
      )

      if (resp.status === 200) {
         if (callback) {
            callback(resp.data.data)
         }
      }
   }
}

export function getBatchId(batchId, callback) {
   return async () => {
      const resp = await Apiservice.invokeGet(
         `${Apiservice.url.AUCTION_URL}/api/secured/negotiation-batch-item/find-by-negotiation-batch/${batchId}`,
      )

      if (resp.status === 200) {
         if (callback) {
            callback(resp.data.data)
         }
      }
   }
}

export function changeStatus(negotiation, status, callback) {
   return async () => {
      const resp = await Apiservice.invokePost(`${Apiservice.url.AUCTION_URL}/api/secured/negotiation/change-status`, {
         negotiation: negotiation,
         status: status,
      })

      if (resp.status == 200) {
         dispatch({
            type: "GET_NEGOTIATION",
            payload: resp.data.data,
         })

         if (callback) {
            callback(resp.data.data)
         }
      }
   }
}

export function findById(negoId, callback) {
   return async () => {
      const resp = await Apiservice.invokeGet(`${Apiservice.url.AUCTION_URL}/api/secured/negotiation/${negoId}`)
      if (resp.status == 200) {
         dispatch({
            type: "GET_NEGOTIATION",
            payload: resp.data.data,
         })

         if (callback) {
            callback(resp.data.data)
         }
      }
   }
}
