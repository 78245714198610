import React, { useEffect, useState } from "react";
import { getProcurement } from "../../../redux/action/procurementAction";
import { connect, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Col, InputGroup, Row } from "react-bootstrap";
import Pagetitle from "../../../components/Pagetitle";
import Chip from "../../../components/Chip";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { GreyCard } from "../../../components/styled/Generalcard";
import { SelectPicker } from "rsuite";
import Datatable from "../../../components/Datatable";
import Dateinfo from "../../../components/Dateinfo";
import IconTrophy from "../../../assets/images/Icon-Trophy.svg";
import CurrencyInput from "../../../components/CurrencyInput";
import Buttonaction from "../../../components/Buttonaction";

const NegotiationUnitPrice = ({ dataProcurement }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { procurementId } = useParams();

  useEffect(() => {
    if (procurementId) {
      dispatch(
        getProcurement({ procurementId }, (res) => {
          console.log(res);
        })
      );
    }
  }, []);

  const [negotiationDataList, setNegotiationDataList] = useState([
    {
      itemCode: "L001",
      itemName: "Laptop Toshiba Satelite C800",
      neededQuantity: "999",
      initialPrice: "996.960.000.00 IDR",
      finalNegotiationPrice: 0,
      subtotal: "996.960.000.000.00 IDR",
    },
  ]);

  const columns = [
    {
      name: "Item Code",
      sortable: true,
      grow: 1,
      cell: (row) => (
        <div className="d-flex align-items-center">
          <span>{row.itemCode}</span>
        </div>
      ),
    },
    {
      name: "Item Name",
      sortable: true,
      grow: 3,
      cell: (row) => (
        <div
          className="d-flex align-items-center"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {row.itemName}
        </div>
      ),
    },
    {
      name: "Needed Quantity",
      sortable: true,
      grow: 2,
      cell: (row) => (
        <div className="d-flex align-items-center">{row.neededQuantity}</div>
      ),
    },
    {
      name: "Initial Price",
      sortable: true,
      grow: 1.3,
      cell: (row) => (
        <div className="d-flex align-items-center justify-content-between w-100">
          {row.initialPrice}
        </div>
      ),
    },
    {
      name: "Final Negotiation Price",
      sortable: true,
      grow: 2,
      cell: (row) => (
        <div className="d-flex align-items-center">
          {!row.finalNegotiationPrice ? (
            <>
              <InputGroup className="mr-2">
                <CurrencyInput
                  style={{ height: "34px" }}
                  placeholder="0.00"
                  // value={finalOffer - autoReducer}
                  // onValueChange={(v) => {
                  //   newNegotiationDataList = new Array(negotiationDataList);
                  //   newNegotiationDataList[index].finalNegotiationPrice = v;
                  // }}
                  className="form-control text-right"
                />
                <InputGroup.Text style={{ height: "34px" }}>
                  IDR
                </InputGroup.Text>
              </InputGroup>
            </>
          ) : (
            <div className="d-flex align-items-center">
              {row.finalNegotiationPrice}
            </div>
          )}
        </div>
      ),
    },
    {
      name: "Subtotal",
      sortable: true,
      grow: 2,
      cell: (row) => (
        <div className="d-flex align-items-center">
          {/* <Statuslabel className="m-0" label={row.status} status={row.status} /> */}
          {row.subtotal}
        </div>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col lg={12}>
          <>
            <Row>
              <Col>
                <Breadcrumbs
                  data={["Dashboard", "Procurement", "Initiation"]}
                />
              </Col>
            </Row>

            <Row>
              <Col className="mb-4">
                <Pagetitle
                  title={dataProcurement?.procurementNumber}
                  onBackButtonClicked={() =>
                    history.push(`/initiation/form/${procurementId}`)
                  }
                  withBackButton={true}
                  lcomp={
                    <Chip kind={dataProcurement?.status.toLowerCase()}>
                      <p>
                        {dataProcurement?.status.charAt(0) +
                          dataProcurement?.status.slice(1).toLowerCase()}
                      </p>
                    </Chip>
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col className="mb-5">
                <GreyCard>
                  <Row>
                    <Col className="d-flex justify-content-between align-items-center px-4 mt-3 mb-1">
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#454545",
                        }}
                      >
                        Information
                      </p>
                    </Col>
                  </Row>

                  <hr style={{ background: "#C4C4C4" }} />

                  <Row className="d-flex align-items-center mb-3 px-4">
                    <Col lg={2}>Procurement Name</Col>
                    <Col>{dataProcurement?.procurementName}</Col>
                  </Row>

                  <Row className="d-flex align-items-center mb-3 px-4">
                    <Col lg={2}>Vendor</Col>
                    <Col>PT Agung Sejahtera</Col>
                  </Row>
                </GreyCard>
              </Col>
            </Row>

            <Row>
              <Col className="mb-5">
                <GreyCard style={{ paddingBottom: "34px" }}>
                  <Row>
                    <Col className="d-flex justify-content-between align-items-center px-4 mt-3">
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                          color: "#454545",
                        }}
                      >
                        Bidding History
                      </p>
                    </Col>
                  </Row>
                  <hr style={{ background: "#C4C4C4" }} />

                  <Datatable.Table
                    data={negotiationDataList}
                    columns={columns}
                    showHeader={true}
                    // style={{ width: "100%" }}
                    customStyles={{
                      // table: {
                      //   style: {
                      //     minWidth: "100vw",
                      //     maxWidth: "150vw",
                      //   },
                      // },
                      headRow: {
                        style: {
                          background: "#D0D6E0",
                        },
                      },
                      rows: {
                        style: {
                          cursor: "pointer",
                          background: "#F3F5F4",
                          "&:hover": {
                            background: "white",
                            borderBottom: "none",
                            ".rdt_TableCell": {
                              background: "white",
                            },
                          },
                          width: "100%",
                        },
                      },
                      cells: {
                        style: {
                          background: "#f3f5f4",
                          "&:hover": {
                            background: "white",
                          },
                        },
                      },
                    }}
                  />
                </GreyCard>
              </Col>
            </Row>

            <Row>
              <Col className="d-flex justify-content-center mb-5">
                <Buttonaction
                  borderblueOcean
                  label="Back to List"
                  style={{ marginRight: "24px" }}
                  onClick={() =>
                    history.push(`/initiation/form/${procurementId}`)
                  }
                />
                <Buttonaction blueOcean label="Save price" />
              </Col>
            </Row>
          </>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => ({
  dataProcurement: state.procurementReducer.dataProcurement,
});

export default connect(mapStateToProps)(NegotiationUnitPrice);
