import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Col, Row, InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import toast from "react-hot-toast";

import Datatable from "../../../../../components/Datatable";
import Buttonaction from "../../../../../components/Buttonaction";
import ModalForm from "../../../../../components/Modalform";
import CurrencyInput from "../../../../../components/CurrencyInput";
import Formservice from "../../../../../services/Formservice";
import { GreyCard } from "../../../../../components/styled/Generalcard";

import {
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableFooter,
  TablePagination,
  TableRow,
} from "@mui/material";
import {
  fetchAllVendorScores,
  setFinalNegotiationPricePerVendor,
  submitNegoTotalByUnit,
} from "../../../../../redux/action/negotiationAction";
import MuiDataTable from "../../../../../components/MuiDataTable";
import { login } from "../../../../../redux/action/authAction";
// import SubmitModal from "../../../../../components/Submitmodal";

const NegoUnitPrice = ({
  passedInRef,
  dataProcurement,
  vendorItems,
  setVendorItems,
  procurementVendorData,
  setNegotiationDataList,
  fetchVendorScores
}) => {
  const dispatch = useDispatch();
  const { batchId } = useParams();
  // const confirmModalRef = useRef();
  const [dataState, setDataState] = useState({
    page: 0,
    limit: 10,
    query: "",
    selectedRows: [],
  });
  const [grandTotal, setGrandTotal] = useState(0);

  const handleSavePrice = () => {
    const vendorLastPrice = procurementVendorData.lastPrice;
    
    if (grandTotal < vendorLastPrice || vendorLastPrice === 0) {
      const newVendorItems = [];

      for (const item of vendorItems) {
        const newObj = { ...item, finalPrice: item.finalPrice };
        delete newObj.newFinalPrice;
        delete newObj.newSubTotal;

        newVendorItems.push(newObj);
      }

      dispatch(
        submitNegoTotalByUnit(newVendorItems, (res) => {
          passedInRef.current.hide();
          dispatch(
            fetchAllVendorScores(batchId, (res) => {
              setNegotiationDataList(res);
              fetchVendorScores()
            })
          );
        })
      );

      // dispatch(
      //   setFinalNegotiationPricePerVendor(newProcurementVendorData, (res) => {
      //     // when negotiation price submitted then:
      //     passedInRef.current.hide();
      //     dispatch(fetchAllVendorScores(batchId));
      //   })
      // );
    } else {
      toast.error("Final price must be lower than last action price");
    }
  };
  const columnTitle = [
    "Item Code",
    "Item Name",
    "Needed Quantity",
    "Last Price",
    "Final Negotiation Price",
    "Subtotal",
  ];

  const handleGrandtotal = () => {
    let grandTotal = 0;
    for (const item of vendorItems) {
      if (item.newSubtotal) {
        grandTotal += item.newSubtotal;
      } else {
        grandTotal += item.subTotal;
      }
    }
    setGrandTotal(grandTotal);
  };

  useEffect(() => {
    handleGrandtotal();
  }, [vendorItems]);

  return (
    <>
      <ModalForm passedInRef={passedInRef} title="Input Negotiation Price">
        <div style={{ overflow: "auto", maxHeight: "70vh" }}>
          <GreyCardStyled>
            <p
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                color: "#454545",
              }}
            >
              Information
            </p>
            <hr style={{ background: "#C4C4C4" }} />

            <Row style={{ padding: "0 2px" }}>
              <Col lg={3}>
                <p style={{ marginBottom: "20px" }}>Procurement Name</p>
                <p>Vendor</p>
              </Col>
              <Col>
                <p className="mt-0" style={{ marginBottom: "20px" }}>
                  {dataProcurement.procurementName}
                </p>
                <p className="m-0">{procurementVendorData.vendorName}</p>
              </Col>
            </Row>
          </GreyCardStyled>

          <GreyCardStyled>
            <p
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                color: "#454545",
              }}
            >
              Item List
            </p>
            <hr style={{ background: "#C4C4C4" }} />

            <MuiDataTable.CustomTable
              withGrandTotal={true}
              grandTotal={
                // vendorItems.length > 0 && handleGrandtotal()
                grandTotal
              }
            >
              <TableHead>
                <TableRow>
                  {columnTitle.map((title, index) => (
                    <TableCell
                      className="py-2"
                      key={index}
                      align={index == 3 || index == 5 ? "right" : "left"}
                      sx={{
                        overflow: "auto",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {vendorItems.map((item, index) => (
                  <TableRow key={index} sx={{ background: "#F3F5F4" }}>
                    <TableCell className="py-2">
                      <div className="d-flex align-items-center">
                        <a
                          // href="#"
                          style={{
                            color: "#454545",
                            fontWeight: "500",
                            textDecoration: "underline",
                          }}
                          onClick={() =>
                            history.push(
                              `/initiation/${procurementId}/vendor/auction/history`
                            )
                          }
                        >
                          {item.itemCode}
                        </a>
                      </div>
                    </TableCell>
                    <TableCell
                      className="py-2"
                      sx={{ overflow: "auto", whiteSpace: "nowrap" }}
                    >
                      {item.itemName}
                    </TableCell>
                    <TableCell className="py-2">{item.qty}</TableCell>
                    <TableCell
                      className="py-2"
                      align="right"
                      sx={{ overflow: "auto", whiteSpace: "nowrap" }}
                    >
                      {" "}
                      {Formservice.CurrencyFormatter(item.lastPrice)}
                    </TableCell>
                    <TableCell className="py-2" sx={{ minWidth: "300px" }}>
                      <>
                        <Row>
                          <Col lg={9}>
                            <InputGroup className="mr-2">
                              <CurrencyInput
                                style={{ height: "34px" }}
                                placeholder="0"
                                onValueChange={(v) => {
                                  const newVendorItems = [...vendorItems];
                                  newVendorItems[index] = {
                                    ...newVendorItems[index],
                                    finalPrice: parseInt(v),
                                    newSubtotal: parseInt(v) * item.qty,
                                  };
                                  setVendorItems(newVendorItems);
                                }}
                                className="form-control text-right"
                              />
                              <InputGroup.Text style={{ height: "34px" }}>
                                IDR
                              </InputGroup.Text>
                            </InputGroup>
                          </Col>
                        </Row>
                        {/* )} */}
                      </>
                    </TableCell>
                    <TableCell
                      className="py-2"
                      align="right"
                      sx={{ overflow: "auto", whiteSpace: "nowrap" }}
                    >
                      <div>
                        {item.newSubtotal
                          ? Formservice.CurrencyFormatter(item.newSubtotal)
                          : Formservice.CurrencyFormatter(item.subTotal)}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </MuiDataTable.CustomTable>
          </GreyCardStyled>

          <div
            className="d-flex justify-content-center"
            style={{ columnGap: "24px", marginBottom: "32px" }}
          >
            <Buttonaction
              borderblueOcean
              label="Back to List"
              onClick={() => passedInRef.current.hide()}
            />
            <Buttonaction
              blueOcean
              label="Save Price"
              onClick={handleSavePrice}
            />
          </div>
        </div>
      </ModalForm>

      {/* <SubmitModal
        passedInRef={confirmModalRef}
        title="Are you sure to submit this data?"
        description="If you submit, the data you made before cannot be change."
      >
        <div>
          <Buttonaction blueOcean label="Submit"/>
          <Buttonaction label="Cancel"/>
        </div>
      </SubmitModal> */}
    </>
  );
};

export default NegoUnitPrice;

const GreyCardStyled = styled(GreyCard)`
  margin-right: 24px;
  margin-left: 24px;
  margin-bottom: 32px;
`;
