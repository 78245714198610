import axios from "axios";
import toast from "react-hot-toast"

const initAxiosConfig = () => {
    console.log("INIT AXIOS CONFIG");
    axios.defaults.validateStatus = (status) => {
        return status;
    }

    // axios middleware
    axios.interceptors.request.use(function(config){
        return config;
    })
    
    axios.interceptors.response.use(function(response){
        if(response.status === 401){
            toast.error("Session Timeout");
            localStorage.removeItem("access_token")
            localStorage.removeItem("refresh_token")
            location.href = "/buyer-login"
        }
        return response;
    }, function(err){
        if(err.response.status === 401){
            localStorage.removeItem("access_token")
            localStorage.removeItem("refresh_token")
            location.href = "/buyer-login"
        }
    });
}

export default {
    initAxiosConfig
}